import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Context } from 'store';
import { axiosServiceInstance } from 'config/axiosConfig';
import { Select, Checkbox, Typography } from '@material-ui/core';
import Moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import getColumns from './getColumns.js';
import RequestDeletionButton from './RequestDeletionButton.js';
import RequestDeletion from 'views/RequestDeletion.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';
import MyDataDetails from 'components/data/MyDataDetails.js';
import { makeStyles } from '@material-ui/core/styles';
import Search from 'components/dashboard/Search';
import RequestDeletionMultiple from 'views/RequestDeletionMultiple.js';
import CommonDataGrid from './CommonDataGrid.js';
import tw from 'twin.macro';
import SearchBar from 'material-ui-search-bar';
import { useCallback } from 'react';
import { PreDestroy } from 'ag-grid-community';
import { grid } from '@mui/system';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const Wrapper = tw.div``;

const AllTileDataTable = ({ Tile, stateData, getDomains }) => {
  const classes = useStyles();
  const history = useHistory();
  const [nosearchresult, setnosearchresult] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedDatum, setSelectedDatum] = useState(null);
  const [markNeededDataList, setMarkNeededDataList] = useState([]);
  const [markDSRDataList, setMarkDSRDataList] = useState([]);
  const [checkedN, setCheckedN] = useState(true);
  const [checkedR, setCheckedR] = useState(false);
  const [initialList, setInitialList] = useState([]);
  const [enableCheckbox, setEnableCheckbox] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [countOf, setCountOf] = useState([]);
  const [tableMsg, setTableMsg] = useState(true);
  const [timeFilter, setTimeFilter] = useState('');
  const [tableLoader, setTableLoader] = useState(true);
  const [dsrPopup, setDsrPopup] = useState(false);
  const [dsrSinglePopup, setDsrSinglePopup] = useState(false);
  const [getState, setState] = useState({});
  const [getAllDomainLastKey, setAllDomainLastKey] = useState(null);
  const [getRecState, setRecState] = useState([]);
  const [SearchErrMsg, setSearchErrMsg] = useState(false);
  const [searchdomain, setSearchdomain] = useState(false);
  const [searchdomainval, setSearchdomainval] = useState('');
  const [displaySearchIcon, setdisplaySearchIcon] = useState(true);
  const [getSelectedRow, setSelectedRow] = useState([]); //
  const [getCountDiff, setCountDiff] = useState([]);
  const [isDataFetchAll, setDataFetchAll] = useState(false);
  const [isDataFetch, setDataFetch] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const [oldDataLength, setOldDataLength] = useState(0);
  const [isAPICall, setisAPICall] = useState(false);
  const [startRow, setStartRow] = useState(0);
  const [endRow, setLastRow] = useState(100);

  let pendingCount =
    (((stateData || []).myData || []).neededDomain || 0) +
    (((stateData || []).myData || []).pendingDomain || 0);
  let submittedCount =
    (((stateData || []).myData || []).processingDomain || 0) +
    (((stateData || []).myData || []).failedDomain || 0) +
    (((stateData || []).myData || []).initiatedDomain || 0);
  let completedCount = ((stateData || []).myData || []).reclaimedDomain || 0;

  let Pendingdomainlist = [];
  let MarkNeededDomainList = [];
  let DSRdataList = [];
  let searchValue = '';
  let isConcat = false;
  let isNullKey = false;
  const gridRef = useRef();
  useEffect(() => {
    if (!isLoading && state.user.username) {
      dispatch({
        type: 'Pending_TILE_DATA',
        payload: [],
      });
      Pendingdomainlist = [];
      setTableLoader(true);
      setLoading(true);
      _getConnectionData();
    }
    if (Tile !== 'pending') {
      setCheckedN('');
      setCheckedR('');
      setTimeFilter('');
    }
    if ((state.accSwitch || state.accRefresh) === true) {
      setSearchErrMsg(false);
      dispatch({
        type: 'Pending_TILE_DATA',
        payload: [],
      });
      dispatch({
        type: 'Selected_Row_Count',
        payload: 0,
      });
      dispatch({
        type: 'AG_DATA_LIST',
        payload: [],
      });
      setSelectedRow([]);
      Pendingdomainlist = [];
      setTimeFilter('');
      setEnableCheckbox(false);
      setCheckedN(true);
      setCheckedR(false);
      setSearchdomainval('');
      setTableLoader(true);
      isConcat = false;
      _getMyData(false, true, state.dashboardData.dropDownData, '');
      dispatch({
        type: 'ACC_Refresh',
        payload: false,
      });
      dispatch({
        type: 'ACC_Switch',
        payload: false,
      });
    }

    if (state.reloadDomainTable === true) {
      setSearchErrMsg(false);
      setSearchdomainval('');
      dispatch({
        type: 'Pending_TILE_DATA',
        payload: [],
      });
      Pendingdomainlist = [];
      if (Tile !== 'pending') {
        setCheckedN('');
        setCheckedR('');
        setTimeFilter('');
        setSearchdomainval('');
      }
      searchValue = '';
      setTableLoader(true);
      setTableMsg(true);
      setnosearchresult(false);
      isConcat = false;
      _CountOfAllDomainData(state.dashboardData.dropDownData);
      _getMyData(
        checkedR,
        checkedN,
        state.dashboardData.dropDownData,
        timeFilter,
        ''
      );
      dispatch({
        type: 'Reload_Domain_Table',
        payload: false,
      });
    }
    if (
      JSON.stringify(countOf) !== JSON.stringify((stateData || []).myData || [])
    ) {
      setCountOf((stateData || []).myData || []);
    }
    if (isAPICall && getAllDomainLastKey) {
      dispatch({
        type: 'Pending_TILE_DATA',
        payload: [],
      });
      NextSearchResultDomain(getAllDomainLastKey);
    }
  }, [state, stateData, isAPICall]);

  //--------------------Domain List API(_getMydata)-------------------------------------
  async function _getConnectionData() {
    setAllDomainLastKey(null);
    setCountOf((stateData || []).myData || []);
    _CountOfAllDomainData(stateData.dropDownData || []);
    _getMyData(false, true, stateData.dropDownData || [], '');
  }
  async function _CountOfAllDomainData(dropDownData) {
    var dropdownEmailId = localStorage.getItem('updatedEmailId');
    if (
      JSON.stringify(countOf) !== JSON.stringify((stateData || []).myData || [])
    ) {
      setCountOf((stateData || []).myData || []);
    }
    dispatch({
      type: 'DASHBOARD_DATA',
      payload: {
        myData: (stateData || []).myData || [],
        dropDownData: dropDownData === undefined ? [] : dropDownData,
        emailId:
          dropdownEmailId === null ||
            dropdownEmailId === 0 ||
            dropdownEmailId === undefined
            ? dropDownData[0].emailId
            : dropdownEmailId,
      },
    });
  }
  //Function for fetching data list v2 API
  async function _getMyData(
    RecommendedCheckbox = false,
    HideNeededCheckbox = true,
    dropDownData,
    TimeFilter = '',
    SearchDomainValue = '',
    Searchlastkey = null
  ) {
    try {
      var dropdownEmailId = localStorage.getItem('updatedEmailId');
      setInitialList([]);
      const selectedData = markNeededDataList;
      isConcat = Searchlastkey === null ? false : true;
      ///Get Domain api ends here
      const myDataList = await axiosServiceInstance
        // .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
        .post(`/pieeye-pfmdomain/api/dashboard/_get/v2`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            tile: Tile, //'pending',"initiated","reclaimed",
            emailId:
              dropdownEmailId === null ||
                dropdownEmailId === 0 ||
                dropdownEmailId === undefined
                ? dropDownData[0].emailId
                : dropdownEmailId,
            showRecommendedDomain: RecommendedCheckbox,
            hideNeededDomain: HideNeededCheckbox,
            domainName: SearchDomainValue,
            monthFlag: TimeFilter,
            parsedEmailDataId: Searchlastkey || null,
          },
        })
        .catch((error) => {
          setTableLoader((error || []).data.lastKey !== null ? true : false);
          if (
            (error || []).data.lastKey !== null &&
            (error || []).data.lastKey !== undefined
          ) {
            isNullKey = true;
            setAllDomainLastKey(
              ((error || []).data || []).lastKey === null
                ? ''
                : ((error || []).data || []).lastKey
            );
            // NextSearchResultDomain((error || []).data.lastKey);
            setisAPICall(true);
          } else if (
            (error || []).data.errors[0].message === 'Data not found' ||
            (error || []).data.errors[0].message === 'Please provide status'
          ) {
            setTableLoader((error || []).data.lastKey !== null ? true : false);
            setAllDomainLastKey(
              (error || []).data.lastKey !== null
                ? null
                : ((error || []).data || []).lastKey
            );
            setLoading(true);
            setState([]);
          } else {
            isNullKey = false;
            setAllDomainLastKey(null);
          }
          setLoading(true);
          setState([]);
        });

      if (myDataList !== undefined) {
        if (
          ((myDataList || []).data || []).lastKey !== null &&
          ((myDataList || []).data || []).lastKey !== undefined
        ) {
          // Store the last key in the redux state / localstorage
          isNullKey = true;
          dispatch({
            type: 'AllDomain_Last_Key',
            payload:
              ((myDataList || []).data || []).lastKey === null
                ? ''
                : ((myDataList || []).data || []).lastKey,
          });
          setAllDomainLastKey(
            ((myDataList || []).data || []).lastKey === null
              ? ''
              : ((myDataList || []).data || []).lastKey
          );
        } else {
          isNullKey = false;
          setAllDomainLastKey(null);
        }
      }

      dispatch({
        type: 'MY_DATA',
        payload:
          myDataList === undefined
            ? []
            : ((myDataList || []).data || []).responseListObject || [],
      });

      setState(
        myDataList === undefined
          ? []
          : ((myDataList || []).data || []).responseListObject || []
      );

      const myRecomendedDataList = [];
      if (
        ((myDataList || []).data || []).responseListObject !== undefined &&
        ((myDataList || []).data || []).responseListObject.length >= 1
      ) {
        var PendingAndRecDomains = (
          ((myRecomendedDataList || []).data || []).responseListObject || []
        ).concat(((myDataList || []).data || []).responseListObject);
        PendingAndRecDomains =
          Tile !== 'pending'
            ? PendingAndRecDomains.sort((a, b) =>
              a.statusDate < b.statusDate ? 1 : -1
            )
            : PendingAndRecDomains;
        PendingAndRecDomains.map((datum, index) => {
          if (datum !== undefined) {
            var formateddate = Moment(datum.lastEmailDate).format('MM/DD/YYYY');
            var formatedFirstdate = Moment(datum.firstEmailDate).format(
              'MM/DD/YYYY'
            );
            var requestedDate = Moment(datum.statusDate).format('MM/DD/YYYY');

            var element = {};

            element.id = datum.domainName;
            element.domainLogo = datum.domainLogo;
            element.domainName = datum.domainName;
            element.lastEmailDate = new Date(formateddate).toLocaleDateString(
              'en-US',
              {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              }
            );

            if (element.lastEmailDate === 'Invalid Date') {
              const date = formateddate;
              const [day, month, year] = date.split('/');
              element.lastEmailDate = `${month}/${day}/${year}`;

              element.lastEmailDate = new Date(
                element.lastEmailDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            element.dsrContactEmail = datum.dsrContactEmail;
            element.firstEmailDate = new Date(
              formatedFirstdate
            ).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            });

            if (element.firstEmailDate === 'Invalid Date') {
              const date = formatedFirstdate;
              const [day, month, year] = date.split('/');
              element.firstEmailDate = `${month}/${day}/${year}`;
              element.firstEmailDate = new Date(
                element.firstEmailDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            element.emailAddress = datum.emailId;
            element.name = datum.name;
            element.noOfEmails = datum.noOfEmails;
            element.dsrRecommendation = datum.dsrRecommendation;
            element.statusDate =
              Tile === 'pending'
                ? '-'
                : new Date(requestedDate).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                });

            if (element.statusDate === 'Invalid Date') {
              const date = requestedDate;
              const [day, month, year] = date.split('/');
              element.statusDate = `${month}/${day}/${year}`;
              element.statusDate = new Date(
                element.statusDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            element.isSwitch = false;
            element.isLogo = true;
            element.domainUrl = datum.domainUrl;
            element.status =
              datum.dsrRecommendation === 1 && datum.status === 'pending'
                ? 'Recommended'
                : datum.dsrRecommendation === 0 && datum.status === 'pending'
                  ? '-'
                  : datum.status === 'needed'
                    ? 'Needed'
                    : datum.status === 'initiated'
                      ? 'Submitted'
                      : datum.status === 'processing'
                        ? 'Processing'
                        : datum.status === 'failed'
                          ? 'Failed'
                          : datum.status === 'reclaimed'
                            ? 'Completed'
                            : datum.status;
            Pendingdomainlist.push(element);
          }
        });
      }

      if (Tile === 'pending') {
        let RList = Pendingdomainlist.filter(function (e) {
          return e.status === 'Recommended';
        });
        let NList = Pendingdomainlist.filter(function (e) {
          return e.status === 'Needed';
        });
        let PList = Pendingdomainlist.filter(function (e) {
          return e.status === '-';
        });
        Pendingdomainlist = RList.concat(NList);
        Pendingdomainlist = Pendingdomainlist.concat(PList);
      }
      console.log('Concat->', isConcat);
      Pendingdomainlist = isConcat
        ? (state.pendingtiledomainlist || []).concat(Pendingdomainlist)
        : Pendingdomainlist || [];
      //   setRowsData(Pendingdomainlist || []);
      console.log('Array Data List -->', Pendingdomainlist);
      setDataLength(Pendingdomainlist.length);
      dispatch({
        type: 'Pending_TILE_DATA',
        payload: Pendingdomainlist === undefined ? [] : Pendingdomainlist || [],
      });
      isConcat = false;
      setTableMsg(false);
      setTableLoader(isNullKey ? true : false);
      // setTableLoader(false);
      setLoading(true);
      console.log('Concat->', isConcat);
    } catch (e) {
      console.error('Unable to fetch domains:', e);
      setTableMsg(false);
      setTableLoader(isNullKey ? true : false);
      if ((e.response || []).status || '' === 404) {
        setLoading(true);
      }
      setLoading(true);
    }
  }
  async function NextSearchResultDomain(Searchlastkey) {
    setTableLoader(true);
    try {
      _getMyData(
        checkedR,
        checkedN,
        state.dashboardData.dropDownData,
        timeFilter,
        searchdomainval,
        Searchlastkey
      );
      setisAPICall(false);
    } catch (e) {
      console.error('Unable to fetch domains:', e);
      if (e.status === 404) {
        // setLoaded(true);
        //  setError({ ...((e.response || []).status || null) });
      }
    }
  }
  //------------------------------------------------------------------------------------------

  const sortAndFilter = (allOfTheData, sortModel) => {
    return sortData(sortModel, allOfTheData);
  };

  const sortData = (sortModel, data) => {
    const sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    // do an in memory sort of the data, across all the fields
    const resultOfSort = data.slice();
    resultOfSort.sort(function (a, b) {
      for (let k = 0; k < sortModel.length; k++) {
        const sortColModel = sortModel[k];
        let valueA = '';
        let valueB = '';
        if (
          sortColModel.colId === 'firstEmailDate' ||
          sortColModel.colId === 'lastEmailDate' ||
          sortColModel.colId === 'statusDate'
        ) {
          valueA = new Date(a[sortColModel.colId]);
          valueB = new Date(b[sortColModel.colId]);
        } else {
          valueA = a[sortColModel.colId];
          valueB = b[sortColModel.colId];
        }
        // this filter didn't find a difference, move onto the next one
        if (valueA == valueB) {
          continue;
        }
        const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
        if (valueA > valueB) {
          return sortDirection;
        } else {
          return sortDirection * -1;
        }
      }
      // no filters found a difference
      return 0;
    });
    return resultOfSort;
  };
  //let data = [];

  const onGridReady = useCallback(
    (params) => {
      let data = state.pendingtiledomainlist || [];
      let dataList = data;
      let dataSource = {
        rowCount: undefined,

        getRows: (params) => {
          //console.log('asking for ' + params.startRow + ' to ' + params.endRow);
          if (
            dataLength >= params.startRow &&
            dataLength <= params.endRow &&
            getAllDomainLastKey !== (null || undefined)
          ) {
            isConcat = true;
            setisAPICall(true);
          }
          // At this point in your code, you would call the server.
          // To make the demo look real, wait for 500ms before returning
          setTimeout(function () {
            const dataAfterSortingAndFiltering = sortAndFilter(
              dataList,
              params.sortModel
            );
            // take a slice of the total rows
            const rowsThisPage = (dataAfterSortingAndFiltering || []).slice(
              params.startRow,
              params.endRow
            );
            // if on or after the last page, work out the last row.
            let lastRow = -1;
            if ((dataAfterSortingAndFiltering || []).length <= params.endRow) {
              lastRow = (dataAfterSortingAndFiltering || []).length;
            }
            // call the success callback
            params.successCallback(rowsThisPage, lastRow);
            if (gridRef.current !== null && Tile === 'pending') {
              setDataFetchAll(true);
              gridRef.current.api.forEachNode(function (node) {
                (state.agDataList || []).map((x) => {
                  if (!!node.data && node.data.domainName === x.domainName) {
                    node.setSelected(true);
                  }
                });
              });
              setDataFetch(false);
            }
          }, 2000);
        },
      };
      params.api.setDatasource(dataSource);
    },
    [state.pendingtiledomainlist, isAPICall]
  );
  //--------------------Table Functionality-------------------------------------
  const dataTable = (
    <>
      <CommonDataGrid
        gridRef={gridRef}
        Tile={Tile}
        columnData={getColumns({ setRowsData, Tile: Tile })}
        //tableData={state.pendingtiledomainlist || []}
        onDomainClick={clickOnDomainName}
        onSelectionCheckbox={onSelectionChanged}
        setDataFetchAllFlag={setDataFetchAllFlag}
        setDataFetchFlag={setDataFetchFlag}
        onGridReady={onGridReady}
        deselectAllFlag={deselectAllFlag}
        cacheBlockSize={
          (pendingCount || submittedCount || completedCount) > 500
            ? dataLength - 1
            : dataLength || 100
        }
      />
    </>
  );
  function clickOnDomainName(event) {
    if (event.column.colId === 'domainName') {
      setSelectedDatum(event.data);
    }
  }

  function setDataFetchAllFlag(value) {
    setDataFetchAll(value);
  }
  function setDataFetchFlag(value) {
    setDataFetch(value);
  }
  function deselectAllFlag(value) {
    setSelectedRow(value);
  }
  function _selectionProcess(event, isALL) {
    if (Tile === 'pending') {
      let allDomain = [...getSelectedRow];
      let allSelectedRowStr = JSON.stringify(getSelectedRow);
      DSRdataList = [];
      const selectedRows =
        event.api !== undefined ? event.api.getSelectedRows() : [];
      console.log('selected data -> ', selectedRows);
      DSRdataList = selectedRows;
      setMarkDSRDataList(DSRdataList);
      (DSRdataList || []).forEach((item) => {
        if (!allSelectedRowStr.includes(item.domainLogo)) {
          allDomain.push(item);
        }
      });
      if (event.api !== undefined) {
        event.api.forEachNode(function (node) {
          if (node.isSelected() === false) {
            const logo = (node.data || []).domainLogo;
            allDomain = (allDomain || []).filter((x) => {
              if (x.domainLogo !== logo) {
                return x;
              }
            });
          }
        });
      }
      setSelectedRow(allDomain);
      setMarkNeededDataList(allDomain);
      dispatch({
        type: 'AG_DATA_LIST',
        payload: allDomain || [],
      });
      dispatch({
        type: 'Selected_Row_Count',
        payload: allDomain.length || 0,
      });
    }
  }
  let count = 0;
  function onSelectionChanged(event) {
    count++;
    if (!isDataFetchAll) {
      _selectionProcess(event);
      count = 0;
    } else {
      if (count === 1) {
        setTimeout(() => {
          setDataFetchAll(false);
          _selectionProcess(event);
        }, 3000);
      }
      return null;
    }
  }
  //--------------------Checkbox Functionality------------------------------------------
  function onCheckboxEvent(
    RecommendedCheckbox = false,
    HideNeededCheckbox = true
  ) {
    dispatch({
      type: 'Pending_TILE_DATA',
      payload: [],
    });
    Pendingdomainlist = [];
    isConcat = false;
    setTableLoader(true);
    _getMyData(
      RecommendedCheckbox,
      HideNeededCheckbox,
      state.dashboardData.dropDownData,
      timeFilter,
      searchdomainval
    );
  }
  //--------------------TimeFilter Functionality----------------------------------------
  function onTimeFilterChange(TimeFilterCount) {
    dispatch({
      type: 'Pending_TILE_DATA',
      payload: [],
    });
    Pendingdomainlist = [];
    isConcat = false;
    setTableLoader(true);
    _getMyData(
      checkedR,
      checkedN,
      state.dashboardData.dropDownData,
      TimeFilterCount,
      searchdomainval
    );
    setTimeFilter(TimeFilterCount);
  }
  //--------------------callBack Functions-------------------------------------
  function getDsrPopup(value) {
    setDsrPopup(value);
  }
  function getSingleDsrPopup(value) {
    setDsrSinglePopup(value);
  }
  //--------------------Search Functions-------------------------------------
  function debouncecall(func, delay) {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  const debunceEvent = debouncecall((newValue) => {
    const eve = { key: 't' };
    setSearchdomainval(newValue);
    searchValue = newValue;
    SearchDomain(newValue, eve);
  }, 800);

  function SearchDomain(searchdomainParamVal, e) {
    setdisplaySearchIcon(false);
    searchdomainParamVal = searchdomainParamVal.toLowerCase();

    if (
      searchdomainParamVal === '' ||
      searchdomainParamVal === undefined ||
      (e.keyCode === 8 && searchdomainParamVal === '')
    ) {
      setSearchdomain(false);
      setdisplaySearchIcon(true);
      setSearchErrMsg(false);

      dispatch({
        type: 'Reload_Domain_Table',
        payload: true,
      });
    } else {
      if (searchdomainParamVal.length > 1) {
        setSearchErrMsg(false);
        localStorage.removeItem('PendingDomainList');
        const searchTimeout = setTimeout(() => {
          SearchDomainApi(searchdomainParamVal);
        }, 50);
        setSearchdomain(true);
      } else {
        setSearchdomain(false);
        setSearchErrMsg(true);
      }
    }
  }
  async function SearchDomainApi(searchdomainParamVal) {
    dispatch({
      type: 'Pending_TILE_DATA',
      payload: [],
    });
    Pendingdomainlist = [];
    setTableLoader(true);
    isConcat = false;
    try {
      _getMyData(
        checkedR,
        checkedN,
        state.dashboardData.dropDownData,
        timeFilter,
        searchdomainParamVal
      );
    } catch (e) {
      console.error('Unable to fetch domains:', e);
      if (e.status === 404) {
        // setLoaded(true);
        //  setError({ ...((e.response || []).status || null) });
      }
    }
  }
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Backdrop className={classes.backdrop} open={selectedDatum}>
        {selectedDatum && (
          <MyDataDetails
            data={{ selectedDatum }}
            selectDatum={setSelectedDatum}
            getSingleDsrPopup={getSingleDsrPopup}
          />
        )}
      </Backdrop>
      <Backdrop className={classes.backdrop} open={dsrPopup || dsrSinglePopup}>
        {selectedDatum && (
          <MyDataDetails
            data={{ selectedDatum }}
            selectDatum={setSelectedDatum}
            getSingleDsrPopup={getSingleDsrPopup}
          />
        )}
      </Backdrop>
      <Backdrop className={classes.backdrop} open={dsrPopup || dsrSinglePopup}>
        {dsrPopup && (
          <RequestDeletionMultiple
            getDsrPopup={getDsrPopup}
            deselectAllFlag={deselectAllFlag}
          />
        )}
        {dsrSinglePopup && (
          <RequestDeletion
            getSingleDsrPopup={getSingleDsrPopup}
            deselectAllFlag={deselectAllFlag}
          />
        )}
      </Backdrop>

      <div className="CustomFilter">
        <Wrapper id="SearchWrapper">
          <div id="DomainSearch">
            <SearchBar
              placeholder="Search your domain"
              value={searchdomainval}
              onRequestSearch={(e) => SearchDomain(searchdomainval, e)}
              onChange={(newValue) => {
                debunceEvent(newValue);
              }}
              onCancelSearch={(e) => {
                setSearchdomainval('');
                searchValue = '';
                setSearchdomain(false);
                setSearchErrMsg(false);
                dispatch({
                  type: 'Reload_Domain_Table',
                  payload: true,
                });
                dispatch({
                  type: 'Pending_TILE_DATA',
                  payload: [],
                });
              }}
            />
          </div>
          {SearchErrMsg && !nosearchresult ? (
            <p className="SearchErr">Please enter atleast 2 characters</p>
          ) : (
            ''
          )}
        </Wrapper>

        {Tile === 'pending' ? (
          <div className="DomainTableOuterInput">
            <div className="mr-10">
              <Checkbox
                id="checkedN"
                className="TableFIlter"
                color="#000"
                checked={checkedN}
                disabled={enableCheckbox}
                onClick={(e) => {
                  let CheckedN = e.target.checked;
                  setCheckedN(e.target.checked);
                  onCheckboxEvent(checkedR, CheckedN);
                }}
              />
              <span className="FilterLabel">
                Hide Needed (
                {countOf.neededDomain === undefined ? '' : countOf.neededDomain}
                )
              </span>
              <Checkbox
                id="checkedR"
                className="TableFIlter"
                color="#000"
                checked={checkedR}
                onClick={(e) => {
                  let CheckedR = e.target.checked;
                  setCheckedR(e.target.checked);
                  if (CheckedR === true) {
                    setCheckedN(true);
                  }
                  setEnableCheckbox(e.target.checked);
                  onCheckboxEvent(
                    CheckedR,
                    CheckedR === true ? true : checkedN
                  );
                }}
              />
              <span className="FilterLabel pr-10">
                Show Recommended (
                {countOf.recommendedDsrCount === undefined
                  ? ''
                  : countOf.recommendedDsrCount}
                )
              </span>
              <span className="FilterLabel DomainDropdown">Time Frame</span>
              <Select
                className="TimeFrameOption"
                native
                value={timeFilter || ''}
                onChange={(event) => {
                  let TimeFilterCount = event.target.value;
                  onTimeFilterChange(TimeFilterCount);
                }}
              >
                <option value="">&nbsp;All Domains</option>
                <option value={1}>&nbsp;1 Month</option>
                <option value={6}>&nbsp;6 Months</option>
                <option value={12}>&nbsp;12 Months</option>
              </Select>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {nosearchresult ? (
        <></>
      ) : (
        <div className={SearchErrMsg ? "App1" : "App"}>
          {(state.pendingtiledomainlist || []).length >= 1 && dataTable}
          {!((state.pendingtiledomainlist || []).length >= 1) &&
            (tableLoader ? (
              <center>
                <CircularProgress />
              </center>
            ) : (
              getAllDomainLastKey === null && (
                <div id="noSearchResult">
                  <Typography paragraph>No Domain Found.</Typography>
                </div>
              )
            ))}
        </div>
      )}

      {Tile === 'pending' && (
        <RequestDeletionButton
          getDsrPopup={getDsrPopup}
          DataList={
            markNeededDataList !== []
              ? markNeededDataList
              : MarkNeededDomainList //str
          }
          DSRList={getSelectedRow}
          deselectModal={setMarkNeededDataList}
          gridRef={gridRef}
          deselectAll={setSelectedRow}
        />
      )}
    </>
  );
};

export default AllTileDataTable;
