import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import tw from 'twin.macro';
import { PrimaryButton } from 'components/misc/Buttons';

const CardWrapper = tw.div`flex flex-col h-full bg-gray-200`;
const CardNumber = tw.h1`text-center`;
const CardText = tw.div`flex-1 px-6 py-6`;
const CardTitle = tw.h6`group-hocus:text-primary-500 transition duration-300 `;
const CardDescription = tw.p``;
const ButtonContainer = tw.div`flex justify-center`;
const CardActionButton = tw(PrimaryButton)`my-3 mx-auto`;

export default function Card({
  value,
  header,
  description,
  href,
  buttonText,
  aHeader,
}) {
  const history = useHistory();
  return (
    <CardWrapper
      style={{ cursor: 'pointer' }}
      //className="group group1 ActiveCard"
      className={'group group1 ' + (header === aHeader ? 'ActiveCard' : '')}
      initial="rest"
      whileHover="hover"
      animate="rest"
    >
      <CardText id="CardContent">
        <div>
          <CardNumber id="cardValue">{value}</CardNumber>
        </div>
        <div>
          <CardTitle id="cardHeader">{header}</CardTitle>
          <CardDescription id="cardDesc">{description}</CardDescription>
        </div>
      </CardText>
    </CardWrapper>
  );
}
