import { useContext, useState, useEffect } from 'react';
import { axiosServiceInstance } from 'config/axiosConfig';
import { Context } from 'store';
import {
  Chip,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import ClearIcon from '@material-ui/icons/Clear';
import { Box } from '@material-ui/core';

const rootStyle = {
  // maxWidth: '650px',
};
const clearIconStyle = {
  color: 'black',
  textAlign: 'right',
  marginTop: '-25px',

  marginRight: '5px',
};

export default function DsrDetails({
  dsr,
  userEmailId,
  getModel,
  closeModal,
  status,
}) {
  const [state] = useContext(Context);
  // TODO: Display Errors
  const [errors, setError] = useState(null); //eslint-disable-line no-unused-vars
  const [getState, setState] = useState({});
  const [getRequestedDate, setRequestedDate] = useState(null);
  const [getDomain, setDomain] = useState({
    domainName: '',
    loaded: false,
  });

  const header = dsr ? dsr.name : null;
  const domain = dsr ? dsr.domainName : null;

  const requestedOn = new Date(getRequestedDate).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  async function _getMyDsrs() {
    try {
      const dsrDetails = await axiosServiceInstance
        .post(`/pieeye-pfmdsr/api/dashboard/_getDsrDetails`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            domainName: domain || '',
            emailId: userEmailId || '',
          },
        })
        .catch((error) => {});

      const htmlData =
        (((dsrDetails || []).data || []).responseObject || []).template ||
        '<div></div>';
      const requestedDate =
        (((dsrDetails || []).data || []).responseObject || []).createdDate ||
        '';
      const domainName =
        (((dsrDetails || []).data || []).responseObject || []).domainName || '';

      setRequestedDate(requestedDate);
      setDomain({
        domainName: domainName,
        loaded: true,
      });
      setState(htmlData);
    } catch (e) {
      setDomain({
        domainName: '',
        loaded: true,
      });
      console.error('Unable to fetch domains:', e);

      if ((e.response || []).status || '' === 404) {
        setError({ ...((e.response || []).status || null) });
      }
    }
  }
  useEffect(() => {
    if (domain && domain !== getDomain.domainName) {
      _getMyDsrs();
    }
  });

  return (
    getDomain.domainName && (
      <Card id="DSRCard" style={rootStyle} onClick={(e) => e.stopPropagation()}>
        <CardHeader title={header} />
        <Box style={clearIconStyle}>
          <ClearIcon
            onClick={() => {
              closeModal(null);
            }}
          />
        </Box>
        <CardContent>
          <Typography id="DataDomain" variant="subtitle1" color="textSecondary">
            {getDomain.domainName}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            <b>Requested on :</b>
            {requestedOn}
          </Typography>
          <Typography variant="subtitle1">
            <b> Status:</b>{' '}
            <Chip
              color="primary"
              size="small"
              label={status === 'reclaimed' ? 'completed' : status}
            />
          </Typography>
          {ReactHtmlParser(getState)}
        </CardContent>
      </Card>
    )
  );
}
