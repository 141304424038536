import { Widget } from '@typeform/embed-react';
//import { PopupButton } from '@typeform/embed-react'
//import { Sidetab } from '@typeform/embed-react'

const FeedbackTypeform = () => {
  return (
    <Widget
      id="YCqGQCD9"
      style={{ width: '100%', height: '550px' }}
      className="my-button"
    />
  );

  //return <Sidetab id="YCqGQCD9" hidden={{ utm_source: 'pfm',utm_medium: 'website',utm_content: 'content1',utm_campaign: 'refer a friend' ,}} buttonText="click to open" />
};

export default FeedbackTypeform;
