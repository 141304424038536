import { useContext, useEffect, useState } from 'react';
import {
  Typography,
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { Context } from '../store';
import SmallPageWrapper from '../components/wrapper/SmallPageWrapper';
import createUUID from '../util/uuid';
import { PrimaryButton } from 'components/misc/Buttons';
import { axiosServiceInstance } from 'config/axiosConfig';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import {
  tenant,
  redirectUri,
  writescope,
  google_redirectUri,
  google_write_scope,
  getOAuthClientId,
} from 'config/msal';

const ButtonContainer = tw.div`flex justify-center`;
const CardActionButton = tw(PrimaryButton)`my-3 mx-auto`;

const rootStyle = {
  padding: '1rem',
};
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  alignItemsAndJustifyContent: {
    width: '100%',
    height: 380,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function RequestDeletion(props) {
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const [loaded, setLoaded] = useState(false);
  const [getState, setState] = useState({});
  const history = useHistory();
  const [err, setErr] = useState('');

  const [popup, setPopup] = useState(false);
  const [header, setHeader] = useState('DSR Raised Successfully');
  const [msg, setMessage] = useState('');

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const companyData = state.myData.find((a) => a.id === id);
  const customID = 'Dsr-id';
  var permissionWindow;
  let writePermission = 2;

  async function _sendEmail() {
    try {
      var cardData = state.domainDataForViewTemplate || [];
      const emailData = await axiosServiceInstance
        .post(`/pieeye-pfmdsr/api/dsr/_sendEmail`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            emailId: cardData.emailAddress || '',
            contactEmailId: cardData.domain.dsrContactEmail || '',
            template: getState,
            domainName: cardData.domain.domainName || '',
          },
        })
        .catch((error) => {
          if (error && (error.status === 404 || error.status === 400)) {
            // setErr( "Currently, Unable to raise the Data Subject Request, please try after sometime")
            setErr('Oops! something went wrong! Please try after sometime');
            document.getElementById('sendMail_Btn').disabled = false;
            document.getElementById('sendMail_Btn').innerHTML =
              'Submit Data Subject Request';
          }
          setLoaded(true);
        });
      //setLoaded(true);
      dispatch({
        type: 'MY_DATA',
        payload: undefined,
      });
      dispatch({
        type: 'DASHBOARD_DATA_COUNT',
        payload: 1,
      });
      if (emailData && emailData.status === 200) {
        setHeader('DSR Raised Successfully');
        setMessage('');
        toast.success('DSR Raised Successfully', {
          toastId: customID,
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });

        props.deselectAllFlag([]);
        dispatch({
          type: 'Selected_Row_Count',
          payload: 0,
        });
        dispatch({
          type: 'AG_DATA_LIST',
          payload: [],
        });
        if (document.getElementById('deselectAll') !== null) {
          document.getElementById('deselectAll').click();
        }
        setTimeout(() => {
          dispatch({
            type: 'Reload_Domain_Table',
            payload: true,
          });
          dispatch({
            type: 'Reload_Domain_Tile',
            payload: true,
          });
          props.getSingleDsrPopup(false);
          history.push('/dashboard');

          var x = document.getElementsByTagName('BODY')[0];
          x.style.overflow = 'auto';
        }, 1000);
      }
    } catch (error) {}
  }

  async function authWritePermissions() {
    //setLoaded(false);
    setErr('');
    document.getElementById('sendMail_Btn').disabled = true;
    document.getElementById('sendMail_Btn').innerHTML = 'Please wait..';
    var cardData = state.domainDataForViewTemplate || [];
    const username = state.user.username;

    //Fetching userInformation _getConnection - start
    const response = await axiosServiceInstance.post(
      `/pieeye-pfmuser/api/dashboard/_getConnection`,
      {
        requestInfo: {
          userName: username,
          token: state.user.idToken,
        },
      }
    );
    let userData = response.data.responseListObject;
    writePermission = 2;
    let providerName = '';
    let ProviderAuthUrl = '';

    // get provider name and writePermission status
    userData.forEach((element) => {
      if (element.emailId === cardData.emailAddress) {
        providerName = element.emailType;
        writePermission = element.writeEmailPermission;
      }
    });

    // Set Auth URL
    switch (providerName) {
      case 'Google':
        ProviderAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?\
client_id=${getOAuthClientId('Google')}\
&response_type=code\
&redirect_uri=${google_redirectUri}\
&scope=${google_write_scope.join('%20')}\
&state=12345\
&prompt=consent\
&access_type=offline`;
        break;

      case 'Microsoft':
        ProviderAuthUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?\
client_id=${getOAuthClientId('Microsoft')}\
&response_type=code\
&redirect_uri=${redirectUri}\
&scope=${writescope.join('%20')}\
&response_mode=query\
&state=12345\
&nonce=678910`;
        break;
      default:
        ProviderAuthUrl = '';
    }
    //Fetching userInformation _getConnection - end

    //open auth if permission not set
    if (parseInt(writePermission) === 0) {
      localStorage.setItem('loginType', 'dsr');
      localStorage.setItem('permissionFor', cardData.emailAddress);
      permissionWindow = window.open(
        ProviderAuthUrl,
        '',
        'width=600,height=550'
      );

      //every 2 seconds check if permission was given
      let interval = setInterval(async () => {
        const username = state.user.username;

        //Fetching userInformation _getConnection - start
        if (localStorage.getItem('GetEmailPermission') === 'true') {
          const response = await axiosServiceInstance.post(
            `/pieeye-pfmuser/api/dashboard/_getConnection`,
            {
              requestInfo: {
                userName: username,
                token: state.user.idToken,
              },
            }
          );
          let theData = response.data.responseListObject;

          theData.forEach((element) => {
            if (
              element.emailId === cardData.emailAddress &&
              parseInt(element.writeEmailPermission) === 1
            ) {
              _sendEmail();

              setHeader('DSR Raised Successfully');
              setMessage('');
              toast.success('DSR Raised Successfully', {
                toastId: customID,
                position: 'top-center',
                autoClose: 6000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
              });
              props.deselectAllFlag([]);
              dispatch({
                type: 'Selected_Row_Count',
                payload: 0,
              });
              dispatch({
                type: 'AG_DATA_LIST',
                payload: [],
              });
              if (document.getElementById('deselectAll') !== null) {
                document.getElementById('deselectAll').click();
              }

              setTimeout(() => {
                dispatch({
                  type: 'Reload_Domain_Table',
                  payload: true,
                });
                dispatch({
                  type: 'Reload_Domain_Tile',
                  payload: true,
                });
                props.getSingleDsrPopup(false);
                history.push('/dashboard');

                var x = document.getElementsByTagName('BODY')[0];
                x.style.overflow = 'auto';
              }, 1000);
              localStorage.removeItem('GetEmailPermission');
              clearInterval(interval);
            }
          });
        }
        var outhFail = localStorage.getItem('oauthFail');

        if (outhFail === 'true') {
          document.getElementById('sendMail_Btn').innerHTML =
            'Submit Data Subject Request';
          localStorage.removeItem('oauthFail');
          document.getElementById('sendMail_Btn').disabled = false;
          setErr(
            'There was an error while raising the Data Subject Request. Please try again'
          );
          setTimeout(function () {
            setErr('');
          }, 5000);
          clearInterval(interval);
        }
      }, 2000);
    }
    // if permission is set already then send mail
    if (parseInt(writePermission) === 1) {
      _sendEmail();
    }
  }

  useEffect(() => {
    async function _getViewTemplate() {
      try {
        const templatedata = await axiosServiceInstance
          .post(`/pieeye-pfmdsr/api/dsr/_reclaimTemplate`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
          })
          .catch((error) => {
            setLoaded(true);
          });

        var cardData = state.domainDataForViewTemplate || [];

        var htmlData =
          (((templatedata || []).data || []).responseObject || []).value ||
          '<div></div>';
        const requestedOn = new Date(
          cardData.domain.lastEmailDate || ''
        ).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });

        var dynamicHtml = htmlData
          .replaceAll('[:domain]', cardData.domain.domainName || '')
          .replaceAll('[:name]', cardData.name || '')
          .replaceAll('[:email]', cardData.emailAddress || '')
          .replaceAll('<body>', '<div>')
          .replaceAll('</body>', '</div>')
          .replaceAll('[:lastEmailDate]', requestedOn || '');

        dispatch({
          type: 'VIEW_TEMPLATE',
          payload: dynamicHtml || '<div></div>',
        });
        setState(dynamicHtml || '<div></div>');
        setLoaded(true);
      } catch (error) {
        setLoaded(true);
      }
    }

    if (!loaded && state.user.username) {
      if (state.domainDataForViewTemplate === undefined) {
        // history.push('/my-data');
        _getViewTemplate();
      } else if (state.dynamicHtml === undefined) {
        _getViewTemplate();
      }
    }
  }, [loaded, state, setState, history, dispatch, permissionWindow]);

  return (
    <>
      <div id="iddataSubReq">
        {loaded ? (
          <SmallPageWrapper id="aa">
            <div style={rootStyle} id="EmailPreview">
              <Typography variant="h3" align="center">
                Data Subject Request
              </Typography>

              <Typography variant="h6">Email Preview</Typography>

              {ReactHtmlParser(getState || '<div></div>')}
              <ButtonContainer className="mt-20" id="DomainDtlBtn">
                <CardActionButton
                  id="sendMail_Btn"
                  onClick={() => {
                    dispatch({
                      type: 'CREATE_DATA_SUBJECT_REQUEST',
                      payload: {
                        ...companyData,
                        id: createUUID(),
                        requestDate: Date.now(),
                      },
                    });
                    // _sendEmail();
                    authWritePermissions();

                    // history.push('/my-dsrs');
                  }}
                >
                  Submit Data Subject Request
                </CardActionButton>
                <CardActionButton
                  id="sendMail_Btn SendBtn_Transparent"
                  onClick={() => {
                    props.deselectAllFlag([]);
                    dispatch({
                      type: 'Selected_Row_Count',
                      payload: 0,
                    });
                    dispatch({
                      type: 'AG_DATA_LIST',
                      payload: [],
                    });
                    props.getSingleDsrPopup(false);
                    setPopup(false);
                    var x = document.getElementsByTagName('BODY')[0];
                    x.style.overflow = 'auto';
                  }}
                >
                  Cancel
                </CardActionButton>
              </ButtonContainer>
              <span
                style={{
                  color: 'red',
                  textAlign: 'center',
                  display: 'inline-block',
                  width: '100%',
                }}
              >
                {err}
              </span>
            </div>
          </SmallPageWrapper>
        ) : (
          <Box className={classes.alignItemsAndJustifyContent}>
            <CircularProgress />
          </Box>
        )}
        <>
          <Backdrop
            className={classes.backdrop}
            id="MyBackdrop"
            open={popup}
            onClick={() => {
              setPopup(false);
            }}
          >
            <Card>
              <Box
                style={{ color: 'black', textAlign: 'right', padding: '5px' }}
              >
                <ClearIcon
                  className="PopupClearIcon"
                  onClick={() => {
                    setPopup(false);
                  }}
                />
              </Box>
              <CardHeader className="DeletPopupHeader" title={header} />
              <hr />
              <CardContent id="ModalContent">
                <Typography id="DeleteText" variant="h6" color="textPrimary">
                  {msg}
                </Typography>
              </CardContent>
            </Card>
          </Backdrop>
        </>
      </div>
    </>
  );
}
