import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';

const redirectSignIn = `${window.location.origin}/cognito_callback`;
const redirectSignOut = `${window.location.origin}/cognito_signout`;

function getCognitoAuthConfig() {
  switch (window.location.hostname) {
    case 'localhost':
      return {
        userPoolId: 'us-east-2_b4ginRpi2', //UserPool ID
        region: 'us-east-2',
        userPoolWebClientId: '1atjvpdlhuu693fdgchtiil26e',
        oauth: {
          domain: 'privacyfor-me.auth.us-east-2.amazoncognito.com',
          scope: [
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn,
          redirectSignOut,
          responseType: 'code',
        },
      };
    case 'dev.privacyfor.me':
      return {
        userPoolId: 'us-east-2_b4ginRpi2', //UserPool ID
        region: 'us-east-2',
        userPoolWebClientId: 'tcmrlvqg76jjo3147470g78vi',
        oauth: {
          domain: 'privacyfor-me.auth.us-east-2.amazoncognito.com',
          scope: [
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn,
          redirectSignOut,
          responseType: 'code',
        },
      };
    case 'privacyfor.me':
      return {
        userPoolId: 'us-east-1_WNEV2K2Na', //UserPool ID
        region: 'us-east-1',
        userPoolWebClientId: '7ujaffn6jm1foq88oskc22obt1',
        oauth: {
          domain: 'login.privacyfor.me',
          scope: [
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn,
          redirectSignOut,
          responseType: 'code',
        },
      };
    default:
      console.warn(`Unknown host for user pool : ${window.location.hostname}`);
  }
}
Amplify.configure({
  Auth: getCognitoAuthConfig(),
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
