import { useState } from 'react';
import { Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  Header,
  Message,
  Form,
  Input,
  SubmitButton,
  Label,
  InputRow,
} from './Components';
import { useHistory } from 'react-router-dom';

export default function ResetPasswordForm({
  email,
  setError,
  setState,
  setStatus,
}) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setStatus(null);

    const formData = new FormData(e.target);
    const code = formData.get('code');
    const password = formData.get('password');

    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      setStatus('Password has been reset.');
      setState('complete');
      history.push('/');
    } catch (e) {
      console.error('Unable to reset password:', e);
      const { message } = e;
      setError(`Unable to reset password: ${message}`);
    }
  };

  const loadIndicator = loading ? (
    <CircularProgress color="inherit" size="1rem" />
  ) : null;

  return (
    <>
      <Header>Reset Password</Header>
      <Message>Check your email for a code to reset your password.</Message>
      <Form onSubmit={handleSubmit} disabled={loading}>
        <InputRow>
          <Label>Email</Label>
          <Input name="email" type="email" value={email} disabled required />
        </InputRow>
        <InputRow>
          <Label>Password</Label>
          <Input
            name="password"
            type="password"
            placeholder="password"
            required
          />
        </InputRow>
        <InputRow>
          <Label>Code</Label>
          <Input
            name="code"
            type="text"
            placeholder="verification code"
            required
          />
        </InputRow>
        <SubmitButton disabled={loading}>
          {loadIndicator}
          <span className="text">Change Password</span>
        </SubmitButton>
      </Form>
    </>
  );
}
