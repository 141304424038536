import { useState } from 'react';
import { useHistory } from 'react-router';

import IntroWrapper from 'components/wrapper/IntroWrapper';
import IntroPage from 'components/intro/IntroPage';
import IntroButtonRow from 'components/intro/IntroButtonRow';

import PrivacyBrands from 'images/ws-Privacy_Brands.svg';
import AppPrivacy from 'images/ws-App_Privacy.svg';
import AIBotProtection from 'images/ws-AI_Bot_Protection.svg';
import Key from 'images/ws-landing-hero-fold.svg';
import IntroPagination from 'components/intro/IntroPaginantion';

const pages = [
  {
    title: 'Congratulations!',
    image: PrivacyBrands,
    text: "You have taken your first step towards cleaning up your digital footprint. Let's walk through what is going to happen next.",
    buttonText: 'What Now?',
    activedot: 0,
  },
  {
    title: 'What Happens Now?',
    image: AIBotProtection,
    text: "We are currently scanning your email headers, looking for companies with your data. Then we will display them to you so you can see which companies you'd like to revoke access to your data.",
    buttonText: 'Next',
    activedot: 1,
  },
  {
    title: 'How small can you make your digital footprint?',
    image: AppPrivacy,
    text: 'How many companies actually need access to your data? What are these companies doing with your data? Have you thought of this before? Browse your data and start a Data Subject Request today!',
    buttonText: 'Next', //'What is a DSR?',
    activedot: 2,
  },
  {
    title: 'What is a Data Subject Request?',
    image: PrivacyBrands,
    text: 'When you connect your email account, we will send a company a request on your behalf to delete your data. Under certain laws they are required to do this.',
    buttonText: 'Next',
    activedot: 3,
  },
  {
    title: 'Ready to Begin?',
    image: Key,
    text: "Let's go see what data you can reclaim today!",
    buttonText: 'Get Started Now!',
    activedot: 4,
  },
];

export default function IntroFlow() {
  const [page, setPage] = useState(0);
  const history = useHistory();

  localStorage.setItem('hasSeenIntroFlow', true);

  const handleOnClick = () => {
    if (page + 1 < pages.length) {
      setPage(page + 1);
    } else {
      history.push('/dashboard');
    }
  };

  const pageContent = <IntroPage {...pages[page]} />;
  const buttonText = pages[page].buttonText;
  const activedot = pages[page].activedot;

  return (
    <IntroWrapper>
      {pageContent}
      <IntroButtonRow onClick={handleOnClick} text={buttonText} index={page} />
      <IntroPagination activeDot={activedot} />
    </IntroWrapper>
  );
}
