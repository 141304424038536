import { useContext, useState, useEffect } from 'react';
import { Context } from 'store';
import { axiosServiceInstance } from 'config/axiosConfig';
import {
  Button,
  Grid,
  Card,
  Typography,
  CardContent,
  CardHeader,
  Box,
} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { RequestDeletionMultiple } from 'views';

export default function RequestDeletionButton(props) {
  const [state, dispatch] = useContext(Context);
  const [loaded, setLoaded] = useState(false);
  const [btndisable, setbtndisable] = useState('true');

  const history = useHistory();

  useEffect(() => {});

  let dsrDataList = [];
  if (props.DSRList !== undefined) {
    (props.DSRList || []).map((datum, index) => {
      if (datum !== undefined) {
        //&& props.DataList.includes(datum.domainName)
        const requestedOn = new Date(
          datum !== undefined ? datum.lastEmailDate : ''
        ).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });

        var element = {};
        element.domainName = datum.domainName;
        element.lastEmailDate = requestedOn;
        element.contactEmailId = datum.dsrContactEmail;
        element.emailAddress = datum.emailAddress;
        element.name = state.user.name;
        dsrDataList.push(element);
      }
    });
  }

  let emailId = localStorage.getItem('updatedEmailId');
  const domainlistobj = {
    name: state.user.name,
    domain: dsrDataList || [],
    emailAddress:
      (dsrDataList[0] || []).emailAddress === undefined
        ? (state.dashboardData || []).emailId
        : (dsrDataList[0] || []).emailAddress,
  };

  /*Mark As Needed Domains */
  async function udateStatusNeeded(NeededDomain) {
    const domainNameList = (NeededDomain || []).map((x) => {
      return x.domainName;
    });
    try {
      await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/domainStatus/_bulkUpdate`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'needed',
            emailId: (state.dashboardData || []).emailId,
            bulkDomain: domainNameList || [],
          },
        })
        .catch((e) => {
          console.error(e);
        });
      localStorage.setItem('RealoadCount', true);
      dispatch({
        type: 'Pending_TILE_DATA',
        payload: [],
      });

      toast.success('Domains marked as needed.', {
        position: 'top-center',
        autoClose: 6000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      // props.gridRef.current.api.forEachNode(function (node) {
      //   node.setSelected(false);
      // });
      props.deselectAll([]);
      dispatch({
        type: 'Selected_Row_Count',
        payload: 0,
      });
      dispatch({
        type: 'AG_DATA_LIST',
        payload: [],
      });
      dispatch({
        type: 'Reload_Domain_Table',
        payload: true,
      });
      if (props.gridRef.current !== null) {
        props.gridRef.current.api.deselectAll();
      }
      if (document.getElementById('deselectAll') !== null) {
        document.getElementById('deselectAll').click();
      }
      setLoaded(false);
    } catch (e) {
      console.error(e);
      setLoaded(false);
    }
    history.push('/dashboard');
  }

  /*Mark As DSR Domains */

  return (
    <div id="DomainBtnWrapper">
      <Grid item xs={12}>
        <Button
          style={{ minWidth: '122px' }}
          variant="outlined"
          color="primary"
          id="Markneded"
          disabled={!state.selectedRowCount > 0}
          onClick={() => {
            udateStatusNeeded(props.DataList);
          }}
          //title={(state.selectedRowCount || 0) + ' Selected'}
          className={state.selectedRowCount ? '' : 'disablereqbtn'}
        >
          Mark As Needed
          <div className="OnHover">
            {(state.selectedRowCount || 0) + ' Selected'}
          </div>
        </Button>

        <Button
          style={{ minWidth: '122px', float: 'right' }}
          variant="outlined"
          color="primary"
          id="RequestDeletion"
          disabled={!state.selectedRowCount > 0}
          //title={(state.selectedRowCount || 0) + ' Selected'}
          className={state.selectedRowCount ? '' : 'disablereqbtn'}
          onClick={() => {
            var payload = domainlistobj;
            dispatch({
              type: 'CARD_DETAIL_FOR_VIEW_TEMPLATE',
              payload: payload,
            });
            // history.push(`/request-deletion-multiple?id=1234`);
            //history.push(`/dashboard?id=1234`);
            props.getDsrPopup(true);
            var x = document.getElementsByTagName('BODY')[0];
            x.style.overflow = 'hidden';
            // dispatch({
            //   type: 'Pending_TILE_DATA',
            //   payload: [],
            // });
          }}
        >
          Request Deletion For Selected
          <div className="OnHover1">
            {(state.selectedRowCount || 0) + ' Selected'}
          </div>
        </Button>
      </Grid>
    </div>
  );
}
