import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GoogleLogo from '../../images/Figma_N_Google.svg';
import MicrosfotLogo from '../../images/Figma_N_Microsoft.svg';

const useStyles = makeStyles((theme) => ({
  email: {
    fontStyle: 'normal',
    fontSize: '12px',
    color: '#8E8E8E',
    marginLeft: '.5rem',
    fontWeight: '400',
    fontFamily: 'Inter',
  },
  emailWordBreak: {
    wordBreak: 'break-all',
  },
}));

export default function ProviderConnectionItem({ children, label, email }) {
  const classes = useStyles();
  const emailContent = email ? (
    <span className={classes.email}>{email}</span>
  ) : null;

  return (
    <>
      <Grid container spacing={2} id="EmailConBox">
        <Grid
          item
          md={7}
          sm={7}
          xs={12}
          className={classes.emailWordBreak}
          id="ConnectBtn"
        >
          <div className="ConnectLogo">
            {' '}
            {label === 'Google' ? (
              <img src={GoogleLogo} className="pr-10" />
            ) : label === 'Microsoft' ? (
              <img src={MicrosfotLogo} className="pr-10" />
            ) : (
              ''
            )}
            {label}
          </div>
          <div className="ConnectEmail"> {emailContent}</div>
        </Grid>
        <Grid item md={5} sm={5} xs={12} id="Text_Right">
          {children}
        </Grid>
      </Grid>
    </>
  );
}
