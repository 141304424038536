import styled from 'styled-components';
import tw from 'twin.macro';

import { CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { useEffect, useContext } from 'react';
import Context from '../../../src/store';
import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-1.svg';
import Sidebar from './Sidebar';
import FullPageHeader from 'components/headers/full/FullPageHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

//Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const Wrapper = tw.div`flex max-w-screen-xl mx-auto`;

export default function FullPageWrapper({ children, selected }) {
  const classes = useStyles();
  // const [state, dispatch] = useContext(Context);

  // console.log("connected",state);

  return (
    <AnimationRevealPage disabled>
      <FullPageHeader />
      <Wrapper>
        <CssBaseline />
        {/* <Sidebar selected={selected} /> */}
        <main className={classes.content}>{children}</main>
      </Wrapper>
      {/* <DecoratorBlob1 /> */}
    </AnimationRevealPage>
  );
}
