import { Button, TextField, Grid } from '@material-ui/core';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import tw from 'twin.macro';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import EyePassClose from '../../images/eyePassClose.svg';
import EyePass from '../../images/eyePass.svg';
import CompletedLogo from '../../images/Figma_Completed.svg';
import CrossIcon from '../../images/ErrorPass.svg';

const ErrorMessage = tw.div`bg-red-300 text-red-800 text-sm border border-red-800 rounded my-2 p-4`;
const Form = tw.form`max-w-sm`;
const Input = tw.input`w-full px-4 py-2 rounded-lg font-medium border border-gray-200 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mb-2 mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

// const rowStyle = {
//   margin: '.5rem 0',
//   width: '250px',
// };

export default function ChangePassword() {
  // const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const error = errorMessage ? (
    <ErrorMessage>{errorMessage}</ErrorMessage>
  ) : null;
  const [oldPasswordIcon, setOldPasswordIcon] = useState(EyePassClose);
  const [newPasswordIcon, setNewPasswordIcon] = useState(EyePassClose);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(EyePassClose);
  const [isLenght, setIsLenght] = useState(false);
  const [isLower, setIsLower] = useState(false);
  const [isUpper, setIsUpper] = useState(false);
  const [isNumeric, setIsNumeric] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);

  const resetStates = () => {
    setOldPasswordIcon(EyePassClose);
    setNewPasswordIcon(EyePassClose);
    setConfirmPasswordIcon(EyePassClose);
    setIsLenght(false);
    setIsLower(false);
    setIsUpper(false);
    setIsNumeric(false);
    setIsSpecial(false);
  };

  const togglePasswordShow = (idName) => {
    const password = document.querySelector(idName);
    if (password.value) {
      const type =
        password.getAttribute('type') === 'password' ? 'text' : 'password';
      password.setAttribute('type', type);
      if (idName === '#oldPassword') {
        let logo = type === 'password' ? EyePassClose : EyePass;
        setOldPasswordIcon(logo);
      }
      if (idName === '#newPassword') {
        let logo = type === 'password' ? EyePassClose : EyePass;
        setNewPasswordIcon(logo);
      }
      if (idName === '#confirmPassword') {
        let logo = type === 'password' ? EyePassClose : EyePass;
        setConfirmPasswordIcon(logo);
      }
    }
  };
  const validatePassword = (e) => {
    let password = e.target.value;
    if (password.match(/([a-z])/)) {
      setIsLower(true);
    } else {
      setIsLower(false);
    }
    if (password.match(/([A-Z])/)) {
      setIsUpper(true);
    } else {
      setIsUpper(false);
    }
    if (password.match(/([0-9])/)) {
      setIsNumeric(true);
    } else {
      setIsNumeric(false);
    }
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      setIsSpecial(true);
    } else {
      setIsSpecial(false);
    }
    if (password.length >= 10) {
      setIsLenght(true);
    } else {
      setIsLenght(false);
    }
  };

  // ======================== start ===============================
  return (
    <Grid container spacing={2} id="ChangePassRule">
      <Grid item md={6} sm={6} xs={12}>
        <Form
          id="password-form"
          onSubmit={async (e) => {
            e.preventDefault();
            setErrorMessage(null);
            if (isLenght && isLower && isNumeric && isSpecial && isUpper) {
              const formData = new FormData(e.target);
              const newPassword = formData.get('newPassword');
              const oldPassword = formData.get('oldPassword');
              const confirmPassword = formData.get('confirmPassword');
              if (newPassword === confirmPassword) {
                Auth.currentAuthenticatedUser()
                  .then((user) => {
                    return Auth.changePassword(user, oldPassword, newPassword);
                  })
                  .then((data) => {
                    toast.success('Password saved successfully.', {
                      position: 'top-center',
                      autoClose: 6000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: 0,
                    });
                    // return setErrorMessage("password change successfully.");
                    document.getElementById('password-form').reset();
                    resetStates();
                    // setReset(true)
                  })
                  .catch((err) => {
                    return setErrorMessage(err.message);
                  });
              } else {
                return setErrorMessage(
                  'New Password & Confirm Password does not match'
                );
              }
            } else {
              return setErrorMessage('Password must meet requirements');
            }
          }}
        >
          <Input
            id="oldPassword"
            className="ChangePassInput"
            name="oldPassword"
            type="password"
            placeholder="Old Password"
            required
          />
          <img
            src={oldPasswordIcon}
            className="EyeIcon"
            onClick={() => {
              togglePasswordShow('#oldPassword');
            }}
          />
          <Input
            id="newPassword"
            className="ChangePassInput"
            name="newPassword"
            type="password"
            placeholder="New Password"
            // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            // title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
            required
            onChange={(e) => {
              validatePassword(e);
            }}
          />
          <img
            src={newPasswordIcon}
            className="EyeIcon"
            onClick={() => {
              togglePasswordShow('#newPassword');
            }}
          />
          <Input
            id="confirmPassword"
            className="ChangePassInput"
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            required
          />
          <img
            src={confirmPasswordIcon}
            className="EyeIcon"
            onClick={() => {
              togglePasswordShow('#confirmPassword');
            }}
          />
          <SubmitButton type="submit" id="ChangePass">
            Save Changes
          </SubmitButton>
          {error}
        </Form>
      </Grid>
      <Grid item md={6} sm={6} xs={12} className="pl-15">
        <p>Password must contain:</p>
        <div className="CustomFlex">
          {isLenght ? <img src={CompletedLogo} /> : <img src={CrossIcon} />}{' '}
          <p className="pl-5">at least 10 characters</p>
        </div>
        <div className="CustomFlex">
          {isLower ? <img src={CompletedLogo} /> : <img src={CrossIcon} />}{' '}
          <p className="pl-5">a minimum of 1 lower case letter</p>
        </div>
        <div className="CustomFlex">
          {isUpper ? <img src={CompletedLogo} /> : <img src={CrossIcon} />}{' '}
          <p className="pl-5">a minimum of 1 upper case letter</p>
        </div>
        <div className="CustomFlex">
          {isNumeric ? <img src={CompletedLogo} /> : <img src={CrossIcon} />}{' '}
          <p className="pl-5">a minimum of 1 numeric character</p>
        </div>
        <div className="CustomFlex">
          {isSpecial ? <img src={CompletedLogo} /> : <img src={CrossIcon} />}{' '}
          <p className="pl-5">a minimum of 1 special character</p>
        </div>
      </Grid>
    </Grid>
  );
  // ======================== end =================================
}
