import React from 'react';
import tw from 'twin.macro';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import logo from '../../images/Figma_LogoPFM.svg';
import bgFooterImg from '../../images/bg-footer.svg';

const Container = tw(ContainerBase)`text-gray-100 -mx-8 -mb-8`;
//bg-gray-900
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8 pr-2`;
const LogoText = tw.h5`ml-2 text-[#ffffff] text-base md:uppercase font-semibold tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2  text-[#ffffff] border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-white`; //text-gray-600
export default function MiniCenteredFooter() {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Privacy For Me</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link href="/login">Login</Link>
            <Link href="/privacy-policy">Privacy Policy</Link>
            <Link href="/terms-of-service">Terms of Service</Link>
          </LinksContainer>
          <CopyrightText>
            &copy; Copyright 2022, Privacy For Me. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
}
