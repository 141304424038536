import { useContext, useEffect, useState } from 'react';
import { Context } from 'store';
import FullPageWrapper from 'components/wrapper/FullPageWrapper';
import MissingConnection from 'components/data/MissingConnection';
import MyDataContent from 'components/data/MyDataContentAllDomains';
// import { PrimaryButton } from 'components/misc/Buttons';
import { axiosServiceInstance } from 'config/axiosConfig';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  alignItemsAndJustifyContent: {
    width: '100%',
    height: 380,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
export default function MyDataNewPage() {
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const [loaded, setLoaded] = useState(false);
  const [onchangeeventflag, setonchangeeventflag] = useState(false);

  // TODO: Display errors
  const [errors, setError] = useState(null); //eslint-disable-line no-unused-vars
  // const { connectedAccounts } = state;
  const [getState, setState] = useState({});
  const [getDashboardState, setDashboardState] = useState({});
  const [getRecState, setRecState] = useState([]);
  const [getDropdown, setSetDropdown] = useState({});
  // const [getLastKey, setLastKey] = useState('');
  const [getAllDomainLastKey, setAllDomainLastKey] = useState('');
  const [getalldata, setallgetdata] = useState(true);
  let RecDomainArr = [];

  async function _changeDashBoardData(emailId) {
    setallgetdata(false);
    // setonchangeeventflag(false);
    try {
      const myDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'pending',
            emailId: emailId || '',
          },
        })

        .catch((error) => {
          setLoaded(true);
        });
      dispatch({
        type: 'MY_DATA',
        payload:
          myDataList === undefined
            ? []
            : ((myDataList || []).data || []).responseListObject || [],
      });
      setState(
        myDataList === undefined
          ? []
          : ((myDataList || []).data || []).responseListObject || []
      );

      if (
        ((myDataList || []).data || []).lastKey !== null &&
        ((myDataList || []).data || []).lastKey !== undefined
      ) {
        // Store the last key in the redux stte / localstorage
        dispatch({
          type: 'AllDomain_Last_Key',
          payload:
            ((myDataList || []).data || []).lastKey === null
              ? ''
              : ((myDataList || []).data || []).lastKey,
        });
        setAllDomainLastKey(
          ((myDataList || []).data || []).lastKey === null
            ? ''
            : ((myDataList || []).data || []).lastKey
        );
      }

      //On change Get the recommended domains:

      const myRecomendedDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'recommended',
            emailId: emailId,
          },
        })
        .catch((error) => {
          setLoaded(true);
          setRecState([]);
        });

      dispatch({
        type: 'MY_Recommended_DATA',
        payload:
          myRecomendedDataList === undefined
            ? []
            : ((myRecomendedDataList || []).data || []).responseListObject ||
              [],
      });

      setRecState(
        myRecomendedDataList === undefined
          ? []
          : ((myRecomendedDataList || []).data || []).responseListObject || []
      );

      setSetDropdown({
        dropDownData: getDropdown.dropDownData,
        emailId: emailId,
      });

      if (
        ((myRecomendedDataList || []).data || []).lastKey !== null &&
        ((myRecomendedDataList || []).data || []).lastKey !== undefined
      ) {
        RecDomainArr = RecDomainArr.concat(
          myRecomendedDataList.data.responseListObject
        );
        dispatch({
          type: 'Last_Key',
          payload:
            ((myRecomendedDataList || []).data || []).lastKey === null
              ? ''
              : ((myRecomendedDataList || []).data || []).lastKey,
        });
        /*
         setLastKey(
           ((myRecomendedDataList || []).data || []).lastKey === null 
           ? ''
           : ((myRecomendedDataList || []).data || []).lastKey  
        );
        */
      }

      //Get DOMAIN API For getting the recommended and pending domain cnt:
      const allDomain = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_getAllDomain`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            emailId: emailId,
          },
        })
        .catch((error) => {
          setLoaded(true);
          // setCountFlag(true);
        });

      setDashboardState({
        myData:
          (allDomain || []).data === undefined
            ? []
            : allDomain.data.responseListObject[0],
        dropDownData: getDropdown.dropDownData,
        emailId: emailId,
      });

      dispatch({
        type: 'DASHBOARD_DATA',
        payload: {
          myData:
            (allDomain || []).data === undefined
              ? []
              : allDomain.data.responseListObject[0],
          dropDownData: getDropdown.dropDownData,
          emailId: emailId,
        },
      });
      dispatch({
        type: 'MY_SELECTED_DATA',
        payload: [],
      });
      ///Get Domain api ends here

      let NeededDomain = allDomain.data.responseListObject[0].neededDomain;
      localStorage.setItem('NeededDomainCount', NeededDomain);

      //On Change Get the Recommended api ends here.
      localStorage.setItem('updatedEmailId', emailId);
      setallgetdata(true);
    } catch (error) {}
  }

  useEffect(() => {
    // Get the Recommended domain ends here:
    async function _getMyData() {
      setallgetdata(false);
      try {
        const dropDownData = await axiosServiceInstance
          .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
          })
          .catch((error) => {
            // alert('error');

            setLoaded(true);
            setState([]);
          });
        let dropdownEmailId = localStorage.getItem('updatedEmailId');

        //Get DOMAIN API For getting the recommended and pending domain cnt:
        const allDomain =
          dropDownData === undefined
            ? []
            : await axiosServiceInstance
                .post(`/pieeye-pfmdomain/api/dashboard/_getAllDomain`, {
                  requestInfo: {
                    userName: state.user.username || '',
                    token: state.user.idToken || '',
                  },
                  requestBody: {
                    emailId:
                      dropdownEmailId === null || dropdownEmailId === 0
                        ? dropDownData.data.responseListObject[0].emailId
                        : dropdownEmailId,
                  },
                })
                .catch((error) => {
                  setLoaded(true);
                  // setCountFlag(true);
                  // console.log('in error');
                });
        setDashboardState({
          myData:
            (allDomain || []).data === undefined
              ? []
              : allDomain.data.responseListObject[0],
          dropDownData:
            dropDownData === undefined
              ? []
              : dropDownData.data.responseListObject,
          emailId:
            dropdownEmailId === null || dropdownEmailId === 0
              ? dropDownData.data.responseListObject[0].emailId
              : dropdownEmailId,
        });

        let NeededDomain = allDomain.data.responseListObject[0].neededDomain;
        localStorage.setItem('NeededDomainCount', NeededDomain);

        dispatch({
          type: 'DASHBOARD_DATA',
          payload: {
            myData:
              (allDomain || []).data === undefined
                ? []
                : allDomain.data.responseListObject[0],
            dropDownData:
              dropDownData === undefined
                ? []
                : dropDownData.data.responseListObject,
            emailId:
              dropdownEmailId === null || dropdownEmailId === 0
                ? dropDownData.data.responseListObject[0].emailId
                : dropdownEmailId,
          },
        });
        ///Get Domain api ends here
        const myDataList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'pending',
              emailId:
                dropdownEmailId === null || dropdownEmailId === 0
                  ? dropDownData.data.responseListObject[0].emailId
                  : dropdownEmailId,
            },
          })
          .catch((error) => {
            setLoaded(true);
            setState([]);
          });

        if (
          ((myDataList || []).data || []).lastKey !== null &&
          ((myDataList || []).data || []).lastKey !== undefined
        ) {
          // Store the last key in the redux stte / localstorage
          dispatch({
            type: 'AllDomain_Last_Key',
            payload:
              ((myDataList || []).data || []).lastKey === null
                ? ''
                : ((myDataList || []).data || []).lastKey,
          });
          setAllDomainLastKey(
            ((myDataList || []).data || []).lastKey === null
              ? ''
              : ((myDataList || []).data || []).lastKey
          );
        }

        dispatch({
          type: 'MY_DATA',
          payload:
            myDataList === undefined
              ? []
              : ((myDataList || []).data || []).responseListObject || [],
        });

        setState(
          myDataList === undefined
            ? []
            : ((myDataList || []).data || []).responseListObject || []
        );

        //Get the recommended domains:
        // if(localStorage.getItem('ProgressBarApiCall')){
        const myRecomendedDataList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'recommended',
              emailId:
                dropdownEmailId === null || dropdownEmailId === 0
                  ? dropDownData.data.responseListObject[0].emailId
                  : dropdownEmailId,
            },
          })
          .catch((error) => {
            setLoaded(true);
            setRecState([]);
            // console.log('in error');
          });
        if (
          ((myRecomendedDataList || []).data || []).lastKey !== null &&
          ((myRecomendedDataList || []).data || []).lastKey !== undefined
        ) {
          RecDomainArr = RecDomainArr.concat(
            myRecomendedDataList.data.responseListObject
          );
          dispatch({
            type: 'Last_Key',
            payload:
              ((myRecomendedDataList || []).data || []).lastKey === null
                ? ''
                : ((myRecomendedDataList || []).data || []).lastKey,
          });
          /*
          setLastKey(
            ((myRecomendedDataList || []).data || []).lastKey === null 
            ? ''
            : ((myRecomendedDataList || []).data || []).lastKey  
         );
         */
        }
        dispatch({
          type: 'MY_Recommended_DATA',
          payload:
            myRecomendedDataList === undefined
              ? []
              : ((myRecomendedDataList || []).data || []).responseListObject ||
                [],
        });

        setRecState(
          myRecomendedDataList === undefined
            ? []
            : ((myRecomendedDataList || []).data || []).responseListObject || []
        );
        // }

        setSetDropdown({
          myData:
            (allDomain || []).data === undefined
              ? []
              : allDomain.data.responseListObject[0],
          dropDownData:
            dropDownData === undefined
              ? []
              : dropDownData.data.responseListObject,
          emailId:
            dropdownEmailId === null || dropdownEmailId === 0
              ? dropDownData.data.responseListObject[0].emailId
              : dropdownEmailId,
        });
        setallgetdata(true);
        setLoaded(true);
      } catch (e) {
        console.error('Unable to fetch domains:', e);

        if ((e.response || []).status || '' === 404) {
          setLoaded(true);
          setError({ ...((e.response || []).status || null) });
        }
        setLoaded(true);
      }
    }

    if (!loaded && state.user.username) {
      _getMyData();
      setLoaded(true);
    }
  }, [loaded, state, dispatch]);

  const emailFormContent =
    (getDropdown.dropDownData || []).length > 1 ? (
      <FormControl className={classes.formControl} id="AccountEmail">
        <InputLabel id="demo-simple-select-label">Email:</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={getDropdown.emailId || ''}
          onChange={(event) => {
            var emailId = event.target.value;
            if (emailId !== '') {
              _changeDashBoardData(emailId);
            }
          }}
        >
          <MenuItem value="" key="select">
            Select
          </MenuItem>
          {(getDropdown.dropDownData || []).map((data) => (
            <MenuItem value={data.emailId} key={data.emailId}>
              {data.emailId}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : null;

  if (!loaded || JSON.stringify(getState) === '{}') {
    return (
      <FullPageWrapper>
        <Box className={classes.alignItemsAndJustifyContent}>
          <CircularProgress />
        </Box>
      </FullPageWrapper>
    );
  }

  if (
    (getDropdown.dropDownData || []).length === 0 &&
    getState.length === 0 &&
    getRecState.length === 0
  ) {
    return (
      <FullPageWrapper>
        {emailFormContent}
        <MissingConnection />
      </FullPageWrapper>
    );
  }

  /*
  if (getState.length === 0 && getRecState.length === 0) {
   
    return (
      <FullPageWrapper selected="my-data">
        {emailFormContent}
        <MissingConnection />
      </FullPageWrapper>
    );
  }
  */

  if (getalldata) {
    return (
      <FullPageWrapper>
        {emailFormContent}
        <MyDataContent
          myDataDetail={getState || []}
          updateSearchstate={onchangeeventflag}
          myRecommendedDataDetails={getRecState || []}
          pendingDsrCount={
            ((getDashboardState || []).myData || []).pendingDomain
          }
          recommendedDsrCount={
            ((getDashboardState || []).myData || []).recommendedDsrCount
          }
          initiatedDomain={
            ((getDashboardState || []).myData || []).initiatedDomain
          }
        />
      </FullPageWrapper>
    );
  }
  return (
    <FullPageWrapper>
      <Box className={classes.alignItemsAndJustifyContent}>
        <CircularProgress />
      </Box>
    </FullPageWrapper>
  );
  // return (
  //   <FullPageWrapper selected="my-data">
  //     {/* Temporary flow placeholder until this process is smooth */}
  //     You do not have any data items currently. We might be in the process of
  //     scanning for companies with your data, or you might just be all set. Check
  //     back soon for updates. <br />
  //     <PrimaryButton>Refresh Data</PrimaryButton>
  //   </FullPageWrapper>
  // );
}
