import {
  Box,
  Button,
  Typography,
  Backdrop,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import tw from 'twin.macro';
import { PrimaryButton, SecondaryButton } from 'components/misc/Buttons';
import { useContext, useState, useEffect } from 'react';
import FullPageWrapper from '../components/wrapper/FullPageWrapper';
import SettingsWrapper from '../components/wrapper/SettingsWrapper';
import { useHistory } from 'react-router-dom';
import ProviderConnection from '../components/settings/ProviderConnection';
import { axiosServiceInstance } from 'config/axiosConfig';
import { Context } from '../store';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import MyDataCarousal from '../components/data/MyDataCarousal';
import MyDataCard from 'components/data/MyDataCard';
import MyDataDetails from 'components/data/MyDataDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tabs, Tab } from '@mui/material';
import TabPanel from 'components/common/TabPanel';
import PropTypes from 'prop-types';
import { Form } from 'components/account/Components';
import ChangePassword from 'components/settings/ChangePassword';
const CardContainer = tw.div`relative mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`;
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Account() {
  //const [state] = useContext(Context);
  const [state, dispatch] = useContext(Context);
  const [popup, setPopup] = useState(false);
  const [header, setHeader] = useState('Deleting Account');
  const [msg, setMessage] = useState(
    `Warning! We will remove all existing information about you including name,
    email, email provider connections, and details on the companies
    that have your information.`
  );
  const [defaultPop, setIsDefault] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [loadNeeded, setloadNeeded] = useState(false);
  const [errors, setError] = useState(null);
  const [selectedDatum, setSelectedDatum] = useState(null);
  const [value, setValue] = React.useState(0);
  const [getDropdown, setSetDropdown] = useState({});
  const [isShowDD, setIsShowDD] = useState(false);
  const [provider, setProvider] = useState();
  const [tabclicked, settabclicked] = useState(false);
  const customId = 'custom-id-Removed';

  const history = useHistory();

  useEffect(() => {
    if (!loadNeeded) {
      localStorage.setItem('AllDomainPageUrl', history.location.pathname);
      _getMyData();
    }
    if (state.accountdisconnected === true) {
      _getMyData();
      dispatch({
        type: 'Account_Disconnected',
        payload: false,
      });
    }
  });
  const btnstyle = {
    color: '#fff',
    fontSize: '12px',
    fontWeight: '500',
    backgroundColor: '#2C3E5D',
    border: 'unset',
    marginTop: '1rem',
    textTransform: 'capitalize',
  };
  const pull_provider = (data) => {
    setProvider(data);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // Get the Recommended domain ends here:
  async function _getMyData() {
    let dropdownEmailId = localStorage.getItem('updatedEmailId');
    if ((state.dashboardData || []).dropDownData !== undefined) {
      setloadNeeded(true);
      try {
        setSetDropdown({
          dropDownData: (state.dashboardData || []).dropDownData,
          emailId:
            dropdownEmailId ||
            (state.dashboardData || []).dropDownData[0].emailId,
        });

        dispatch({
          type: 'Selected_Row_Count',
          payload: 0,
        });
      } catch (e) {
        console.error('Unable to fetch domains:', e);

        if ((e.response || []).status || '' === 404) {
          setError({ ...((e.response || []).status || null) });
        }
      }
    }
  }

  const classes = useStyles();

  const deleteMsg = (
    <>
      <div>
        <p className="AccountDltBody">
          Sorry to see you Go!
          <br />
          Please provide your feedback on your experience here.
        </p>
        <PrimaryButton
          target="_blank"
          id="yesButtons"
          onClick={() => {
            window.open('/feedback', '_blank');
            setTimeout(() => {
              window.location = '/';
            }, 3000);
          }}
        >
          Give feedback
        </PrimaryButton>
        <PrimaryButton
          id="noButtons"
          onClick={() => {
            window.location = '/';
          }}
        >
          No, thanks
        </PrimaryButton>
      </div>
    </>
  );

  async function deleteAccount() {
    setIsDefault(false);
    await axiosServiceInstance
      .post(`/pieeye-pfmuser/api/account/_deleteAccount`, {
        requestInfo: {
          userName: state.user.username,
          token: state.user.idToken || '',
        },
      })
      .then(function (response) {
        if (response.status === 200) setHeader('Account Deleted Successfully.');
        //setMessage(response.data.responseObject);
        //setPopup(true);
        setMessage(deleteMsg);
        setPopup(true);
        localStorage.removeItem('selected_account');
        localStorage.removeItem('authenticationGoogle');
        localStorage.removeItem('authenticationMS');
        localStorage.clear();
        // if (localStorage.length === 0) {
        //   setTimeout(() => {
        //     window.location = '/';
        //   }, 8000);
        // }
      })
      .catch((error) => {
        setHeader('Deletion Failed');
        setMessage(
          'User does not exists, You will be redirect to home page in 5 seconds...'
        );
        setPopup(true);
        setTimeout(() => {
          window.location = '/';
        }, 5000);
      });
  }
  //Tab Section
  const handleChange = (event, newValue) => {
    settabclicked(true);
    dispatch({
      type: 'Active_tab_no',
      payload: newValue,
    });
    setValue(newValue);
  };
  const EmailConnection = <ProviderConnection func={pull_provider} />;
  const Security = (
    <>
      <ChangePassword />
    </>
  );
  const Delete = (
    <>
      <Typography paragraph className="DeletingAcBody">
        {/* Delete account blurb.  */}
        Warning! We will remove all existing information about you including
        name, email, email provider connections, and details on the companies
        that have your information.
      </Typography>
      <Box>
        <Button
          style={btnstyle}
          variant="outlined"
          color="secondary"
          onClick={() => {
            setPopup(true);
          }}
        >
          Delete My Account
        </Button>
      </Box>
    </>
  );
  const tabData = [
    {
      label: 'Email Connections',
      id: 'AccountTab',
      panelHeader: 'Email Provider Connections',
      component: EmailConnection,
      fromSignUp: true,
    },
    {
      label: localStorage.getItem('fromSignUp') ? 'Security' : 'Delete Account',
      id: 'AccountTab',
      panelHeader: localStorage.getItem('fromSignUp')
        ? 'Change Password'
        : 'Deleting Account',
      component: localStorage.getItem('fromSignUp') ? Security : Delete,
      fromSignUp: localStorage.getItem('fromSignUp') ? true : false,
    },
    {
      label: 'Delete Account',
      id: 'AccountTab',
      panelHeader: 'Deleting Account',
      component: Delete,
      fromSignUp: true,
    },
  ];

  const TabMap = (tabData || []).map((x, index) => {
    if (x.fromSignUp) {
      return (
        <Tab
          label={x.label}
          {...a11yProps(index + 1)}
          id={x.id}
          // disabled={tabdisable}
        />
      );
    }
  });
  const TabPanelMap = (tabData || []).map((x, index) => {
    return (
      <>
        <TabPanel value={value} index={index}>
          <SettingsWrapper header={x.panelHeader}>
            <>{x.component}</>
          </SettingsWrapper>
        </TabPanel>
      </>
    );
  });

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={selectedDatum}
        onClick={() => {
          setSelectedDatum(null);
        }}
      >
        {selectedDatum && (
          <MyDataDetails
            data={{ selectedDatum }}
            selectDatum={setSelectedDatum}
          />
        )}
      </Backdrop>

      <FullPageWrapper selected="account">
        <Typography className="AccountHeading">Account Settings</Typography>

        <Box sx={{ width: '100%' }} id="AllDomainTabWrapper">
          <div className="Tab_flex">
            <Box
              sx={{
                borderColor: '#000',
                color: '#6417ff',
                textTransform: 'initial !important',
              }}
              id="AllDomainTabInnerWrapper"
            >
              <Tabs
                indicatorColor="Black"
                className={classes.tabs}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                id="AllDomainTab"
              >
                {TabMap}
              </Tabs>
            </Box>
          </div>
        </Box>
        {TabPanelMap}

        {loaded ? (
          <CircularProgress />
        ) : (
          <>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>
        )}
        <>
          <Backdrop
            className={classes.backdrop}
            open={popup}
            onClick={() => {
              if (localStorage.length === 0) {
                setTimeout(() => {
                  window.location = '/';
                }, 5000);
              }
            }}
          >
            <Card
              maxWidth="sm"
              style={{
                marginTop: '4vh',
                maxWidth: '41%',
                borderRadius: '16px',
              }}
            >
              <Box
                style={{ color: 'black', textAlign: 'right', padding: '5px' }}
              >
                {/* <ClearIcon
                  className="PopupClearIcon"
                  onClick={() => {
                    setPopup(false);
                    if (localStorage.length === 0) {
                      window.location = '/';
                    }
                  }}
                /> */}
              </Box>
              <CardHeader className="DeletPopupHeader" title={header} />
              {/* <hr /> */}
              <CardContent id="DeleteModalContent">
                <Typography id="DeleteText" variant="h6" color="textPrimary">
                  {msg}
                </Typography>
                {defaultPop ? (
                  <>
                    <PrimaryButton id="yesButtons" onClick={deleteAccount}>
                      Permanently delete account
                    </PrimaryButton>
                    <SecondaryButton
                      id="noButtons"
                      onClick={() => {
                        setPopup(false);
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                  </>
                ) : null}
              </CardContent>
            </Card>
          </Backdrop>
        </>
      </FullPageWrapper>
    </>
  );
}
