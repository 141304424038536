import tw from 'twin.macro';

const OuterWrapper = tw.div`bg-gradient-to-t from-[#BFD9FF] via-[#EEF3FF] to-[#BFD9FF] m-0`;

const Wrapper = tw.div`w-full md:w-1/2 mx-auto`;

export default function IntroWrapper({ children }) {
  return (
    <OuterWrapper id="IntroBG">
      <Wrapper>{children}</Wrapper>
    </OuterWrapper>
  );
}
