import { Auth } from 'aws-amplify';
import LoginButton from './LoginButtonLink';
import microsoftIconImageSrc from 'images/microsoft-icon.png';

export default function MicrosoftLoginButton({ text }) {
  return (
    <>
      <LoginButton
        onClick={(e) => {
          e.preventDefault();
          localStorage.setItem('loginType', 'signup');
          localStorage.setItem('selected_provider', 'Microsoft');
          localStorage.setItem('connect_provider', 'Microsoft');
          Auth.federatedSignIn({ provider: 'Microsoft' });
        }}
        url="https://login.microsoftonline.com"
        text={`${text}`}
        iconImageSrc={microsoftIconImageSrc}
      />
    </>
  );
}
