import FullPageWrapper from 'components/wrapper/FullPageWrapper';
import WelcomeBanner from 'components/dashboard/WelcomeBanner';
import Tiles from 'components/dashboard/Tiles';
import DomainTable from 'components/dashboard/DomainTable';
import HeadingText from 'components/dashboard/HeadingText';
import Search from 'components/dashboard/Search';
import { useEffect, useContext } from 'react';
import { Context } from '../store';

export default function NewDashboard() {
  const [state, dispatch] = useContext(Context);

  useEffect(() => {});
  return (
    <FullPageWrapper selected="dashboard" class="DashboardWrapper">
      <div id="DashWrapper">
        <Tiles />
      </div>
    </FullPageWrapper>
  );
}
