import { CssBaseline, Container, Paper } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import Header, {
  ImageLink,
  NavLink,
  NavLinks,
} from 'components/headers/light.js';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import CustomizeIconImage from 'images/customize-icon.svg';
import FastIconImage from 'images/fast-icon.svg';
import ShieldIconImage from 'images/shield-icon.svg';
import SupportIconImage from 'images/support-icon.svg';
import FeedbackIcon from 'images/feedback.png';
import UserSignoutIconImage from 'images/user-signout.svg';
import ReferFriendImage from 'images/ReferFriend.svg';
//const Icon = tw.img`inline-block mr-2`;
const Destoplinks = [];

const mobileLinkItems = [
  {
    imageSrc: ShieldIconImage,
    text: 'Dashboard',
    href: 'dashboard',
  },
  {
    imageSrc: FastIconImage,
    text: 'My Data',
    href: 'my-data',
  },
  {
    imageSrc: SupportIconImage,
    text: 'My Deletion Requests',
    href: 'my-deletion-requests',
  },
  {
    imageSrc: CustomizeIconImage,
    text: 'Account Settings',
    href: 'account',
  },
  {
    imageSrc: UserSignoutIconImage,
    text: 'Sign Out',
    href: 'signout',
    onClick: (ev) => {
      ev.preventDefault();
      localStorage.removeItem('myId');
      localStorage.removeItem('routeReload');
      localStorage.removeItem('ProgressBarApiCall');
      localStorage.removeItem('fromSignUp');
      Auth.signOut();
    },
  },
  {
    imageSrc: FeedbackIcon,
    text: 'Feedback',
    href: 'userFeedback',
  },
  {
    imageSrc: ReferFriendImage,
    text: 'Refer A Friend',
    href: 'referAFriend',
  },
];

export default function SmallPageWrapper({ children }) {
  const history = useHistory();
  const linkMapper = (item) => {
    const onClick = item.onClick
      ? item.onClick
      : (e) => {
          e.preventDefault();
          history.push(`/${item.href}`);
        };

    if (item.imageSrc) {
      return (
        <ImageLink key={item.href} href={`/${item.href}`} onClick={onClick}>
          <img src={item.imageSrc} alt={item.text} />
          <span>{item.text}</span>
        </ImageLink>
      );
    }

    return (
      <NavLink key={item.href} href={`/${item.href}`} onClick={onClick}>
        {item.text}
      </NavLink>
    );
  };
  const mobileLinks = mobileLinkItems.map(linkMapper);

  const mLinks = [<NavLinks key={2}>{mobileLinks}</NavLinks>];
  return (
    <AnimationRevealPage disabled>
      {/* <Header links={Destoplinks} mobileLinks={mLinks} /> */}
      <CssBaseline />
      <Container
        maxWidth="sm"
        style={{ marginTop: '4vh' }}
        id="RequestDeletetionPopup"
      >
        <Paper variant="outlined">{children}</Paper>
      </Container>
    </AnimationRevealPage>
  );
}
