import { useContext, useEffect } from 'react';
import FullPageWrapper from 'components/wrapper/FullPageWrapper';
import FeedbackTypeform from './FeedbackTypeform.js';
import { Context } from 'store';
export default function Feedback() {
  const [state, dispatch] = useContext(Context);
  useEffect(() => {
    dispatch({
      type: 'Selected_Row_Count',
      payload: 0,
    });
  }, []);
  return (
    <FullPageWrapper selected="feedback">
      <div id="referfriend">
        <FeedbackTypeform />
      </div>
    </FullPageWrapper>
  );
}
