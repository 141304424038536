import tw from 'twin.macro';

import CustomizeIconImage from 'images/customize-icon.svg';
import FastIconImage from 'images/fast-icon.svg';
import ShieldIconImage from 'images/shield-icon.svg';
import SupportIconImage from 'images/support-icon.svg';
import FeedbackIcon from 'images/feedback.png';
import SidebarItem from './SidebarItem';
import ReferFriendImage from 'images/ReferFriend.svg';
import ReferFriendImgWhite from 'images/ReferFriendWhite.png';

import { useContext, useState } from 'react';
import { TwitterButton } from 'react-social';
import Fbicon from '@material-ui/icons/Facebook';
import Twicon from '@material-ui/icons/Twitter';
import { Context } from 'store';
import { makeStyles } from '@material-ui/core/styles';
import ReferFriend from '../../views/Referer.js';
import '../../views/ReferFriend.css';
import { Backdrop, Card } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { FacebookShareButton } from 'react-share';
var CryptoJS = require('crypto-js');

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const SidebarNav = tw.nav`hidden lg:flex `;
const SidebarContainer = tw.div`mt-5`;

const navItems = [
  {
    imageSrc: ShieldIconImage,
    text: 'Dashboard',
    href: 'dashboard',
  },
  {
    imageSrc: FastIconImage,
    text: 'My Data',
    href: 'my-data',
  },
  {
    imageSrc: SupportIconImage,
    text: 'My Deletion Requests',
    href: 'my-deletion-requests',
  },
  {
    imageSrc: CustomizeIconImage,
    text: 'Account Settings',
    href: 'account',
  },
  {
    imageSrc: FeedbackIcon,
    text: 'Feedback',
    href: 'userFeedback',
  },
  {
    imageSrc: ReferFriendImage,
    text: 'Refer A Friend',
    href: 'referAFriend',
  },
];

export default function Sidebar({ selected }) {
  const [state] = useContext(Context);
  const [popup, setPopup] = useState(false);

  const classes = useStyles();
  var queryparam =
    'utm_source=pfm&utm_medium=website&utm_campaign=refer-a-friend&referrer_email=' +
    state.user.email;
  var fbqueryparam =
    'utm_source=pfm&utm_medium=facebook&utm_campaign=refer-a-friend&referrer_email=' +
    state.user.email;
  var twqueryparam =
    'utm_source=pfm&utm_medium=twitter&utm_campaign=refer-a-friend&referrer_email=' +
    state.user.email;

  // Encrypt
  var encryptedquerryparam = CryptoJS.AES.encrypt(
    queryparam,
    'secret-key@123$!'
  ).toString();
  var encryptedquerryparamFb = CryptoJS.AES.encrypt(
    fbqueryparam,
    'secret-key@123$!'
  ).toString();
  var encryptedquerryparamTw = CryptoJS.AES.encrypt(
    twqueryparam,
    'secret-key@123$!'
  ).toString();
  //var encryptedquerryparam = tinyurlres;
  var emailurl = 'https://privacyfor.me?' + encryptedquerryparam;
  var fburl = 'https://privacyfor.me?' + encryptedquerryparamFb;
  var twurl = 'https://privacyfor.me?' + encryptedquerryparamTw;

  let msg =
    'Hey Twitter Friends! I just tried out PrivacyForMe to reclaim my data from a lot of companies! Check it out here!';
  let fbmsg =
    'Hey Facebook Friends! I just tried out PrivacyForMe to reclaim my data from a lot of companies! Check it out here!';

  const items = navItems.map((item) => (
    <SidebarItem
      key={item.href}
      selected={selected === item.href}
      {...item}
      id="PFMSidebar"
    />
  ));

  return (
    <div>
      <div title="Refer A Friend" className="RAFPopup">
        <span
          onClick={(e) => {
            {
              !popup ? setPopup(true) : setPopup(false);
            }
          }}
        >
          <img src={ReferFriendImgWhite} alt="Refer a friend" />
        </span>
      </div>
      <SidebarNav>
        <SidebarContainer id="PFMSidebar">{items}</SidebarContainer>
      </SidebarNav>
      <>
        {
          <Backdrop id="modalPopup" className={classes.backdrop} open={popup}>
            <Card>
              <div className="RAF">
                <div class="socialMainWrapper">
                  <ClearIcon
                    className="ReferClose"
                    onClick={() => {
                      setPopup(false);
                    }}
                  />
                  <div class="socialhead">Refer A Friend </div>
                  <div className="Custom-d-flex">
                    <div class="ReferalLink">
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        placeholder="https://privacyfor.me?U2FsdGVkX1+nVJyQHFUZmZPfkvedKyliZTg0JkT3IJQziMmlNm8QJhmD/YTz266Qj+3agW774b+V2fIqCrxbTz0eToCBASVeJnsuXgcungRu+8iFAWXqsUkrsu3XQPKaiXW+b+sz4iWLVr3htVnyK1duli5jdfbSwNbEG7dc+TcTVK0BqprcSeChIIgd1w+i"
                      />
                      <span
                        className="URLCopy"
                        id="copybtn"
                        title="Copy"
                        onClick={(e) => {
                          e.preventDefault();
                          navigator.clipboard.writeText(emailurl);
                          // document.getElementById('copybtn').innerHTML =
                          //  'Url Copied';
                        }}
                      >
                        <i class="fa fa-copy"></i>
                      </span>
                    </div>
                    <div class="SocialLinks">
                      {/* <div>
                      <h3 class="SocialTitle">Refer by Social Media </h3>
                    </div> */}
                      <div class="SocialIcons">
                        <FacebookShareButton
                          url={fburl}
                          quote={fbmsg}
                          hashtag={'PrivacyForMe'}
                          description={'PrivacyForMe'}
                          className="fbsocial"
                        >
                          <Fbicon />
                        </FacebookShareButton>
                        <TwitterButton
                          url={twurl}
                          message={msg}
                          appId="23066349"
                          class="twsocial"
                        >
                          <Twicon />
                        </TwitterButton>
                        <TwitterButton
                          url={twurl}
                          message={msg}
                          appId="23066349"
                          class="twsocial"
                        ></TwitterButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="referfriend">
                  <ReferFriend />
                </div>
              </div>
            </Card>
          </Backdrop>
        }
      </>
    </div>
  );
}
