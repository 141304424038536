import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import tw from 'twin.macro';
import styled from 'styled-components';

import { Context } from 'store';
import SmallPageWrapper from 'components/wrapper/SmallPageWrapper';

const Wrapper = tw.div`p-8`;
const Header = tw.div`text-lg mb-2`;
const Message = tw.p`mb-5`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-[#E25668] text-gray-100 w-full py-4 rounded-lg  transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Email = tw.span`text-primary-700`;
const ErrorMessage = tw.div`bg-red-300 text-red-800 text-sm border border-red-800 rounded my-2 p-4`;

export default function ConfirmSignUp({ submitButtonText = 'Confirm' }) {
  const history = useHistory();
  const [state] = useContext(Context);
  const [errorMessage, setErrorMessage] = useState(null);

  const personalizedHeader = state.user.name ? `${state.user.name}, ` : null;
  const personalizedMessage = state.user.email ? (
    <>
      . An email has been sent to you at <Email>{state.user.email}</Email>
    </>
  ) : null;
  const error = errorMessage ? (
    <ErrorMessage>{errorMessage}</ErrorMessage>
  ) : null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // TODO: Disable form
    try {
      const formData = new FormData(e.target);
      const code = formData.get('verification-code');

      await Auth.confirmSignUp(state.user.email, code);
      history.push('/login');
    } catch (ex) {
      // TODO: Enable form
      if (['CodeMismatchException', 'ExpiredCodeException'].includes(ex.code)) {
        return setErrorMessage(ex.message);
      }
      console.error('Unable to confirm sign up: ', ex);
    }
  };

  return (
    <SmallPageWrapper>
      <Wrapper>
        <Header>{personalizedHeader}Confirm Your Email Account</Header>
        <Message>
          To continue, please enter the email verification code
          {personalizedMessage}
        </Message>
        <Form onSubmit={handleSubmit}>
          <Input
            name="verification-code"
            type="text"
            placeholder="verification code"
            required
          />
          <SubmitButton>
            <span className="text" style={{ color: '#FFFFFF' }}>
              {submitButtonText}
            </span>
          </SubmitButton>
          {error}
        </Form>
      </Wrapper>
    </SmallPageWrapper>
  );
}
