// First we need to import axios.js
import axios from 'axios';
//import Store from '../store';
// Next we make an 'instance' of it
function getBaseURL() {
  switch (window.location.hostname) {
    case 'localhost':
      // return 'http://dev-pieeye-alb-1723185869.us-west-1.elb.amazonaws.com';
      return 'https://bes1occzod.execute-api.us-east-2.amazonaws.com/uat';
    case 'dev.privacyfor.me':
      return 'https://bes1occzod.execute-api.us-east-2.amazonaws.com/uat';
    case 'privacyfor.me':
      return 'https://api.privacyfor.me';
    default:
      console.warn(`Unknown host for base url: ${window.location.hostname}`);
  }
}
export const axiosServiceInstance = axios.create({
  baseURL: getBaseURL(),
});

export const axiosNoBaseUrlInstance = axios.create();

axiosServiceInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('myId');
  config.headers.token = token;
  return config;
});

axiosServiceInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      document.getElementById('ppbtn').click();
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else if (status === 404) {
      //return Promise.reject(error);
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosServiceInstance;
