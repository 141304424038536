import { useState } from 'react';
import { Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Header, Message, Form, Input, SubmitButton } from './Components';

export default function ForgotPasswordForm({
  setEmail,
  setError,
  setState,
  setStatus,
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setStatus(null);

    const formData = new FormData(e.target);
    const email = formData.get('email');
    setEmail(email);

    try {
      const response = await Auth.forgotPassword(email);
      const {
        CodeDeliveryDetails: { AttributeName, Destination },
      } = response;

      setStatus(`An ${AttributeName} has been sent to ${Destination}`);
      setState('reset');
    } catch (e) {
      console.error('Unable to reset password:', e);
      const { message } = e;
      setError(`Unable to reset password: ${message}`);
    }
    setLoading(false);
  };

  const loadIndicator = loading ? (
    <CircularProgress color="inherit" size="1rem" />
  ) : null;

  return (
    <>
      <Header>Forgot Password</Header>
      <Message>Enter your email address to reset your password.</Message>
      <Form onSubmit={handleSubmit} disabled={loading}>
        <Input name="email" type="email" placeholder="email" required />
        <SubmitButton disabled={loading}>
          {loadIndicator}
          <span className="text">Reset Password</span>
        </SubmitButton>
      </Form>
    </>
  );
}
