import { useContext, useEffect, useState } from 'react';
import tw from 'twin.macro';

import { Context } from 'store';
import Card from './TileCard';
import { ErrorMessage } from 'components/misc/Message';
import {
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { axiosServiceInstance } from 'config/axiosConfig';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
//import 'bootstrap/dist/css/bootstrap.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import DomainTable from './DomainTable';
import HeadingText from './HeadingText';
import DsrDomainTable from './DsrDomainTable';
import CompleteDomainTable from './CompleteDomainTable';
import AllTileDataTable from './AllTileDataTable';

const UserLink = tw.span`text-primary-500`;
const CardsWrapper = tw.div`mb-8 flex flex-wrap -mr-3 relative`;
const CardContainer = tw.div`relative mt-1 sm:pt-3 pr-3 w-full sm:w-1/3 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  alignItemsAndJustifyContent: {
    width: '100%',
    height: 380,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
export default function Tiles() {
  const classes = useStyles();

  const [state, dispatch] = useContext(Context);
  const [tableLoader, setTableLoader] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [getError, setConnError] = useState(false);
  const [errors, setError] = useState(null);
  const [scanCount, setScanCount] = useState(0);
  const [activeHeader, setActiveHeader] = useState('Companies With Your Data');
  const [getState, setState] = useState({
    myData: [],
    dropDownData: [],
  });
  const [getCountFlag, setCountFlag] = useState(false);
  const [tileData, setTileData] = useState('pending');
  const [titleData, setTitleData] = useState('Companies With Your Data');
  const [textData, setTextData] = useState(
    'Here you can find a list of companies that have your data. Click Request Deletion to start a Data Subject Request.'
  );

  const [isDropDownData, setIsDropDownData] = useState([]);
  let intitscanpercentage = 1;
  useEffect(() => {
    if (!initialLoaded && state.user.username) {
      getDomains();
      ScanProgressBarInterval();
    }
    if (
      state.reloadDomainTile === true ||
      state.reloadDomainTable === true ||
      localStorage.getItem('RealoadCount') === true
    ) {
      getDomains();
      localStorage.setItem('RealoadCount', false);
      dispatch({
        type: 'Reload_Domain_Tile',
        payload: false,
      });
      dispatch({
        type: 'Reload_Domain_Table',
        payload: false,
      });
    }
    if (state.accSwitch === true) {
      setLoaded(false);
      selectedTile('', cardData[0]);
      dispatch({
        type: 'ACC_Switch',
        payload: false,
      });
    }
    const countUpdate = setInterval(() => {
      if (((getState || []).myData || []).processingDomain !== 0) {
        getDomains(countUpdate);
        dispatch({
          type: 'Reload_Domain_Table',
          payload: true,
        });
      } else {
        clearInterval(countUpdate);
      }
    }, 30000);
    return () => clearInterval(countUpdate);
  }, [state]);
  async function getDomains() {
    try {
      setError(null);
      setInitialLoaded(true);
      let dropDownData;

      if (
        (state.dashboardData || []).dropDownData === undefined ||
        state.accSwitch
      ) {
        const responseData = await axiosServiceInstance
          .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
            requestInfo: {
              userName: state.user.username,
              token: state.user.idToken || '',
            },
          })
          .catch((error) => {
            setLoaded(true);
            setConnError(true);
          });
        dropDownData = (responseData.data || []).responseListObject || {};
      } else {
        dropDownData =
          state.progressData !== undefined
            ? state.progressData || []
            : (state.dashboardData || []).dropDownData;
      }
      let selectedProvider =
        dropDownData.length > 1
          ? localStorage.getItem('connect_provider')
          : localStorage.getItem('selected_account') ||
            localStorage.getItem('connect_provider');
      var InitialMail = (dropDownData || []).filter((x) => {
        if (x.emailType === selectedProvider) {
          return x;
        }
      });
      if (InitialMail.length !== 0) {
        localStorage.setItem(
          'selected_account',
          InitialMail[0].emailType || ''
        );
        localStorage.setItem('updatedEmailId', InitialMail[0].emailId || '');
      } else {
        const responseData = await axiosServiceInstance
          .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
            requestInfo: {
              userName: state.user.username,
              token: state.user.idToken || '',
            },
          })
          .catch((error) => {
            setLoaded(true);
            setConnError(true);
          });
        let ddropdownData = (responseData.data || []).responseListObject || {};
        localStorage.setItem(
          'selected_account',
          //state.dashboardData.dropDownData[0].emailType || ''
          ddropdownData[0].emailType || ''
        );
        localStorage.setItem(
          'updatedEmailId',
          //state.dashboardData.dropDownData[0].emailId || ''
          ddropdownData[0].emailId || ''
        );
      }

      let dropdownEmailId = localStorage.getItem('updatedEmailId');
      const allDomain =
        dropDownData === undefined
          ? []
          : await axiosServiceInstance
              .post(`/pieeye-pfmdomain/api/dashboard/_getAllDomain`, {
                requestInfo: {
                  userName: state.user.username || '',
                  token: state.user.idToken || '',
                },
                requestBody: {
                  emailId:
                    dropdownEmailId ||
                    ((state || []).dashboardData || []).emailId ||
                    dropDownData[0].emailId ||
                    '',
                },
              })
              .catch((error) => {
                setLoaded(true);
                setCountFlag(true);
              });
      setState({
        myData:
          (allDomain || []).data === undefined
            ? []
            : allDomain.data.responseListObject[0],
        dropDownData: dropDownData === undefined ? [] : dropDownData,
        emailId:
          dropdownEmailId === null || dropdownEmailId === 0
            ? dropDownData[0].emailId
            : dropdownEmailId,
      });
      setLoaded(true);
      dispatch({
        type: 'DASHBOARD_DATA',
        payload: {
          myData:
            (allDomain || []).data === undefined
              ? []
              : allDomain.data.responseListObject[0],
          dropDownData: dropDownData === undefined ? [] : dropDownData,
          emailId:
            dropdownEmailId === null || dropdownEmailId === 0
              ? dropDownData[0].emailId
              : dropdownEmailId,
        },
      });
      dispatch({
        type: 'DASHBOARD_DATA_COUNT',
        payload: 0,
      });

      if (dropdownEmailId !== null) {
        var selectedEmailObj =
          dropDownData ||
          [].filter(function (e) {
            return e.emailId === dropdownEmailId;
          });
        if (selectedEmailObj[0].scanPercentage === 0) {
          intitscanpercentage = 1;
        } else {
          intitscanpercentage = selectedEmailObj[0].scanPercentage;
        }
        /*Hide progressbar if scan is*/
        if (
          selectedEmailObj[0].status === 'completed' &&
          selectedEmailObj[0].scanPercentage === 100
        ) {
          localStorage.setItem('ProgressBarApiCall', true);
          localStorage.removeItem('rescanenabled');
        } else {
          localStorage.setItem(
            'rescanenabled',
            selectedEmailObj[0].emailId || ''
          );
          localStorage.removeItem('ProgressBarApiCall');
        }
        setTimeout(() => {
          setScanCount(intitscanpercentage);
        }, 1000);
      } else {
        let scanpercentageval = dropDownData[0].scanPercentage;
        if (dropDownData[0].status === 'pending' && scanpercentageval === 100) {
          setScanCount('5');
        } else {
          if (scanpercentageval === 0) {
            intitscanpercentage = 1;
          } else {
            intitscanpercentage = scanpercentageval;
          }
          if (
            dropDownData[0].status === 'completed' &&
            dropDownData[0].scanPercentage === 100
          ) {
            localStorage.setItem('ProgressBarApiCall', true);
            localStorage.removeItem('rescanenabled');
          } else {
            localStorage.setItem(
              'rescanenabled',
              dropDownData[0].emailId || ''
            );
            localStorage.removeItem('ProgressBarApiCall');
          }
          setTimeout(() => {
            setScanCount(intitscanpercentage);
          }, 1000);
        }
      }
    } catch (e) {
      console.error('Unable to fetch domains:', e);

      if ((e.response || []).status || '' === 404) {
        setLoaded(true);
        setError({ ...((e.response || []).status || null) });
      }
      setLoaded(true);
    }
    dispatch({
      type: 'Reload_Domain_Tile',
      payload: false,
    });
  }

  async function ScanProgressBarInterval() {
    const limitedInterval = setInterval(() => {
      if (
        !localStorage.getItem('ProgressBarApiCall') &&
        localStorage.length > 0
      ) {
        UpdateScanProgress(limitedInterval);

        dispatch({
          type: 'ACC_Refresh',
          payload: true,
        });
      }
    }, 30000);
  }
  async function UpdateScanProgress(scaninterval) {
    try {
      setError(null);
      var dropDownData = await axiosServiceInstance
        .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
          requestInfo: {
            userName: state.user.username,
            token: state.user.idToken || '',
          },
        })
        .catch((error) => {
          setLoaded(true);
        });
      dispatch({
        type: 'Progress_Bar',
        payload: (dropDownData.data || []).responseListObject || {},
      });
      let dropdownEmailId = localStorage.getItem('updatedEmailId');
      let dropdownEmailIdcookie = localStorage.getItem('updatedEmailId');

      if (dropDownData.data.responseListObject.length <= 1) {
        if (dropDownData.data.responseListObject[0].scanPercentage === 0) {
          intitscanpercentage = 1;
        } else {
          intitscanpercentage =
            dropDownData.data.responseListObject[0].scanPercentage;
        }
        setTimeout(() => {
          setScanCount(intitscanpercentage);
        }, 1000);
        if (dropDownData.data.responseListObject[0].scanPercentage >= 100) {
          clearInterval(scaninterval);
          localStorage.setItem('ProgressBarApiCall', true);
          localStorage.removeItem('rescanenabled');
        }
      } else {
        if (dropdownEmailId !== null) {
          var selectedEmailObj = dropDownData.data.responseListObject.filter(
            function (e) {
              return e.emailId === dropdownEmailId;
            }
          );
        } else {
          selectedEmailObj = dropDownData.data.responseListObject.filter(
            function (e) {
              return (
                e.emailId === dropDownData.data.responseListObject[0].emailId
              );
            }
          );
        }
        if (
          selectedEmailObj[0].status === 'completed' &&
          selectedEmailObj[0].scanPercentage === 100
        ) {
          dispatch({
            type: 'Reload_Domain_Table',
            payload: true,
          });
        } else {
          localStorage.setItem('rescanenabled', selectedEmailObj[0].emailId);
        }
        setTimeout(() => {
          setScanCount(selectedEmailObj[0].scanPercentage);
        }, 1000);
        if (
          dropDownData.data.responseListObject[0].scanPercentage >= 100 &&
          dropDownData.data.responseListObject[1].scanPercentage >= 100
        ) {
          clearInterval(scaninterval);
          localStorage.setItem('ProgressBarApiCall', true);
          localStorage.removeItem('rescanenabled');
        }
      }
      const allDomain =
        dropDownData === undefined
          ? []
          : await axiosServiceInstance
              .post(`/pieeye-pfmdomain/api/dashboard/_getAllDomain`, {
                requestInfo: {
                  userName: state.user.username || '',
                  token: state.user.idToken || '',
                },
                requestBody: {
                  emailId:
                    dropdownEmailId === null || dropdownEmailId === 0
                      ? dropDownData.data.responseListObject[0].emailId
                      : dropdownEmailId,
                },
              })
              .catch((error) => {
                setLoaded(true);
                setCountFlag(true);
              });
      setState({
        myData:
          (allDomain || []).data === undefined
            ? []
            : allDomain.data.responseListObject[0],
        dropDownData:
          dropDownData === undefined
            ? []
            : dropDownData.data.responseListObject,
        emailId:
          dropdownEmailId === undefined || dropdownEmailId === null
            ? dropDownData.data.responseListObject[0].emailId
            : dropdownEmailId,
      });

      dispatch({
        type: 'DASHBOARD_DATA',
        payload: {
          myData:
            (allDomain || []).data === undefined
              ? []
              : allDomain.data.responseListObject[0],
          dropDownData:
            dropDownData === undefined
              ? []
              : (dropDownData || []).data.responseListObject,
          emailId:
            dropdownEmailId === null || dropdownEmailId === 0
              ? dropDownData.data.responseListObject[0].emailId
              : dropdownEmailId,
        },
      });
      setIsDropDownData((dropDownData || []).data.responseListObject);
      dispatch({
        type: 'DASHBOARD_DATA_COUNT',
        payload: 0,
      });
      setLoaded(true);
    } catch (e) {
      console.error('Unable to fetch domains:', e);
      setLoaded(true);
      if ((e.response || []).status || '' === 404) {
        setLoaded(true);
        setError({ ...((e.response || []).status || null) });
      }
    }
  }
  let cardData = [
    {
      header: 'Companies With Your Data',
      description:
        'The number of companies with your data. Each of these companies share a piece of your digital footprint.',
      value:
        (((getState || []).myData || []).neededDomain || 0) +
        (((getState || []).myData || []).pendingDomain || 0),
      href: 'my-data',
      buttonText: 'View Data',
      tableDescription:
        'Here you can find a list of companies that have your data. Click Request Deletion to start a Data Subject Request.',
    },
    {
      header: 'Submitted Data Subject Requests',
      description:
        "The number of Data Subject Requests you have submitted. You're doing great, keep up the good work.",
      value:
        (((getState || []).myData || []).processingDomain || 0) +
        (((getState || []).myData || []).failedDomain || 0) +
        (((getState || []).myData || []).initiatedDomain || 0),
      href: 'my-deletion-requests?filter=initiated',
      buttonText: 'View Submitted Requests',
      tableDescription:
        'Here you can find a list of submitted data subject requests.',
    },
    {
      header: 'Successful Data Subject Requests',
      description:
        'The number of Data Subject Requests that have successfully been completed. Just that many less possible data branches for you to worry about.',
      value: ((getState || []).myData || []).reclaimedDomain || 0,
      href: 'my-deletion-requests',
      buttonText: 'View Completed Requests',
      tableDescription:
        'Here you can find a list of successful data subject requests.',
    },
  ];
  const errorMessage = errors
    ? Object.keys(errors).map((i) => (
        <ErrorMessage>
          {errors[i].message} [code:{errors[i].error}]
        </ErrorMessage>
      ))
    : null;
  const cardContent = cardData.map((datum, index) => {
    return (
      <CardContainer
        key={datum.href}
        id={`DashboardCard${index}`}
        onClick={(e) => {
          e.preventDefault();
          if (datum.value > 0) {
            selectedTile(e, datum);
          }
        }}
        disabled={datum.value === 0}
      >
        <Card {...datum} aHeader={activeHeader} />
      </CardContainer>
    );
  });
  const emailFormContent = '';
  function selectedTile(e, data) {
    dispatch({
      type: 'Selected_Row_Count',
      payload: 0,
    });
    dispatch({
      type: 'AG_DATA_LIST',
      payload: [],
    });
    setActiveHeader(data.header);
    if (data.header == 'Companies With Your Data') {
      setTileData('pending');
      setTitleData(cardData[0].header);
      setTextData(cardData[0].tableDescription);
    }
    if (data.header == 'Submitted Data Subject Requests') {
      setTileData('initiated');
      setTitleData(cardData[1].header);
      setTextData(cardData[1].tableDescription);
    }
    if (data.header == 'Successful Data Subject Requests') {
      setTileData('reclaimed');
      setTitleData(cardData[2].header);
      setTextData(cardData[2].tableDescription);
    }
    dispatch({
      type: 'Pending_TILE_DATA',
      payload: [],
    });
    dispatch({
      type: 'ACC_Refresh',
      payload: true,
    });
  }
  return (
    <>
      {loaded ? (
        (((getState || []).myData || []).pendingDomain === 0 &&
          ((getState || []).myData || []).initiatedDomain === 0 &&
          ((getState || []).myData || []).reclaimedDomain &&
          0) ||
        getCountFlag ? (
          <>
            {emailFormContent}
            <Box style={{ textAlign: 'right' }}>
              <Link>
                <UserLink>
                  <Button
                    style={{ minWidth: '122px', float: 'right' }}
                    variant="outlined"
                    color="primary"
                    id="Refresh"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    <i
                      className="fa fa-refresh"
                      style={{ fontSize: 14, paddingRight: '5px' }}
                    />{' '}
                    Refresh
                  </Button>
                </UserLink>
              </Link>{' '}
            </Box>{' '}
            <Typography></Typography>
            Email scan is in progress. It might take some time depending on your
            mailbox. Please refresh the page.
          </>
        ) : state.noAccountFound ? (
          <>
            {emailFormContent}
            <Typography></Typography>
            Currently, Privacy For Me doesn't have any accounts connected.{' '}
            <Link to="/account">
              <UserLink>Connect an email provider</UserLink>
            </Link>{' '}
            to get started today!
          </>
        ) : (
          <>
            <Box style={{ textAlign: 'right' }}>
              <Link></Link>{' '}
            </Box>
            <>
              {localStorage.getItem('ProgressBarApiCall')
                ? ''
                : scanCount !== 100 && (
                    <>
                      <div className="Progress_d-flex">
                        <ProgressBar id="progressBar" now={scanCount} />
                        <h4
                          id="Percentage"
                          style={{
                            color: '#5071A8',
                            width: '14%',
                            fontWeight: '600',
                            textAlign: 'right',
                            paddingRight: '2px',
                          }}
                        >{`${scanCount}% completed`}</h4>
                      </div>
                    </>
                  )}
            </>
            <CardsWrapper>{cardContent}</CardsWrapper>
            <Button
              style={{ minWidth: '122px', float: 'right' }}
              variant="outlined"
              color="primary"
              id="Refresh"
              onClick={() => {
                setLoaded(false);
                UpdateScanProgress();
                dispatch({
                  type: 'Selected_Row_Count',
                  payload: 0,
                });
                dispatch({
                  type: 'AG_DATA_LIST',
                  payload: [],
                });
                dispatch({
                  type: 'Reload_Domain_Tile',
                  payload: true,
                });
                dispatch({
                  type: 'Pending_TILE_DATA',
                  payload: [],
                });
                dispatch({
                  type: 'ACC_Refresh',
                  payload: true,
                });
              }}
            >
              <i
                className="fa fa-refresh"
                style={{ fontSize: 14, paddingRight: '5px' }}
              />{' '}
              Refresh Data
            </Button>
          </>
        )
      ) : (
        <Box style={{ textAlign: 'center', margin: '68px' }}>
          <CircularProgress />
        </Box>
      )}
      {getCountFlag || (getState.dropDownData || []).length === 0 ? (
        ''
      ) : (
        <>
          <HeadingText title={titleData} text={textData} />
          <AllTileDataTable Tile={tileData} stateData={getState} />
        </>
      )}
    </>
  );
}
