import tw from 'twin.macro';
import styled from 'styled-components';
import './buttonnewStyle.css';
export const SocialLoginButton = styled.button`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: #1d2739;
  }
  .iconContainer {
    background: unset;
  }
  .SocialLoginBtn {
    background-color: #f8f8f8 !important;
  }
  .SocialLoginBtn:hover {
    background-color: rgba(247, 250, 252) !important;
  }
`;

export default function LoginButton({ iconImageSrc, text, onClick }) {
  return (
    <SocialLoginButton onClick={onClick} id="SocialLoginBtn">
      <span className="iconContainer">
        <img src={iconImageSrc} className="icon" alt={text} />
      </span>
      <span className="text">{text}</span>
    </SocialLoginButton>
    // <div class="g-sign-in-button" onClick={onClick}>
    //   <div class="content-wrapper">
    //     <div class="logo-wrapper">
    //       <img src='https://developers.google.com/identity/images/g-logo.png' alt={text} />
    //     </div>
    //     <span class="text-container">
    //       <span>{text}</span>
    //     </span>
    //   </div>
    // </div>
  );
}
