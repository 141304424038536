import { useContext, useEffect, useState } from 'react';
import tw from 'twin.macro';

import { Context } from 'store';
import Card from './Card';
import { ErrorMessage } from 'components/misc/Message';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { axiosServiceInstance } from 'config/axiosConfig';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
//import 'bootstrap/dist/css/bootstrap.css';
import ProgressBar from 'react-bootstrap/ProgressBar';

const UserLink = tw.span`text-primary-500`;
const CardsWrapper = tw.div`mb-8 flex flex-wrap -mr-3 relative`;
const CardContainer = tw.div`relative mt-1 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  alignItemsAndJustifyContent: {
    width: '100%',
    height: 380,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
export default function Cards() {
  const classes = useStyles();

  const [state, dispatch] = useContext(Context);
  const [loaded, setLoaded] = useState(false);
  const [errors, setError] = useState(null);
  const [scanpercentage, setScanpercentage] = useState(0);
  const [getState, setState] = useState({
    myData: [],
    dropDownData: [],
  });
  const [getCountFlag, setCountFlag] = useState(false);
  let intitscanpercentage = 1;
  useEffect(() => {
    async function getDomains() {
      try {
        setError(null);
        const dropDownData = await axiosServiceInstance
          .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
            requestInfo: {
              userName: state.user.username,
              token: state.user.idToken || '',
            },
          })
          .catch((error) => {
            setLoaded(true);
          });

        let dropdownEmailId = localStorage.getItem('updatedEmailId');

        const allDomain =
          dropDownData === undefined
            ? []
            : await axiosServiceInstance
                .post(`/pieeye-pfmdomain/api/dashboard/_getAllDomain`, {
                  requestInfo: {
                    userName: state.user.username || '',
                    token: state.user.idToken || '',
                  },
                  requestBody: {
                    emailId:
                      ((state || []).dashboardData || []).emailId ||
                      dropdownEmailId ||
                      dropDownData.data.responseListObject[0].emailId ||
                      '',
                  },
                })
                .catch((error) => {
                  setLoaded(true);
                  setCountFlag(true);
                });

        setState({
          myData:
            (allDomain || []).data === undefined
              ? []
              : allDomain.data.responseListObject[0],
          dropDownData:
            dropDownData === undefined
              ? []
              : dropDownData.data.responseListObject,
          emailId:
            dropdownEmailId === null || dropdownEmailId === 0
              ? dropDownData.data.responseListObject[0].emailId
              : dropdownEmailId,
        });

        let NeededDomain = allDomain.data.responseListObject[0].neededDomain;
        localStorage.setItem('NeededDomainCount', NeededDomain);

        dispatch({
          type: 'DASHBOARD_DATA',
          payload: {
            myData:
              (allDomain || []).data === undefined
                ? []
                : allDomain.data.responseListObject[0],
            dropDownData:
              dropDownData === undefined
                ? []
                : dropDownData.data.responseListObject,
            emailId:
              dropdownEmailId === null || dropdownEmailId === 0
                ? dropDownData.data.responseListObject[0].emailId
                : dropdownEmailId,
          },
        });
        dispatch({
          type: 'DASHBOARD_DATA_COUNT',
          payload: 0,
        });

        if (dropdownEmailId !== null) {
          var selectedEmailObj = dropDownData.data.responseListObject.filter(
            function (e) {
              return e.emailId === dropdownEmailId;
            }
          );
          if (selectedEmailObj[0].scanPercentage === 0) {
            intitscanpercentage = 1;
          } else {
            intitscanpercentage = selectedEmailObj[0].scanPercentage;
          }
          /*Hide progressbar if scan is*/
          if (
            selectedEmailObj[0].status === 'completed' &&
            selectedEmailObj[0].scanPercentage === 100
          ) {
            localStorage.setItem('ProgressBarApiCall', true);
            localStorage.removeItem('rescanenabled');
          } else {
            localStorage.setItem(
              'rescanenabled',
              selectedEmailObj[0].emailId || ''
            );
            localStorage.removeItem('ProgressBarApiCall');
          }

          setScanpercentage(intitscanpercentage);
        } else {
          let scanpercentageval =
            dropDownData.data.responseListObject[0].scanPercentage;
          if (
            dropDownData.data.responseListObject[0].status === 'pending' &&
            scanpercentageval === 100
          ) {
            setScanpercentage('5');
          } else {
            if (scanpercentageval === 0) {
              intitscanpercentage = 1;
            } else {
              intitscanpercentage = scanpercentageval;
            }
            if (
              dropDownData.data.responseListObject[0].status === 'completed' &&
              dropDownData.data.responseListObject[0].scanPercentage === 100
            ) {
              localStorage.setItem('ProgressBarApiCall', true);
              localStorage.removeItem('rescanenabled');
            } else {
              localStorage.setItem(
                'rescanenabled',
                dropDownData.data.responseListObject[0].emailId || ''
              );
              localStorage.removeItem('ProgressBarApiCall');
            }

            setScanpercentage(intitscanpercentage);
          }
        }

        //&&(localStorage.getItem('updatedEmailId') === localStorage.getItem('rescanenabled')

        // if(localStorage.getItem('rescanenabled') !== '' && localStorage.getItem('rescanenabled') !== null && localStorage.getItem('rescanenabled') !== undefined){
        //   if(localStorage.getItem('updatedEmailId') !== localStorage.getItem('rescanenabled')) {
        //     localStorage.setItem('ProgressBarApiCall', true);
        //     }
        //     else{
        //       localStorage.removeItem('ProgressBarApiCall');
        //     }
        //   }
        // if (!localStorage.getItem('ProgressBarApiCall')  ) {
        //   localStorage.setItem(
        //     'rescanenabled',
        //     dropdownEmailId ||
        //     ((state || []).dashboardData || []).emailId ||
        //       dropDownData.data.responseListObject[0].emailId ||
        //       ''
        //   );

        // }
        setLoaded(true);
      } catch (e) {
        console.error('Unable to fetch domains:', e);

        if ((e.response || []).status || '' === 404) {
          setLoaded(true);
          setError({ ...((e.response || []).status || null) });
        }
        setLoaded(true);
      }
    }

    if (!loaded && state.user.username) {
      getDomains();
      ScanProgressBarInterval();
    }

    const countUpdate = setInterval(() => {
      if (((getState || []).myData || []).processingDomain !== 0) {
        getDomains(countUpdate);
      } else {
        clearInterval(countUpdate);
      }
    }, 30000);
    return () => clearInterval(countUpdate);

    //UpdateScanProgress();
  });

  //[loaded, state, dispatch, getCountFlag]);
  async function ScanProgressBarInterval() {
    const limitedInterval = setInterval(() => {
      if (
        !localStorage.getItem('ProgressBarApiCall') &&
        localStorage.length > 0
      ) {
        UpdateScanProgress(limitedInterval);
      }
    }, 30000);
  }

  async function UpdateScanProgress(scaninterval) {
    try {
      setError(null);
      const dropDownData = await axiosServiceInstance
        .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
          requestInfo: {
            userName: state.user.username,
            token: state.user.idToken || '',
          },
        })
        .catch((error) => {
          setLoaded(true);
        });
      let dropdownEmailId = localStorage.getItem('updatedEmailId');

      let dropdownEmailIdcookie = localStorage.getItem('updatedEmailId');
      // localStorage.setItem(
      //   'rescanenabled',
      //   ((state || []).dashboardData || []).emailId ||
      //     dropdownEmailId ||
      //     dropDownData.data.responseListObject[0].emailId ||
      //     ''
      // );
      if (dropDownData.data.responseListObject.length <= 1) {
        if (dropDownData.data.responseListObject[0].scanPercentage === 0) {
          intitscanpercentage = 1;
        } else {
          intitscanpercentage =
            dropDownData.data.responseListObject[0].scanPercentage;
        }
        setScanpercentage(intitscanpercentage);

        if (dropDownData.data.responseListObject[0].scanPercentage >= 100) {
          clearInterval(scaninterval);
          localStorage.setItem('ProgressBarApiCall', true);
          localStorage.removeItem('rescanenabled');
        }
      } else {
        if (dropdownEmailId !== null) {
          var selectedEmailObj = dropDownData.data.responseListObject.filter(
            function (e) {
              return e.emailId === dropdownEmailId;
            }
          );
        } else {
          selectedEmailObj = dropDownData.data.responseListObject.filter(
            function (e) {
              return (
                e.emailId === dropDownData.data.responseListObject[0].emailId
              );
            }
          );
        }
        if (
          selectedEmailObj[0].status === 'completed' &&
          selectedEmailObj[0].scanPercentage === 100
        ) {
          //  localStorage.setItem('ProgressBarApiCall', true);
        } else {
          localStorage.setItem('rescanenabled', selectedEmailObj[0].emailId);
          // localStorage.removeItem('ProgressBarApiCall');
        }
        setScanpercentage(selectedEmailObj[0].scanPercentage);
        if (
          dropDownData.data.responseListObject[0].scanPercentage >= 100 &&
          dropDownData.data.responseListObject[1].scanPercentage >= 100
        ) {
          clearInterval(scaninterval);
          localStorage.setItem('ProgressBarApiCall', true);
          localStorage.removeItem('rescanenabled');
        }
      }
      const allDomain =
        dropDownData === undefined
          ? []
          : await axiosServiceInstance
              .post(`/pieeye-pfmdomain/api/dashboard/_getAllDomain`, {
                requestInfo: {
                  userName: state.user.username || '',
                  token: state.user.idToken || '',
                },
                requestBody: {
                  emailId:
                    dropdownEmailId === null || dropdownEmailId === 0
                      ? dropDownData.data.responseListObject[0].emailId
                      : dropdownEmailId,
                },
              })
              .catch((error) => {
                setLoaded(true);
                setCountFlag(true);
              });
      setState({
        myData:
          (allDomain || []).data === undefined
            ? []
            : allDomain.data.responseListObject[0],
        dropDownData:
          dropDownData === undefined
            ? []
            : dropDownData.data.responseListObject,
        emailId:
          dropdownEmailId === undefined || dropdownEmailId === null
            ? dropDownData.data.responseListObject[0].emailId
            : dropdownEmailId,
      });

      let NeededDomain = allDomain.data.responseListObject[0].neededDomain;
      localStorage.setItem('NeededDomainCount', NeededDomain);

      dispatch({
        type: 'DASHBOARD_DATA',
        payload: {
          myData:
            (allDomain || []).data === undefined
              ? []
              : allDomain.data.responseListObject[0],
          dropDownData:
            dropDownData === undefined
              ? []
              : dropDownData.data.responseListObject,
          emailId:
            dropdownEmailId === null || dropdownEmailId === 0
              ? dropDownData.data.responseListObject[0].emailId
              : dropdownEmailId,
          // emailId:
          //   state.dashboardData === undefined ||
          //   state.dashboardData.length === 0
          //     ? dropDownData.data.responseListObject[0].emailId
          //     : ((state || []).dashboardData || []).emailId,
        },
      });
      dispatch({
        type: 'DASHBOARD_DATA_COUNT',
        payload: 0,
      });
      /*
      let scanpercentage
      val =
        dropDownData.data.responseListObject[0].scanPercentage;
      
      if ( dropDownData.data.responseListObject.length > 1) {
        var selectedEmailObj =  dropDownData.data.responseListObject.filter(function (e) {
          return e.emailId == getState.emailId;
        });
        setScanpercentage(selectedEmailObj[0].scanPercentage);
        if (selectedEmailObj[0].scanPercentage >= 100) {
          clearInterval(scaninterval);
          localStorage.setItem('ProgressBarApiCall', true);
        }
      } else {
        let userData = dropDownData.data.responseListObject;
        
        setScanpercentage(userData[0].scanPercentage);

        if (userData[0].scanPercentage >= 100) {
          clearInterval(scaninterval);
          localStorage.setItem('ProgressBarApiCall', true);
        }
      }
      */
      setLoaded(true);
    } catch (e) {
      console.error('Unable to fetch domains:', e);
      setLoaded(true);
      if ((e.response || []).status || '' === 404) {
        setLoaded(true);
        setError({ ...((e.response || []).status || null) });
      }
    }
    //},30000);
  }
  async function _changeDashBoardData(emailId) {
    try {
      setLoaded(false);
      setCountFlag(false);
      const allDomain = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_getAllDomain`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            emailId: emailId,
          },
        })
        .catch((error) => {
          setLoaded(true);
        });
      localStorage.setItem('updatedEmailId', emailId);

      let NeededDomain = allDomain.data.responseListObject[0].neededDomain;
      localStorage.setItem('NeededDomainCount', NeededDomain);

      // if(localStorage.getItem('rescanenabled') !== '' && localStorage.getItem('rescanenabled') !== null && localStorage.getItem('rescanenabled') !== undefined){
      //   if(localStorage.getItem('updatedEmailId') !== localStorage.getItem('rescanenabled')) {
      //     localStorage.setItem('ProgressBarApiCall', true);
      //     }
      //     else{
      //       localStorage.removeItem('ProgressBarApiCall');
      //     }
      //   }

      setState({
        myData:
          allDomain === undefined ? [] : allDomain.data.responseListObject[0],
        dropDownData: getState.dropDownData || [],
        emailId: emailId,
      });

      dispatch({
        type: 'DASHBOARD_DATA',
        payload: {
          myData: allDomain.data.responseListObject[0],
          dropDownData: getState.dropDownData,
          emailId: emailId,
        },
      });
      dispatch({
        type: 'MY_SELECTED_DATA',
        payload: [],
      });
    } catch (error) {
      setLoaded(true);
    }

    if (getState.dropDownData.length > 1) {
      var selectedEmailObj = getState.dropDownData.filter(function (e) {
        return e.emailId == emailId;
      });

      var scanpercent = selectedEmailObj[0].scanPercentage;
      if (selectedEmailObj[0].scanPercentage === 0) {
        scanpercent = 10;
      }
      if (
        selectedEmailObj[0].status === 'completed' &&
        selectedEmailObj[0].scanPercentage === 100
      ) {
        localStorage.setItem('ProgressBarApiCall', true);
        localStorage.removeItem('rescanenabled');
      } else {
        localStorage.setItem('rescanenabled', emailId);
        localStorage.removeItem('ProgressBarApiCall');
      }
      setScanpercentage(scanpercent);
    }

    // UpdateScanProgress(emailId);
  }

  const cardData = [
    {
      header: 'Companies With Your Data',
      description:
        'The number of companies with your data. Each of these companies share a piece of your digital footprint.',
      value: ((getState || []).myData || []).pendingDomain || 0,
      // state.myData.length,
      href: 'my-data',
      buttonText: 'View Data',
    },
    {
      header: 'Submitted Data Subject Requests',
      description:
        "The number of Data Subject Requests you have submitted. You're doing great, keep up the good work.",
      value: ((getState || []).myData || []).initiatedDomain || 0,
      href: 'my-deletion-requests?filter=initiated',
      buttonText: 'View Submitted Requests',
    },
    {
      header: 'Successful Data Subject Requests',
      description:
        'The number of Data Subject Requests that have successfully been completed. Just that many less possible data branches for you to worry about.',
      value: ((getState || []).myData || []).reclaimedDomain || 0,
      href: 'my-deletion-requests',
      buttonText: 'View Completed Requests',
    },
  ];

  const errorMessage = errors
    ? Object.keys(errors).map((i) => (
        <ErrorMessage>
          {errors[i].message} [code:{errors[i].error}]
        </ErrorMessage>
      ))
    : null;

  const cardContent = cardData.map((datum) => {
    return (
      <CardContainer key={datum.href} id="DashboardCard">
        <Card {...datum} />
      </CardContainer>
    );
  });

  const emailFormContent =
    (getState.dropDownData || []).length > 1 ? (
      <FormControl className={classes.formControl} id="AccountEmail">
        <InputLabel id="demo-simple-select-label">Email:</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={getState.emailId || ''}
          placeholder="Email"
          onChange={(event) => {
            var emailId = event.target.value;
            if (emailId !== '') {
              _changeDashBoardData(emailId);
            }
          }}
        >
          {(getState.dropDownData || []).map((data) => (
            <MenuItem value={data.emailId} key={data.emailId}>
              {data.emailId}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : null;

  return (
    <>
      {loaded ? (
        (((getState || []).myData || []).pendingDomain === 0 &&
          ((getState || []).myData || []).initiatedDomain === 0 &&
          ((getState || []).myData || []).reclaimedDomain &&
          0) ||
        getCountFlag ? (
          <>
            {emailFormContent}
            <Box style={{ textAlign: 'right' }}>
              <Link>
                <UserLink
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh Data
                </UserLink>
              </Link>{' '}
            </Box>{' '}
            <Typography></Typography>
            Email scan is in progress. It might take some time depending on your
            mailbox. Please refresh the page.
          </>
        ) : (getState.dropDownData || []).length === 0 ? (
          <>
            {emailFormContent}
            <Typography></Typography>
            Currently, Privacy For Me doesn't have any accounts connected.{' '}
            <Link to="/account">
              <UserLink>Connect an email provider</UserLink>
            </Link>{' '}
            to get started today!
          </>
        ) : (
          <>
            {errorMessage}
            {emailFormContent}
            <Box style={{ textAlign: 'right' }}>
              <Link>
                <UserLink
                  onClick={() => {
                    setLoaded(false);
                    UpdateScanProgress();
                  }}
                >
                  Refresh Data
                </UserLink>
              </Link>{' '}
            </Box>
            <>
              {localStorage.getItem('ProgressBarApiCall') ? (
                ''
              ) : (
                <ProgressBar
                  id="progressBar"
                  now={scanpercentage}
                  label={`${scanpercentage}% Inbox scanned`}
                />
              )}
            </>

            <CardsWrapper>{cardContent}</CardsWrapper>
          </>
        )
      ) : (
        <Box className={classes.alignItemsAndJustifyContent}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
