import { useContext } from 'react';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';

import { Context } from '../store';
import { PrimaryButton } from 'components/misc/Buttons';

const CardActionButton = tw(PrimaryButton)`my-3 mx-auto px-1 py-3 w-full`;

export function SelectableEmailProvider({ provider }) {
  const history = useHistory();
  const [state, dispatch] = useContext(Context); //eslint-disable-line no-unused-vars

  const dpi = window.devicePixelRatio;
  const size = 175 * dpi;

  return (
    <div style={{ width: '175px' }}>
      <img
        src={`//logo.clearbit.com/${provider.domain}?size=${size}`}
        alt="Logo"
        width="175"
        height="175"
      />

      <CardActionButton
        onClick={() => {
          dispatch({
            type: 'CONNECT_EMAIL_PROVIDER',
            payload: {
              ...provider,
              emailAddress: `john.smith@${provider.domain}`,
            },
          });
          history.push(
            `connected?provider=${encodeURIComponent(provider.name)}`
          );
        }}
      >
        Connect {provider.name}
      </CardActionButton>
    </div>
  );
}

export default SelectableEmailProvider;
