import { Typography, Card, CardContent, CardHeader } from '@material-ui/core';
import { useContext, useState } from 'react';
import { axiosServiceInstance } from 'config/axiosConfig';
import { PrimaryButton, SecondaryButton } from 'components/misc/Buttons';
import { Context } from 'store';
import ClearIcon from '@material-ui/icons/Clear';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'bootstrap';
/* const rowStyle = {
  margin: '.5rem 0',
  width: '250px',
};
 */
export default function DeleteConnection({
  emailId,
  selectDisconnect,
  allProvider,
  emailType,
}) {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const [popup, setPopup] = useState(false); //eslint-disable-line no-unused-vars
  async function _deleteConection() {
    try {
      const deleteConnection = await axiosServiceInstance
        .post(`/pieeye-pfmuser/api/account/_deleteConnection`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            emailId: emailId || '',
          },
        })
        .catch((error) => {
          window.location = '/dashboard';
        });
      // var totalProvider = state.allProvider;
      if (deleteConnection && deleteConnection.status === 200) {
        const accounts = (allProvider || []).filter(
          (account) => account.emailId !== emailId
        );
        var disconnectEmail =
          localStorage.getItem('updatedEmailId') ||
          (state.dashboardData || []).emailId;

        if (disconnectEmail === emailId || disconnectEmail === undefined) {
          localStorage.removeItem('updatedEmailId');
          dispatch({
            type: 'MY_Needed_DATA',
            payload: [],
          });

          if (state.dashboardData.dropDownData.length <= 2) {
            if (
              emailType === 'Google' &&
              state.dashboardData.dropDownData.length === 2
            ) {
              localStorage.setItem('selected_account', 'Microsoft');
            } else if (
              emailType === 'Microsoft' &&
              state.dashboardData.dropDownData.length === 2
            ) {
              localStorage.setItem('selected_account', 'Google');
            } else {
              localStorage.removeItem('selected_account');
            }
            dispatch({
              type: 'DASHBOARD_DATA',
              payload: [],
            });
          }
        }

        dispatch({
          type: 'ALL_PROVIDER',
          payload: accounts,
        });
        dispatch({
          type: 'DASHBOARD_DATA',
          payload: [],
        });
        dispatch({
          type: 'MY_DATA',
          payload: [],
        });
        dispatch({
          type: 'ACC_Refresh',
          payload: true,
        });
      }
      // var getLoaded = loaded
    } catch (e) {
      console.error('Unable to fetch domains:', e);

      if ((e.response || []).status || '' === 404) {
        // setLoaded(true);
        // setError({ ...(e.response || []).status || null });
      }
    }
  }

  return (
    <Card id="DisconnectModal">
      <CardHeader title="Confirm Disconnect?" />
      <CardContent id=" ">
        <Typography id="DisconnectText" variant="h6" color="textPrimary">
          Do you want to disconnect this email account?
        </Typography>

        <PrimaryButton id="yesButtons" onClick={_deleteConection}>
          Yes
        </PrimaryButton>
        <SecondaryButton
          id="noButtons"
          onClick={() => {
            setPopup(false);
          }}
        >
          No
        </SecondaryButton>
      </CardContent>
    </Card>
  );
}
