import {
  tenant,
  redirectUri,
  scope,
  google_redirectUri,
  google_scope,
  getOAuthClientId,
} from 'config/msal';

export const oauthUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?\
client_id=${getOAuthClientId('Microsoft')}\
&response_type=code\
&redirect_uri=${redirectUri}\
&scope=${scope.join('%20')}\
&response_mode=query\
&state=12345\
&nonce=678910`;

export const googleOauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?\
client_id=${getOAuthClientId('Google')}\
&response_type=code\
&redirect_uri=${google_redirectUri}\
&scope=${google_scope.join('%20')}\
&state=12345\
&prompt=consent\
&access_type=offline`;
