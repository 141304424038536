import { useContext, useState } from 'react';
import { TwitterButton } from 'react-social';
import FullPageWrapper from 'components/wrapper/FullPageWrapper';
import Fbicon from '@material-ui/icons/Facebook';
import Twicon from '@material-ui/icons/Twitter';
import { Context } from 'store';
import { makeStyles } from '@material-ui/core/styles';
import ReferFriend from './Referer.js';
import {
  Box,
  Button,
  Typography,
  Backdrop,
  Card,
  CardContent,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { FacebookShareButton } from 'react-share';
//var TinyURL = require('tinyurl');
var CryptoJS = require('crypto-js');

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function ReferAFriend() {
  const [state] = useContext(Context);
  const [popup, setPopup] = useState(false);

  //const [copysuccess, setcopymsg] = useState('Copy Url');
  const classes = useStyles();
  var queryparam =
    'utm_source=pfm&utm_medium=website&utm_campaign=refer-a-friend&referrer_email=' +
    state.user.email;
  var fbqueryparam =
    'utm_source=pfm&utm_medium=facebook&utm_campaign=refer-a-friend&referrer_email=' +
    state.user.email;
  var twqueryparam =
    'utm_source=pfm&utm_medium=twitter&utm_campaign=refer-a-friend&referrer_email=' +
    state.user.email;
  /*
  var tinyurl = 'https://privacyfor.me?utm_source=pfm&utm_medium=website&utm_campaign=refer-a-friend&referrer_email=' +
  state.user.email;
    TinyURL.shorten(tinyurl, function(res, err) {
    if (err)
     
      seturl(res);
      //var tinyurlres = res;
  });
  */
  // Encrypt
  var encryptedquerryparam = CryptoJS.AES.encrypt(
    queryparam,
    'secret-key@123$!'
  ).toString();
  var encryptedquerryparamFb = CryptoJS.AES.encrypt(
    fbqueryparam,
    'secret-key@123$!'
  ).toString();
  var encryptedquerryparamTw = CryptoJS.AES.encrypt(
    twqueryparam,
    'secret-key@123$!'
  ).toString();
  //var encryptedquerryparam = tinyurlres;
  var emailurl = 'https://privacyfor.me?' + encryptedquerryparam;
  var fburl = 'https://privacyfor.me?' + encryptedquerryparamFb;
  var twurl = 'https://privacyfor.me?' + encryptedquerryparamTw;
  //var twurl = 'https://privacyfor.me?';
  /*
  let url =
    'https://rcbnap1l0l3.typeform.com/to/jhCg2B7v?utm_source=pfm&utm_medium=facebook&utm_campaign=refer-a-friend&utm_term=fbtem&utm_content=fbcontent#referrer_email=' +
    state.user.email +
    '&referrer_name=' +
    state.user.name;
    
  let twurl =
    'https://rcbnap1l0l3.typeform.com/to/jhCg2B7v?utm_source=pfm&utm_medium=twitter&utm_campaign=refer-a-friend&utm_term=twtem&utm_content=twcontent#referrer_email=' +
    state.user.email +
    '&referrer_name=' +
    state.user.name;
  */
  let msg =
    'Hey Twitter Friends! I just tried out PrivacyForMe to reclaim my data from a lot of companies! Check it out here!';
  let fbmsg =
    'Hey Facebook Friends! I just tried out PrivacyForMe to reclaim my data from a lot of companies! Check it out here!';

  return (
    <FullPageWrapper selected="referAFriend" class="RafWrapper">
      <div class="socialMainWrapper SocialIcons">
        <span class="socialhead">Share On Social Media: </span>
        <FacebookShareButton
          url={fburl}
          quote={fbmsg}
          hashtag={'PrivacyForMe'}
          description={'PrivacyForMe'}
          className="fbsocial"
        >
          <Fbicon />
        </FacebookShareButton>
        <TwitterButton
          url={twurl}
          message={msg}
          appId="23066349"
          class="twsocial"
        >
          <Twicon />
        </TwitterButton>

        <Button
          variant="outlined"
          color="primary"
          className="CopyURLBtn"
          // style={{ float: 'Right' }}
          id="copybtn"
          title="Copy"
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(emailurl);
            //document.getElementById('copybtn').innerHTML = 'Url Copied';
          }}
        >
          Copy Url
        </Button>
      </div>
      <div id="referfriend">
        <ReferFriend />
      </div>

      <>
        <Backdrop className={classes.backdrop} open={popup}>
          <Card>
            <Box style={{ color: 'black', textAlign: 'right', padding: '5px' }}>
              <ClearIcon
                onClick={() => {
                  setPopup(false);
                }}
              />
            </Box>

            <CardContent id="ModalContent">
              <Typography id="DeleteText" variant="h6" color="textPrimary">
                {emailurl}
              </Typography>
            </CardContent>
          </Card>
        </Backdrop>
      </>
    </FullPageWrapper>
  );
}
