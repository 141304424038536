import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import Header from '../headers/light.js';

import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-1.svg';
import LandingHero from '../../images/landing-hero-fold.svg';
//import CustomersLogoStripImage from '../../images/customers-logo-strip.png';
import Google_logo from '../../images/google_logo.png';
import Microsoft_logo from '../../images/microsoft_logo.png';

import HeaderBG from 'images/HeaderBg.svg';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg text-black`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-lg bg-[#E25668] py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
/*
const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;
*/
export default function TwoColumnWithInput({ roundedHeaderButton }) {
  const history = useHistory();
  const [email, setEmail] = useState(null);

  return (
    <div className="HeaderBg" imageSrc={HeaderBG}>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Take Control of{' '}
              <span tw="text-[#334F7E]">
                <br />
                Your Privacy
              </span>
            </Heading>
            <Paragraph>
              Your personal data is in the hands of hundreds of companies. Find
              out which have it, and take control.
            </Paragraph>
            <Actions>
              {/* <input
                type="email"
                placeholder="Your E-mail Address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              /> */}
              <button
                id="GetStartedBtn"
                onClick={() => {
                  document.getElementById('accountPopup').click();
                  //history.push('/email');
                }}
              >
                Get Started FREE
              </button>
            </Actions>
            {/* <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}
            {/* <div className="CertifiedLogo">
              <p>Certified by</p>
              <div className="flex">
                <img
                  className="GoogleLogo"
                  alt="GoogleLogo"
                  src={Google_logo}
                />{' '}
                &{' '}
                <img
                  className="MicrosoftLogo"
                  alt="MicrosoftLogo"
                  src={Microsoft_logo}
                />
              </div>
            </div> */}
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer className="Headerimg">
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src={LandingHero}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        {/* <DecoratorBlob1 /> */}
      </Container>
    </div>
  );
}
