import { motion } from 'framer-motion';
import tw from 'twin.macro';

const Page = tw.div`p-4`;
const PageTitle = tw.div`font-display font-bold text-[#2C3E5D] text-3xl text-center my-2 mt-5`;
const PageText = tw.div`font-display text-[#5071A8] text-lg text-center mt-3`;
const PageImage = tw.img`max-h-half min-h-half mt-6 self-center ml-24`;

export default function IntroPage({ title, image, text }) {
  return (
    <Page>
      <motion.div
        key={title}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          delay: 0,
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
      >
        <PageTitle className="IntroHeading">{title}</PageTitle>
        <PageText className="IntroBody">{text}</PageText>
        <PageImage className="IntroImg" src={image} />
      </motion.div>
    </Page>
  );
}
