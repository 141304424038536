import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';
import { Context } from 'store';
import { PrimaryButton } from 'components/misc/Buttons';
import { useEffect, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { Box, CardHeader } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import FailedLogo from '../../images/Figma_Failed.svg';
import SubmittedLogo from '../../images/Figma_Submitted.svg';
import CompletedLogo from '../../images/Figma_Completed.svg';
import ProcessingLogo from '../../images/Figma_Processing.svg';
import ClockLogo from '../../images/clock.png';
import ViewLogo from '../../images/google_logo.png';
import RequestDeletion from 'views/RequestDeletion';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import axiosServiceInstance from 'config/axiosConfig';

const ContentWrapper = tw.div`bg-gray-200 rounded border-2 w-2/3 p-8`;
// const Domain = tw.div`inline text-primary-500 ml-1`;
const Details = tw.p`text-secondary-500 mt-3`;
const EmailsWrapper = tw.ul``;
const EmailItem = tw.li`text-secondary-500 px-3`;
const EmailFrom = tw.div`inline-block ml-2`;
const EmailSubject = tw.div`inline-block`;
const EmailDate = tw.div`inline-block ml-2`;

const ButtonContainer = tw.div`flex mt-8`;
const CardActionButton = tw(PrimaryButton)``;

function emailDate(date) {
  return new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
}

const clearIconStyle = {
  color: 'black',
  textAlign: 'right',
  marginTop: '-25px',
  marginRight: '-25px',
};

export default function MyDataDetails({
  data,
  selectDatum,
  getSingleDsrPopup,
}) {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const [geterror, seterror] = useState(true);

  const { emails, id } = data; //.selectedDatum;
  const domainlistobj = {
    name: state.user.name,
    domain: (data || []).selectedDatum || [],
    emailAddress: (data || []).selectedDatum.emailAddress, //state.user.email,
  };

  const emailsContent = emails
    ? emails.map((email) => (
        <EmailItem key={`${email.from}-${email.date}`}>
          <EmailSubject>{email.subject}</EmailSubject>
          <EmailFrom>({email.from})</EmailFrom>
          <EmailDate>[{emailDate(email.date)}]</EmailDate>
        </EmailItem>
      ))
    : null;
  useEffect(() => {});

  let firstEmailDate = new Date(
    ((data || []).selectedDatum || []).firstEmailDate || ''
  ).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  if (firstEmailDate === 'Invalid Date') {
    const date = ((data || []).selectedDatum || []).firstEmailDate || '';
    const [day, month, year] = date.split('/');
    firstEmailDate = `${month}/${day}/${year}`;
    firstEmailDate = new Date(firstEmailDate).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  let lastEmailDate = new Date(
    ((data || []).selectedDatum || []).lastEmailDate || ''
  ).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  if (lastEmailDate === 'Invalid Date') {
    const date = ((data || []).selectedDatum || []).lastEmailDate || '';
    const [day, month, year] = date.split('/');
    lastEmailDate = `${month}/${day}/${year}`;
    lastEmailDate = new Date(lastEmailDate).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  let statusDate = new Date(
    ((data || []).selectedDatum || []).statusDate || ''
  ).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  if (statusDate === 'Invalid Date') {
    const date = ((data || []).selectedDatum || []).statusDate || '';
    const [day, month, year] = date.split('/');
    statusDate = `${month}/${day}/${year}`;
    statusDate = new Date(statusDate).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }
  const DomainStatus = (
    <h2 className="DomainStatus">
      {((data || []).selectedDatum || []).status === '-' ? (
        <>
          <img src={ClockLogo} className="" />
          Pending
        </>
      ) : ((data || []).selectedDatum || []).status === 'Recommended' ? (
        <>
          <img src={ClockLogo} className="" />
          Recommended
        </>
      ) : ((data || []).selectedDatum || []).status === 'Needed' ? (
        <>
          <img src={ClockLogo} className="" />
          Needed
        </>
      ) : ((data || []).selectedDatum || []).status === 'Failed' ? (
        <>
          <img src={FailedLogo} className="" />
          Failed
        </>
      ) : ((data || []).selectedDatum || []).status === 'Submitted' ? (
        <>
          <img src={SubmittedLogo} className="" />
          Submitted
        </>
      ) : ((data || []).selectedDatum || []).status === 'Processing' ? (
        <>
          <img src={ProcessingLogo} className="" />
          Processing
        </>
      ) : ((data || []).selectedDatum || []).status === 'Completed' ? (
        <>
          <img src={CompletedLogo} className="" />
          Completed
        </>
      ) : (
        ''
      )}
    </h2>
  );

  async function udateStatusNeeded(domainname, status) {
    try {
      await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/domainStatus/_update`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: status,
            emailId: (state.dashboardData || []).emailId,
            domainName: domainname,
          },
        })

        .catch((e) => {
          console.error(e);
        });
      dispatch({
        type: 'Reload_Domain_Table',
        payload: true,
      });

      toast.success('Removed successfully ', {
        // toastId: customId,
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <ContentWrapper
        className="DomainDetailsModal"
        onClick={(e) => e.stopPropagation()}
      >
        <CardHeader className="DomainDetailsHeader" title="Domain Details" />

        {/* <Paper> */}
        <div className="View_d-flex">
          <div className="LogoFlex">
            <div className="ViewRequestDeletionLogo MobileSize">
              {/* <img src={ViewLogo} /> */}
              {geterror ? (
                <>
                  <img
                    className="imgid"
                    id={data.selectedDatum.domainName.trim()}
                    src={`//logo.clearbit.com/${data.selectedDatum.domainName}`}
                    alt=""
                    onError={(e) => (
                      (e.target.onerror = null), seterror(false)
                    )}
                  />
                </>
              ) : (
                <>
                  <h1 className="imgid" id="DomainLogoAlpha">
                    {((data || []).selectedDatum || []).domainName
                      .charAt(0)
                      .toUpperCase()}
                  </h1>
                </>
              )}
            </div>
            <div className="ViewRequestDeletionLogo DesktopView">
              {geterror ? (
                <>
                  <a
                    className="DomainDetailName"
                    href={((data || []).selectedDatum || []).domainUrl || ''}
                    target="_blank"
                  >
                    {((data || []).selectedDatum || []).domainName || ''}
                  </a>
                  {DomainStatus}
                </>
              ) : (
                <>
                  <a
                    className="DomainDetailName"
                    href={((data || []).selectedDatum || []).domainUrl || ''}
                    target="_blank"
                  >
                    {((data || []).selectedDatum || []).domainName || ''}
                  </a>
                  {DomainStatus}
                </>
              )}
            </div>
          </div>
          <div className="ViewRequestDeletionData">
            <div className="ViewRequestDeletionLogo MobileView1">
              {geterror ? (
                <>
                  <a
                    className="DomainDetailName"
                    href={((data || []).selectedDatum || []).domainUrl || ''}
                    target="_blank"
                  >
                    {((data || []).selectedDatum || []).domainName || ''}
                  </a>
                  {DomainStatus}
                </>
              ) : (
                <>
                  <a
                    className="DomainDetailName"
                    href={((data || []).selectedDatum || []).domainUrl || ''}
                    target="_blank"
                  >
                    {((data || []).selectedDatum || []).domainName || ''}
                  </a>
                  {DomainStatus}
                </>
              )}
            </div>

            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="ViewTableCell">
                    <span className="subTitle">DSR Contact Email</span>
                    <br />
                    <span className="subBody">
                      {((data || []).selectedDatum || []).dsrContactEmail || ''}
                    </span>
                  </TableCell>

                  <TableCell className="ViewTableCell">
                    <span className="subTitle">First Email</span>
                    <br />
                    <b>{firstEmailDate || ''}</b>
                  </TableCell>

                  <TableCell className="ViewTableCell">
                    <span className="subTitle">Last Email</span>
                    <br />
                    <b> {lastEmailDate || ''}</b>
                  </TableCell>
                  <TableCell className="ViewTableCell">
                    <span className="subTitle"># of Emails</span>
                    <br />
                    <b>
                      {' '}
                      {((data || []).selectedDatum || []).noOfEmails || ''}
                    </b>
                  </TableCell>
                  <TableCell className="ViewTableCell">
                    <span className="subTitle">Requested On</span>
                    <br />
                    <b>
                      {' '}
                      {statusDate === 'Invalid Date' ? '-' : statusDate || ''}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
              <EmailsWrapper>{emailsContent}</EmailsWrapper>
            </Table>

            <div>
              {' '}
              <ButtonContainer id="DomainDtlBtn">
                <CardActionButton
                  className={
                    ((data || []).selectedDatum || []).status === 'initiated' ||
                    ((data || []).selectedDatum || []).status === 'failed'
                      ? 'disablereqbtn'
                      : '' + ' ' + 'RequestDeletionBtn'
                  }
                  onClick={() => {
                    if (
                      ((data || []).selectedDatum || []).status === '-' ||
                      ((data || []).selectedDatum || []).status === 'Failed' ||
                      ((data || []).selectedDatum || []).status ===
                        'Recommended' ||
                      ((data || []).selectedDatum || []).status === 'Needed'
                    ) {
                      var payload = domainlistobj;
                      dispatch({
                        type: 'CARD_DETAIL_FOR_VIEW_TEMPLATE',
                        payload: payload,
                      });
                      getSingleDsrPopup(true);
                      var x = document.getElementsByTagName('BODY')[0];
                      x.style.overflow = 'hidden';
                      selectDatum(null);
                    } else {
                      selectDatum(null);
                      var x = document.getElementsByTagName('BODY')[0];
                      x.style.overflow = 'auto';
                    }
                  }}
                >
                  {((data || []).selectedDatum || []).status === 'Submitted' ||
                  ((data || []).selectedDatum || []).status === 'Completed'
                    ? 'OK' //'DSR Raised'
                    : ((data || []).selectedDatum || []).status === 'Failed'
                    ? 'Retry' //'DSR Failed'
                    : 'Request Deletion'}
                </CardActionButton>
                {((data || []).selectedDatum || []).status === 'Needed' ? (
                  <CardActionButton
                    className="CancelBtn"
                    style={{ marginRight: '20px' }}
                    onClick={() => {
                      udateStatusNeeded(
                        ((data || []).selectedDatum || []).domainName,
                        ((data || []).selectedDatum || []).status === 'Needed'
                          ? 'pending'
                          : 'needed'
                      );
                      selectDatum(null);
                    }}
                  >
                    {'Remove Needed'}
                  </CardActionButton>
                ) : (
                  ''
                )}
                <CardActionButton
                  className="CancelBtn"
                  onClick={() => {
                    selectDatum(null);
                  }}
                >
                  {'Cancel'}
                </CardActionButton>
              </ButtonContainer>
            </div>
          </div>
        </div>
        {/* </Paper> */}
      </ContentWrapper>
    </>
  );
}
