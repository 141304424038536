import { useContext, useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Card,
  Typography,
  CardContent,
  CardHeader,
  Box,
} from '@material-ui/core';
// import { useHistory } from 'react-router-dom';
// import AddIcon from '@material-ui/icons/Add';
import DeleteConnection from './DeleteDisconnection';
import { useHistory } from 'react-router-dom';
import ProviderConnectionItem from './ProviderConnectionItem';
import { Context } from '../../store';
import { oauthUrl, googleOauthUrl } from '../../config/oauthUrls';
import { Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//import { axiosServiceInstance } from '../config/axiosConfig';
import { axiosServiceInstance } from '../../config/axiosConfig';
import ClearIcon from '@material-ui/icons/Clear';
import { toast } from 'react-toastify';
import MicrosfotLogo from '../../images/Figma_N_Microsoft.svg';
import GoogleLogo from '../../images/Figma_N_Google.svg';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
export default function ProviderConnectionRow({
  name,
  provider,
  domain,
  emailAddress,
}) {
  // const history = useHistory();
  const history = useHistory();
  const [{ allProvider }] = useContext(Context);
  const [state, dispatch] = useContext(Context); //eslint-disable-line no-unused-vars
  const [getDisconnect, setDisconnect] = useState(null); //eslint-disable-line no-unused-vars
  const classes = useStyles();
  const [popup, setPopup] = useState(false);
  const [disablebothRescan, setdisablebothRescan] = useState(false);
  const accounts = (allProvider || []).filter(
    (account) => account.emailType === provider
  );
  const displayYahoo = provider === 'Yahoo' ? true : false;
  const [header, setHeader] = useState('Deletion Requests successful');
  const [msg, setMessage] = useState('');

  useEffect(() => {
    if (((state.dashboardData || []).dropDownData || []).length > 1) {
      // state.dashboardData.dropDownData.map((data, index) => {
      //   if (data.scanPercentage < 100 && data.status !== 'completed' ) {

      //     setdisablebothRescan(true);
      //   }
      //   else{
      //     setdisablebothRescan(false);

      //   }
      // });
      if (
        ((state.dashboardData || []).dropDownData || [])[0].scanPercentage <
          100 &&
        ((state.dashboardData || []).dropDownData || [])[1].scanPercentage <
          100 &&
        ((state.dashboardData || []).dropDownData || [])[0].status !==
          'completed' &&
        ((state.dashboardData || []).dropDownData || [])[1].status !==
          'completed'
      ) {
        setdisablebothRescan(true);
      }
    } else {
      setdisablebothRescan(false);
    }
    // if(
    //     ((((state.dashboardData || []).dropDownData || [])[0] || {}).status === 'completed' )
    //    && ((((state.dashboardData || []).dropDownData || [])[1] || {}).status === 'completed' )
    //   )
    //   {

    //     localStorage.removeItem('rescanenabled');
    //     setdisablebothRescan(false);

    //   }
  });
  async function Rescan(ReescanEmailId) {
    localStorage.setItem('rescanenabled', ReescanEmailId);
    localStorage.setItem('updatedEmailId', ReescanEmailId);
    // dispatch({
    //   type: 'DASHBOARD_DATA',
    //   payload: {
    //     emailId: ReescanEmailId,
    //   },
    // });
    const response = await axiosServiceInstance
      .post(`/pieeye-pfmuser/api/account/_pfmDataRequest/rescan`, {
        requestInfo: {
          userName: state.user.username,
          token: state.user.idToken,
        },
        requestBody: {
          emailId: ReescanEmailId || '',
        },
      })
      .then((response) => {
        if (parseInt(response.status) === 200) {
          localStorage.removeItem('ProgressBarApiCall');

          // localStorage.removeItem('rescanenabled');

          setHeader('Rescan In Progress');
          setMessage('');
          //setPopup(true);
          setTimeout(() => {
            window.location = '/dashboard';
          }, 5000);
          toast.success('Rescan In Progress', {
            position: 'top-center',
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      });
  }
  if (displayYahoo) {
    return <></>;
  } else if (accounts.length) {
    const content = accounts.map((account, index) => {
      // const add =
      //   index === 0 ? (
      //     <Button
      //       disabled
      //       style={{ marginLeft: '1rem' }}
      //       variant="outlined"
      //       onClick={() => {
      //         dispatch({
      //           type: 'CONNECT_EMAIL_PROVIDER',
      //           payload: {
      //             name,
      //             provider,
      //             emailAddress: `john.smith${accounts.length + 1}@${domain}`,
      //           },
      //         });
      //         history.push(
      //           `connected?provider=${encodeURIComponent(provider)}`
      //         );
      //       }}
      //     >
      //       <AddIcon />
      //     </Button>
      //   ) : null;

      return (
        <>
          <Backdrop
            className={classes.backdrop}
            open={getDisconnect}
            onClick={() => {
              setDisconnect(null);
              // setModel(true)
            }}
          >
            <Card style={{ borderRadius: '16px' }}>
              {getDisconnect && (
                <DeleteConnection
                  emailId={account.emailId || ''}
                  selectDisconnect={setDisconnect}
                  allProvider={allProvider}
                  emailType={account.emailType || ''}
                />
              )}
            </Card>
          </Backdrop>

          <Backdrop
            className={classes.backdrop}
            id="MyAccountBackdrop"
            open={popup}
            onClick={() => {
              setPopup(false);
            }}
          >
            <Card>
              <Box
                style={{ color: 'black', textAlign: 'left', padding: '5px' }}
              >
                {/* <ClearIcon
                  className="PopupClearIcon"
                  onClick={() => {
                    setPopup(false);
                  }}
                /> */}
              </Box>
              <CardHeader className="DeletPopupHeader" title={header} />
              <CardContent id="ModalContent">
                <Typography id="DeleteText" variant="h6" color="textPrimary">
                  {msg}
                </Typography>
              </CardContent>
            </Card>
          </Backdrop>

          <ProviderConnectionItem
            label={name}
            email={account.emailId || ''}
            key={account.emailAddress}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                localStorage.removeItem('selected_account');
                dispatch({
                  type: 'DISCONNECT_EMAIL_PROVIDER',
                  payload: {
                    emailAddress: account.emailAddress,
                  },
                });

                setDisconnect(provider);
              }}
            >
              Disconnect
            </Button>
            <Button
              variant="outlined"
              color="primary"
              id="RescanBtn"
              disabled={
                !disablebothRescan
                  ? localStorage.getItem('rescanenabled') &&
                    localStorage.getItem('rescanenabled') === account.emailId
                    ? true
                    : false
                  : true
              }
              className={
                !disablebothRescan
                  ? localStorage.getItem('rescanenabled') &&
                    localStorage.getItem('rescanenabled') === account.emailId
                    ? 'disablereqbtn'
                    : ''
                  : 'disablereqbtn'
              }
              onClick={() => {
                Rescan(account.emailId);
              }}
            >
              Rescan
            </Button>
            {/* {add} */}
          </ProviderConnectionItem>
        </>
      );
    });

    return <>{content}</>;
  } else {
    return (
      <>
        <Grid container spacing={2} id="EmailConBox">
          <Grid item md={7} sm={7} xs={12} id="ConnectBtn">
            <div className="ConnectLogo">
              {name === 'Google' ? (
                <img src={GoogleLogo} className="pr-10" />
              ) : name === 'Microsoft' ? (
                <img src={MicrosfotLogo} className="pr-10" />
              ) : (
                ''
              )}
              {name}
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12} id="Text_Right">
            <Button
              style={{ minWidth: '122px' }}
              variant="outlined"
              color="primary"
              disabled={provider === 'Yahoo' ? true : false}
              onClick={() => {
                localStorage.setItem('loginType', 'connect');
                localStorage.setItem('connect_provider', provider);
                localStorage.removeItem('ProgressBarApiCall');
                dispatch({
                  type: 'DASHBOARD_DATA',
                  payload: [],
                });
                dispatch({
                  type: 'CONNECT_EMAIL_PROVIDER',
                  payload: {
                    name,
                    provider,
                    emailAddress: `john.smith@${domain}`,
                  },
                });

                var oauthUrlPath =
                  provider === 'Google'
                    ? googleOauthUrl.replaceAll(' ', '')
                    : oauthUrl.replaceAll(' ', '');
                window.location.href = oauthUrlPath;
              }}
            >
              Connect
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}
