import { useHistory } from 'react-router';
import tw from 'twin.macro';

const NavItem = tw.a`block my-1 py-2 px-3 text-primary-500 whitespace-nowrap rounded min-w-full`;
const NavItemSelected = tw(NavItem)`bg-blue-200`;

const Icon = tw.img`inline-block mr-2`;

const style = {
  minWidth: '250px',
};

export default function SidebarItem({ selected, imageSrc, text, href }) {
  const history = useHistory();

  const icon = imageSrc ? <Icon src={imageSrc} alt={text} /> : null;

  const handleOnClick = (e) => {
    e.preventDefault();
    // window.hiss = history;
    if (history.location.pathname.indexOf(href) > -1) {
      history.go(0);
    } else {
      history.push(`/${href}`);
    }
  };

  if (selected) {
    return (
      <NavItemSelected
        key={href}
        href={href}
        onClick={handleOnClick}
        style={style}
      >
        {icon}
        {text}
      </NavItemSelected>
    );
  }
  return (
    <NavItem key={href} href={href} onClick={handleOnClick} style={style}>
      {icon}
      {text}
    </NavItem>
  );
}
