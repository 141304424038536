import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Context } from 'store';
import { ReactComponent as LoginIcon } from 'feather-icons/dist/icons/log-in.svg';

const ErrorMessage = tw.div`bg-red-300 text-red-800 text-sm border border-red-800 rounded my-2 p-4`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-[#E25668] text-gray-100 w-full py-4 rounded-lg md:uppercase transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default function SignInForm({
  submitButtonText = 'Sign In',
  SubmitButtonIcon = LoginIcon,
}) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [state, dispatch] = useContext(Context); //eslint-disable-line no-unused-vars

  const error = errorMessage ? (
    <ErrorMessage>{errorMessage}</ErrorMessage>
  ) : null;

  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        // Disable form
        setErrorMessage(null);

        const formData = new FormData(e.target);
        const username = formData.get('email');
        const password = formData.get('password');
        try {
          const signInResult = await Auth.signIn({
            username,
            password,
          });

          dispatch({
            type: 'USER_SIGN_IN',
            payload: {
              username: signInResult.username,
              name: signInResult.attributes.name,
              email: signInResult.attributes.email,
              accessToken: signInResult.signInUserSession.accessToken.jwtToken,
              idToken: signInResult.signInUserSession.idToken.jwtToken,
              refreshToken: signInResult.signInUserSession.refreshToken.token,
            },
          });

          localStorage.setItem('fromSignUp', true);
          history.push('/dashboard');
        } catch (ex) {
          // TODO: Enable form
          if (['NotAuthorizedException'].includes(ex.code)) {
            return setErrorMessage(ex.message);
          }
          console.error('Unable to sign in: ', ex);
        }
      }}
    >
      <Input name="email" type="email" placeholder="Email" required />
      <Input name="password" type="password" placeholder="Password" required />
      <SubmitButton type="submit">
        {/* <SubmitButtonIcon className="icon" /> */}
        <span className="text">{submitButtonText}</span>
      </SubmitButton>
      {error}
    </Form>
  );
}
