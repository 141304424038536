import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import {
  Account,
  ConfirmSignUp,
  ConnectionSuccessful,
  CreateAccount,
  Dashboard,
  Feedback,
  ForgotPassword,
  IntroFlow,
  LandingPage,
  Login,
  MyData,
  MyDataNewPage,
  MyDSRs,
  PlaceholderEmailConnectDialog,
  PrivacyPolicy,
  RequestDeletion,
  RequestDeletionMultiple,
  TermsOfService,
  BugBoundry,
  NewDashboard,
} from './views/';
import ThankYou from 'views/thankyou';
import ReferAFriend from 'views/newfeedback';
import PopupFeedback from '../src/components/account/PopupFeedback';

function AppRouter() {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/dashboard">
          <NewDashboard />
          {/* <Dashboard /> */}
        </PrivateRoute>

        <PrivateRoute path="/account">
          <Account />
        </PrivateRoute>
        <PrivateRoute path="/my-deletion-requests">
          <MyDSRs />
        </PrivateRoute>
        <PrivateRoute path="/my-data">
          <MyData />
        </PrivateRoute>
        <PrivateRoute path="/alldomains">
          <MyDataNewPage />
        </PrivateRoute>
        <PrivateRoute path="/request-deletion">
          <RequestDeletion />
        </PrivateRoute>
        <PrivateRoute path="/request-deletion-multiple">
          <RequestDeletionMultiple />
        </PrivateRoute>
        <Route path={['/login', '/cognito_signout']}>
          {/* <Login /> */}
          <LandingPage />
        </Route>
        <Route path="/createAccount">
          <CreateAccount />
        </Route>
        <PrivateRoute path="/userFeedback">
          <Feedback />
        </PrivateRoute>
        <PrivateRoute path="/newdashboard">
          <NewDashboard />
        </PrivateRoute>
        <Route path="/feedback">
          <PopupFeedback />
        </Route>
        <Route
          path={[
            '/connected',
            '/gmail-auth',
            '/microsoft-auth',
            '/oidc_callback',
            '/yahoo-auth',
            '/cognito_callback',
          ]}
        >
          <ConnectionSuccessful />
        </Route>
        <Route path="/email">
          <CreateAccount />
        </Route>
        <Route path="/connectEmail">
          <PlaceholderEmailConnectDialog />
        </Route>
        <Route path="/confirm-signup">
          <ConfirmSignUp />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/bugbounty">
          <BugBoundry />
        </Route>
        <Route path="/terms-of-service">
          <TermsOfService />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/getting-started">
          <IntroFlow />
        </Route>
        <Route path="/" exact>
          <LandingPage />
        </Route>
        <Route path="/thankyou" exact>
          <ThankYou />
        </Route>

        <Route path="/referAFriend" exact>
          <ReferAFriend />
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRouter;
