import React from 'react';
import { useState, useEffect, useContext } from 'react';
import DomainLogo from './DomainLogo';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { Context } from 'store';
import { axiosServiceInstance } from 'config/axiosConfig';
import { toast } from 'react-toastify';
import moment from 'moment';
import Disable_Toggle from '../../images/disable_toggle.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
const customId = 'custom-id-Added';

const styles = {
  select: { margin: '0 20px' },
  buttonsCellContainer: {
    padding: '0 20px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  editButton: {
    background: '#f3f3f3',
    outline: 'none',
    cursor: 'pointer',
    padding: 4,
    display: 'inline-flex',
    border: 'none',
    borderRadius: '50%',
    boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)',
  },
  buttonsCellEditorContainer: {
    height: '100%',
    width: '100%',
    display: 'inline-flex',
    padding: '0 20px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelButton: {
    background: '#f3f3f3',
    outline: 'none',
    cursor: 'pointer',
    marginRight: 10,
    padding: 2,
    display: 'inline-flex',
    border: 'none',
    borderRadius: '50%',
    boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)',
  },
  saveButton: {
    background: '#f3f3f3',
    outline: 'none',
    cursor: 'pointer',
    padding: 2,
    display: 'inline-flex',
    border: 'none',
    borderRadius: '50%',
    boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)',
  },
};
//var switchToggle;
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#5071A8',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function GetColumns({ setRowsData, Tile, renderTable }) {
  const [state, dispatch] = useContext(Context);
  const [loaded, setLoaded] = useState(false);

  async function updateSubmittedStatus(e, data) {
    if (data.status === 'Submitted' && e.target.checked === true) {
      try {
        await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/domainStatus/_update`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'reclaimed',
              emailId: data.emailAddress,
              domainName: data.domainName,
            },
          })
          .catch((e) => {
            console.error(e);
          });
        dispatch({
          type: 'Reload_Domain_Table',
          payload: true,
        });
        toast.success('DSR Completed Successfully', {
          toastId: customId,
          position: 'top-center',
          autoClose: 6000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
        //renderTable(true);
        setLoaded(false);
      } catch (e) {
        console.error(e);
        setLoaded(false);
      }
    }
  }

  const DomainName = (params) => {
    if (params.data !== undefined) {
      return [
        <>
          {' '}
          {
            <div className="pl-5">
              {<DomainLogo data={params.data || ''} />}
              {(params.data.status || '') === 'Recommended' ? (
                <>
                  <div
                    style={{
                      lineHeight: '18px',
                      marginLeft: '10px',
                      color: '#3457B4',
                      cursor: 'pointer',
                    }}
                  >
                    {params.data.domainName || ''}
                    <br />
                    <div className="GridDomainStatus">{`(Recommended)`}</div>
                  </div>
                </>
              ) : (params.data.status || '') === 'Needed' ? (
                <>
                  <div
                    style={{
                      lineHeight: '18px',
                      marginLeft: '10px',
                      color: '#3457B4',
                      cursor: 'pointer',
                    }}
                  >
                    {params.data.domainName || ''}
                    <br /> <div className="GridDomainStatus">{`(Needed)`}</div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    lineHeight: '18px',
                    marginLeft: '10px',
                    color: '#3457B4',
                    cursor: 'pointer',
                  }}
                >
                  {params.data.domainName || ''}
                </div>
              )}
            </div>
          }
        </>,
      ];
    } else {
      return (
        <div style={{ marginTop: '10px' }}>
          <CircularProgress size={25} />
        </div>
      );
    }
  };

  return [
    {
      field: 'domainName',
      headerName: 'NAME',
      minWidth: '250',
      // headerCheckboxSelection: true,
      checkboxSelection: Tile === 'pending' ? true : false,
      cellRenderer: DomainName,
    },
    {
      field: 'dsrContactEmail',
      headerName: 'DSR CONTACT EMAIL',
      minWidth: '230',
    },

    {
      field: 'firstEmailDate',
      headerName: 'FIRST EMAIL',
      sortable: true,
    },
    {
      field: 'lastEmailDate',
      headerName: 'LAST EMAIL',
      sortable: true,
    },
    {
      field: 'noOfEmails',
      headerName: '# OF EMAILS',
      hide: true,
    },
    {
      field: 'statusDate',
      headerName: 'REQUESTED ON',

      sortable: Tile === 'pending' ? false : true,
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          return (
            <>
              {params.data.status === 'Recommended'
                ? '-'
                : params.data.status === 'Needed'
                ? '-'
                : params.data.status === '-'
                ? '-'
                : params.data.statusDate}
            </>
          );
        } else {
          return (
            <img
              style={{ display: 'none' }}
              src="https://www.ag-grid.com/example-assets/loading.gif"
            />
          );
        }
      },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      sortable: Tile === 'pending' ? false : true,
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          return [
            <>
              <div
                className={
                  params.data.status === 'Failed'
                    ? 'failedStatus'
                    : params.data.status === 'Completed'
                    ? 'completedStatus'
                    : ''
                }
              >
                {params.data.status === 'Recommended'
                  ? '-'
                  : params.data.status === 'Needed'
                  ? '-'
                  : params.data.status}
              </div>
            </>,
          ];
        } else {
          return (
            <img
              style={{ display: 'none' }}
              src="https://www.ag-grid.com/example-assets/loading.gif"
            />
          );
        }
      },
    },
    {
      headerName: '',
      sortable: false,
      hide: Tile === 'initiated' ? false : true,
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          return [
            <>
              {(params.data.status || '') === 'Submitted' ? (
                <>
                  {' '}
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <AntSwitch
                      onChange={(e) => {
                        updateSubmittedStatus(e, params.data);
                        if (e.target.value === 'on') {
                          params.data.isSwitch = true;
                        } else {
                          params.data.isSwitch = false;
                        }
                      }}
                      inputProps={{ 'aria-label': 'ant design' }}
                    />
                    <h4
                      style={{ marginLeft: '10px' }}
                      className={
                        (params.data.isSwitch || '') === true
                          ? 'SwitchComplete pl-5'
                          : ' pl-5'
                      }
                    >
                      Complete
                    </h4>
                  </span>
                </>
              ) : (
                <>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <img src={Disable_Toggle} />
                    <h4 className="Disabled pl-10"> Complete</h4>
                  </span>
                </>
              )}
            </>,
          ];
        } else {
          return (
            <img
              style={{ display: 'none' }}
              src="https://www.ag-grid.com/example-assets/loading.gif"
            />
          );
        }
      },
    },
  ];
}
