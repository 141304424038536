import styled from 'styled-components';
import tw from 'twin.macro';
import { CssBaseline, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Header from 'components/headers/light.js';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { SecondaryButton } from 'components/misc/Buttons';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-1.svg';

import SelectEmailProvider from '../components/SelectEmailProvider';

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
const ButtonContainer = tw.div`flex justify-center mt-20`;
const CardActionSecondaryButton = tw(SecondaryButton)`my-3 mx-auto`;

const useStyles = makeStyles({
  root: {
    maxWidth: '1100px',
  },
  textPrimary: { paddingTop: '1rem' },
  textSecondary: {},
});

export function EmailProvider() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container maxWidth="md" className={classes.root}>
        <CssBaseline />
        <Container maxWidth="md">
          <Typography
            paragraph
            className={classes.textPrimary}
            color="textPrimary"
          >
            Get started with Privacy For Me by connecting your email provider.
          </Typography>
          <Typography
            paragraph
            className={classes.textSecondary}
            color="textSecondary"
          >
            Once you have connected an email provide Privacy For Me will provide
            you with a list of companies that have your data. Afterwards you
            will be able to review and submit a data removal request.
          </Typography>
        </Container>

        <SelectEmailProvider />

        <ButtonContainer>
          <CardActionSecondaryButton
            onClick={() => {
              history.push('createAccount');
            }}
          >
            Skip email provider connection and create account
          </CardActionSecondaryButton>
        </ButtonContainer>
      </Container>
      <DecoratorBlob1 />
    </AnimationRevealPage>
  );
}

export default EmailProvider;
