import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Hero from 'components/hero/TwoColumnWithInput.js';
import Features from 'components/features/ThreeColWithSideImage.js';
import MainFeature from 'components/features/TwoColWithButton.js';
import MainFeature2 from 'components/features/TwoColWithTwoHorizontalFeaturesAndButton.js';
import FeatureWithSteps from 'components/features/TwoColWithSteps.js';
import Testimonial from 'components/testimonials/TwoColumnWithImageAndRating.js';
import FAQ from 'components/faqs/SingleCol.js';
import GetStarted from 'components/cta/GetStarted';
import Footer from 'components/footers/MiniCenteredFooter';
import heroScreenshotImageSrc from 'images/Privacy_Brands.svg';
//import macHeroScreenshotImageSrc from 'images/App_Privacy.svg';
import prototypeIllustrationImageSrc from 'images/AI_Bot_Protection.svg';
import Adam_Cuppy from 'images/adamCuppy.jpg';
import CharlotteHale from 'images/charlotteHale.jpg';
import VideoPlay from 'images/video-play.svg';
import RegisterIcon from 'images/register-icon.svg';
import ConnectIcon from 'images/connect-icon.svg';
import BrowseIcon from 'images/browse-icon.svg';

//import PFMVideo from '../images/Privacy_For_Me_Video.mp4';

export default function LandingPage() {
  const Subheading = tw.span`uppercase tracking-widest font-medium text-primary-500 text-[#1D2739]`; //757575
  const Subheading1 = tw.span`uppercase tracking-widest font-medium text-primary-500 text-[#757575]`; //757575
  const HighlightedText = tw.span`text-[#334F7E] font-bold`;
  const DescriptionText = tw.span`text-black`;
  const DescriptionMinded = tw.span`text-[#757575]`;
  const StepsText = tw.span`w-full right-0 top-0 bottom-0 bg-primary-500 text-[#ffffff] font-bold mt-4 my-4 rounded-md bg-[#E25668] py-3 flex items-center justify-center sm:w-20 sm:leading-none focus:outline-none transition duration-300`;
  const Principle = tw.span`w-full right-0 top-0 bottom-0 bg-primary-500 text-[#ffffff] font-bold mr-2 my-4 sm:my-2 rounded-lg bg-[#E25668] py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none transition duration-300`;
  return (
    <AnimationRevealPage disabled>
      <div className="AppDotBg" />
      <Hero roundedHeaderButton={true} />

      <Features
        subheading={<Subheading></Subheading>}
        heading={
          <>
            <HighlightedText>Your Data is Personal</HighlightedText>
          </>
        }
        description={
          <DescriptionText>
            You own of your personal data, and with laws like GDPR, CCPA and
            others, you have the right to ask any company that has it to delete,
            modify, or simply not to sell it. Take back control of your data in
            a few short steps.
          </DescriptionText>
        }
      />
      <MainFeature
        subheading={<Subheading1>Join Thousands of Others</Subheading1>}
        heading={<HighlightedText>Be Privacy Minded</HighlightedText>}
        description={
          <DescriptionMinded>
            Truly believing in the importance privacy is the the first step.
          </DescriptionMinded>
        }
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />

      {/*not in use <iframe src="https://player.vimeo.com/video/650987585?h=5459729409" width="750px" height="500px" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
      <div style={{ backgroundColor: '#1D2739' }} className="VideoSection">
        {/* <h2 id="vdowrapper" style={{ color: "#FFFFFF", opacity: "0.1" }}>
          How it</h2>
        <img
          src={VideoPlay}
          onClick={() => { }}
        />   <h2 id="vdowrapper" style={{ color: "#FFFFFF", opacity: "0.1" }}> works
        </h2> */}
        <div className="VideoFrame">
          <iframe
            title="PFM Video"
            src="https://s3.us-east-2.amazonaws.com/dev.privacyfor.me/Privacy+For+Me+Video.mp4"
            width="500px"
            height="300px"
            frameborder="0"
            allow="fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <FeatureWithSteps
        subheading={<StepsText id="GetStartedstepsBtn">STEPS</StepsText>}
        heading={
          <>
            <HighlightedText>
              {' '}
              Easy to
              <br /> Get Started.
            </HighlightedText>
          </>
        }
        textOnLeft={false}
        // imageSrc={macHeroScreenshotImageSrc}
        // imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        steps={[
          {
            heading: 'Register',
            description: 'Create and account using Gmail or Outlook',
            imageSrc: RegisterIcon,
          },
          {
            heading: 'Connect',
            description: 'We never see the content of your emails.',
            imageSrc: ConnectIcon,
          },
          {
            imageSrc: BrowseIcon,
            heading: 'Browse who has your Data',
            description:
              'See which companies have your data., and then decide if you want to exercise your data rights. ',
          },
        ]}
      />
      <MainFeature2
        className="WhyCareSection"
        subheading={<Principle id="GetStartedBtn">THE PRINCIPLE</Principle>}
        heading={
          <>
            <HighlightedText>
              Why should I care who has my data?
            </HighlightedText>
          </>
        }
        description="Companies lose data, leak data and have it stolen every day.  The more places your personal data appears, the more exposure you have.  Why should a company that made you sign up to see an article 5 years ago still have your data?  Reduce your data footprint today. "
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[]}
      />
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Users {''}
            <br />
            {/* <HighlightedText> */}
            Love Us.
            {/* </HighlightedText> */}
          </>
        }
        description="Showing our users that exercising your privacy rights doesn't require  sifting through a privacy policy, or finding a form buried on a website  is why we built this!"
        testimonials={[
          {
            stars: 5,
            profileImageSrc: CharlotteHale,
            heading: 'Incredibly easy to use!',
            quote:
              'Privacy For ME made it easy for me to finally ask brands to delete my personal data. All within a few minutes!',
            customerName: 'Charlotte',
            customerTitle: 'New York City',
          },
          {
            stars: 5,
            profileImageSrc: Adam_Cuppy,
            heading: '30 Seconds well spent',
            quote:
              'You will be happy to know that this is a very useful service you can use at no cost. In under 30 seconds, I was provided with a complete list of companies who are using my personal Data!',
            customerName: 'Peter',
            customerTitle: 'Los Angeles',
          },
        ]}
      />

      <FAQ
        id="FAQ"
        // subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            {/* <HighlightedText> */}
            Do You have Questions?
            {/* </HighlightedText> */}
          </>
        }
        description="We have got answers to all of them. See below or ask us directly."
        faqs={[
          {
            question: 'Who do you share information with?',
            answer: 'We do not share any data outside the company.',
          },
          {
            question: 'What data do you collect?',
            answer:
              'Privacy For Me collects basic contact information to get your account set up, and connects your email inboxes with your authorization. We only collect information from your transactional emails, such as subscriptions, purchase receipts, shipping confirms or account confirmations.  We are not interested in your personal emails, and only focus on commercial and transactional emails.',
          },
          {
            question:
              'How does Privacy For Me handle security with connected inboxes?',
            answer:
              'Whenever Privacy For Me transmits or stores your personal data, Privacy For Me works hard to protect it. For example, Privacy For Me encrypts data while exchanging it with your computer and uses top security measures to prevent unauthorized or unintended access to our network.',
          },
          {
            question: 'Do you keep my data safe?',
            answer:
              "Yes. It's our highest priority as a privacy company, so we adhere to strict security protocols. We encrypt sensitive data that might identify you. We also monitor, and test our production systems for any vulnerability. We are very strict with access to our databases, and employees who have access complete ongoing privacy and security training.",
          },
          // {
          //   question: 'Will you be transparent on data?',
          //   answer:
          //     "We will always inform you what data we collect and how we use it. It's a commitment to  transparency.  If you have any questions, concerns, or want anything further explained, please reach out to us: privacy@privacyfor.me",
          // },
          {
            question: 'Is this service free?',
            answer:
              ' Yes. Privacy For Me is free for anyone wishing to invoke their data rights with a company that has their data. Take it for a spin and use it as much as you like.  In future there may be services for which we may charge a minimal subscription. ',
          },
          // {
          //   question: 'Do you read or store the content of my emails?',
          //   answer:
          //     'We never read or store the content your emails. The information that is needed to determine which companies have your personal data, is contained in the header of your emails and includes the date, the company that sent you the email, and the subject line of the email. From this, we can help you clean up your data footprint. We can also see if the companies that you requested delete your information, or actually doing so, and how long it takes them to respond to you.',
          // },
          // {
          //   question:
          //     'Can I disconnect my email inboxes and delete my account with you?',
          //   answer:
          //     'You may disconnect your emails, or even delete your account from Privacy For Me at any time. ',
          // },
          // {
          //   question: 'How can I reach you?',
          //   answer:
          //     'For any query, you can contact us at privacy@privacyfor.me',
          // },
        ]}
      />
      {/* <GetStarted text="People all over the world are happily using Privacy For Me." /> */}
      {/* <iframe src="https://player.vimeo.com/video/650987585?h=5459729409" width="850px" height="650px" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>       */}
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
}
