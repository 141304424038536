import { useContext } from 'react';
import tw from 'twin.macro';
import SearchBar from 'material-ui-search-bar';
import { Context } from 'store';

const Wrapper = tw.div``;
const Header = tw.h2`text-lg`;

export default function WelcomeBanner() {
  const [state] = useContext(Context); //eslint-disable-line no-unused-vars

  const nameContent = state.user.name ? state.user.name : null;

  return (
    <Wrapper>
      <Header>
        Welcome <span id="WelcomeName"> {nameContent}</span>
      </Header>
    </Wrapper>
  );
}
