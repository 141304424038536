import * as React from 'react';
import { useContext, useState } from 'react';
import {
  Backdrop,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';
import { Context } from 'store';
import MyDataDetails from 'components/data/MyDataDetails';
import MyDataCard from 'components/data/MyDataCard';
import MyDataCarousal from 'components/data/MyDataCarousal';
import { useEffect } from 'react';
import SearchBar from 'material-ui-search-bar';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { axiosServiceInstance } from 'config/axiosConfig';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PrimaryButton, SecondaryButton } from 'components/misc/Buttons';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Moment from 'moment';
//import axios from 'axios';
// creating a cancel token using the CancelToken.source factory
// const CancelToken = axios.CancelToken;
// const source = CancelToken.source();
// const token = source.token;
import ClearIcon from '@material-ui/icons/Clear';
//import Modal from 'react-modal';
//--------------------
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/***Tab Section Methods */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
/* -- Tab Dection Methods */

const statusHeading = {
  fontSize: '1rem',
  lineHeight: 2.33,
  opacity: 0.7,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
  fontFamily: 'Roboto',
};
const TabHeading = {
  fontSize: '1rem',
  lineHeight: 2.33,
  opacity: 0.7,
  display: 'flex',
  justifyContent: 'right',

  fontFamily: 'Roboto',
};
var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    //backgroundColor: theme.palette.background.paper,
    //width: 500
  },
  indicator: {
    // background: "none"
  },
  tabs: {
    "& button[aria-selected='true']": {
      border: '2px solid #000 !important',
      borderColor: '#000 !important',
    },
    '& button': {
      padding: 5,
    },
    "& button[aria-selected='true']": {
      position: 'relative',

      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        //background: "linear-gradient(0deg, red 30%, #000 90%)",
        borderColor: '#6415ff !important',
        borderBottom: '1px solid #6415ff',
        zIndex: 0,
      },

      '& > *': { zIndex: 0 },
      '& > .MuiTab-wrapper': {
        background: 'none',
        borderColor: '#000',
        height: '100%',
      },
    },
  },
}));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
//const Cards = tw.div`mt-8 flex flex-wrap -mr-3 relative`;
const CardContainer = tw.div`relative mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`;
const ButtonContainer = tw.div`flex justify-center`;
const CardActionButton = tw(PrimaryButton)`my-3 mx-auto`;
const CardActionSecondaryButton = tw(SecondaryButton)`my-3 mx-auto`;

export default function MyDataContent({
  myDataDetail,
  onchangeeventflag,
  myRecommendedDataDetails,
  pendingDsrCount,
  recommendedDsrCount,
  initiatedDomain,
  BeforeOneMonthDomain,
  BeforeSixMonthDomain,
  BeforetwelveMonthDomain,
}) {
  const [loading, setLodaing] = useState(true);
  const classes = useStyles();
  const [selectedDatum, setSelectedDatum] = useState(null);
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [tabdisable, settabdisable] = useState(
    localStorage.getItem('ProgressBarApiCall') ? '' : 'true'
  );

  const [searchdomain, setSearchdomain] = useState(false);
  const [searchdomainval, setSearchdomainval] = useState('');
  const [SearchcardContent, setSearchcardContent] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [SearchErrMsg, setSearchErrMsg] = useState(false);

  const [getState, setState] = useState({});
  const [getRecState, setRecState] = useState(myRecommendedDataDetails || []);
  const [getAllState, setAllState] = useState(myDataDetail || []);
  const [getLastKey, setLastKey] = useState('');
  const [getAllDomainLastKey, setAllDomainLastKey] = useState('');
  const [Recdomaincnt, setRecdomaincnt] = useState('');
  const [displaySearchIcon, setdisplaySearchIcon] = useState(true);
  const [showloader, setshowloader] = useState(false);
  const [SelectedDsrState, setSelectedDsrState] = useState([]);
  const [RecDomainCnt, setRecDomainCnt] = useState(0);
  const [getPendingSearchcardContent, setPendingSearchcardContent] = useState(
    []
  );
  const [tabdomainleng, settabdomainleng] = useState(
    pendingDsrCount - recommendedDsrCount
  );
  const [onemontharr, setonemontharr] = useState([]);
  const [sixmontharr, setsixmontharr] = useState([]);
  const [yearmontharr, setyearmontharr] = useState([]);

  const [pageState, setPageState] = useState('view');

  const updatePageState = (state) => {
    setPageState(state);
  };
  const [popup, setPopup] = useState(false);
  const [header, setHeader] = useState('Confirm Delete?');
  const [msg, setMessage] = useState(
    'You cannot raise more than 25 deletion requests at once'
  );
  const [defaultPop, setIsDefault] = useState(true);
  const [value, setValue] = React.useState(0);
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [tabclicked, settabclicked] = useState(false);
  const [setNeededDomains, NeededDomains] = useState(false);
  const AllDomainArres = [];
  const RecDomainArr = [];
  const AllDomainArr = [];
  let AllDomainArray = [];
  let pendingdomainArr = [];

  let cardContentnew = [];
  let RecDomainArray = [];
  let selecteddomainlist = [];

  let DomainBeforeMonth = [];
  let DomainBeforeSixMonth = [];
  let DomainBeforeYear = [];
  let needededomainlist = [];
  let DomainBeforeArr = [];
  let DomainBeforeMonthArr = [];
  let DomainBeforesixArr = [];

  const [allMydataParamVal, UpdateMyDataParamVal] = useState([]);
  let MyDataCheckbox = (event) => {
    let value = event.target.value;
    state.UpdateMyDataParam(value);
  };
  // let nosearchdata = [];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    localStorage.setItem('AllDomainPageUrl', history.location.pathname);
    if (loading) {
      setLodaing(false);
    }

    if (state.dashboardData !== null && state.dashboardData !== undefined) {
      var selectedEmailObj = state.dashboardData.dropDownData.filter(function (
        e
      ) {
        return e.emailId === state.dashboardData.emailId;
      });
      if (
        selectedEmailObj[0].scanPercentage === 100 &&
        selectedEmailObj[0].status === 'completed'
      ) {
        settabdisable('');
      } else {
        settabdisable('true');
      }
    }
  }, [loading, state]);

  if (
    state.mySelectedDataDetails !== undefined &&
    state.mySelectedDataDetails.length >= 1
  ) {
    (state.mySelectedDataDetails || []).map((datum, index) => {
      if (datum !== undefined) {
        const requestedOn = new Date(
          datum !== undefined ? datum.lastEmailDate : ''
        ).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });

        var element = {};

        var todaydate = new Date();
        var datebeforesix = new Date();
        var curdate = new Date();

        //todaydate.setMonth(todaydate.getMonth() - 1);
        var dateLimitOneMonth = Moment(todaydate).format(
          'YYYY-MM-DDTHH:mm:ssZ'
        );
        datebeforesix.setMonth(todaydate.getMonth() - 1);
        const dateLimitSixMonth = Moment(datebeforesix).format(
          'YYYY-MM-DDTHH:mm:ssZ'
        );
        curdate.setMonth(curdate.getMonth() - 6);
        var dateLimitSixMonthpresent = Moment(curdate).format(
          'YYYY-MM-DDTHH:mm:ssZ'
        );
        var date = new Date();
        date.setFullYear(date.getFullYear() - 1);
        var dateLimitOneYear = Moment(date).format('YYYY-MM-DDTHH:mm:ssZ');

        element.domainName = datum.domainName;
        element.lastEmailDate = requestedOn;
        element.contactEmailId = datum.dsrContactEmail;
        element.emailAddress = datum.emailId;
        element.name = datum.name;
        selecteddomainlist.push(element);
        needededomainlist.push(element.domainName);

        if (datum.dsrRecommendation === 1) {
          RecDomainArr.push(datum);
        } else {
          AllDomainArr.push(datum);
          if (datum.firstEmailDate > dateLimitSixMonthpresent) {
            DomainBeforesixArr.push(datum);
          }

          if (datum.firstEmailDate > dateLimitSixMonth) {
            DomainBeforeMonthArr.push(datum);
          }
          if (datum.firstEmailDate > dateLimitOneYear) {
            DomainBeforeArr.push(datum);
          }
        }
      }
    });
    // setRecDomainCnt(RecDomainArr.length);
  }

  const handleChange = (event, newValue) => {
    settabclicked(true);
    dispatch({
      type: 'Active_tab_no',
      payload: newValue,
    });

    if (newValue === 0) {
      dispatch({
        type: 'Active_tab_count',
        payload: pendingDsrCount - recommendedDsrCount - AllDomainArr.length,
      });
    } else if (newValue === 2) {
      //settabdomainleng(cardContentBeforeOneMonth.length);
      dispatch({
        type: 'Active_tab_count',
        payload: BeforeOneMonthDomain - DomainBeforeMonthArr.length || 0,
      });
    } else if (newValue === 3) {
      // settabdomainleng(cardContentBeforeSixMonth.length);
      dispatch({
        type: 'Active_tab_count',
        payload: BeforeSixMonthDomain - DomainBeforesixArr.length || 0,
      });
    } else if (newValue === 4) {
      dispatch({
        type: 'Active_tab_count',
        payload: BeforetwelveMonthDomain - DomainBeforeArr.length || 0,
      });
    } else {
      dispatch({
        type: 'Active_tab_count',
        payload: state.domainsBeforetwelveMonth.length,
      });
    }
    setValue(newValue);
  };

  /*Mark As Needed Domains */
  async function udateStatusNeeded(domainobj) {
    try {
      await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/domainStatus/_bulkUpdate`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'needed',
            emailId: (state.dashboardData || []).emailId,
            bulkDomain: needededomainlist || [],
          },
        })

        .catch((e) => {
          console.error(e);
        });

      toast.success('Domains marked as needed.', {
        position: 'top-center',
        autoClose: 6000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });

      // window.location.reload();
      dispatch({
        type: 'MY_SELECTED_DATA',
        payload: [],
      });

      dispatch({
        type: 'Update_Needed_DATA',
        payload: true,
      });
      setLoaded(false);
    } catch (e) {
      console.error(e);
      setLoaded(false);
    }
  }

  function debouncecall(func, delay) {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }
  const debunceEvent = debouncecall((newValue) => {
    const eve = { key: 't' };
    setSearchdomainval(newValue);
    SearchDomain(newValue, eve);
  }, 800);
  // Get all Next Search Result Ends Here
  async function NextSearchResultDomain(Searchlastkey, domaintype, searchval) {
    setshowloader(true);
    try {
      //Get the all domains:
      const myAllDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: domaintype,
            emailId: (state.dashboardData || []).emailId,
            parsedEmailDataId: Searchlastkey || null,
            domainName: searchval,
          },
        })
        .catch((error) => {
          if (
            (error || []).data.lastKey !== null &&
            (error || []).data.lastKey !== undefined
          ) {
            NextSearchResultDomain(
              (error || []).data.lastKey,
              domaintype,
              searchval
            );
          } else {
            if (localStorage.getItem('PendingDomainList')) {
              const unique = [
                ...new Set(
                  JSON.parse(localStorage.getItem('PendingDomainList')) ||
                    [].map((item) => item.domainName)
                ),
              ];
              setSearchcardContent(
                JSON.parse(localStorage.getItem('PendingDomainList')) ===
                  undefined
                  ? []
                  : unique || []
              );
              dispatch({
                type: 'Domain_Search_DATA',
                payload:
                  JSON.parse(localStorage.getItem('PendingDomainList')) ===
                  undefined
                    ? []
                    : unique || [],
              });
            }
            setLoaded(true);
            setshowloader(false);
            localStorage.removeItem('PendingDomainList');
          }
        });

      if (myAllDataList !== undefined) {
        var PendingDomainArray = (
          JSON.parse(localStorage.getItem('PendingDomainList')) || []
        ).concat(myAllDataList.data.responseListObject);

        localStorage.setItem(
          'PendingDomainList',
          JSON.stringify(PendingDomainArray)
        );
        if (
          ((myAllDataList || []).data || []).lastKey !== null &&
          ((myAllDataList || []).data || []).lastKey !== undefined
        ) {
          NextSearchResultDomain(
            ((myAllDataList || []).data || []).lastKey,
            domaintype,
            searchval
          );
        } else {
          setSearchcardContent(
            JSON.parse(localStorage.getItem('PendingDomainList')) === undefined
              ? []
              : JSON.parse(localStorage.getItem('PendingDomainList')) || []
          );
          dispatch({
            type: 'Domain_Search_DATA',
            payload:
              JSON.parse(localStorage.getItem('PendingDomainList')) ===
              undefined
                ? []
                : JSON.parse(localStorage.getItem('PendingDomainList')) || [],
          });
          setLoaded(true);

          setshowloader(false);
          setState([]);
          localStorage.removeItem('PendingDomainList');
        }
      }
    } catch (e) {
      console.error('Unable to fetch domains:', e);

      if (e.status === 404) {
        setLoaded(true);
        //  setError({ ...((e.response || []).status || null) });
      }
    }
  }
  //Get all Next Search Result Ends Here

  // NextAll Domain Count:
  async function NextAllDomain(month) {
    try {
      //Get the all domains:
      if (month === 1 || month === 6 || month === 12) {
        const myAllDataList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'pending',
              emailId: (state.dashboardData || []).emailId,
              // || dropDownData.data.responseListObject[0].emailId,
              parsedEmailDataId: state.getAllDomain_lastkey || null,
              monthFlag: month,
            },
          })
          .catch((error) => {
            setLoaded(true);
            setState([]);
          });
        /* Concat pending domains for 1 month */
        if (month === 1) {
          const AllPendingDomainArray = state.domainsBeforeMonth.concat(
            myAllDataList.data.responseListObject
          );
          dispatch({
            type: 'Domains_Before_Month',
            payload:
              AllPendingDomainArray === undefined
                ? []
                : AllPendingDomainArray || [],
          });
        }
        /* Concat pending domains for  the 6 month */
        if (month === 6) {
          const AllPendingDomainArray = state.domainsBeforesixMonth.concat(
            myAllDataList.data.responseListObject
          );
          dispatch({
            type: 'Domains_Before_Six_Month',
            payload:
              AllPendingDomainArray === undefined
                ? []
                : AllPendingDomainArray || [],
          });
        }
        /* Concat Pending domains for 12 month */
        if (month === 12) {
          const AllPendingDomainArray = state.domainsBeforetwelveMonth.concat(
            myAllDataList.data.responseListObject
          );
          dispatch({
            type: 'Domains_Before_Twelve_Month',
            payload:
              AllPendingDomainArray === undefined
                ? []
                : AllPendingDomainArray || [],
          });
        }
      } else {
        const myAllDataList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'pending',
              emailId: (state.dashboardData || []).emailId,
              // || dropDownData.data.responseListObject[0].emailId,
              parsedEmailDataId: state.getAllDomain_lastkey || null,
            },
          })
          .catch((error) => {
            setLoaded(true);
            setState([]);
          });
        if (((myAllDataList || []).data || []).lastKey !== null) {
          // Store the last key in the redux stte / localstorage
          dispatch({
            type: 'AllDomain_Last_Key',
            payload:
              ((myAllDataList || []).data || []).lastKey === null
                ? ''
                : ((myAllDataList || []).data || []).lastKey,
          });
        }
        AllDomainArray = getAllState.concat(
          myAllDataList.data.responseListObject
        );

        dispatch({
          type: 'MY_DATA',
          payload: AllDomainArray === undefined ? [] : AllDomainArray || [],
        });
      }

      setAllState(AllDomainArray === undefined ? [] : AllDomainArray || []);
    } catch (e) {
      console.error('Unable to fetch domains:', e);

      if (e.status === 404) {
        setLoaded(true);
      }
    }
  }
  //Get all Next Domain Ends Here
  async function NextRecommendedDomain() {
    try {
      const dropDownData = await axiosServiceInstance
        .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
        })
        .catch((error) => {
          setLoaded(true);
          setState([]);
        });

      //Get the recommended domains:
      const myRecomendedDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'recommended',
            emailId:
              (state.dashboardData || []).emailId ||
              dropDownData.data.responseListObject[0].emailId,
            parsedEmailDataId: state.getlastkey || null,
          },
        })
        .catch((error) => {
          setLoaded(true);
          setState([]);
        });

      if (((myRecomendedDataList || []).data || []).lastKey !== null) {
        // Store the last key in the redux stte / localstorage
        dispatch({
          type: 'Last_Key',
          payload:
            ((myRecomendedDataList || []).data || []).lastKey === null
              ? ''
              : ((myRecomendedDataList || []).data || []).lastKey,
        });
        setLastKey(
          ((myRecomendedDataList || []).data || []).lastKey === null
            ? ''
            : ((myRecomendedDataList || []).data || []).lastKey
        );
      }
      RecDomainArray = getRecState.concat(
        myRecomendedDataList.data.responseListObject
      );

      dispatch({
        type: 'MY_Recommended_DATA',
        payload: RecDomainArray === undefined ? [] : RecDomainArray || [],
      });
      setRecState(RecDomainArray === undefined ? [] : RecDomainArray || []);
    } catch (e) {
      console.error('Unable to fetch domains:', e);

      if (e.status === 404) {
        setLoaded(true);
      }
    }
  }

  function SearchDomain(searchdomainParamVal, e) {
    setshowloader(true);
    setdisplaySearchIcon(false);
    searchdomainParamVal = searchdomainParamVal.toLowerCase();

    if (
      searchdomainParamVal === '' ||
      searchdomainParamVal === undefined ||
      (e.keyCode === 8 && searchdomainParamVal === '')
    ) {
      setSearchdomain(false);
      setdisplaySearchIcon(true);
      setSearchErrMsg(false);
      setSearchcardContent([]);
      dispatch({
        type: 'Domain_Search_DATA',
        payload: [],
      });
    } else {
      if (searchdomainParamVal.length > 1) {
        setSearchErrMsg(false);
        localStorage.removeItem('PendingDomainList');
        setSearchcardContent([]);
        dispatch({
          type: 'Domain_Search_DATA',
          payload: [],
        });
        const searchTimeout = setTimeout(() => {
          SearchDomainApi(searchdomainParamVal);
        }, 50);

        setSearchdomain(true);
      } else {
        setSearchdomain(false);
        setSearchErrMsg(true);
      }
    }
  }

  /*Final Search Domain API */
  async function SearchDomainApi(searchdomainParamVal) {
    try {
      //Get the all domains:
      const myAllDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'pending',
            emailId: (state.dashboardData || []).emailId,
            //parsedEmailDataId: state.getAllDomain_lastkey || null,
            domainName: searchdomainParamVal,
          },
        })
        .catch((error) => {
          if (
            (error || []).data.lastKey !== null &&
            (error || []).data.lastKey !== undefined
          ) {
            NextSearchResultDomain(
              (error || []).data.lastKey,
              'pending',
              searchdomainParamVal
            );
          }

          setLoaded(true);
          //  setRecState([]);
        });
      if (myAllDataList !== undefined) {
        pendingdomainArr =
          myAllDataList.data.responseListObject.concat(pendingdomainArr);
        localStorage.setItem(
          'PendingDomainList',
          JSON.stringify(myAllDataList.data.responseListObject)
        );
      }
      // setPendingSearchcardContent(
      //   myAllDataList === undefined
      //     ? []
      //     : myAllDataList.data.responseListObject || []
      // );

      //Search the Recommended domains:
      //Get the recommended domains:

      const myRecomendedDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'recommended',
            emailId: (state.dashboardData || []).emailId,
            //parsedEmailDataId: state.getlastkey || null,
            domainName: searchdomainParamVal,
          },
        })
        .catch((error) => {
          if (
            (error || []).data.lastKey !== null &&
            (error || []).data.lastKey !== undefined
          ) {
            NextSearchResultDomain(
              (error || []).data.lastKey,
              'recommended',
              searchdomainParamVal
            );
          }
          setLoaded(true);
          setState([]);
        });

      //End of Search the Recommended Domains:
      const searchTimeoutwait = setTimeout(() => {
        if (myRecomendedDataList !== undefined) {
          if (
            JSON.parse(localStorage.getItem('PendingDomainList')) !== undefined
          ) {
            AllDomainArray =
              myRecomendedDataList.data.responseListObject.concat(
                JSON.parse(localStorage.getItem('PendingDomainList'))
              );
          } else {
            AllDomainArray = myRecomendedDataList.data.responseListObject;
          }

          setSearchcardContent(
            AllDomainArray === undefined ? [] : AllDomainArray
          );
          dispatch({
            type: 'Domain_Search_DATA',
            payload: AllDomainArray === undefined ? [] : AllDomainArray,
          });
        } else {
          setSearchcardContent(
            JSON.parse(localStorage.getItem('PendingDomainList')) === undefined
              ? []
              : JSON.parse(localStorage.getItem('PendingDomainList')) || []
          );
          dispatch({
            type: 'Domain_Search_DATA',
            payload:
              JSON.parse(localStorage.getItem('PendingDomainList')) ===
              undefined
                ? []
                : JSON.parse(localStorage.getItem('PendingDomainList')) || [],
          });
          setTimeout(() => {
            setshowloader(false);
          }, 500);
        }
      }, 500);
    } catch (e) {
      console.error('Unable to fetch domains:', e);

      if (e.status === 404) {
        // alert("not fund");
        setLoaded(true);
        //  setError({ ...((e.response || []).status || null) });
      }
    }
  }

  var todaydate = new Date();
  var datebeforesix = new Date();
  var curdate = new Date();

  //todaydate.setMonth(todaydate.getMonth() - 1);
  var dateLimitOneMonth = Moment(todaydate).format('YYYY-MM-DDTHH:mm:ssZ');
  datebeforesix.setMonth(todaydate.getMonth() - 1);
  const dateLimitSixMonth = Moment(datebeforesix).format(
    'YYYY-MM-DDTHH:mm:ssZ'
  );
  curdate.setMonth(curdate.getMonth() - 6);
  var dateLimitSixMonthpresent = Moment(curdate).format('YYYY-MM-DDTHH:mm:ssZ');
  var date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  var dateLimitOneYear = Moment(date).format('YYYY-MM-DDTHH:mm:ssZ');

  if (state.myDataDetails != undefined || state.myDataDetails != []) {
    (state.myDataDetails || []).map((datum, index) => {
      if (datum.firstEmailDate > dateLimitSixMonthpresent) {
        DomainBeforeSixMonth.push(datum);
      }
      if (datum.firstEmailDate > dateLimitSixMonth) {
        DomainBeforeMonth.push(datum);
      }
      if (datum.firstEmailDate > dateLimitOneYear) {
        DomainBeforeYear.push(datum);
      }
    });
  }
  const cardContent = (state.myDataDetails || []).map((datum, index) => {
    if (datum.status === 'initiated') {
      var disablevar = 'disabled';
    } else {
      var disablevar = '';
    }

    let SelectedCardobj = (state.mySelectedDataDetails || []).find(
      (o) => o.domainName === datum.domainName
    );
    if (SelectedCardobj === undefined) {
      return (
        <CardContainer key={index} id="MyCardWrapper">
          <MyDataCard
            index={index}
            id={datum.id}
            name={datum.name}
            domain={datum.domainName}
            emailAddress={datum.emailId}
            status={datum.status}
            userDetail={state.user || []}
            lastEmailDate={datum.lastEmailDate || ''}
            contactEmail={datum.dsrContactEmail || ''}
            alternateContactEmail={datum.dsrAlternativeEmail || ''}
            viewDetail={datum}
            selectDatum={setSelectedDatum}
            checked=""
            disabled={disablevar}
            NeededDomains={NeededDomains}
          />
        </CardContainer>
      );
    }
  });
  //}

  const cardContentBeforeOneMonth = (state.domainsBeforeMonth || []).map(
    (datum, index) => {
      if (datum.status === 'initiated') {
        var disablevar = 'disabled';
      } else {
        var disablevar = '';
      }
      let SelectedCardobj = (state.mySelectedDataDetails || []).find(
        (o) => o.domainName === datum.domainName
      );
      if (SelectedCardobj === undefined) {
        return (
          <CardContainer key={index} id="MyCardWrapper">
            <MyDataCard
              index={index}
              id={datum.id}
              name={datum.name}
              domain={datum.domainName}
              emailAddress={datum.emailId}
              status={datum.status}
              userDetail={state.user || []}
              lastEmailDate={datum.lastEmailDate || ''}
              contactEmail={datum.dsrContactEmail || ''}
              alternateContactEmail={datum.dsrAlternativeEmail || ''}
              viewDetail={datum}
              selectDatum={setSelectedDatum}
              checked=""
              disabled={disablevar}
            />
          </CardContainer>
        );
      }
    }
  );

  const cardContentBeforeSixMonth = (state.domainsBeforesixMonth || []).map(
    (datum, index) => {
      if (datum.status === 'initiated') {
        var disablevar = 'disabled';
      } else {
        var disablevar = '';
      }
      let SelectedCardobj = (state.mySelectedDataDetails || []).find(
        (o) => o.domainName === datum.domainName
      );
      if (SelectedCardobj === undefined) {
        return (
          <CardContainer key={index} id="MyCardWrapper">
            <MyDataCard
              index={index}
              id={datum.id}
              name={datum.name}
              domain={datum.domainName}
              emailAddress={datum.emailId}
              status={datum.status}
              userDetail={state.user || []}
              lastEmailDate={datum.lastEmailDate || ''}
              contactEmail={datum.dsrContactEmail || ''}
              alternateContactEmail={datum.dsrAlternativeEmail || ''}
              viewDetail={datum}
              selectDatum={setSelectedDatum}
              checked=""
              disabled={disablevar}
            />
          </CardContainer>
        );
      }
    }
  );
  const cardContentBeforeOneYear = (state.domainsBeforetwelveMonth || []).map(
    (datum, index) => {
      if (datum.status === 'initiated') {
        var disablevar = 'disabled';
      } else {
        var disablevar = '';
      }
      let SelectedCardobj = (state.mySelectedDataDetails || []).find(
        (o) => o.domainName === datum.domainName
      );
      if (SelectedCardobj === undefined) {
        return (
          <CardContainer key={index} id="MyCardWrapper">
            <MyDataCard
              index={index}
              id={datum.id}
              name={datum.name}
              domain={datum.domainName}
              emailAddress={datum.emailId}
              status={datum.status}
              userDetail={state.user || []}
              lastEmailDate={datum.lastEmailDate || ''}
              contactEmail={datum.dsrContactEmail || ''}
              alternateContactEmail={datum.dsrAlternativeEmail || ''}
              viewDetail={datum}
              selectDatum={setSelectedDatum}
              checked=""
              disabled={disablevar}
            />
          </CardContainer>
        );
      }
    }
  );

  //if(getRecState.length > 0){

  const recommendedcardContent = (state.myRecommendedDataDetails || []).map(
    (datum, index) => {
      if (datum.status === 'initiated') {
        var disablevar = 'disabled';
      } else {
        var disablevar = '';
      }
      // if (datum.dsrRecommendation === 1) {
      let SelectedCardobj = (state.mySelectedDataDetails || []).find(
        (o) => o.domainName === datum.domainName
      );
      if (SelectedCardobj === undefined) {
        return (
          <CardContainer key={index} id="MyCardWrapper">
            <MyDataCard
              index={index}
              id={datum.id}
              name={datum.name}
              domain={datum.domainName}
              emailAddress={datum.emailId}
              status={datum.status}
              userDetail={state.user || []}
              lastEmailDate={datum.lastEmailDate || ''}
              contactEmail={datum.dsrContactEmail || ''}
              alternateContactEmail={datum.dsrAlternativeEmail || ''}
              viewDetail={datum}
              selectDatum={setSelectedDatum}
              checked=""
              disabled={disablevar}
            />
          </CardContainer>
        );
      }
      //}
    }
  );
  //}

  //My Selected DSR:
  const selectedDsr = (state.mySelectedDataDetails || []).map(
    (datum, index) => {
      if (datum !== undefined) {
        return (
          <CardContainer key={index} id="MyCardWrapper">
            <MyDataCard
              index={index}
              id={datum.id}
              name={datum.name}
              domain={datum.domainName}
              emailAddress={datum.emailId}
              status={datum.status}
              userDetail={state.user || []}
              lastEmailDate={datum.lastEmailDate || ''}
              contactEmail={datum.dsrContactEmail || ''}
              alternateContactEmail={datum.dsrAlternativeEmail || ''}
              viewDetail={datum}
              selectDatum={setSelectedDatum}
              pageState={pageState}
              triggerParentUpdate={updatePageState}
              checked="true"
            />
          </CardContainer>
        );
      }
    }
  );
  const domainlistobj = {
    name: state.user.name,
    domain: selecteddomainlist || [],
    emailAddress: state.user.email,
  };

  //-- My Selected DSR
  if (searchdomain) {
    if (
      state.DomainSearchDataDetails !== undefined &&
      state.DomainSearchDataDetails.length > 0
    ) {
      if (
        state.DomainSearchDataDetails[0] === null &&
        state.DomainSearchDataDetails.length === 1
      ) {
        setSearchdomain(false);
        setSearchdomainval('');
      }
      cardContentnew = state.DomainSearchDataDetails.map((datum, index) => {
        if (datum !== undefined && datum !== null) {
          if (datum.status === 'initiated') {
            var disablevar = 'disabled';
          } else {
            var disablevar = '';
          }
          var checkisselected = (state.mySelectedDataDetails || []).filter(
            function (e) {
              return e.domainName == datum.domainName;
            }
          );
          if (checkisselected.length > 0) {
            var checktick = 'checked';
          } else {
            var checktick = '';
          }
          return (
            <CardContainer key={index} id="MyCardWrapper">
              <MyDataCard
                index={index}
                id={datum.id}
                name={datum.name}
                domain={datum.domainName}
                emailAddress={datum.emailId}
                status={datum.status}
                userDetail={state.user || []}
                lastEmailDate={datum.lastEmailDate || ''}
                contactEmail={datum.dsrContactEmail || ''}
                alternateContactEmail={datum.dsrAlternativeEmail || ''}
                viewDetail={datum}
                selectDatum={setSelectedDatum}
                checked={checktick}
                disabled={disablevar}
              />
            </CardContainer>
          );
        }
      });
    }
  }

  const nosearchdatanew = <span>No Data Found</span>;

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={selectedDatum}
        onClick={() => {
          setSelectedDatum(null);
        }}
      >
        {selectedDatum && (
          <MyDataDetails
            data={{ selectedDatum }}
            selectDatum={setSelectedDatum}
          />
        )}
      </Backdrop>

      <>
        <Backdrop
          className={classes.backdrop}
          open={popup}
          onClick={() => {
            setPopup(false);
          }}
        >
          <Card>
            <Box style={{ color: 'black', textAlign: 'right', padding: '5px' }}>
              <ClearIcon
                className="PopupClearIcon"
                onClick={() => {
                  setPopup(false);
                }}
              />
            </Box>
            {/* <CardHeader className="DeletPopupHeader" title={header} />
            <hr /> */}
            <CardContent id="ModalContent">
              <Typography id="DeleteText" variant="h6" color="textPrimary">
                {msg}
              </Typography>
              {defaultPop ? (
                <>
                  {/* <PrimaryButton id="yesButtons" >
                    Yes
                  </PrimaryButton> */}
                  <PrimaryButton
                    id="noButtons"
                    onClick={() => {
                      setPopup(false);
                    }}
                  >
                    Ok
                  </PrimaryButton>
                </>
              ) : null}
            </CardContent>
          </Card>
        </Backdrop>
      </>
      {/* 
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        
        contentLabel="Example Modal"
      >
        <Box style={{ color: 'black', textAlign: 'right', padding: '5px' }}>
          <ClearIcon
            className="PopupClearIcon"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </Box>
        
        <CardContent id="ModalContent">
          <Typography id="DeleteText" variant="h6" color="textPrimary">
            {msg}
          </Typography>
          {defaultPop ? (
            <>
              
              <PrimaryButton
                id="noButtons"
                className="DeletePopupBtn"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Ok
              </PrimaryButton>
            </>
          ) : null}
        </CardContent>
      </Modal> */}

      <div id="MyDataPage">
        <Typography variant="h4" gutterBottom>
          Companies with your data
        </Typography>
        <Typography paragraph>
          Here you can find a list of companies that have your data. Click
          Request Deletion to start a Data Subject Request.
        </Typography>
        <div id="DomainSearch">
          <SearchBar
            value={searchdomainval}
            onRequestSearch={(e) => SearchDomain(searchdomainval, e)}
            onChange={(newValue) => {
              debunceEvent(newValue);
            }}
            onCancelSearch={() => {
              setPendingSearchcardContent([]);
              setSearchdomain(false);
              setSearchErrMsg(false);
            }}
            //onBlur={(e) => SearchDomain(searchdomainval, e)}
            // onKeyPress={(e) => SearchDomain(searchdomainval)}
            // onKeyUp={(e) => SearchDomain(searchdomainval, e)}
            placeholder="Search your domain"
            searchIcon={<></>}
          />
        </div>
        {SearchErrMsg ? (
          <p className="SearchErr">Please enter atleast 2 characters</p>
        ) : (
          ''
        )}

        {searchdomain ? (
          cardContentnew.length > 0 ? (
            <>
              <Typography variant="h5" gutterBottom>
                <span class="socialhead">Search Result: </span>
              </Typography>
              <div className="CarouselSlider">
                <Carousel
                  responsive={responsive}
                  showArrows={true}
                  swipeable={true}
                  draggable={false}
                  showDots={false}
                  infinite={false}
                  autoPlay={false}
                  autoPlaySpeed={7000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  slidesToSlide={4}
                  shouldResetAutoplay={false}
                >
                  {cardContentnew}
                </Carousel>
              </div>
            </>
          ) : showloader ? (
            <div id="mydataloader">
              <CircularProgress />
            </div>
          ) : (
            <div id="noSearchResult">
              <Typography paragraph>No Domain Found.</Typography>
            </div>
          )
        ) : (
          <div>
            {selectedDsr.length >= 1 && (
              <>
                <ButtonContainer id="DataFooterButton" className="MultiDSRBtn">
                  <CardActionButton
                    // onClick={openModal}
                    onClick={() => {
                      localStorage.setItem(
                        'AllDomainPageUrl',
                        history.location.pathname
                      );
                      // if (domainlistobj.domain.length > 25) {
                      //   // setIsOpen(true);
                      //   setMessage(
                      //     'You cannot raise more than 25 deletion requests at once.'
                      //   );
                      //   setPopup(true);
                      // } else {
                      var Name = 'seema';
                      var payload = domainlistobj;

                      dispatch({
                        type: 'CARD_DETAIL_FOR_VIEW_TEMPLATE',
                        payload: payload,
                      });
                      history.push(`/request-deletion-multiple?id=1234`);
                      //}
                    }}
                  >
                    {/* {status === 'initiated' ? 'DSR Raised' : 'Request Deletion'} */}
                    {'Request Deletion for Selected'}
                  </CardActionButton>
                  <CardActionButton
                    onClick={() => {
                      // setLoaded(true);
                      udateStatusNeeded(needededomainlist);
                    }}
                  >
                    {'Mark Needed For Selected'}
                  </CardActionButton>
                </ButtonContainer>
                <div style={statusHeading} className="Tab_flex">
                  <p>
                    <span class="socialhead pt-0">Selected Domains:</span>
                  </p>
                  <p class="socialhead pt-0">Total ({selectedDsr.length})</p>
                </div>

                <div className="CarouselSlider">
                  <Carousel
                    responsive={responsive}
                    showArrows={true}
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={7000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    slidesToSlide={4}
                    shouldResetAutoplay={false}
                  >
                    {selectedDsr}
                  </Carousel>
                </div>
              </>
            )}
            <MyDataCarousal
              status="recomended"
              carousaltitle="We recommend you to raise Data Subject Request for"
              domaintotal={recommendedDsrCount - RecDomainArr.length}
              selectedrenderdedcardContent={recommendedcardContent}
            />

            {/* {getRecState.length - RecDomainArr.length >= 1 && (
              <>
                <div style={statusHeading} className="Tab_flex">
                  <p>
                    <span class="socialhead pt-0">
                      We recommend you to raise Data Subject Request for:{' '}
                    </span>
                  </p>
                  <p class="socialhead pt-0">
                    Total ({recommendedDsrCount - RecDomainArr.length})
                  </p>
                </div>

                <div className="CarouselSlider">
                  <Carousel
                    responsive={responsive}
                    showArrows={true}
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={7000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    slidesToSlide={4}
                     shouldResetAutoplay={false}
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                      if (currentSlide >= getRecState.length - 4) {
                        if (
                          state.getlastkey !== null &&
                          state.getlastkey !== undefined
                        ) {
                          NextRecommendedDomain();
                        }
                      }
                    }}
                  >
                    {recommendedcardContent}
                  </Carousel>
                </div>
              </>
            )} */}

            <Box sx={{ width: '100%' }} id="AllDomainTabWrapper">
              <div className="Tab_flex">
                <Box
                  sx={{
                    borderColor: '#000',
                    color: '#6417ff',
                    textTransform: 'initial !important',
                  }}
                  id="AllDomainTabInnerWrapper"
                >
                  <Tabs
                    indicatorColor="Black"
                    className={classes.tabs}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    id="AllDomainTab"
                  >
                    <Tab
                      label="All Domains"
                      {...a11yProps(0)}
                      id="AllDomainTabOne"
                    />
                    <Tab
                      label="When First Joined:"
                      // {...a11yProps(1)}
                      // id="AllDomainTabTwo"
                      disabled
                    />
                    <Tab
                      label="1 Month"
                      {...a11yProps(1)}
                      id="AllDomainTabTwo"
                      disabled={tabdisable}
                    />
                    <Tab
                      label="6 Months"
                      {...a11yProps(2)}
                      id="AllDomainTabThree"
                      disabled={tabdisable}
                    />
                    <Tab
                      label="12 Months"
                      {...a11yProps(3)}
                      id="AllDomainTabFour"
                      disabled={tabdisable}
                    />
                  </Tabs>
                </Box>
                <div className="TabTotalWrapper">
                  <p class="socialhead">
                    Total (
                    {tabclicked
                      ? state.active_tab_cnt
                      : pendingDsrCount -
                        recommendedDsrCount -
                        AllDomainArr.length}
                    )
                  </p>
                </div>
              </div>
              <TabPanel value={value} index={0}>
                {cardContent.length >= 1 && (
                  <>
                    <div className="AllDomainCount" style={TabHeading}>
                      {
                        <p class="socialhead alldomainhead">
                          {' '}
                          {recommendedcardContent.length > 1 ? '' : ''}
                        </p>
                      }
                    </div>

                    <div className="CarouselSlider">
                      <Carousel
                        responsive={responsive}
                        showArrows={false}
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        infinite={false}
                        autoPlay={false}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        slidesToSlide={4}
                        shouldResetAutoplay={false}
                        auto
                        beforeChange={(
                          previousSlide,
                          { currentSlide, onMove }
                        ) => {
                          if (currentSlide >= getAllState.length - 8) {
                            if (
                              state.getAllDomain_lastkey !== null &&
                              state.getAllDomain_lastkey !== undefined
                            ) {
                              NextAllDomain(0);
                            }
                          }
                        }}
                      >
                        {cardContent}
                      </Carousel>
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {cardContentBeforeOneMonth.length >= 1 ? (
                  <>
                    <div style={TabHeading}>
                      {/* <p class="socialhead">
                  Joined Over A Month Ago
                    </p> */}
                    </div>

                    <div className="CarouselSlider">
                      <Carousel
                        responsive={responsive}
                        showArrows={true}
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        infinite={false}
                        autoPlay={false}
                        autoPlaySpeed={7000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        slidesToSlide={4}
                        shouldResetAutoplay={false}
                        beforeChange={(
                          previousSlide,
                          { currentSlide, onMove }
                        ) => {
                          if (
                            currentSlide >=
                            cardContentBeforeOneMonth.length - 8
                          ) {
                            if (
                              state.getAllDomain_lastkey !== null &&
                              state.getAllDomain_lastkey !== undefined
                            ) {
                              NextAllDomain(1);
                            }
                          }
                        }}
                      >
                        {cardContentBeforeOneMonth}
                      </Carousel>
                    </div>
                  </>
                ) : (
                  <div id="noSearchResult">
                    <Typography paragraph>No Domain Found.</Typography>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                {cardContentBeforeSixMonth.length >= 1 ? (
                  <>
                    <div style={TabHeading}>
                      {/* <p class="socialhead">
                    {' '}
                    {recommendedcardContent.length <= 1
                      ? 'Domains in your Inbox'
                      : 'Other domains in your inbox:'}
                  </p> */}
                    </div>

                    <div className="CarouselSlider">
                      <Carousel
                        responsive={responsive}
                        showArrows={true}
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        infinite={false}
                        autoPlay={false}
                        autoPlaySpeed={7000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        slidesToSlide={4}
                        shouldResetAutoplay={false}
                        beforeChange={(
                          previousSlide,
                          { currentSlide, onMove }
                        ) => {
                          if (
                            currentSlide >=
                            cardContentBeforeSixMonth.length - 8
                          ) {
                            if (
                              state.getAllDomain_lastkey !== null &&
                              state.getAllDomain_lastkey !== undefined
                            ) {
                              NextAllDomain(6);
                            }
                          }
                        }}
                      >
                        {cardContentBeforeSixMonth}
                      </Carousel>
                    </div>
                  </>
                ) : (
                  <div id="noSearchResult">
                    <Typography paragraph>No Domain Found.</Typography>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={4}>
                {cardContentBeforeOneYear.length >= 1 ? (
                  <>
                    <div style={TabHeading}>
                      {/* <p class="socialhead">
                    {' '}
                    {recommendedcardContent.length <= 1
                      ? 'Domains in your Inbox'
                      : 'Other domains in your inbox:'}
                  </p> */}
                    </div>

                    <div className="CarouselSlider">
                      <Carousel
                        responsive={responsive}
                        showArrows={true}
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        infinite={false}
                        autoPlay={false}
                        autoPlaySpeed={7000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        slidesToSlide={4}
                        shouldResetAutoplay={false}
                        beforeChange={(
                          previousSlide,
                          { currentSlide, onMove }
                        ) => {
                          if (
                            currentSlide >=
                            cardContentBeforeOneYear.length - 8
                          ) {
                            if (
                              state.getAllDomain_lastkey !== null &&
                              state.getAllDomain_lastkey !== undefined
                            ) {
                              NextAllDomain(12);
                            }
                          }
                        }}
                      >
                        {cardContentBeforeOneYear}
                      </Carousel>
                    </div>
                  </>
                ) : (
                  <div id="noSearchResult">
                    <Typography paragraph>No Domain Found.</Typography>
                  </div>
                )}
              </TabPanel>
            </Box>
          </div>
        )}
      </div>
    </>
  );
}
