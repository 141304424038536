import { useContext, useState, useEffect } from 'react';
import tw from 'twin.macro';
import SearchBar from 'material-ui-search-bar';
//import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Context } from 'store';
import { axiosServiceInstance } from 'config/axiosConfig';
import { Typography } from '@material-ui/core';
import Moment from 'moment';
import { memo } from 'react';

const Wrapper = tw.div``;

const Search = ({ data, dataList, isNoDomain, renderTable }) => {
  const [state, dispatch] = useContext(Context);
  const [searchdomainval, setSearchdomainval] = useState('');
  const [displaySearchIcon, setdisplaySearchIcon] = useState(true);
  const [showloader, setshowloader] = useState(false);
  const [searchdomain, setSearchdomain] = useState(false);
  const [SearchErrMsg, setSearchErrMsg] = useState(false);
  const [SearchcardContent, setSearchcardContent] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [getState, setState] = useState({});
  const [nosearchresult, setnosearchresult] = useState(false);
  let AllDomainArray = [];
  let pendingdomainArr = [];

  useEffect(() => {
    if (state.reloadDomainTable === true) {
      setSearchdomainval('');
    }
  }, [state]);

  function debouncecall(func, delay) {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }
  const debunceEvent = debouncecall((newValue) => {
    const eve = { key: 't' };
    setSearchdomainval(newValue);
    SearchDomain(newValue, eve);
  }, 800);
  function SearchDomain(searchdomainParamVal, e) {
    setshowloader(true);
    setdisplaySearchIcon(false);
    searchdomainParamVal = searchdomainParamVal.toLowerCase();

    if (
      searchdomainParamVal === '' ||
      searchdomainParamVal === undefined ||
      (e.keyCode === 8 && searchdomainParamVal === '')
    ) {
      setSearchdomain(false);
      setdisplaySearchIcon(true);
      setSearchErrMsg(false);

      dispatch({
        type: 'Reload_Domain_Table',
        payload: true,
      });
      renderTable(false, true);

      // dispatch({
      //   type: 'Pending_TILE_DATA',
      //   payload: dataList === undefined ? [] : dataList || [],
      // });
    } else {
      if (searchdomainParamVal.length > 1) {
        setSearchErrMsg(false);
        localStorage.removeItem('PendingDomainList');

        const searchTimeout = setTimeout(() => {
          SearchDomainApi(searchdomainParamVal);
        }, 50);

        setSearchdomain(true);
      } else {
        setSearchdomain(false);
        setSearchErrMsg(true);
      }
    }
  }

  /*Final Search Domain API */
  async function SearchDomainApi(searchdomainParamVal) {
    try {
      //Get the all domains:
      if (
        data === 'PendingRecommended' ||
        data === 'PendingRecommendedNeeded'
      ) {
        var myAllDataList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'pending',
              emailId: (state.dashboardData || []).emailId,
              //parsedEmailDataId: state.getAllDomain_lastkey || null,
              domainName: searchdomainParamVal,
            },
          })
          .catch((error) => {
            if (
              (error || []).data.errors[0].message === 'Data not found' ||
              (error || []).data.errors[0].message === 'Please provide status'
            ) {
              setLoaded(true);
            } else if (
              (error || []).data.lastKey !== null &&
              (error || []).data.lastKey !== undefined
            ) {
              NextSearchResultDomain(
                (error || []).data.lastKey,
                'pending',
                searchdomainParamVal
              );
            }

            setLoaded(true);
            //  setRecState([]);
          });
        if (myAllDataList !== undefined) {
          pendingdomainArr =
            myAllDataList.data.responseListObject.concat(pendingdomainArr);
          localStorage.setItem(
            'PendingDomainList',
            JSON.stringify(myAllDataList.data.responseListObject)
          );
        }
      }
      // setPendingSearchcardContent(
      //   myAllDataList === undefined
      //     ? []
      //     : myAllDataList.data.responseListObject || []
      // );

      //Search the Recommended domains:
      //Get the recommended domains:
      if (
        data === 'PendingRecommended' ||
        data === 'Recommended' ||
        data === 'PendingRecommendedNeeded'
      ) {
        var myRecomendedDataList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'recommended',
              emailId: (state.dashboardData || []).emailId,
              //parsedEmailDataId: state.getlastkey || null,
              domainName: searchdomainParamVal,
            },
          })
          .catch((error) => {
            if (
              (error || []).data.errors[0].message === 'Data not found' ||
              (error || []).data.errors[0].message === 'Please provide status'
            ) {
              setLoaded(true);
            } else if (
              (error || []).data.lastKey !== null &&
              (error || []).data.lastKey !== undefined
            ) {
              NextSearchResultDomain(
                (error || []).data.lastKey,
                'recommended',
                searchdomainParamVal
              );
            }
            setLoaded(true);
            //setState([]);
          });
      }
      //End of Search the Recommended Domains:

      //search Needed domains:
      if (data === 'PendingRecommendedNeeded') {
        var myNeededDomainList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'needed',
              emailId: (state.dashboardData || []).emailId,
              //parsedEmailDataId: state.getlastkey || null,
              domainName: searchdomainParamVal,
            },
          })
          .catch((error) => {
            if (
              (error || []).data.errors[0].message === 'Data not found' ||
              (error || []).data.errors[0].message === 'Please provide status'
            ) {
              setLoaded(true);
            } else if (
              (error || []).data.lastKey !== null &&
              (error || []).data.lastKey !== undefined
            ) {
              NextSearchResultDomain(
                (error || []).data.lastKey,
                'needed',
                searchdomainParamVal
              );
            }
            setLoaded(true);
            //setState([]);
          });
      }
      //End of search Needed domain

      //search FailedProcessing domains:
      if (data === 'FailedProcessingInitiated') {
        var failedProcessingList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'failed,processing,initiated',
              emailId: (state.dashboardData || []).emailId,
              //parsedEmailDataId: state.getlastkey || null,
              domainName: searchdomainParamVal,
            },
          })
          .catch((error) => {
            if (
              (error || []).data.errors[0].message === 'Data not found' ||
              (error || []).data.errors[0].message === 'Please provide status'
            ) {
              setLoaded(true);
            } else if (
              (error || []).data.lastKey !== null &&
              (error || []).data.lastKey !== undefined
            ) {
              NextSearchResultDomain(
                (error || []).data.lastKey,
                'failed,processing',
                searchdomainParamVal
              );
            }
            setLoaded(true);
            //setState([]);
          });
      }
      //End of search FailedProcessing domain

      //search InitaitedReclaimed domains:
      if (data === 'Reclaimed') {
        var initaitedReclaimedList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'reclaimed',
              emailId: (state.dashboardData || []).emailId,
              //parsedEmailDataId: state.getlastkey || null,
              domainName: searchdomainParamVal,
            },
          })
          .catch((error) => {
            if (
              (error || []).data.errors[0].message === 'Data not found' ||
              (error || []).data.errors[0].message === 'Please provide status'
            ) {
              setLoaded(true);
            } else if (
              (error || []).data.lastKey !== null &&
              (error || []).data.lastKey !== undefined
            ) {
              NextSearchResultDomain(
                (error || []).data.lastKey,
                'initiated,reclaimed',
                searchdomainParamVal
              );
            }
            setLoaded(true);
            //setState([]);
          });
      }
      //End of search InitaitedReclaimed domain

      const searchTimeoutwait = setTimeout(() => {
        //first tile search conditions
        if (
          myRecomendedDataList !== undefined ||
          myNeededDomainList !== undefined
        ) {
          if (
            (JSON.parse(localStorage.getItem('PendingDomainList')) !==
              undefined &&
              JSON.parse(localStorage.getItem('PendingDomainList')) !== null) ||
            myNeededDomainList !== undefined
          ) {
            if (myRecomendedDataList !== undefined) {
              AllDomainArray =
                myRecomendedDataList.data.responseListObject.concat(
                  JSON.parse(localStorage.getItem('PendingDomainList'))
                );
            }

            if (myNeededDomainList !== undefined) {
              if (
                JSON.parse(localStorage.getItem('PendingDomainList')) !==
                  undefined &&
                JSON.parse(localStorage.getItem('PendingDomainList')) !== null
              ) {
                AllDomainArray =
                  myNeededDomainList.data.responseListObject.concat(
                    JSON.parse(localStorage.getItem('PendingDomainList'))
                  );
                if (myRecomendedDataList !== undefined) {
                  AllDomainArray =
                    myRecomendedDataList.data.responseListObject.concat(
                      JSON.parse(localStorage.getItem('PendingDomainList'))
                    );
                  AllDomainArray = AllDomainArray.concat(
                    myNeededDomainList.data.responseListObject
                  );
                }
              } else {
                AllDomainArray = AllDomainArray.concat(
                  myNeededDomainList.data.responseListObject
                );
                //myNeededDomainList = null;
              }
            }
            //console.log('Searched domain data if--->', AllDomainArray);
          } else {
            AllDomainArray = myRecomendedDataList.data.responseListObject;
            //console.log('Searched domain data else--->', AllDomainArray);
          }
          // dispatch({
          //   type: 'Pending_TILE_DATA',
          //   payload:
          //     JSON.parse(localStorage.getItem('PendingDomainList')) ===
          //     undefined
          //       ? []
          //       : JSON.parse(localStorage.getItem('PendingDomainList')) || [],
          // });
        } else {
          if (
            failedProcessingList !== undefined ||
            initaitedReclaimedList !== undefined
          ) {
            if (failedProcessingList !== undefined) {
              AllDomainArray = failedProcessingList.data.responseListObject;
            } else {
              failedProcessingList = null;
              if (initaitedReclaimedList !== undefined) {
                AllDomainArray = initaitedReclaimedList.data.responseListObject;
              } else {
                initaitedReclaimedList = null;
              }
            }
          } else if (
            JSON.parse(localStorage.getItem('PendingDomainList')) === null ||
            myRecomendedDataList === null ||
            myNeededDomainList === null ||
            initaitedReclaimedList === null ||
            failedProcessingList === null
          ) {
            //useb
            dispatch({
              type: 'No_Search_Result',
              payload: true,
            });
            //renderTable(true, false);
          } else {
            AllDomainArray =
              JSON.parse(localStorage.getItem('PendingDomainList')) === null
                ? []
                : JSON.parse(localStorage.getItem('PendingDomainList')) || [];
          }
          setTimeout(() => {
            setshowloader(false);
          }, 500);
        }

        var Pendingdomainlist = [];
        (AllDomainArray || []).map((datum, index) => {
          if (datum !== undefined) {
            var formateddate = Moment(datum.lastEmailDate).format('MM/DD/YYYY');
            var formatedFirstdate = Moment(datum.firstEmailDate).format(
              'MM/DD/YYYY'
            );
            var requestedDate = Moment(datum.statusDate).format('MM/DD/YYYY');
            var element = {};
            element.id = datum.domainName;
            element.domainLogo = datum.domainLogo;
            element.domainName = datum.domainName; // + RecFlag;
            element.lastEmailDate = //formateddate;
              new Date(formateddate).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });

            if (element.lastEmailDate === 'Invalid Date') {
              const date = formateddate;
              const [day, month, year] = date.split('/');
              element.lastEmailDate = `${month}/${day}/${year}`;
              element.lastEmailDate = new Date(
                element.lastEmailDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            element.dsrContactEmail = datum.dsrContactEmail;
            element.firstEmailDate = //formatedFirstdate;
              new Date(formatedFirstdate).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });

            if (element.firstEmailDate === 'Invalid Date') {
              const date = formatedFirstdate;
              const [day, month, year] = date.split('/');
              element.firstEmailDate = `${month}/${day}/${year}`;
              element.firstEmailDate = new Date(
                element.firstEmailDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            element.emailAddress = datum.emailId;
            element.name = datum.name;
            element.noOfEmails = datum.noOfEmails;
            element.dsrRecommendation = datum.dsrRecommendation;
            element.date = '-';
            element.domainLogo = datum.domainLogo;
            element.isLogo = true;
            element.isSwitch = false;
            element.domainUrl = datum.domainUrl;
            element.status =
              datum.dsrRecommendation === 1 && datum.status === 'pending'
                ? 'Recommended'
                : datum.dsrRecommendation === 0 && datum.status === 'pending'
                ? '-'
                : datum.status === 'needed'
                ? 'Needed'
                : datum.status === 'processing'
                ? 'Processing'
                : datum.status === 'failed'
                ? 'Failed'
                : datum.status === 'initiated'
                ? 'Submitted'
                : datum.status === 'reclaimed'
                ? 'Completed'
                : datum.status;

            element.statusDate = new Date(requestedDate).toLocaleDateString(
              'en-US',
              {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              }
            );

            if (element.statusDate === 'Invalid Date') {
              const date = requestedDate;
              const [day, month, year] = date.split('/');
              element.statusDate = `${month}/${day}/${year}`;
              element.statusDate = new Date(
                element.statusDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            Pendingdomainlist.push(element);
          }
        });
        dispatch({
          type: 'Pending_TILE_DATA',
          payload:
            Pendingdomainlist === undefined ? [] : Pendingdomainlist || [],
        });
      }, 500);
    } catch (e) {
      console.error('Unable to fetch domains:', e);
      if (e.status === 404) {
        // alert("not fund");
        setLoaded(true);
        //  setError({ ...((e.response || []).status || null) });
      }
    }
  }
  async function NextSearchResultDomain(Searchlastkey, domaintype, searchval) {
    setshowloader(true);
    try {
      //Get the all domains:
      const myAllDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: domaintype,
            emailId: (state.dashboardData || []).emailId,
            parsedEmailDataId: Searchlastkey || null,
            domainName: searchval,
          },
        })
        .catch((error) => {
          if (
            (error || []).data.lastKey !== null &&
            (error || []).data.lastKey !== undefined
          ) {
            NextSearchResultDomain(
              (error || []).data.lastKey,
              domaintype,
              searchval
            );
          } else {
            if (localStorage.getItem('PendingDomainList')) {
              const unique = [
                ...new Set(
                  JSON.parse(localStorage.getItem('PendingDomainList')) ||
                    [].map((item) => item.domainName)
                ),
              ];
              setSearchcardContent(
                JSON.parse(localStorage.getItem('PendingDomainList')) ===
                  undefined
                  ? []
                  : unique || []
              );
              dispatch({
                type: 'Domain_Search_DATA',
                payload:
                  JSON.parse(localStorage.getItem('PendingDomainList')) ===
                  undefined
                    ? []
                    : unique || [],
              });
            }
            setLoaded(true);
            setshowloader(false);
            localStorage.removeItem('PendingDomainList');
          }
        });

      if (myAllDataList !== undefined) {
        var PendingDomainArray = (
          JSON.parse(localStorage.getItem('PendingDomainList')) || []
        ).concat(myAllDataList.data.responseListObject);

        localStorage.setItem(
          'PendingDomainList',
          JSON.stringify(PendingDomainArray)
        );
        if (
          ((myAllDataList || []).data || []).lastKey !== null &&
          ((myAllDataList || []).data || []).lastKey !== undefined
        ) {
          NextSearchResultDomain(
            ((myAllDataList || []).data || []).lastKey,
            domaintype,
            searchval
          );
        } else {
          setSearchcardContent(
            JSON.parse(localStorage.getItem('PendingDomainList')) === undefined
              ? []
              : JSON.parse(localStorage.getItem('PendingDomainList')) || []
          );
          dispatch({
            type: 'Domain_Search_DATA',
            payload:
              JSON.parse(localStorage.getItem('PendingDomainList')) ===
              undefined
                ? []
                : JSON.parse(localStorage.getItem('PendingDomainList')) || [],
          });
          setLoaded(true);

          setshowloader(false);
          setState([]);
          localStorage.removeItem('PendingDomainList');
        }
      }
    } catch (e) {
      console.error('Unable to fetch domains:', e);

      if (e.status === 404) {
        setLoaded(true);
        //  setError({ ...((e.response || []).status || null) });
      }
    }
  }

  return (
    <Wrapper id="SearchWrapper">
      <div id="DomainSearch">
        <SearchBar
          placeholder="Search your domain"
          value={searchdomainval}
          onRequestSearch={(e) => SearchDomain(searchdomainval, e)}
          onChange={(newValue) => {
            debunceEvent(newValue);
          }}
          onCancelSearch={(e) => {
            setSearchdomain(false);
            setSearchErrMsg(false);
            dispatch({
              type: 'Reload_Domain_Table',
              payload: true,
            });
            renderTable(false, true);
            dispatch({
              type: 'Pending_TILE_DATA',
              payload: true, //dataList === undefined ? [] : dataList || [],
            });
            // setPendingSearchcardContent([]);
          }}
        />
      </div>
      {SearchErrMsg && !isNoDomain ? (
        <p className="SearchErr">Please enter atleast 2 characters</p>
      ) : (
        ''
      )}
      {/* <div id="noSearchResult">
        <Typography paragraph>No Domain Found.</Typography>
      </div> */}
    </Wrapper>
  );
};

export default memo(Search);
