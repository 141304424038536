// export const clientId = '72c30b54-7b3c-4bbd-9b0e-e04bc6908257';
export const redirectUri = `${window.location.origin}/oidc_callback`;
export const tenant = 'consumers';
export const scope = ['offline_access', 'user.read', 'mail.read', 'mail.send'];

// export const google_clientId =
//   '511392174555-79rgebqicn3l22kleeu3pp55d61rooqg.apps.googleusercontent.com';
export const google_redirectUri = `${window.location.origin}/gmail-auth`;
export const google_scope = [
  'https://www.googleapis.com/auth/gmail.readonly',
  'profile',
  'email',
  'https://www.googleapis.com/auth/gmail.send',
];
export const writescope = [
  'offline_access',
  'user.read',
  'mail.read',
  'mail.send',
];
export const google_write_scope = [
  'https://www.googleapis.com/auth/gmail.readonly',
  'profile',
  'email',
  'https://www.googleapis.com/auth/gmail.send',
];
export function getOAuthClientId(provider) {
  switch (provider) {
    case 'Microsoft':
      switch (window.location.hostname) {
        case 'localhost':
          return '72c30b54-7b3c-4bbd-9b0e-e04bc6908257';
        case 'dev.privacyfor.me':
          return '72c30b54-7b3c-4bbd-9b0e-e04bc6908257';
        case 'privacyfor.me':
          return '0b1f9423-994c-44f6-8fa3-d43f597b3ad9';
        default:
          console.warn(
            `Unknown host for Oauth client: ${window.location.hostname}`
          );
      }
      break;
    case 'Google':
      switch (window.location.hostname) {
        case 'localhost':
          return '443720604703-apjt469jde3ijp5tetpk78as32urilnd.apps.googleusercontent.com';
        case 'dev.privacyfor.me':
          return '443720604703-apjt469jde3ijp5tetpk78as32urilnd.apps.googleusercontent.com';
        case 'privacyfor.me':
          return '511392174555-si4tckb2eest4a8erh6gq6gnkh0p8d5r.apps.googleusercontent.com';
        default:
          console.warn(
            `Unknown host for Oauth client: ${window.location.hostname}`
          );
      }
      break;
    default:
      console.warn(`Unknown Provider: ${provider}`);
  }
}
