import { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import tw from 'twin.macro';
import styled from 'styled-components';
import { ReactComponent as SignUpIcon } from 'feather-icons/dist/icons/user-plus.svg';

import { Context } from 'store';
import Google_logo from 'images/google-cert.svg'; //google_logo.png';
import Microsoft_logo from 'images/microsoft-cert.svg'; //;microsoft_logo.png';

const ErrorMessage = tw.div`bg-red-300 text-red-800 text-sm border border-red-800 rounded my-2 p-4`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mb-2 mt-5 tracking-wide font-semibold bg-[#E25668] text-gray-100 w-full py-4 rounded-lg md:uppercase transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default function SingInForm({
  submitButtonText = 'Sign Up',
  SubmitButtonIcon = SignUpIcon,
  tosUrl = 'terms-of-service',
  privacyPolicyUrl = 'privacy-policy',
  signInUrl = '#',
  setLogin,
  setAccount,
}) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [state, dispatch] = useContext(Context); //eslint-disable-line no-unused-vars

  const error = errorMessage ? (
    <ErrorMessage>{errorMessage}</ErrorMessage>
  ) : null;

  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        // TODO: Disable form
        setErrorMessage(null);

        const formData = new FormData(e.target);
        const name = formData.get('name');
        const email = formData.get('email');
        const password = formData.get('password');

        try {
          await Auth.signUp({
            username: email,
            password,
            attributes: {
              name,
              email,
            },
          });
          dispatch({
            type: 'USER_SIGN_UP',
            payload: {
              username: email,
              name,
              email,
            },
          });

          history.push('/confirm-signup');
        } catch (ex) {
          // TODO: Enable form
          if (
            ['UsernameExistsException', 'InvalidPasswordException'].includes(
              ex.code
            )
          ) {
            return setErrorMessage(ex.message);
          }
          console.error('Unable to sign up: ', ex);
        }
      }}
    >
      <Input name="name" type="text" placeholder="Name" required />
      <Input name="email" type="email" placeholder="Email" required />
      <Input name="password" type="password" placeholder="Password" required />
      <SubmitButton type="submit">
        {/* <SubmitButtonIcon className="icon" /> */}
        <span className="text">{submitButtonText}</span>
      </SubmitButton>
      {error}

      <div id="linkAddress" class="pt-10" style={{ textAlign: 'center' }}>
        <p tw="text-xs text-gray-600 text-center">
          I agree to abide by Privacy For Me's{' '}
          <a href={tosUrl} tw="border-b border-gray-500 ">
            Terms of Service
          </a>{' '}
          and its{' '}
          <a href={privacyPolicyUrl} tw="border-b border-gray-500 ">
            Privacy Policy
          </a>
        </p>
      </div>
      <div className="CertifiedLogo">
        <div className="flex">
          <img
            className="GoogleLogo"
            alt="GoogleLogo"
            src={Google_logo}
            style={{ marginRight: '25px', width: '124px', height: '40px' }}
          />{' '}
          <img
            className="MicrosoftLogo"
            alt="MicrosoftLogo"
            src={Microsoft_logo}
            style={{ width: '97px', height: '40px' }}
          />
        </div>
      </div>
      <div className="pt-10 mb-20">
        <p
          style={{ marginLeft: '30px' }}
          tw="text-sm text-gray-600 text-center"
          className="textColor"
        >
          Already have an account?{' '}
          <a
            className="signInColor"
            href={signInUrl}
            tw="border-b border-gray-500 "
            onClick={(e) => {
              e.preventDefault();
              setLogin(true);
              setAccount(false);
              //history.push('/login');
            }}
          >
            Sign In
          </a>
        </p>
      </div>
    </Form>
  );
}
