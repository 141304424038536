import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { Context } from 'store';
import { borderRadius } from '@mui/system';

const CommonDataGrid = ({
  Tile,
  gridRef,
  columnData,
  tableData,
  onDomainClick,
  onSelectionCheckbox,
  onGridReady,
  cacheBlockSize,
  setDataFetchFlag,
  setDataFetchAllFlag,
  deselectAllFlag,
  inTableMsg,
  onDateSorting,
  selectionModel,
}) => {
  const [state, dispatch] = useContext(Context);

  function selectAll(event) {
    setDataFetchAllFlag(true);
    gridRef.current.api.forEachNode(function (node) {
      console.log('Selection start');
      node.setSelected(true);
    });
    console.log('Selection end');
    setDataFetchFlag(false);
  }
  function deselectAll() {
    if (gridRef.current !== null) {
      gridRef.current.api.deselectAll();
      setDataFetchAllFlag(true);
      gridRef.current.api.forEachNode(function (node) {
        node.setSelected(false);
      });
      setDataFetchFlag(false);
    }
    deselectAllFlag([]);
    dispatch({
      type: 'AG_DATA_LIST',
      payload: [],
    });
    dispatch({
      type: 'Selected_Row_Count',
      payload: 0,
    });
  }
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: false,
      sortable: true,
      lockPosition: true,
    };
  }, []);
  const getRowId = useCallback(function (params) {
    return params.data.domainName.toString();
  }, []);

  return (
    <>
      {Tile === 'pending' ? (
        <>
          <button
            onClick={(event) => {
              selectAll();
            }}
          >
            Select All
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button
            id="deselectAll"
            className="deselectAll"
            onClick={() => {
              deselectAll();
              setTimeout(() => {
                deselectAll();
              }, 100);
            }}
          >
            Deselect All
          </button>
        </>
      ) : (
        ''
      )}
      <div
        className="ag-theme-alpine"
        style={{ height: 500, backgroundColor: '#F0F6FF', borderRadius: '4px' }}
      >
        <AgGridReact
          ref={gridRef}
          getRowId={getRowId}
          suppressRowHoverHighlight={false}
          columnHoverHighlight={false}
          //rowData={tableData}
          columnDefs={columnData}
          defaultColDef={defaultColDef}
          rowSelection={'multiple'}
          rowModelType={'infinite'}
          rowHeight={48}
          rowStyle={{
            borderRadius: '4px',
            borderBottom: '#F0F6FF 3px solid',
            borderTop: '#F0F6FF 5px solid',
          }}
          onGridReady={onGridReady}
          cacheBlockSize={cacheBlockSize}
          onSelectionChanged={onSelectionCheckbox}
          onCellClicked={onDomainClick}
          suppressRowClickSelection={true}
        ></AgGridReact>
      </div>
    </>
  );
};

export default CommonDataGrid;
