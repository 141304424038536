export const sites = [
  {
    id: '01',
    name: 'Wikipedia',
    domain: 'en.wikipedia.org',
  },
  {
    id: '02',
    name: 'Youtube',
    domain: 'youtube.com',
  },
  { id: '03', name: 'Amazon', domain: 'amazon.com' },
  {
    id: '04',
    name: 'Facebook',
    domain: 'facebook.com',
  },
  {
    id: '05',
    name: 'Twitter',
    domain: 'twitter.com',
  },
  { id: '06', name: 'Fandom', domain: 'fandom.com' },
  {
    id: '07',
    name: 'Pinterest',
    domain: 'pinterest.com',
  },
  { id: '08', name: 'IMDB', domain: 'imdb.com' },
  { id: '09', name: 'Reddit', domain: 'reddit.com' },
  {
    id: '10',
    name: 'Yelp',
    domain: 'yelp.com',
  },
  {
    id: '11',
    name: 'Instagram',
    domain: 'instagram.com',
  },
  {
    id: '12',
    name: 'Ebay',
    domain: 'ebay.com',
  },
  {
    id: '13',
    name: 'Walmart',
    domain: 'walmart.com',
  },
  { id: '14', name: 'Craigslist', domain: 'craigslist.org' },
  { id: '15', name: 'Healthline', domain: 'healthline.com' },
  { id: '16', name: 'Trip Advisor', domain: 'tripadvisor.com' },
  { id: '17', name: 'LinkedIn', domain: 'linkedin.com' },
  { id: '18', name: 'Web MD', domain: 'webmd.com' },
  { id: '19', name: 'Netflix', domain: 'netflix.com' },
  { id: '20', name: 'Apple', domain: 'apple.com' },
  { id: '21', name: 'Home Depot', domain: 'homedepot.com' },
  { id: '22', name: 'Yahoo! Mail', domain: 'mail.yahoo.com' },
  { id: '23', name: 'CNN', domain: 'cnn.com' },
  { id: '24', name: 'Etsy', domain: 'etsy.com' },
  { id: '25', name: 'Google', domain: 'google.com' },
  { id: '26', name: 'Yahoo', domain: 'yahoo.com' },
  { id: '27', name: 'Indeed', domain: 'indeed.com' },
  { id: '28', name: 'Target', domain: 'target.com' },
  { id: '29', name: 'Microsoft', domain: 'microsoft.com' },
  { id: '30', name: 'NY Times', domain: 'nytimes.com' },
  { id: '31', name: 'Mayo Clinic', domain: 'mayoclinic.org' },
  { id: '32', name: 'ESPN', domain: 'espn.com' },
  { id: '33', name: 'USPS', domain: 'usps.com' },
  { id: '34', name: 'Quizlet', domain: 'quizlet.com' },
  { id: '35', name: 'Gamepedia', domain: 'gamepedia.com' },
  { id: '36', name: 'Lowes', domain: 'lowes.com' },
  { id: '37', name: 'IRS', domain: 'irs.gov' },
  { id: '38', name: 'NIH', domain: 'nih.gov' },
  { id: '39', name: 'Merriam Webster', domain: 'merriam-webster.com' },
  { id: '40', name: 'Steam', domain: 'steampowered.com' },
  { id: '41', name: 'MapQuest', domain: 'mapquest.com' },
  { id: '42', name: 'Fox News', domain: 'foxnews.com' },
  { id: '43', name: 'All Recipes', domain: 'allrecipes.com' },
  { id: '44', name: 'Quora', domain: 'quora.com' },
  { id: '45', name: 'Aol.', domain: 'aol.com' },
  { id: '46', name: 'Britannica', domain: 'britannica.com' },
  { id: '47', name: 'Outlook', domain: 'live.com' },
  { id: '48', name: 'Best Buy', domain: 'bestbuy.com' },
  { id: '49', name: 'Rotten Tomatoes', domain: 'rottentomatoes.com' },
  { id: '50', name: 'State of California', domain: 'ca.gov' },
  { id: '51', name: 'Google Play', domain: 'play.google.com' },
  { id: '52', name: 'Cnet', domain: 'cnet.com' },
  { id: '53', name: 'Roblox', domain: 'roblox.com' },
  { id: '54', name: 'US News', domain: 'usnews.com' },
  { id: '55', name: 'Zillow', domain: 'zillow.com' },
  { id: '56', name: 'Business Insider', domain: 'businessinsider.com' },
  { id: '57', name: 'Bulbagarden', domain: 'bulbagarden.net' },
  { id: '58', name: 'PayPal', domain: 'paypal.com' },
  { id: '59', name: 'Yahoo! Finance', domain: 'finance.yahoo.com' },
  { id: '60', name: 'Genius', domain: 'genius.com' },
  { id: '61', name: 'USA Today', domain: 'usatoday.com' },
  { id: '62', name: 'Realtor', domain: 'realtor.com' },
  { id: '63', name: 'Medical News Today', domain: 'medicalnewstoday.com' },
  { id: '64', name: 'FedEx', domain: 'fedex.com' },
  { id: '65', name: 'Bank of America', domain: 'bankofamerica.com' },
  { id: '66', name: 'Washington Post', domain: 'washingtonpost.com' },
  { id: '67', name: 'Investopedia', domain: 'investopedia.com' },
  { id: '68', name: 'Speed Test', domain: 'speedtest.net' },
  { id: '69', name: 'Spotify', domain: 'spotify.com' },
  { id: '70', name: 'CDC', domain: 'cdc.gov' },
  { id: '71', name: 'Chase', domain: 'chase.com' },
  { id: '72', name: 'Hulu', domain: 'hulu.com' },
  { id: '73', name: 'Xfinity', domain: 'xfinity.com' },
  { id: '74', name: 'MSN', domain: 'msn.com' },
  { id: '75', name: 'Dictionary', domain: 'dictionary.com' },
  { id: '76', name: 'Weather', domain: 'weather.com' },
  { id: '77', name: 'UPS', domain: 'ups.com' },
  { id: '78', name: 'Verizon', domain: 'verizon.com' },
  { id: '79', name: 'Forbes', domain: 'forbes.com' },
  { id: '80', name: 'Wowhead', domain: 'wowhead.com' },
  { id: '81', name: 'Expedia', domain: 'expedia.com' },
  { id: '82', name: 'Urban Dictionary', domain: 'urbandictionary.com' },
  { id: '83', name: 'Food Network', domain: 'foodnetwork.com' },
  { id: '84', name: 'NBC News', domain: 'nbcnews.com' },
  { id: '85', name: 'Macys', domain: 'macys.com' },
  { id: '86', name: 'Apartments', domain: 'apartments.com' },
  { id: '87', name: 'IGN', domain: 'ign.com' },
  { id: '88', name: 'Capital One', domain: 'capitalone.com' },
  { id: '89', name: 'Costco', domain: 'costco.com' },
  { id: '90', name: 'The Guardian', domain: 'theguardian.com' },
  { id: '91', name: 'CNBC', domain: 'cnbc.com' },
  { id: '92', name: 'Glassdoor', domain: 'glassdoor.com' },
  { id: '93', name: 'Yellow Pages', domain: 'yellowpages.com' },
  { id: '94', name: 'AT&T', domain: 'att.com' },
  { id: '95', name: 'BBC', domain: 'bbc.com' },
  { id: '96', name: 'Khan Academy', domain: 'khanacademy.org' },
  { id: '97', name: 'New York', domain: 'ny.gov' },
  { id: '98', name: 'Twitch', domain: 'twitch.tv' },
  { id: '99', name: 'Adobe', domain: 'adobe.com' },
  { id: '100', name: 'CBS Sports', domain: 'cbssports.com' },
];
