import '../../components/intro/IntroPagination.css';

export default function IntroPagination({ activeDot }) {
  return (
    <>
      <div className="customDot">
        <div className={'dot ' + (activeDot === 0 ? 'active' : '')}></div>
        <div className={'dot ' + (activeDot === 1 ? 'active' : '')}></div>
        <div className={'dot ' + (activeDot === 2 ? 'active' : '')}></div>
        <div className={'dot ' + (activeDot === 3 ? 'active' : '')}></div>
        <div className={'dot ' + (activeDot === 4 ? 'active' : '')}></div>
      </div>
    </>
  );
}
