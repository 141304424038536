import { Grid } from '@material-ui/core';
import { useContext, useState, useEffect } from 'react';
import ProviderConnectionRow from './ProviderConnectionRow';
import { Context } from 'store';
import { axiosServiceInstance } from 'config/axiosConfig';

export default function ProviderConnection(props) {
  const [state, dispatch] = useContext(Context);
  // TODO: Displays errors
  const [errors, setError] = useState(null); //eslint-disable-line no-unused-vars
  const [getMicrosoft, setMicrosoft] = useState({});
  // TODO: What is the pupose of these states?
  const [getGoogle, setGoogle] = useState({}); //eslint-disable-line no-unused-vars
  const [getYahoo, setYahoo] = useState({}); //eslint-disable-line no-unused-vars
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // if (!loaded && state.user.username && state.allProvider === undefined) {
    _getMyDsrs();
    // }
    props.func(state.allProvider);
  });

  async function _getMyDsrs() {
    if ((state.dashboardData || []).dropDownData !== undefined) {
      var allProvider = (state.dashboardData || []).dropDownData || [];
      _setProvider(allProvider);
    }
  }

  function _setProvider(allProvider) {
    const Google = (allProvider || []).filter(
      (account) => account.emailType === 'Google'
    );
    const Microsoft = (allProvider || []).filter(
      (account) => account.emailType === 'Microsoft'
    );
    const Yahoo = (allProvider || []).filter(
      (account) => account.emailType === 'Yahoo'
    );
    setGoogle(Google);
    setMicrosoft(Microsoft);
    setYahoo(Yahoo);

    dispatch({
      type: 'ALL_PROVIDER',
      payload: allProvider === undefined ? [] : allProvider,
    });
    dispatch({
      type: 'GOOGLE',
      payload: Google === undefined ? [] : Google,
    });
    dispatch({
      type: 'MICROSOFT',
      payload: Microsoft === undefined ? [] : Microsoft,
    });
    dispatch({
      type: 'YAHOO',
      payload: Yahoo === undefined ? [] : Yahoo,
    });
  }

  return (
    <Grid container spacing={3} id="EmailConnectionContainer">
      <ProviderConnectionRow
        name="Yahoo! Mail"
        provider="Yahoo"
        domain="yahoo.com"
      />
      <ProviderConnectionRow
        name="Microsoft"
        provider="Microsoft"
        domain="live.com"
        emailAddress={getMicrosoft || []}
      />
      <ProviderConnectionRow
        name="Google"
        provider="Google"
        domain="gmail.com"
      />
    </Grid>
  );
}
