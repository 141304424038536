import { useContext } from 'react';
import { Widget } from '@typeform/embed-react';
//import { ReactTypeformEmbed } from "react-typeform-embed";
import '@typeform/embed/build/css/popup.css';
import { Context } from 'store';
import { useEffect } from 'react';
var CryptoJS = require('crypto-js');
const ReferFriend = () => {
  const [state, dispatch] = useContext(Context);
  var queryparam =
    'utm_source=pfm&utm_medium=website&utm_campaign=refer-a-friend&referrer_email=' +
    state.user.email;
  var encryptedquerryparam = CryptoJS.AES.encrypt(
    queryparam,
    'secret-key@123$!'
  ).toString();
  var emailurl = 'https://privacyfor.me?' + encryptedquerryparam;
  useEffect(() => {
    dispatch({
      type: 'Selected_Row_Count',
      payload: 0,
    });
  }, []);
  return (
    <Widget
      id="jhCg2B7v"
      height={510}
      opacity={80}
      hidden={{
        utm_source: 'pfm',
        utm_medium: 'website',
        utm_content: 'content1',
        referrer_email: state.user.email,
        referrer_name: state.user.name,
        referrer_url: emailurl,
        utm_campaign: 'refer-a-friend',
      }}
      /*
onSubmit={(event) => {
}}
*/
    />
  );
};

export default ReferFriend;
