import { Divider, Typography } from '@material-ui/core';

export default function SettingsWrapper({ children, header }) {
  return (
    <div style={{ marginTop: '2rem', marginBottom: '1rem', maxWidth: '670px' }}>
      <Typography variant="h5" gutterBottom className="TabPanelHeading">
        {header}
      </Typography>
      {/* <Divider /> */}
      <div style={{ padding: '.5rem 0px' }}>{children}</div>
    </div>
  );
}
