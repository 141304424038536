import Container from '@material-ui/core/Container';
import SelectableEmailProvider from './SelectableEmailProvider';

const providers = [
  { name: 'Yahoo!', domain: 'yahoo.com', provider: 'yahoo' },
  { name: 'Outlook', domain: 'outlook.live.com', provider: 'outlook' },
  { name: 'Gmail', domain: 'mail.google.com', provider: 'gmail' },
];

export function SelectEmailProvider() {
  // TODO: MUI component or stylized

  const content = providers.map((provider) => (
    <SelectableEmailProvider key={provider.domain} provider={provider} />
  ));

  return (
    <Container
      maxWidth="md"
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        margin: '0 auto',
      }}
    >
      {content}
    </Container>
  );
}

export default SelectEmailProvider;
