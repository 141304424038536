import { Link } from 'react-router-dom';
import tw from 'twin.macro';

const UserLink = tw.span`text-primary-500`;
const MissingConnectionWrapper = tw.div``;

export default function MissingConnection() {
  return (
    <MissingConnectionWrapper>
      Currently Privacy For Me isn't aware of any companies with your data.{' '}
      <Link to="/account">
        <UserLink>Connect an email provider</UserLink>
      </Link>{' '}
      to get started today!
    </MissingConnectionWrapper>
  );
}
