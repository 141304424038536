import { useContext, useEffect, useState } from 'react';
import React, { useCallback, useMemo, useRef } from 'react';
import { Context } from 'store';
import { axiosServiceInstance } from 'config/axiosConfig';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  Box,
  Typography,
} from '@material-ui/core';
import Moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridTable from '@nadavshaar/react-grid-table';
import getColumns from './getColumns.js';
import RequestDeletionButton from './RequestDeletionButton.js';
import RequestDeletion from 'views/RequestDeletion.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';
import MyDataDetails from 'components/data/MyDataDetails.js';
import { makeStyles } from '@material-ui/core/styles';
import Search from 'components/dashboard/Search';
import RequestDeletionMultiple from 'views/RequestDeletionMultiple.js';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function DomainTable() {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState();
  const [selectdRow, setselectdRow] = useState();
  const [nosearchresult, setnosearchresult] = useState(false);

  const [state, dispatch] = useContext(Context);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [getState, setState] = useState({});
  const [getAllDomainLastKey, setAllDomainLastKey] = useState('');
  const [getRecState, setRecState] = useState([]);

  const classes = useStyles();
  const history = useHistory();
  const [selectedDatum, setSelectedDatum] = useState(null);
  const [markNeededDataList, setMarkNeededDataList] = useState([]);
  const [markDSRDataList, setMarkDSRDataList] = useState([]);

  const [checkedN, setCheckedN] = useState(true);
  const [checkedR, setCheckedR] = useState(false);

  let CheckedN = true;
  let CheckedR = false;

  const [initialList, setInitialList] = useState([]);
  const [recommendedCList, setRecommendedCList] = useState([]);
  const [allNDomain, setAllNDomain] = useState([]);
  const [enableCheckbox, setEnableCheckbox] = useState(false);
  const [recList, setRecList] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [searchDataList, setSearchDataList] = useState([]);
  const [countOf, setCountOf] = useState([]);
  const [tableMsg, setTableMsg] = useState(true);
  const [timeFilter, setTimeFilter] = useState('');
  const [tableLoader, setTableLoader] = useState(true);
  const [dsrPopup, setDsrPopup] = useState(false);
  const [dsrSinglePopup, setDsrSinglePopup] = useState(false);

  const RecDomainArr = [];
  const RecNDomainArr = [];
  let Pendingdomainlist = [];
  let MarkNeededDomainList = [];
  let RecommendedList = [];
  let NeededDomainList = [];
  let AllRecPenNeedList = [];
  let DSRdataList = [];
  let TimeFilterCount;

  useEffect(() => {
    if (!isLoading && state.user.username) {
      dispatch({
        type: 'Pending_TILE_DATA',
        payload: [],
      });
      setTableLoader(true);
      setLoading(true);
      _getMyData();
    }
    if (state.reloadDomainTable === true) {
      setTableLoader(true);
      setTableMsg(true);
      setnosearchresult(false);
      _getMyData(true);
      dispatch({
        type: 'Reload_Domain_Table',
        payload: false,
      });
    }
    if ((state.accRefresh || state.accSwitch) === true) {
      setCheckedN(true);
      setCheckedR(false);
      setTimeFilter('');
      dispatch({
        type: 'ACC_Refresh',
        payload: false,
      });
      dispatch({
        type: 'ACC_Switch',
        payload: false,
      });
    }
  }, [state]);

  function renderTable(nosearch = false, reload = false) {
    // if (reload) {
    //   setnosearchresult(false);
    //   _getMyData(true);
    //   dispatch({
    //     type: 'Reload_Domain_Table',
    //     payload: false,
    //   });
    // }
    // if (nosearch) {
    // setnosearchresult(true);
    // dispatch({
    //   type: 'No_Search_Result',
    //   payload: false,
    // });
    //}
  }
  function onTimeFilterChange(event) {
    var eValue = event.target.value;
    setTimeFilter(eValue);
    dispatch({
      type: 'Pending_TILE_DATA',
      payload: [],
    });
    dispatch({
      type: 'Reload_Domain_Table',
      payload: true,
    });
  } //pendingDomain//recommendedDsrCount
  TimeFilterCount =
    timeFilter === '1'
      ? countOf.oneMonthAgoCount === undefined
        ? ''
        : countOf.oneMonthAgoCount
      : timeFilter === '6'
      ? countOf.sixMonthsAgoCount === undefined
        ? ''
        : countOf.sixMonthsAgoCount
      : timeFilter === '12'
      ? countOf.oneYearAgoCount === undefined
        ? ''
        : countOf.oneYearAgoCount
      : '';

  async function _getMyData(reloadFlag = false) {
    // setallgetdata(false);
    try {
      const dropDownData = await axiosServiceInstance
        .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
        })
        .catch((error) => {
          setLoading(true);
          setState([]);
        });
      let dropdownEmailId = localStorage.getItem('updatedEmailId');

      //Get DOMAIN API For getting the recommended and pending domain cnt:
      const allDomain =
        dropDownData === undefined
          ? []
          : await axiosServiceInstance
              .post(`/pieeye-pfmdomain/api/dashboard/_getAllDomain`, {
                requestInfo: {
                  userName: state.user.username || '',
                  token: state.user.idToken || '',
                },
                requestBody: {
                  emailId:
                    dropdownEmailId === null || dropdownEmailId === 0
                      ? dropDownData.data.responseListObject[0].emailId
                      : dropdownEmailId,
                },
              })
              .catch((error) => {
                setLoading(true);
                // setCountFlag(true);
                // console.log('in error');
              });

      let NeededDomain =
        allDomain !== undefined && allDomain.length > 0
          ? ((allDomain || []).data || []).responseListObject[0].neededDomain
          : 0;
      localStorage.setItem('NeededDomainCount', NeededDomain);
      setCountOf(((allDomain || []).data || []).responseListObject[0]);
      dispatch({
        type: 'DASHBOARD_DATA',
        payload: {
          myData:
            (allDomain || []).data === undefined
              ? []
              : allDomain.data.responseListObject[0],
          dropDownData:
            dropDownData === undefined
              ? []
              : dropDownData.data.responseListObject,
          emailId:
            dropdownEmailId === null ||
            dropdownEmailId === 0 ||
            dropdownEmailId === undefined
              ? dropDownData.data.responseListObject[0].emailId
              : dropdownEmailId,
        },
      });

      setInitialList([]);
      ///Get Domain api ends here
      const myDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            // (timeFilter || '') !== '' ? 'pending,recommended' : 'pending',
            status: 'pending',
            emailId:
              dropdownEmailId === null || dropdownEmailId === 0
                ? dropDownData.data.responseListObject[0].emailId
                : dropdownEmailId,
            monthFlag: timeFilter || '',
          },
        })
        .catch((error) => {
          setLoading(true);
          setState([]);
        });

      if (
        ((myDataList || []).data || []).lastKey !== null &&
        ((myDataList || []).data || []).lastKey !== undefined
      ) {
        // Store the last key in the redux stte / localstorage
        dispatch({
          type: 'AllDomain_Last_Key',
          payload:
            ((myDataList || []).data || []).lastKey === null
              ? ''
              : ((myDataList || []).data || []).lastKey,
        });
        setAllDomainLastKey(
          ((myDataList || []).data || []).lastKey === null
            ? ''
            : ((myDataList || []).data || []).lastKey
        );
      }

      dispatch({
        type: 'MY_DATA',
        payload:
          myDataList === undefined
            ? []
            : ((myDataList || []).data || []).responseListObject || [],
      });

      setState(
        myDataList === undefined
          ? []
          : ((myDataList || []).data || []).responseListObject || []
      );
      //  if ((timeFilter || '') === '') {
      var myRecomendedDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'recommended',
            emailId:
              dropdownEmailId === null || dropdownEmailId === 0
                ? dropDownData.data.responseListObject[0].emailId
                : dropdownEmailId,
          },
        })
        .catch((error) => {
          setRecState([]);
          // console.log('in error');
        });
      //  }

      if (
        ((myRecomendedDataList || []).data || []).lastKey !== null &&
        ((myRecomendedDataList || []).data || []).lastKey !== undefined
      ) {
        RecDomainArr = RecDomainArr.concat(
          myRecomendedDataList.data.responseListObject
        );
        dispatch({
          type: 'Last_Key',
          payload:
            ((myRecomendedDataList || []).data || []).lastKey === null
              ? ''
              : ((myRecomendedDataList || []).data || []).lastKey,
        });
      }
      dispatch({
        type: 'MY_Recommended_DATA',
        payload:
          myRecomendedDataList === undefined
            ? []
            : ((myRecomendedDataList || []).data || []).responseListObject ||
              [],
      });

      if (
        ((myDataList || []).data || []).responseListObject !== undefined &&
        ((myDataList || []).data || []).responseListObject.length >= 1
      ) {
        var PendingAndRecDomains = (
          ((myRecomendedDataList || []).data || []).responseListObject || []
        ).concat(((myDataList || []).data || []).responseListObject);
        PendingAndRecDomains.map((datum, index) => {
          if (datum !== undefined) {
            var formateddate = Moment(datum.lastEmailDate).format(
              'MM/DD/YYYY' //'DD/MM/YYYY'
            );
            var formatedFirstdate = Moment(datum.firstEmailDate).format(
              'MM/DD/YYYY'
            );
            var element = {};

            element.id = datum.domainName;
            element.domainLogo = datum.domainLogo;
            element.domainName = datum.domainName;
            element.lastEmailDate = //formateddate;
              new Date(formateddate).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });

            if (element.lastEmailDate === 'Invalid Date') {
              const date = formateddate;
              const [day, month, year] = date.split('/');
              element.lastEmailDate = `${month}/${day}/${year}`;

              element.lastEmailDate = new Date(
                element.lastEmailDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            element.dsrContactEmail = datum.dsrContactEmail;
            element.firstEmailDate = //formatedFirstdate;
              new Date(formatedFirstdate).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });

            if (element.firstEmailDate === 'Invalid Date') {
              const date = formatedFirstdate;
              const [day, month, year] = date.split('/');
              element.firstEmailDate = `${month}/${day}/${year}`;
              element.firstEmailDate = new Date(
                element.firstEmailDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            element.emailAddress = datum.emailId;
            element.name = datum.name;
            element.noOfEmails = datum.noOfEmails;
            element.dsrRecommendation = datum.dsrRecommendation;
            element.statusDate = '-';

            element.isLogo = true;
            element.domainUrl = datum.domainUrl;
            element.status =
              datum.dsrRecommendation === 1 && datum.status === 'pending'
                ? 'Recommended'
                : datum.dsrRecommendation === 0 && datum.status === 'pending'
                ? '-'
                : datum.status === 'needed'
                ? 'Needed'
                : datum.status;
            Pendingdomainlist.push(element);
          }
        });
      }
      setInitialList(Pendingdomainlist);
      setSearchData('PendingRecommended');
      //setSearchDataList(Pendingdomainlist);

      dispatch({
        type: 'Pending_TILE_DATA',
        payload: Pendingdomainlist === undefined ? [] : Pendingdomainlist || [],
      });
      setRowsData(Pendingdomainlist || []);
      setTableMsg(false);
      setTableLoader(false);
      setLoading(true);
    } catch (e) {
      console.error('Unable to fetch domains:', e);
      setTableMsg(false);
      setTableLoader(false);
      if ((e.response || []).status || '' === 404) {
        setLoading(true);
      }
      setLoading(true);
    }
    if (reloadFlag === true) {
      onTableReload(checkedN, checkedR);
    }
  }

  const elementScrollData = (value) => {
    //console.log('elementScrollData ', value);
  };
  const handleChange = (rowIndex, column, isEdit, event) => {
    setselectdRow(rowIndex.selectedRows);
    dispatch({
      type: 'Selected_Row_Count',
      payload: rowIndex.length,
    });
    console.log(selectdRow);
  };
  function onScroll() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
    }
  }

  async function onCheckboxEvent(status, e) {
    if (e.target.checked === true && status === 'recommended') {
      setCheckedR(true);
      CheckedR = true;
      setEnableCheckbox(true);
      RecommendedList = state.pendingtiledomainlist.filter(function (e) {
        return e.status === 'Recommended';
      });
      setRecommendedCList(RecommendedList);
      dispatch({
        type: 'Pending_TILE_DATA',
        payload:
          RecommendedList === undefined
            ? recommendedCList
            : RecommendedList || [],
      });
      setSearchData('Recommended');
      //setSearchDataList(RecommendedList);
    } else {
      setCheckedR(false);
      setEnableCheckbox(false);
      //Hide Needed uncheck
      if (e.target.checked === false && status === 'needed') {
        setCheckedN(false);
        CheckedN = false;
        //Get the Needed domains:----------------------------------------
        let dropdownEmailId = localStorage.getItem('updatedEmailId');

        let myNeededDomainList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'needed',
              emailId:
                dropdownEmailId === null || dropdownEmailId === 0
                  ? (state.dashboardData || []).emailId
                  : dropdownEmailId,
            },
          })
          .catch((error) => {
            setLoading(true);
            setRecState([]);
            // console.log('in error');
          });
        if (
          ((myNeededDomainList || []).data || []).lastKey !== null &&
          ((myNeededDomainList || []).data || []).lastKey !== undefined
        ) {
          // RecNDomainArr =
          //   myNeededDomainList.data.responseListObject.concat(RecNDomainArr);
          dispatch({
            type: 'Last_Key',
            payload:
              ((myNeededDomainList || []).data || []).lastKey === null
                ? ''
                : ((myNeededDomainList || []).data || []).lastKey,
          });
        }

        (((myNeededDomainList || []).data || []).responseListObject || []).map(
          (datum, index) => {
            if (datum !== undefined) {
              var formateddate = Moment(datum.lastEmailDate).format(
                'MM/DD/YYYY' //'DD/MM/YYYY'
              );
              var formatedFirstdate = Moment(datum.firstEmailDate).format(
                'MM/DD/YYYY' //'DD/MM/YYYY'
              );
              var element = {};

              element.id = datum.domainName;
              element.domainLogo = datum.domainLogo;
              element.domainName = datum.domainName;

              element.lastEmailDate = //formateddate;
                new Date(formateddate).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                });

              if (element.lastEmailDate === 'Invalid Date') {
                const date = formateddate;

                const [day, month, year] = date.split('/');
                element.lastEmailDate = `${month}/${day}/${year}`;

                element.lastEmailDate = new Date(
                  element.lastEmailDate
                ).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                });
              }

              element.dsrContactEmail = datum.dsrContactEmail;
              element.firstEmailDate = //formatedFirstdate;
                new Date(formatedFirstdate).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                });

              if (element.firstEmailDate === 'Invalid Date') {
                const date = formatedFirstdate;
                const [day, month, year] = date.split('/');
                element.firstEmailDate = `${month}/${day}/${year}`;
                element.firstEmailDate = new Date(
                  element.firstEmailDate
                ).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                });
              }

              element.emailAddress = datum.emailId;
              element.name = datum.name;
              element.noOfEmails = datum.noOfEmails;
              element.dsrRecommendation = datum.dsrRecommendation;
              element.statusDate = '-';
              element.isLogo = true;

              element.domainUrl = datum.domainUrl;
              element.status =
                datum.dsrRecommendation === 1 && datum.status === 'pending'
                  ? 'Recommended'
                  : datum.dsrRecommendation === 0 && datum.status === 'pending'
                  ? '-'
                  : datum.status === 'needed'
                  ? 'Needed'
                  : datum.status;

              NeededDomainList.push(element);
            }
          }
        );

        let RList = initialList.filter(function (e) {
          return e.status === 'Recommended';
        });
        let NList = NeededDomainList.filter(function (e) {
          return e.status === 'Needed';
        });
        let PList = initialList.filter(function (e) {
          return e.status === '-';
        });
        AllRecPenNeedList = RList.concat(NList);
        AllRecPenNeedList = AllRecPenNeedList.concat(PList);

        // AllRecPenNeedList = (
        //   myNeededDomainList === undefined ? [] : NeededDomainList || []
        // ).concat(initialList);

        setAllNDomain(AllRecPenNeedList);
        setRowsData(AllRecPenNeedList);

        dispatch({
          type: 'Pending_TILE_DATA',
          payload:
            AllRecPenNeedList === undefined
              ? allNDomain
              : AllRecPenNeedList || [],
        });
        //For Search data scope
        setSearchData('PendingRecommendedNeeded');
        // setSearchDataList(AllRecPenNeedList);
        //--------------------------------------------------------------------------------------------------------
      } else {
        //checking for both checkbox uncheck on rec
        if (checkedN === false) {
          dispatch({
            type: 'Pending_TILE_DATA',
            payload:
              allNDomain === undefined ? AllRecPenNeedList : allNDomain || [],
          });
          setSearchData('PendingRecommendedNeeded');
          //setSearchDataList(AllRecPenNeedList);
        } else {
          dispatch({
            type: 'Pending_TILE_DATA',
            payload: initialList === undefined ? [] : initialList || [],
          });
          setSearchData('PendingRecommended');
          //setSearchDataList(initialList);
        }
      }
      //Hide Needed checked
      if (e.target.checked === true && status === 'needed') {
        CheckedN = true;
        setCheckedN(true);
        dispatch({
          type: 'Pending_TILE_DATA',
          payload: initialList === undefined ? [] : initialList || [],
        });
        setSearchData('PendingRecommended');
        //setSearchDataList(initialList);
      }
    }
    // dispatch({
    //   type: 'Reload_Domain_Table',
    //   payload: true,
    // });
    // console.log("onCheck---->", status, e);
  }

  async function onTableReload(checkedN, checkedR) {
    if (checkedR === true) {
      setCheckedR(true);
      CheckedR = true;
      setEnableCheckbox(true);
      RecommendedList = initialList.filter(function (e) {
        return e.status === 'Recommended';
      });
      setRecommendedCList(RecommendedList);
      dispatch({
        type: 'Pending_TILE_DATA',
        payload:
          RecommendedList === undefined
            ? recommendedCList
            : RecommendedList || [],
      });
      setSearchData('Recommended');
      //setSearchDataList(RecommendedList);
    } else {
      setCheckedR(false);
      setEnableCheckbox(false);
      //Hide Needed uncheck
      if (checkedN === false) {
        setCheckedN(false);
        CheckedN = false;

        //Get the Needed domains:----------------------------------------
        let dropdownEmailId = localStorage.getItem('updatedEmailId');

        let myNeededDomainList = await axiosServiceInstance
          .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
            requestInfo: {
              userName: state.user.username || '',
              token: state.user.idToken || '',
            },
            requestBody: {
              status: 'needed,pending,recommended',
              emailId:
                dropdownEmailId === null || dropdownEmailId === 0
                  ? (state.dashboardData || []).emailId
                  : dropdownEmailId,
              monthFlag: timeFilter || '',
            },
          })
          .catch((error) => {
            setLoading(true);
            setRecState([]);
            // console.log('in error');
          });

        if (
          ((myNeededDomainList || []).data || []).lastKey !== null &&
          ((myNeededDomainList || []).data || []).lastKey !== undefined
        ) {
          // RecNDomainArr =
          //   myNeededDomainList.data.responseListObject.concat(RecNDomainArr);
          dispatch({
            type: 'Last_Key',
            payload:
              ((myNeededDomainList || []).data || []).lastKey === null
                ? ''
                : ((myNeededDomainList || []).data || []).lastKey,
          });
        }

        (((myNeededDomainList || []).data || []).responseListObject || []).map(
          (datum, index) => {
            if (datum !== undefined) {
              var formateddate = Moment(datum.lastEmailDate).format(
                'MM/DD/YYYY' //'DD/MM/YYYY'
              );
              var formatedFirstdate = Moment(datum.firstEmailDate).format(
                'MM/DD/YYYY' //'DD/MM/YYYY'
              );
              var element = {};

              element.id = datum.domainName;
              element.domainLogo = datum.domainLogo;
              element.domainName = datum.domainName;

              element.lastEmailDate = // formateddate;
                new Date(formateddate).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                });

              if (element.lastEmailDate === 'Invalid Date') {
                const date = formateddate;

                const [day, month, year] = date.split('/');
                element.lastEmailDate = `${month}/${day}/${year}`;

                element.lastEmailDate = new Date(
                  element.lastEmailDate
                ).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                });
              }

              element.dsrContactEmail = datum.dsrContactEmail;
              element.firstEmailDate = //formatedFirstdate;
                new Date(formatedFirstdate).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                });

              if (element.firstEmailDate === 'Invalid Date') {
                const date = formatedFirstdate;
                const [day, month, year] = date.split('/');
                element.firstEmailDate = `${month}/${day}/${year}`;
                element.firstEmailDate = new Date(
                  element.firstEmailDate
                ).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                });
              }

              element.emailAddress = datum.emailId;
              element.name = datum.name;
              element.noOfEmails = datum.noOfEmails;
              element.dsrRecommendation = datum.dsrRecommendation;
              element.statusDate = '-';
              element.isLogo = true;
              element.isSwitch = false;

              element.domainUrl = datum.domainUrl;
              element.status =
                datum.dsrRecommendation === 1 && datum.status === 'pending'
                  ? 'Recommended'
                  : datum.dsrRecommendation === 0 && datum.status === 'pending'
                  ? '-'
                  : datum.status === 'needed'
                  ? 'Needed'
                  : datum.status;

              NeededDomainList.push(element);
            }
          }
        );

        let RList = NeededDomainList.filter(function (e) {
          return e.status === 'Recommended';
        });
        let NList = NeededDomainList.filter(function (e) {
          return e.status === 'Needed';
        });
        let PList = NeededDomainList.filter(function (e) {
          return e.status === '-';
        });
        AllRecPenNeedList = RList.concat(NList);
        AllRecPenNeedList = AllRecPenNeedList.concat(PList);

        // AllRecPenNeedList = (
        //   myNeededDomainList === undefined ? [] : NeededDomainList || []
        // )//.concat(initialList);
        setRowsData(AllRecPenNeedList);
        setAllNDomain(AllRecPenNeedList);

        dispatch({
          type: 'Pending_TILE_DATA',
          payload:
            AllRecPenNeedList === undefined
              ? allNDomain
              : AllRecPenNeedList || [],
        });
        //For Search data scope
        setSearchData('PendingRecommendedNeeded');
        //setSearchDataList(AllRecPenNeedList);
        //--------------------------------------------------------------------------------------------------------
      } else {
        //checking for both checkbox uncheck on rec
        if (checkedN === false) {
          dispatch({
            type: 'Pending_TILE_DATA',
            payload:
              allNDomain === undefined ? AllRecPenNeedList : allNDomain || [],
          });
          setSearchData('PendingRecommendedNeeded');
          // setSearchDataList(AllRecPenNeedList);
        } else {
          // dispatch({
          //   type: 'Pending_TILE_DATA',
          //   payload: initialList === undefined ? [] : initialList || [],
          // });
          setSearchData('PendingRecommended');
          // setSearchDataList(initialList);
        }
      }
      //Hide Needed checked
      if (checkedN === true) {
        CheckedN = true;
        setCheckedN(true);

        // dispatch({
        //   type: 'Pending_TILE_DATA',
        //   payload: initialList === undefined ? [] : initialList || [],
        // });
        setSearchData('PendingRecommended');
        //setSearchDataList(initialList);
      }
    }
  }

  function CustomNoRowsOverlay() {
    // return !((state.pendingtiledomainlist || []).length >= 1) ? (
    //   <div id="noSearchResult">
    //     <Typography paragraph>No Domain Found.</Typography>
    //   </div>
    // ) : (
    //   <center>
    //     <CircularProgress />
    //   </center>
    // );
  }

  function getDsrPopup(value) {
    setDsrPopup(value);
  }
  function getSingleDsrPopup(value) {
    setDsrSinglePopup(value);
  }
  var initialSortingData = {};
  const dataTable = (
    <>
      <div style={{ height: '70vh', width: '100%' }} id="DSRCustomTable">
        <DataGrid
          disableColumnMenu={true}
          columns={getColumns({ setRowsData, flag: false })}
          rows={state.pendingtiledomainlist || []}
          checkboxSelection
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          initialState={initialSortingData}
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
            if (params.field === 'domainName') {
              setSelectedDatum(params.row);
            }
          }}
          onSortModelChange={(sortModel) => {
            if (sortModel.length === 0) {
              initialSortingData = {};
            } else if (
              sortModel[0].field === ('firstEmailDate' || 'lastEmailDate') &&
              sortModel[0].sort == 'asc'
            ) {
              initialSortingData = {
                sorting: {
                  sortModel: [
                    {
                      field: 'firstEmailDate',
                      sort: 'asc',
                    },
                    {
                      field: 'lastEmailDate',
                      sort: 'asc',
                    },
                  ],
                },
              };
            } else if (
              sortModel[0].field === ('firstEmailDate' || 'lastEmailDate') &&
              sortModel[0].sort == 'desc'
            ) {
              initialSortingData = {
                sorting: {
                  sortModel: [
                    {
                      field: 'firstEmailDate',
                      sort: 'desc',
                    },
                    {
                      field: 'lastEmailDate',
                      sort: 'desc',
                    },
                  ],
                },
              };
            } else {
            }
          }}
          onSelectionModelChange={(newSelectionModel) => {
            // console.log("selcedted data->", newSelectionModel);
            //DSRdataList = rowsData.map((x) => x.domainName === newSelectionModel);
            DSRdataList = [];
            for (let i = 0; i < newSelectionModel.length; i++) {
              (rowsData || []).filter((x) => {
                if (newSelectionModel[i] === x.domainName) {
                  DSRdataList = DSRdataList.concat(x);
                }
              });
            }
            // console.log("DSRdataList", DSRdataList);
            setMarkDSRDataList(DSRdataList);
            //   MarkNeededDomainList = [];
            //   for (let i = 0; i < newSelectionModel.length; i++) {
            //     MarkNeededDomainList = MarkNeededDomainList.concat(rowsData[newSelectionModel[i] - 1].domainName);
            //   }
            //   console.log("MarkNeededDomainList",MarkNeededDomainList);
            //   setMarkNeededDataList(MarkNeededDomainList);
            MarkNeededDomainList = newSelectionModel;
            setMarkNeededDataList(newSelectionModel);
            dispatch({
              type: 'Selected_Row_Count',
              payload: newSelectionModel.length,
            });
          }}
        />
      </div>
    </>
  );

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Backdrop
        className={classes.backdrop}
        open={selectedDatum}
        // onClick={() => {
        //   setSelectedDatum(null);
        // }}
      >
        {selectedDatum && (
          <MyDataDetails
            data={{ selectedDatum }}
            selectDatum={setSelectedDatum}
            getSingleDsrPopup={getSingleDsrPopup}
          />
        )}
      </Backdrop>
      <Backdrop className={classes.backdrop} open={dsrPopup || dsrSinglePopup}>
        {selectedDatum && (
          <MyDataDetails
            data={{ selectedDatum }}
            selectDatum={setSelectedDatum}
            getSingleDsrPopup={getSingleDsrPopup}
          />
        )}
      </Backdrop>
      <Backdrop className={classes.backdrop} open={dsrPopup || dsrSinglePopup}>
        {dsrPopup && <RequestDeletionMultiple getDsrPopup={getDsrPopup} />}
        {dsrSinglePopup && (
          <RequestDeletion getSingleDsrPopup={getSingleDsrPopup} />
        )}
      </Backdrop>

      <div className="CustomFilter">
        <Search
          data={searchData}
          dataList={searchDataList}
          isNoDomain={nosearchresult}
          renderTable={renderTable}
        />
        <div className="DomainTableOuterInput">
          <div className="mr-10">
            <Checkbox
              id="checkedN"
              className="TableFIlter"
              color="#000"
              checked={checkedN}
              //value={state.accSwitch === true ? true : checkedN}
              disabled={enableCheckbox}
              onClick={(e) => {
                //ShowNeededDomains('needed', e);
                onCheckboxEvent('needed', e);
              }}
            />
            <span className="FilterLabel">
              Hide Needed (
              {countOf.neededDomain === undefined ? '' : countOf.neededDomain})
            </span>
            <Checkbox
              id="checkedR"
              className="TableFIlter"
              color="#000"
              checked={checkedR}
              //value={state.accSwitch === true ? false : checkedR}
              onClick={(e) => {
                //ShowRecommendedDomains('recommended', e);
                onCheckboxEvent('recommended', e);
              }}
            />
            <span className="FilterLabel pr-10">
              Show Recommended (
              {countOf.recommendedDsrCount === undefined
                ? ''
                : countOf.recommendedDsrCount}
              )
            </span>
            <span className="FilterLabel DomainDropdown">Time Frame</span>
            <Select
              className="TimeFrameOption"
              native
              value={timeFilter || ''}
              onChange={(event) => {
                onTimeFilterChange(event);
              }}
            >
              <option value="">All Domains</option>
              <option value={1}>1 Month</option>
              <option value={6}>6 Months</option>
              <option value={12}>12 Months</option>
            </Select>

            {/* <span class="DomainDropdown">({timeFilter === "" ?
              (countOf.pendingDomain === undefined ? '' : countOf.pendingDomain) +
              (countOf.recommendedDsrCount === undefined ? '' : countOf.recommendedDsrCount) : TimeFilterCount})</span>
             <span class="DomainDropdown">Show Category</span>
      <Select
        native
        value={'timeframe'}
        // onChange={this.handleChange('age')}
        inputProps={{
          name: 'age',
          id: 'age-native-simple',
        }}
      >
        <option value="">Select</option>
        <option value={10}>Ten</option>
        <option value={20}>Twenty</option>
        <option value={30}>Thirty</option>
      </Select> */}
          </div>
        </div>
      </div>
      {nosearchresult ? (
        // state.noSearchresult
        <>
          <div id="noSearchResult">
            <Typography paragraph>No Domain Found.</Typography>
          </div>
        </>
      ) : (
        <div className="App">
          {/* {dataTable} */}
          {(state.pendingtiledomainlist || []).length >= 1 && dataTable}
          {!((state.pendingtiledomainlist || []).length >= 1) &&
            (tableLoader ? (
              <center>
                <CircularProgress />
              </center>
            ) : (
              <div id="noSearchResult">
                <Typography paragraph>No Domain Found.</Typography>
              </div>
            ))}
        </div>
      )}

      <RequestDeletionButton
        getDsrPopup={getDsrPopup}
        DataList={
          markNeededDataList !== [] ? markNeededDataList : MarkNeededDomainList
        }
        DSRList={
          markDSRDataList !== [] ? markDSRDataList : MarkNeededDomainList
        }
      />
    </>
  );
}
