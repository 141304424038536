import { useContext, useEffect, useState } from 'react';

import React, { useCallback, useMemo, useRef } from 'react';
import { Context } from 'store';
import { axiosServiceInstance } from 'config/axiosConfig';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Moment from 'moment';
import { CircularProgress, Typography } from '@material-ui/core';

import { DataGrid } from '@mui/x-data-grid';

import GridTable from '@nadavshaar/react-grid-table';
import getColumns from './getColumns.js';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';
import MyDataDetails from 'components/data/MyDataDetails.js';
import { makeStyles } from '@material-ui/core/styles';
import Search from 'components/dashboard/Search';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function CompleteDomainTable() {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState();
  const [selectdRow, setselectdRow] = useState();
  const [nosearchresult, setnosearchresult] = useState(false);

  const [state, dispatch] = useContext(Context);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [getState, setState] = useState({});
  const [getAllDomainLastKey, setAllDomainLastKey] = useState('');
  const [getRecState, setRecState] = useState([]);

  const classes = useStyles();
  const history = useHistory();
  const [selectedDatum, setSelectedDatum] = useState(null);
  const [markNeededDataList, setMarkNeededDataList] = useState([]);
  const [markDSRDataList, setMarkDSRDataList] = useState([]);

  const [checkedN, setCheckedN] = useState(true);
  const [checkedR, setCheckedR] = useState(false);

  let CheckedN = true;
  let CheckedR = false;

  const [initialList, setInitialList] = useState([]);
  const [recommendedCList, setRecommendedCList] = useState([]);
  const [allNDomain, setAllNDomain] = useState([]);
  const [enableCheckbox, setEnableCheckbox] = useState(false);
  const [recList, setRecList] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [searchDataList, setSearchDataList] = useState([]);
  const [tableMsg, setTableMsg] = useState(true);
  const [tableLoader, settableLoader] = useState(true);

  const RecDomainArr = [];
  const RecNDomainArr = [];
  let Pendingdomainlist = [];

  useEffect(() => {
    if (!isLoading && state.user.username) {
      // setTableMsg(true);
      settableLoader(true);
      setLoading(true);
      _getMyData();
    }
    if (state.reloadDomainTable === true) {
      setTableMsg(true);
      setnosearchresult(false);
      _getMyData(true);
      dispatch({
        type: 'Reload_Domain_Table',
        payload: false,
      });
    }
    // if (state.noSearchresult === true) {
    //
    //     setnosearchresult(true);
    //     dispatch({
    //         type: 'No_Search_Result',
    //         payload: false,
    //     });
    // }
  }, [state]);

  async function _getMyData(reloadFlag = false) {
    // setallgetdata(false);
    try {
      const dropDownData = await axiosServiceInstance
        .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
        })
        .catch((error) => {
          setLoading(true);
          setState([]);
        });
      let dropdownEmailId = localStorage.getItem('updatedEmailId');

      //Get DOMAIN API For getting the recommended and pending domain cnt:
      const allDomain =
        dropDownData === undefined
          ? []
          : await axiosServiceInstance
              .post(`/pieeye-pfmdomain/api/dashboard/_getAllDomain`, {
                requestInfo: {
                  userName: state.user.username || '',
                  token: state.user.idToken || '',
                },
                requestBody: {
                  emailId:
                    dropdownEmailId === null || dropdownEmailId === 0
                      ? dropDownData.data.responseListObject[0].emailId
                      : dropdownEmailId,
                },
              })
              .catch((error) => {
                setLoading(true);
                // setCountFlag(true);
                // console.log('in error');
              });

      let NeededDomain =
        allDomain !== undefined && allDomain.length > 0
          ? ((allDomain || []).data || []).responseListObject[0].neededDomain
          : 0;
      localStorage.setItem('NeededDomainCount', NeededDomain);

      dispatch({
        type: 'DASHBOARD_DATA',
        payload: {
          myData:
            (allDomain || []).data === undefined
              ? []
              : allDomain.data.responseListObject[0],
          dropDownData:
            dropDownData === undefined
              ? []
              : dropDownData.data.responseListObject,
          emailId:
            dropdownEmailId === null ||
            dropdownEmailId === 0 ||
            dropdownEmailId === undefined
              ? dropDownData.data.responseListObject[0].emailId
              : dropdownEmailId,
        },
      });

      setInitialList([]);
      ///Get Domain api ends here
      const myDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'reclaimed',
            emailId:
              dropdownEmailId === null || dropdownEmailId === 0
                ? dropDownData.data.responseListObject[0].emailId
                : dropdownEmailId,
          },
        })
        .catch((error) => {
          setLoading(true);
          setState([]);
        });

      if (
        ((myDataList || []).data || []).lastKey !== null &&
        ((myDataList || []).data || []).lastKey !== undefined
      ) {
        // Store the last key in the redux stte / localstorage
        dispatch({
          type: 'AllDomain_Last_Key',
          payload:
            ((myDataList || []).data || []).lastKey === null
              ? ''
              : ((myDataList || []).data || []).lastKey,
        });
        setAllDomainLastKey(
          ((myDataList || []).data || []).lastKey === null
            ? ''
            : ((myDataList || []).data || []).lastKey
        );
      }

      dispatch({
        type: 'MY_DATA',
        payload:
          myDataList === undefined
            ? []
            : ((myDataList || []).data || []).responseListObject || [],
      });

      setState(
        myDataList === undefined
          ? []
          : ((myDataList || []).data || []).responseListObject || []
      );

      // const myRecomendedDataList = await axiosServiceInstance
      //   .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
      //     requestInfo: {
      //       userName: state.user.username || '',
      //       token: state.user.idToken || '',
      //     },
      //     requestBody: {
      //       status: 'recommended',
      //       emailId:
      //         dropdownEmailId === null || dropdownEmailId === 0
      //           ? dropDownData.data.responseListObject[0].emailId
      //           : dropdownEmailId,
      //     },
      //   })
      //   .catch((error) => {
      //     setRecState([]);
      //     // console.log('in error');
      //   });

      // if (
      //   ((myRecomendedDataList || []).data || []).lastKey !== null &&
      //   ((myRecomendedDataList || []).data || []).lastKey !== undefined
      // ) {
      //   RecDomainArr = RecDomainArr.concat(
      //     myRecomendedDataList.data.responseListObject
      //   );
      //   dispatch({
      //     type: 'Last_Key',
      //     payload:
      //       ((myRecomendedDataList || []).data || []).lastKey === null
      //         ? ''
      //         : ((myRecomendedDataList || []).data || []).lastKey,
      //   });
      // }
      // dispatch({
      //   type: 'MY_Recommended_DATA',
      //   payload:
      //     myRecomendedDataList === undefined
      //       ? []
      //       : ((myRecomendedDataList || []).data || []).responseListObject ||
      //       [],
      // });

      if (
        ((myDataList || []).data || []).responseListObject !== undefined &&
        ((myDataList || []).data || []).responseListObject.length >= 1
      ) {
        var PendingAndRecDomains = []
          // ((myRecomendedDataList || []).data || []).responseListObject || []
          .concat(((myDataList || []).data || []).responseListObject);

        PendingAndRecDomains.map((datum, index) => {
          if (datum !== undefined) {
            var formateddate = Moment(datum.lastEmailDate).format(
              'MM/DD/YYYY' //'DD/MM/YYYY'
            );
            var formatedFirstdate = Moment(datum.firstEmailDate).format(
              'MM/DD/YYYY'
            );
            var requestedDate = Moment(datum.statusDate).format('MM/DD/YYYY');
            var element = {};

            element.id = datum.domainName;
            element.domainLogo = datum.domainLogo;
            element.domainName = datum.domainName;
            element.lastEmailDate = //formateddate;
              new Date(formateddate).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });

            if (element.lastEmailDate === 'Invalid Date') {
              const date = formateddate;
              const [day, month, year] = date.split('/');
              element.lastEmailDate = `${month}/${day}/${year}`;

              element.lastEmailDate = new Date(
                element.lastEmailDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            element.dsrContactEmail = datum.dsrContactEmail;
            element.firstEmailDate = //formatedFirstdate;
              new Date(formatedFirstdate).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });

            if (element.firstEmailDate === 'Invalid Date') {
              const date = formatedFirstdate;
              const [day, month, year] = date.split('/');
              element.firstEmailDate = `${month}/${day}/${year}`;
              element.firstEmailDate = new Date(
                element.firstEmailDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            element.statusDate = new Date(requestedDate).toLocaleDateString(
              'en-US',
              {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              }
            );

            if (element.statusDate === 'Invalid Date') {
              const date = requestedDate;
              const [day, month, year] = date.split('/');
              element.statusDate = `${month}/${day}/${year}`;
              element.statusDate = new Date(
                element.statusDate
              ).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              });
            }

            element.emailAddress = datum.emailId;
            element.name = datum.name;
            element.noOfEmails = datum.noOfEmails;
            element.dsrRecommendation = datum.dsrRecommendation;
            //element.date = '-';

            element.isLogo = true;
            element.domainUrl = datum.domainUrl;
            element.status =
              datum.status === 'reclaimed' ? 'Completed' : datum.status;

            Pendingdomainlist.push(element);
          }
        });
      }

      // let cList = Pendingdomainlist.filter(function (e) {
      //     return e.status === 'Completed';
      // });
      // let fList = Pendingdomainlist.filter(function (e) {
      //     return e.status === 'Fullfiled';
      // });
      // Pendingdomainlist = cList.concat(fList);

      setInitialList(Pendingdomainlist);
      setSearchData('Reclaimed');

      //setSearchDataList(Pendingdomainlist);

      dispatch({
        type: 'Pending_TILE_DATA',
        payload: Pendingdomainlist === undefined ? [] : Pendingdomainlist || [],
      });
      setRowsData(Pendingdomainlist || []);
      setTableMsg(false);
      settableLoader(false);
      setLoading(true);
    } catch (e) {
      setTableMsg(false);
      settableLoader(false);
      console.error('Unable to fetch domains:', e);

      if ((e.response || []).status || '' === 404) {
        setLoading(true);
      }
      setLoading(true);
    }
    // if (reloadFlag === true) {
    //   onTableReload(checkedN, checkedR);
    // }
  }

  const elementScrollData = (value) => {
    //console.log('elementScrollData ', value);
  };
  const handleChange = (rowIndex, column, isEdit, event) => {
    setselectdRow(rowIndex.selectedRows);
    dispatch({
      type: 'Selected_Row_Count',
      payload: rowIndex.length,
    });
    // console.log(selectdRow);
  };
  function onScroll() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
    }
  }

  function CustomNoRowsOverlay() {
    return tableMsg ? (
      <center>
        <CircularProgress />
      </center>
    ) : (
      <div id="noSearchResult">
        <Typography paragraph>No Domain Found.</Typography>
      </div>
    );
  }

  const dataTable = (
    <>
      <div style={{ height: '70vh', width: '100%' }} id="DSRCustomTable1">
        <DataGrid
          disableColumnMenu={true}
          columns={getColumns({ setRowsData, flag: false })}
          rows={state.pendingtiledomainlist || []}
          //checkboxSelection

          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          // initialState={{
          //   sorting: {
          //    sortModel: [{ field: 'status', sort: ['Recommended', 'Needed', '-'] }],
          //   },
          // }}
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
            if (params.field === 'domainName') {
              setSelectedDatum(params.row);
            }
          }}

          // onSelectionModelChange={(newSelectionModel) => {
          //   console.log("selcedted data->", newSelectionModel);
          // }}
        />
      </div>
    </>
  );

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Backdrop
        className={classes.backdrop}
        open={selectedDatum}
        //open={viewPopup}
        onClick={() => {
          setSelectedDatum(null);
        }}
      >
        {selectedDatum && (
          <MyDataDetails
            data={{ selectedDatum }}
            selectDatum={setSelectedDatum}
          />
        )}
      </Backdrop>
      <div className="CustomFilter">
        <Search
          data={searchData}
          dataList={searchDataList}
          isNoDomain={nosearchresult}
          result={setnosearchresult}
        />
        {/* <div class="DomainTableOuterInput">
          <div className="mr-10">
            <Checkbox
              className="TableFIlter"
              color="#000"
              defaultChecked={checkedN}
              value=""
              disabled={enableCheckbox}
              onClick={(e) => {
                //ShowNeededDomains('needed', e);
                onCheckboxEvent('needed', e);
              }}
            />
            <span className="FilterLabel">Hide Needed</span>
            <Checkbox
              className="TableFIlter"
              color="#000"
              defaultChecked={checkedR}
              value=""
              onClick={(e) => {
                //ShowRecommendedDomains('recommended', e);
                onCheckboxEvent('recommended', e);
              }}
            />
            <span className="FilterLabel">Show Recommended</span>
            {/* <span class="DomainDropdown">Time Frame</span>
   <Select
         native
        value={'timeframe'}
        // onChange={this.handleChange('age')}
        inputProps={{
          name: 'age',
          id: 'age-native-simple',
        }}
      >
        <option value="">Select</option>
        <option value={10}>Ten</option>
        <option value={20}>Twenty</option>
        <option value={30}>Thirty</option>
      </Select>
      <span class="DomainDropdown">Show Category</span>
      <Select
        native
        value={'timeframe'}
        // onChange={this.handleChange('age')}
        inputProps={{
          name: 'age',
          id: 'age-native-simple',
        }}
      >
        <option value="">Select</option>
        <option value={10}>Ten</option>
        <option value={20}>Twenty</option>
        <option value={30}>Thirty</option>
      </Select>
          </div>
        </div> */}
      </div>
      {nosearchresult ? (
        <>
          <div id="noSearchResult">
            <Typography paragraph>No Domain Found.</Typography>
          </div>
        </>
      ) : (
        <div className="App">
          {!tableLoader && dataTable}
          {tableLoader && (
            <center>
              <CircularProgress />
            </center>
          )}
        </div>
      )}
    </>
  );
}
