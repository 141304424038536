import { useHistory } from 'react-router-dom';
import tw from 'twin.macro';
import { PrimaryButton } from 'components/misc/Buttons';

const CardWrapper = tw.div`flex flex-col h-full bg-gray-200 rounded border-2`;
const CardNumber = tw.h1`text-center font-bold sm:text-5xl lg:text-6xl text-primary-500`;
const CardText = tw.div`flex-1 px-6 py-6`;
const CardTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;
const CardDescription = tw.p``;
const ButtonContainer = tw.div`flex justify-center`;
const CardActionButton = tw(PrimaryButton)`my-3 mx-auto`;

export default function Card({ value, header, description, href, buttonText }) {
  const history = useHistory();
  return (
    <CardWrapper
      className="group group1"
      initial="rest"
      whileHover="hover"
      animate="rest"
    >
      <CardText id="CardContent">
        <CardNumber id="card">{value}</CardNumber>
        <CardTitle>{header}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardText>
      <ButtonContainer id="FooterButton">
        <CardActionButton onClick={() => history.push(`/${href}`)}>
          {buttonText}
        </CardActionButton>
      </ButtonContainer>
    </CardWrapper>
  );
}
