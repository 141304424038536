import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import Header, {
  ImageLink,
  NavLink,
  NavLinks,
} from 'components/headers/light.js';

import CustomizeIconImage from 'images/customize-icon.svg';
import FastIconImage from 'images/fast-icon.svg';
import ShieldIconImage from 'images/shield-icon.svg';
import SupportIconImage from 'images/support-icon.svg';
import UserSignoutIconImage from 'images/user-signout.svg';
import UserPopper from './UserPopper';
import FeedbackIcon from 'images/feedback.png';
import ReferFriendImage from 'images/ReferFriend.svg';

const mobileLinkItems = [
  {
    imageSrc: ShieldIconImage,
    text: 'Dashboard',
    href: 'newDashboard',
  },
  {
    imageSrc: FastIconImage,
    text: 'My Data',
    href: 'my-data',
  },
  {
    imageSrc: SupportIconImage,
    text: 'My Deletion Requests',
    href: 'my-deletion-requests',
  },
  {
    imageSrc: CustomizeIconImage,
    text: 'Account Settings',
    href: 'account',
  },
  {
    imageSrc: UserSignoutIconImage,
    text: 'Sign Out',
    href: 'signout',
    onClick: (ev) => {
      ev.preventDefault();
      localStorage.removeItem('myId');
      localStorage.removeItem('routeReload');
      localStorage.removeItem('ProgressBarApiCall');
      localStorage.removeItem('fromSignUp');
      Auth.signOut();
    },
  },

  {
    imageSrc: FeedbackIcon,
    text: 'Feedback',
    href: 'userFeedback',
  },
  {
    imageSrc: ReferFriendImage,
    text: 'Refer A Friend',
    href: 'referAFriend',
  },
];

export default function FullPageHeader() {
  const history = useHistory();

  const linkMapper = (item) => {
    const onClick = item.onClick
      ? item.onClick
      : (e) => {
          e.preventDefault();
          history.push(`/${item.href}`);
        };

    if (item.imageSrc) {
      return (
        <ImageLink key={item.href} href={`/${item.href}`} onClick={onClick}>
          <img src={item.imageSrc} alt={item.text} />
          <span>{item.text}</span>
        </ImageLink>
      );
    }

    return (
      <NavLink key={item.href} href={`/${item.href}`} onClick={onClick}>
        {item.text}
      </NavLink>
    );
  };

  const navLinks = [<UserPopper key="popper" />];
  // const mobileLinks = mobileLinkItems.map(linkMapper);
  const mobileLinks = [<UserPopper key="popper" />];

  const links = [<NavLinks key={1}>{navLinks}</NavLinks>];
  const mLinks = [<NavLinks key={2}>{mobileLinks}</NavLinks>];

  return <Header links={links} mobileLinks={mLinks} />;
}
