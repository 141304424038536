import { useContext, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { Context } from 'store';

export default function AppAuth({ children }) {
  const [state, dispatch] = useContext(Context); //eslint-disable-line no-unused-vars

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          const {
            signInUserSession: {
              idToken: { payload },
            },
          } = data;

          dispatch({
            type: 'USER_SIGN_IN',
            payload: { ...payload },
          });
          break;
        case 'signOut':
          console.warn('TODO: Implement User Sign Out');
          window.location = '/login';
          break;
        default:
          console.warn(`Unknown auth event: ${event}`);
      }
    });

    async function auth() {
      try {
        const [userInfo, session] = await Promise.all([
          Auth.currentUserInfo(),
          Auth.currentSession(),
        ]);
        if (userInfo?.username && session?.accessToken) {
          return dispatch({
            type: 'USER_SIGN_IN',
            payload: {
              username: userInfo.username,
              name: userInfo.attributes.name,
              email: userInfo.attributes.email,
              accessToken: session.accessToken.jwtToken,
              idToken: session.idToken.jwtToken,
              refreshToken: session.refreshToken.token,
            },
          });
        }
      } catch (e) {
        if (localStorage.getItem('myId')) {
          localStorage.removeItem('myId');
          window.location = '/login';
        }
        console.error(e);
      }
    }

    if (!state.user.name) {
      auth();
    }
  });

  return children;
}
