import { useContext, useState, useEffect } from 'react';
import {
  Backdrop,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { Context } from '../store';
import DsrCard from '../components/dsr/DsrCard';
import FullPageWrapper from '../components/wrapper/FullPageWrapper';
import { axiosServiceInstance } from 'config/axiosConfig';

import { makeStyles } from '@material-ui/core/styles';
import DsrDetails from '../components/dsr/DsrDetails';

import CircularProgress from '@material-ui/core/CircularProgress';
import SearchBar from 'material-ui-search-bar';
const Cards = tw.div`flex flex-wrap -mr-3 relative`;
const CardContainer = tw.div`relative z-20 sm:pt-3 pr-3 w-full sm:w-1/3 lg:w-1/4 max-w-sm mx-auto sm:max-w-none sm:mx-0`;
const PagerWrapper = tw.div`flex my-5 items-center justify-center`;
const UserLink = tw.span`text-primary-500`;
const statusHeading = {
  fontSize: '1rem',
  lineHeight: 2.33,
  opacity: 0.7,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '2rem',
  fontFamily: 'Roboto',
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  wrapper: {
    margin: '0 7% 0 auto',
    display: 'flex',
    alignItems: 'center',
  },

  alignItemsAndJustifyContent: {
    width: '100%',
    height: 380,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const pageSize = 8;

export default function MyDSRs() {
  const [state, dispatch] = useContext(Context);
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  // TODO: Display errors
  const [errors, setError] = useState(null); //eslint-disable-line no-unused-vars
  const [page, setPage] = useState(1);
  const [pageOfPendingDsr, setPageOfPendingDsr] = useState(1);
  const [pageOfFailedDsr, setpageOfFailedDsr] = useState(1);
  const [pageOfProgressDsr, setpageOfProgressDsr] = useState(1);
  const [selectedDSR, setSelectedDSR] = useState(null);
  const [getModel, setModel] = useState(true);
  const [getState, setState] = useState([]);
  const [cardCompleted, setCardCompleted] = useState([]);
  const [cardPendign, setCardPendign] = useState([]);
  const [cardfailed, setCardFailed] = useState([]);
  const [cardprogress, setCardProgress] = useState([]);
  const [getDropdown, setSetDropdown] = useState({});
  const [showloader, setshowloader] = useState(false);

  const [searchdomainval, setSearchdomainval] = useState('');
  const [getPendingSearchcardContent, setPendingSearchcardContent] = useState(
    []
  );
  const [searchdomain, setSearchdomain] = useState(false);
  const [SearchErrMsg, setSearchErrMsg] = useState(false);
  const [displaySearchIcon, setdisplaySearchIcon] = useState(true);
  const [SearchcardContent, setSearchcardContent] = useState([]);

  const [cardSearched, setcardSearched] = useState([]);
  const [Searchpage, setSearchpage] = useState(1);
  let AllDsrArray = [];
  async function _changeDashBoardData(emailId) {
    try {
      const myDsrsList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'initiated,reclaimed,failed',
            emailId: emailId,
          },
        })
        .catch((error) => {
          setLoaded(true);
        });

      dispatch({
        type: 'MY_DSRS',
        payload:
          myDsrsList === undefined
            ? []
            : ((myDsrsList || []).data || []).responseListObject || [],
      });
      dispatch({
        type: 'DASHBOARD_DATA',
        payload: {
          myData: [],
          dropDownData: (state.dashboardData || []).dropDownData || [],
          emailId: emailId,
        },
      });

      setState(
        myDsrsList === undefined
          ? []
          : ((myDsrsList || []).data || []).responseListObject || []
      );
      /*
      if( ((myDsrsList || []).data || []).lastKey !== undefined ){
        FetchNextDsr(emailId,'initiated,reclaimed,failed', ((myDsrsList || []).data || []).lastKey);
      }
*/
      _setDSrAccorddingToStatus(
        ((myDsrsList || []).data || []).responseListObject || []
      );

      setSetDropdown({
        dropDownData: getDropdown.dropDownData,
        emailId: emailId,
      });
      dispatch({
        type: 'MY_SELECTED_DATA',
        payload: [],
      });
      localStorage.setItem('updatedEmailId', emailId);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
    }
  }

  useEffect(() => {
    if (!loaded && state.user.username) {
      _getMyDsrs();
    }
  }, [state]);

  const emailFormContent =
    (getDropdown.dropDownData || []).length > 1 ? (
      <FormControl className={classes.formControl} id="AccountEmail">
        <InputLabel id="demo-simple-select-label">Email:</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={getDropdown.emailId || ''}
          onChange={(event) => {
            var emailId = event.target.value;
            if (emailId !== '') {
              _changeDashBoardData(emailId);
            }
          }}
        >
          <MenuItem value="" key="select">
            Select
          </MenuItem>
          {(getDropdown.dropDownData || []).map((data) => (
            <MenuItem value={data.emailId} key={data.emailId}>
              {data.emailId}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : null;
  const SearchBarHtml = (
    <>
      <div id="DomainSearch" className="DomainSearchDSR">
        <SearchBar
          value={searchdomainval}
          onRequestSearch={(e) => SearchDomain(searchdomainval, e)}
          onChange={(newValue) => {
            debunceEvent(newValue);
          }}
          onCancelSearch={() => {
            setPendingSearchcardContent([]);
            setSearchdomain(false);
            setSearchErrMsg(false);
            dispatch({
              type: 'User_Search_DATA',
              payload: 'false',
            });
          }}
          placeholder="Search your domain"
          searchIcon={<></>}
        />
      </div>
      {SearchErrMsg ? (
        <p className="SearchErr">Please enter atleast 2 characters</p>
      ) : (
        ''
      )}
    </>
  );
  const previesCardPending = [];
  const previesCard = [];
  const failedCard = [];
  const previesCardProgress = [];
  /**********Search API************** */
  function SearchDomain(searchdomainParamVal, e) {
    setshowloader(true);
    setdisplaySearchIcon(false);
    searchdomainParamVal = searchdomainParamVal.toLowerCase();

    if (
      searchdomainParamVal === '' ||
      searchdomainParamVal === undefined ||
      (e.keyCode === 8 && searchdomainParamVal === '')
    ) {
      setSearchdomain(false);
      setdisplaySearchIcon(true);
      setSearchErrMsg(false);
      setcardSearched([]);
      dispatch({
        type: 'User_Search_DATA',
        payload: 'false',
      });
    } else {
      if (searchdomainParamVal.length > 1) {
        setSearchErrMsg(false);
        localStorage.removeItem('PendingDomainList');
        setcardSearched([]);
        dispatch({
          type: 'User_Search_DATA',
          payload: 'true',
        });
        const searchTimeout = setTimeout(() => {
          SearchDomainApi(searchdomainParamVal);
        }, 50);

        setSearchdomain(true);
      } else {
        setSearchdomain(false);
        setSearchErrMsg(true);
      }
    }
  }
  async function SearchDomainApi(searchdomainParamVal) {
    try {
      //Get the all domains:
      let dropdownEmailId = localStorage.getItem('updatedEmailId');
      const myAllDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'initiated,reclaimed,failed',
            emailId: (state.dashboardData || []).emailId || dropdownEmailId,
            //parsedEmailDataId: state.getAllDomain_lastkey || null,
            domainName: searchdomainParamVal,
          },
        })
        .catch((error) => {
          setLoaded(true);
          //  setRecState([]);
        });
      if (myAllDataList !== undefined) {
        setcardSearched(myAllDataList.data.responseListObject);
        dispatch({
          type: 'User_Search_DATA',
          payload: 'true',
        });
      }

      setLoaded(true);
      setshowloader(false);
      //  setRecState([]);
    } catch (e) {
      console.error('Unable to fetch domains:', e);

      if (e.status === 404) {
        // alert("not fund");
        setLoaded(true);
        //  setError({ ...((e.response || []).status || null) });
      }
    }
  }
  function debouncecall(func, delay) {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }
  const debunceEvent = debouncecall((newValue) => {
    const eve = { key: 't' };
    setSearchdomainval(newValue);
    SearchDomain(newValue, eve);
  }, 800);
  async function _setDSrAccorddingToStatus(dsrArray) {
    // dsrArray = dsrArray.reverse();

    dsrArray.forEach((dsr, index) => {
      if (dsr.status === 'reclaimed') {
        previesCard.push(dsr);
      } else if (dsr.status === 'failed') {
        failedCard.push(dsr);
      } else if (dsr.status === 'processing') {
        previesCardProgress.push(dsr);
      } else {
        previesCardPending.push(dsr);
      }
    });
    setCardCompleted(previesCard);
    previesCardPending.sort((a, b) => (a.statusDate < b.statusDate ? 1 : -1));
    setCardPendign(previesCardPending);
    setCardFailed(failedCard);
    setCardProgress(previesCardProgress);
  }
  async function _getMyDsrs() {
    let dropdownEmailId = localStorage.getItem('updatedEmailId');
    try {
      const dropDownData = await axiosServiceInstance
        .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
        })
        .catch((error) => {
          setLoaded(true);
        });

      const queryParams = new URLSearchParams(window.location.search);
      const filter = queryParams.get('filter');

      let filterval =
        filter === 'completed'
          ? 'reclaimed'
          : 'initiated,failed,reclaimed,processing';

      const myDsrsList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: filterval,
            emailId:
              dropdownEmailId === null || dropdownEmailId === undefined
                ? dropDownData.data.responseListObject[0].emailId
                : dropdownEmailId,
          },
        })
        .catch((error) => {
          setLoaded(true);
        });

      dispatch({
        type: 'MY_DSRS',
        payload:
          myDsrsList === undefined
            ? []
            : ((myDsrsList || []).data || []).responseListObject || [],
      });

      setState(
        myDsrsList === undefined
          ? []
          : ((myDsrsList || []).data || []).responseListObject || []
      );
      if (((myDsrsList || []).data || []).lastKey !== undefined) {
        FetchNextDsr(
          dropDownData,
          filterval,
          ((myDsrsList || []).data || []).lastKey
        );
      }
      setSetDropdown({
        dropDownData:
          dropDownData === undefined
            ? []
            : dropDownData.data.responseListObject,
        emailId:
          dropdownEmailId === null || dropdownEmailId === undefined
            ? dropDownData.data.responseListObject[0].emailId
            : dropdownEmailId,
      });
      _setDSrAccorddingToStatus(
        ((myDsrsList || []).data || []).responseListObject || []
      );
      setLoaded(true);
    } catch (e) {
      console.error('Unable to fetch domains:', e);

      if ((e.response || []).status || '' === 404) {
        setLoaded(true);
        setError({ ...((e.response || []).status || null) });
      }
    }
  }
  async function FetchNextDsr(emailId, filterval, lastkey) {
    const myDsrsList = await axiosServiceInstance
      .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
        requestInfo: {
          userName: state.user.username || '',
          token: state.user.idToken || '',
        },
        requestBody: {
          status: filterval,
          emailId: emailId,
        },
      })
      .catch((error) => {
        setLoaded(true);
      });

    AllDsrArray = getState.concat(
      ((myDsrsList || []).data || []).responseListObject || []
    );

    dispatch({
      type: 'MY_DSRS',
      payload: AllDsrArray === undefined ? [] : AllDsrArray || [],
    });

    setState(AllDsrArray === undefined ? [] : AllDsrArray || []);
    // Store the last key in the redux stte / localstorage
    if (((myDsrsList || []).data || []).lastKey !== null) {
      FetchNextDsr(((myDsrsList || []).data || []).lastKey);
    }
  }
  async function udateStatusComplet(domainName) {
    try {
      await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/domainStatus/_update`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: 'reclaimed',
            emailId: getDropdown.emailId,
            domainName: domainName,
          },
        })
        .catch((e) => {
          console.error(e);
        });

      _getMyDsrs();

      let curpagesize = cardPendign.length / 8 - 0.125;
      if (Math.ceil(curpagesize) < pageOfPendingDsr) {
        setPageOfPendingDsr(pageOfPendingDsr - 1);
      }
      setPendingSearchcardContent([]);
      setSearchdomain(false);
      setSearchErrMsg(false);
      setSearchdomainval('');
      setLoaded(false);
    } catch (e) {
      console.error(e);
      setLoaded(false);
    }
  }
  const updateStatus = (domainName) => {
    setLoaded(true);
    udateStatusComplet(domainName);
  };
  if (!loaded) {
    return (
      <FullPageWrapper selected="my-deletion-requests">
        <Box className={classes.alignItemsAndJustifyContent}>
          <CircularProgress />
        </Box>
      </FullPageWrapper>
    );
  }
  if ((getState.length || 0) === 0) {
    return (
      <FullPageWrapper selected="my-deletion-requests">
        <Typography variant="h4" gutterBottom>
          My Data Subject Requests
        </Typography>
        {emailFormContent}
        <Typography></Typography>
        You currently don't have any data subject request. Check{' '}
        <Link to="/my-data">
          <UserLink>Where's My Data?</UserLink>
        </Link>{' '}
        to start a request and take back your data today!
      </FullPageWrapper>
    );
  }
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const startIndexPending = (pageOfPendingDsr - 1) * pageSize;
  const endIndexPending = startIndexPending + pageSize;
  const startIndexFailed = (pageOfFailedDsr - 1) * pageSize;
  const endIndexFailed = startIndexFailed + pageSize;
  const startSearchIndex = (Searchpage - 1) * pageSize;
  const endSearchIndex = startSearchIndex + pageSize;
  const startIndexProgress = (pageOfProgressDsr - 1) * pageSize;
  const endIndexProgress = startIndexProgress + pageSize;

  const displaySearchedDsr = cardSearched.slice(
    startSearchIndex,
    endSearchIndex
  );

  const displayCompletedDsr = cardCompleted.slice(startIndex, endIndex);

  const displayPendingDsr = cardPendign.slice(
    startIndexPending,
    endIndexPending
  );
  const displayFailedDsr = cardfailed.slice(startIndexFailed, endIndexFailed);
  const displayProgressDsr = cardprogress.slice(
    startIndexProgress,
    endIndexProgress
  );
  let cardPendignShow = [];
  let cardCompletedShow = [];
  let cardFailedShow = [];
  let cardSearchedShow = [];
  let cardProgressShow = [];
  let card = (dsr) => {
    return (
      <CardContainer key={dsr.id} id="DSRCard">
        <DsrCard
          // id={dsr.id}

          id={dsr.id}
          name={dsr.name}
          domain={dsr.domainName}
          emailAddress={dsr.emailId}
          userDetail={state.user || []}
          requestDate={dsr.lastEmailDate || ''}
          statusDate={dsr.statusDate || ''}
          contactEmail={dsr.dsrContactEmail || ''}
          alternateContactEmail={dsr.dsrAlternativeEmail || ''}
          onSelect={() => {
            setSelectedDSR(dsr);
            setModel(false);
            // history.push(`/my-deletion-requests?id=${dsr.id}`);
          }}
          updateStatus={() => {
            updateStatus(dsr.domainName);
          }}
          status={dsr.status}
        />
      </CardContainer>
    );
  };

  (displayCompletedDsr || []).forEach((dsr, index) => {
    cardCompletedShow.push(card(dsr));
  });

  (displayPendingDsr || []).forEach((dsr, index) => {
    cardPendignShow.push(card(dsr));
  });
  (displayFailedDsr || []).forEach((dsr, index) => {
    cardFailedShow.push(card(dsr));
  });
  (displaySearchedDsr || []).forEach((dsr, index) => {
    cardSearchedShow.push(card(dsr));
  });
  (displayProgressDsr || []).forEach((dsr, index) => {
    cardProgressShow.push(card(dsr));
  });

  const pages = Math.ceil((cardCompleted.length || 0) / pageSize);
  const pagesPending = Math.ceil((cardPendign.length || 0) / pageSize);
  const pagesFailed = Math.ceil((cardfailed.length || 0) / pageSize);
  const pagesProgress = Math.ceil((cardprogress.length || 0) / pageSize);
  const pager =
    pages > 1 ? (
      <PagerWrapper>
        <Pagination
          count={pages}
          defaultPage={page}
          size="small"
          onChange={(e, page) => {
            setPage(page);
          }}
        />
      </PagerWrapper>
    ) : null;

  const pagerPending =
    pagesPending > 1 ? (
      <PagerWrapper>
        <Pagination
          count={pagesPending}
          defaultPage={pageOfPendingDsr}
          size="small"
          onChange={(e, pageOfPendingDsr) => {
            setPageOfPendingDsr(pageOfPendingDsr);
          }}
        />
      </PagerWrapper>
    ) : null;

  const pagerProgres =
    pagesProgress > 1 ? (
      <PagerWrapper>
        <Pagination
          count={pagesProgress}
          defaultPage={pageOfProgressDsr}
          size="small"
          onChange={(e, pageOfProgressDsr) => {
            setpageOfProgressDsr(pageOfProgressDsr);
          }}
        />
      </PagerWrapper>
    ) : null;

  const pagerFailed =
    pagesFailed > 1 ? (
      <PagerWrapper>
        <Pagination
          count={pagesFailed}
          defaultPage={pageOfFailedDsr}
          size="small"
          onChange={(e, pageOfFailedDsr) => {
            setpageOfFailedDsr(pageOfFailedDsr);
          }}
        />
      </PagerWrapper>
    ) : null;

  const Searchpages = Math.ceil((cardSearched.length || 0) / pageSize);
  const Searchpager =
    Searchpages > 1 ? (
      <PagerWrapper>
        <Pagination
          count={Searchpages}
          defaultPage={Searchpage}
          size="small"
          onChange={(e, Searchpage) => {
            setSearchpage(Searchpage);
          }}
        />
      </PagerWrapper>
    ) : null;

  return (
    <FullPageWrapper selected="my-deletion-requests">
      <Backdrop
        className={classes.backdrop}
        open={selectedDSR}
        onClick={() => {
          setSelectedDSR(null);
          setModel(true);
        }}
      >
        {selectedDSR && (
          <DsrDetails
            dsr={selectedDSR}
            userEmailId={(state.dashboardData || []).emailId || ''}
            getModel={getModel}
            closeModal={setSelectedDSR}
            status={selectedDSR.status}
          />
        )}
      </Backdrop>
      <Typography variant="h4" gutterBottom>
        My Data Subject Requests
      </Typography>
      {emailFormContent}
      {SearchBarHtml}
      {/* <MyDSRSearch /> */}

      {searchdomain ? (
        <>
          {cardSearchedShow.length > 0 ? (
            <div id="failed_dsr">
              <div style={statusHeading}>
                <p>
                  {' '}
                  {cardSearchedShow.length <= 1
                    ? 'Search Result:'
                    : 'Search Result:'}
                </p>
                <p>Total ({cardSearched.length})</p>
              </div>
              <Cards>{cardSearchedShow}</Cards>
            </div>
          ) : showloader ? (
            <div id="mydataloader">
              <CircularProgress />
            </div>
          ) : (
            <div id="noSearchResult">
              <Typography paragraph>No Domain Found.</Typography>
            </div>
          )}
          {Searchpager}
        </>
      ) : (
        <>
          {cardCompletedShow.length > 0 && (
            <div>
              <div style={statusHeading}>
                <p>
                  {cardCompletedShow.length <= 1
                    ? 'Completed Data Subject Request'
                    : 'Completed Data Subject Requests'}
                </p>
                <p>Total ({cardCompleted.length})</p>
              </div>
              <Cards>{cardCompletedShow}</Cards>
            </div>
          )}
          {pager}

          {cardPendignShow.length > 0 && (
            <div id="submited_dsr">
              <div style={statusHeading}>
                <p>
                  {' '}
                  {cardPendignShow.length <= 1
                    ? 'Submitted Data Subject Request'
                    : 'Submitted Data Subject Requests'}
                </p>
                <p>Total ({cardPendign.length})</p>
              </div>
              <Cards>{cardPendignShow}</Cards>
            </div>
          )}
          {pagerPending}
          {cardProgressShow.length > 0 && (
            <div id="submited_dsr">
              <div style={statusHeading}>
                <p>
                  {' '}
                  {cardProgressShow.length <= 1
                    ? 'Processing Data Subject Request'
                    : 'Processing Data Subject Requests'}
                </p>
                <p>Total ({cardprogress.length})</p>
              </div>
              <Cards>{cardProgressShow}</Cards>
            </div>
          )}
          {pagerProgres}

          {cardFailedShow.length > 0 && (
            <div id="failed_dsr">
              <div style={statusHeading}>
                <p>
                  {' '}
                  {cardFailedShow.length <= 1
                    ? 'Failed Data Subject Request'
                    : 'Failed Data Subject Requests'}
                </p>
                <p>Total ({cardfailed.length})</p>
              </div>
              <Cards>{cardFailedShow}</Cards>
            </div>
          )}
          {pagerFailed}
        </>
      )}
    </FullPageWrapper>
  );
}
