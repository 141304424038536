import { useContext, useEffect, useState } from 'react';
import { axiosServiceInstance } from '../config/axiosConfig'; // But instead our new configured version :)
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Context } from '../store';
import SmallPageWrapper from '../components/wrapper/SmallPageWrapper';
import { redirectUri, google_redirectUri } from 'config/msal';
import { oauthUrl, googleOauthUrl } from '../config/oauthUrls';
import CircularProgress from '@material-ui/core/CircularProgress';

const divStyle = {
  margin: '1rem 1.5rem',
};

function getError() {
  const path = window.location.pathname;
  if (path === '/oidc_callback') {
    const queryParams = new URLSearchParams(window.location.search);
    const error = queryParams.get('error');
    const errorDescription = queryParams.get('error_description');

    if (errorDescription) {
      return {
        error,
        errorDescription,
      };
    }
  }

  if (path === '/cognito_callback') {
    const queryParams = new URLSearchParams(window.location.search);
    const error = queryParams.get('error');
    const errorDescription = queryParams.get('error_description');

    if (errorDescription) {
      return {
        error,
        errorDescription,
      };
    }
  }

  return null;
}

function getProvider() {
  const path = window.location.pathname;

  if (path === '/gmail-auth') {
    return 'Google';
  }

  // Connected Microsoft account
  if (path === '/oidc_callback') {
    return 'Microsoft';
  }

  if (path === '/yahoo-auth') {
    return 'Yahoo';
  }

  if (path === '/cognito_callback') {
    return 'Cognito';
  }

  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('provider');
}

export default function ConnectionSuccessful() {
  const history = useHistory();
  const [state, dispatch] = useContext(Context); //eslint-disable-line no-unused-vars
  const provider = getProvider();
  const error = getError();
  const [loaded, setLoaded] = useState(false);
  // const [isProcessing, setIsProcessing] = useState(false);
  const hasSeenIntroFlow = localStorage.getItem('hasSeenIntroFlow');
  const [err, setErr] = useState('');
  const [isErr, setIsErr] = useState(false);
  const destination = hasSeenIntroFlow ? '/dashboard' : '/getting-started';

  Auth.currentSession().then(async (value) => {
    if (state && state.user && state.user.idToken && provider === 'Cognito') {
      const username = state.user.username;
      const response = await axiosServiceInstance
        .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
          requestInfo: {
            userName: username,
            token: state.user.idToken,
          },
        })
        .catch((error) => {
          if (error && error.status === 404 && provider === 'Cognito') {
            const selectedProvider = localStorage.getItem('selected_provider');
            localStorage.removeItem('selected_provider');
            if (selectedProvider && selectedProvider === 'Google') {
              window.location.href = googleOauthUrl;
            } else if (selectedProvider && selectedProvider === 'Microsoft') {
              window.location.href = oauthUrl;
            }
          }
        });
      if (response && response.status === 200) {
        localStorage.removeItem('routeReload');
        history.push(destination);
      }
    }
  });

  useEffect(() => {
    const effect = async () => {
      if (provider === 'Microsoft' || provider === 'Google') {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        const error = queryParams.get('error');
        if (code && state.user && state.user.idToken) {
          const logIntype = localStorage.getItem('loginType');
          const emailId =
            logIntype === 'signup'
              ? state.user.email
              : logIntype === 'dsr'
              ? localStorage.getItem('permissionFor')
              : '';
          const response = await axiosServiceInstance
            .post(`/pieeye-pfmuser/api/account/_pfmDataRequest`, {
              requestInfo: {
                userName: state.user.username,
                token: state.user.idToken,
              },
              requestBody: {
                oauthCode: code,
                emailId: emailId,
                oauthProvider: provider,
                type: logIntype === 'dsr' ? 'dsr' : 'connect',
                writeEmailPermission: '1',
                redirectUrl:
                  provider === 'Microsoft' ? redirectUri : google_redirectUri,
              },
            })
            .then((response) => {
              if (parseInt(response.status) !== 200) {
                setErr(response.data.reasonCode + ', Please try again.');
                setIsErr(true);
              }
              localStorage.removeItem('permissionFor');
              localStorage.removeItem('loginType');
              if (logIntype === 'dsr') {
                localStorage.setItem('GetEmailPermission', true);
                window.close();
              }
              return response;
            })
            .catch((error) => {
              setLoaded(true);
              localStorage.setItem('oauthFail', true);
              window.close();
              setIsErr(true);
            });

          if (logIntype !== 'dsr' && response && response.status === 200) {
            localStorage.removeItem('routeReload');
            history.push(destination);
          }
        } else if (error === 'access_denied') {
          localStorage.setItem('oauthFail', true);
          window.close();
          setIsErr(true);
        } else {
          setLoaded(false);
        }
      }
    };
    if (!loaded) {
      effect();
    }
  }, [loaded, provider, dispatch, state, history, destination]);

  if (error) {
    return (
      <SmallPageWrapper>
        <div style={divStyle}>
          <Typography variant="h5" gutterBottom>
            There was an error connecting to your {provider} account.
          </Typography>
          <Typography paragraph>{error.errorDescription}</Typography>
        </div>
      </SmallPageWrapper>
    );
  }
  return (
    <>
      {isErr === true ? (
        <SmallPageWrapper>
          <div style={divStyle}>
            <Typography variant="h5" gutterBottom>
              There was an error connecting to your {provider} account.
            </Typography>
            <Typography paragraph>{err}</Typography>
          </div>
        </SmallPageWrapper>
      ) : null}

      <div style={{ textAlign: 'center', paddingTop: '20%' }}>
        <CircularProgress />
        <span style={{ width: '100%', float: 'left' }}>
          Please wait while processing...
        </span>
      </div>
    </>
  );
}
