//import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from 'components/misc/Buttons';
import { useEffect, useState, useContext } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { Box, CardHeader } from '@material-ui/core';
import { Context } from 'store';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { axiosServiceInstance } from 'config/axiosConfig';
import CircularProgress from '@material-ui/core/CircularProgress';

const statusHeading = {
  fontSize: '1rem',
  lineHeight: 2.33,
  opacity: 0.7,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
  fontFamily: 'Roboto',
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function MyDataCarousal({
  status,
  carousaltitle,
  domaintotal,
  selectedrenderdedcardContent,
}) {
  const history = useHistory();
  const [geterror, seterror] = useState(true);
  const [state, dispatch] = useContext(Context);
  const [getRecState, setRecState] = useState(state.myRecomendedDataList || []);
  const [loaded, setLoaded] = useState(false);
  const [getState, setState] = useState({});
  const [getLastKey, setLastKey] = useState('');

  const RecDomainArray = [];

  useEffect(() => {});

  //Get all Next Domain Ends Here
  async function NextRecommendedDomain(domainstatus) {
    try {
      const dropDownData = await axiosServiceInstance
        .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
        })
        .catch((error) => {
          setLoaded(true);
          setState([]);
        });

      //Get the recommended domains:
      const myRecomendedDataList = await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/dashboard/_get`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: domainstatus,
            emailId:
              (state.dashboardData || []).emailId ||
              dropDownData.data.responseListObject[0].emailId,
            parsedEmailDataId: state.getlastkey || null,
          },
        })
        .catch((error) => {
          setLoaded(true);
          setState([]);
        });
      if (domainstatus === 'recommended') {
        if (((myRecomendedDataList || []).data || []).lastKey !== null) {
          // Store the last key in the redux stte / localstorage
          dispatch({
            type: 'Last_Key',
            payload:
              ((myRecomendedDataList || []).data || []).lastKey === null
                ? ''
                : ((myRecomendedDataList || []).data || []).lastKey,
          });
          setLastKey(
            ((myRecomendedDataList || []).data || []).lastKey === null
              ? ''
              : ((myRecomendedDataList || []).data || []).lastKey
          );
        }
        RecDomainArray = getRecState.concat(
          myRecomendedDataList.data.responseListObject
        );

        dispatch({
          type: 'MY_Recommended_DATA',
          payload: RecDomainArray === undefined ? [] : RecDomainArray || [],
        });
        setRecState(RecDomainArray === undefined ? [] : RecDomainArray || []);
      } else {
        if (((myRecomendedDataList || []).data || []).lastKey !== null) {
          // Store the last key in the redux stte / localstorage
          dispatch({
            type: 'NeededDomain_Last_Key',
            payload:
              ((myRecomendedDataList || []).data || []).lastKey === null
                ? ''
                : ((myRecomendedDataList || []).data || []).lastKey,
          });
        }
        RecDomainArray = state.myNeededDataDetails.concat(
          myRecomendedDataList.data.responseListObject
        );

        dispatch({
          type: 'MY_Needed_DATA',
          payload: RecDomainArray === undefined ? [] : RecDomainArray || [],
        });
      }
    } catch (e) {
      console.error('Unable to fetch domains:', e);

      if (e.status === 404) {
        setLoaded(true);
      }
    }
  }

  return (
    <>
      {domaintotal >= 1 && (
        <>
          <div style={statusHeading} className="Tab_flex mt-20">
            <p>
              <span class="socialhead pt-0">{carousaltitle || ''}</span>
            </p>
            <p class="socialhead pt-0">Total ({domaintotal || ''})</p>
          </div>

          <div className="CarouselSlider">
            <Carousel
              responsive={responsive}
              showArrows={true}
              swipeable={false}
              draggable={false}
              showDots={false}
              infinite={false}
              autoPlay={false}
              autoPlaySpeed={7000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              slidesToSlide={4}
              shouldResetAutoplay={false}
              afterChange={(previousSlide, { currentSlide, onMove }) => {
                if ((status = 'recommended')) {
                  if (currentSlide >= getRecState.length - 4) {
                    if (
                      state.getlastkey !== null &&
                      state.getlastkey !== undefined
                    ) {
                      NextRecommendedDomain(status);
                    }
                  }
                } else if ((status = 'needed')) {
                  if (currentSlide >= state.myNeededDataDetails.length - 4) {
                    if (
                      state.getNeededDomain_lastkey !== null &&
                      state.getNeededDomain_lastkey !== undefined
                    ) {
                      NextRecommendedDomain(status);
                    }
                  }
                } else {
                }
              }}
            >
              {selectedrenderdedcardContent}
            </Carousel>
          </div>
        </>
      )}
    </>
  );
}
