import { useContext } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Context } from '../store';
import SmallPageWrapper from '../components/wrapper/SmallPageWrapper';

const divStyle = {
  margin: '1rem 1.5rem',
};

function getProvider(email) {
  const domain = email.split('@')[1];
  const term = domain ? domain.toLowerCase() : '';
  switch (term) {
    case 'gmail.com':
      return { name: 'Gmail', domain: 'mail.google.com', provider: 'gmail' };

    case 'live.com':
    case 'hotmail.com':
      return {
        name: 'Outlook',
        domain: 'outlook.live.com',
        provider: 'outlook',
      };

    case 'yahoo.com':
      return { name: 'Yahoo!', domain: 'yahoo.com', provider: 'yahoo' };
    default:
      return { name: '', domain: '', provider: 'unknown' };
  }
}

export default function PlaceholderEmailConnectDialog() {
  const history = useHistory();
  const [state, dispatch] = useContext(Context); //eslint-disable-line no-unused-vars

  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get('email');
  const provider = getProvider(email);
  const providerName = provider ? provider.name : null;
  const emailAddressContent =
    email && email.indexOf('@') > 0 ? (
      <Typography paragraph>Email Address: {email}</Typography>
    ) : null;

  return (
    <SmallPageWrapper>
      <div style={divStyle}>
        <Typography variant="h5" gutterBottom>
          Are you sure you want to connect your {providerName} account?
        </Typography>
        {emailAddressContent}

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={() => {
                dispatch({
                  type: 'CONNECT_EMAIL_PROVIDER',
                  payload: {
                    ...provider,
                    emailAddress: email,
                  },
                });
                history.push('/dashboard');
              }}
            >
              Connect
            </Button>
          </Grid>
        </Grid>
      </div>
    </SmallPageWrapper>
  );
}
