import tw from 'twin.macro';
import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from 'components/misc/Buttons';
import { Context } from 'store';
import { axiosServiceInstance } from 'config/axiosConfig';
import Moment from 'moment';
//--------------------
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Card = tw.div`flex flex-col h-full bg-gray-200 rounded border-2`;
const CardLogo = tw.div`flex h-24 items-center justify-center overflow-hidden`;
const CardText = tw.div`flex-1 px-6 py-6`;
const CardTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;
const CardDescription = tw.p``;

const ButtonContainer = tw.div`flex justify-center`;
const CardActionButton = tw(PrimaryButton)`my-3 mx-auto`;
const CardActionSecondaryButton = tw(SecondaryButton)`my-3 mx-auto`;

const baseSize = 300;

export default function MyDataCard({
  index,
  id,
  name,
  domain,
  emailAddress,
  status,
  userDetail,
  lastEmailDate,
  contactEmail,
  alternateContactEmail,
  viewDetail,
  selectDatum,
  //pagechange,
  pageState,
  triggerParentUpdate,
  checked,
  disabled,
  NeededDomains,
}) {
  const history = useHistory();
  const [state, dispatch] = useContext(Context); //eslint-disable-line no-unused-vars
  const [geterror, seterror] = useState(true);
  //setSelectedDsrState
  const [SelectedDsrState, setSelectedDsrState] = useState([]);
  const [getRecState, setRecState] = useState(
    state.myRecommendedDataDetails || []
  );
  const [setNeededDomainschild, NeededDomainschild] = useState(NeededDomains);
  //const [loading, setLodaing] = useState(true);
  // const [getpage, setpage] = useState(pagechange);
  const dpi = window.devicePixelRatio;
  const size = baseSize * dpi;
  let selectedDsrArr = [];
  const onError = () => {
    document.getElementById(index).style.display = 'block';
    seterror(false);
  };

  const wordWrapStyle = {
    wordWrap: 'break-word',
  };
  const customId = 'custom-id-Added';

  useEffect(() => {}, [SelectedDsrState, setRecState]);

  async function MoveToSelectedDsr(eve) {
    if (eve.currentTarget.checked == false) {
      var selectedselectRecDomain = state.mySelectedDataDetails.filter(
        function (e) {
          return e.domainName == eve.target.id;
        }
      );

      //dsrRecommendation

      const list = state.mySelectedDataDetails.filter(
        (item) => item.domainName !== eve.target.id
      );

      dispatch({
        type: 'MY_SELECTED_DATA',
        payload: list === undefined ? [] : list || [],
      });
      // state.myRecommendedDataDetails.push(list);
      if (selectedselectRecDomain[0].dsrRecommendation === 1) {
        state.myRecommendedDataDetails.push(selectedselectRecDomain[0]);
        dispatch({
          type: 'MY_Recommended_DATA',
          payload:
            state.myRecommendedDataDetails === undefined
              ? []
              : state.myRecommendedDataDetails || [],
        });
      } else {
        state.myDataDetails.push(selectedselectRecDomain[0]);
        dispatch({
          type: 'MY_DATA',
          payload:
            state.myDataDetails === undefined ? [] : state.myDataDetails || [],
        });
        /* push back date wise card */
        var curdate = new Date();
        var datebeforesix = new Date();
        var date = new Date();
        datebeforesix.setMonth(curdate.getMonth() - 1);
        const dateLimitSixMonth = Moment(datebeforesix).format(
          'YYYY-MM-DDTHH:mm:ssZ'
        );

        curdate.setMonth(curdate.getMonth() - 6);
        var dateLimitSixMonthpresent = Moment(curdate).format(
          'YYYY-MM-DDTHH:mm:ssZ'
        );
        date.setFullYear(curdate.getFullYear() - 1);
        var dateLimitOneYear = Moment(date).format('YYYY-MM-DDTHH:mm:ssZ');

        if (selectedselectRecDomain[0].firstEmailDate > dateLimitSixMonth) {
          state.domainsBeforeMonth.push(selectedselectRecDomain[0]);
          dispatch({
            type: 'Domains_Before_Month',
            payload:
              state.domainsBeforeMonth === undefined
                ? []
                : state.domainsBeforeMonth || [],
          });

          state.domainsBeforesixMonth.push(selectedselectRecDomain[0]);
          dispatch({
            type: 'Domains_Before_Six_Month',
            payload:
              state.domainsBeforesixMonth === undefined
                ? []
                : state.domainsBeforesixMonth || [],
          });

          state.domainsBeforetwelveMonth.push(selectedselectRecDomain[0]);
          dispatch({
            type: 'Domains_Before_Twelve_Month',
            payload:
              state.domainsBeforetwelveMonth === undefined
                ? []
                : state.domainsBeforetwelveMonth || [],
          });
          if (state.active_tab_no === 2 || state.active_tab_no === 0) {
            dispatch({
              type: 'Active_tab_count',
              payload: state.active_tab_cnt + 1,
            });
          }
        } else if (
          selectedselectRecDomain[0].firstEmailDate > dateLimitSixMonthpresent
        ) {
          state.domainsBeforesixMonth.push(selectedselectRecDomain[0]);
          dispatch({
            type: 'Domains_Before_Six_Month',
            payload:
              state.domainsBeforesixMonth === undefined
                ? []
                : state.domainsBeforesixMonth || [],
          });

          state.domainsBeforetwelveMonth.push(selectedselectRecDomain[0]);
          dispatch({
            type: 'Domains_Before_Twelve_Month',
            payload:
              state.domainsBeforetwelveMonth === undefined
                ? []
                : state.domainsBeforetwelveMonth || [],
          });
          if (state.active_tab_no === 3 || state.active_tab_no === 0) {
            dispatch({
              type: 'Active_tab_count',
              payload: state.active_tab_cnt + 1,
            });
          }
        } else if (
          selectedselectRecDomain[0].firstEmailDate > dateLimitOneYear
        ) {
          state.domainsBeforetwelveMonth.push(selectedselectRecDomain[0]);
          dispatch({
            type: 'Domains_Before_Twelve_Month',
            payload:
              state.domainsBeforetwelveMonth === undefined
                ? []
                : state.domainsBeforetwelveMonth || [],
          });
          if (state.active_tab_no === 4 || state.active_tab_no === 0) {
            dispatch({
              type: 'Active_tab_count',
              payload: state.active_tab_cnt + 1,
            });
          }
        } else {
          if (state.active_tab_no === 0) {
            dispatch({
              type: 'Active_tab_count',
              payload: state.active_tab_cnt + 1,
            });
          }
        }

        /* */
      }
    } else {
      if (state.mySelectedDataDetails !== undefined) {
        state.mySelectedDataDetails.map((domainobj) =>
          selectedDsrArr.push(domainobj)
        );
      }
      var selectedFromAllDomain = state.myDataDetails.filter(function (e) {
        return e.domainName == eve.target.id;
      });
      if (selectedFromAllDomain.length <= 0) {
        var selectedFromRecDomain = state.myRecommendedDataDetails.filter(
          function (e) {
            return e.domainName == eve.target.id;
          }
        );
        selectedDsrArr.push(selectedFromRecDomain[0]);
        const list = state.myRecommendedDataDetails.filter(
          (item) => item.domainName !== eve.target.id
        );

        // setRecState(list === undefined ? [] : list || []);
        dispatch({
          type: 'MY_SELECTED_DATA',
          payload: selectedDsrArr === undefined ? [] : selectedDsrArr || [],
        });
        setSelectedDsrState(
          selectedDsrArr === undefined ? [] : selectedDsrArr || []
        );
        dispatch({
          type: 'MY_Recommended_DATA',
          payload: list === undefined ? [] : list || [],
        });
      } else {
        selectedDsrArr.push(selectedFromAllDomain[0]);
        const list = state.myDataDetails.filter(
          (item) => item.domainName !== eve.target.id
        );
        dispatch({
          type: 'MY_DATA',
          payload: list === undefined ? [] : list || [],
        });
        /*Remove Card from all slider */
        const listn = state.domainsBeforeMonth.filter(
          (item) => item.domainName !== eve.target.id
        );
        dispatch({
          type: 'Domains_Before_Month',
          payload: listn === undefined ? [] : listn || [],
        });
        const list1 = state.domainsBeforesixMonth.filter(
          (item) => item.domainName !== eve.target.id
        );
        dispatch({
          type: 'Domains_Before_Six_Month',
          payload: list1 === undefined ? [] : list1 || [],
        });
        const list2 = state.domainsBeforetwelveMonth.filter(
          (item) => item.domainName !== eve.target.id
        );
        dispatch({
          type: 'Domains_Before_Twelve_Month',
          payload: list2 === undefined ? [] : list2 || [],
        });
        /*
        if(state.active_tab_no === 2){
          const list = state.domainsBeforeMonth.filter(
            (item) => item.domainName !== eve.target.id
          );
          dispatch({
            type: 'Domains_Before_Month',
            payload: list === undefined ? [] : list || [],
          });
        }
        else if(state.active_tab_no === 3){
          const list1 = state.domainsBeforesixMonth.filter(
            (item) => item.domainName !== eve.target.id
          );
          dispatch({
            type: 'Domains_Before_Six_Month',
            payload: list1 === undefined ? [] : list1 || [],
          });
        }
        else if(state.active_tab_no === 4){
          const list2 = state.domainsBeforetwelveMonth.filter(
            (item) => item.domainName !== eve.target.id
          );
          dispatch({
            type: 'Domains_Before_Twelve_Month',
            payload: list2 === undefined ? [] : list2 || [],
          });
        
        }
        */
        // setState(list === undefined ? [] : list || []);
        dispatch({
          type: 'MY_SELECTED_DATA',
          payload: selectedDsrArr === undefined ? [] : selectedDsrArr || [],
        });
        dispatch({
          type: 'Active_tab_count',
          payload: state.active_tab_cnt - 1,
        });
        setSelectedDsrState(
          selectedDsrArr === undefined ? [] : selectedDsrArr || []
        );
      }
    }
  }
  async function udateStatusNeeded(domainname, status) {
    try {
      await axiosServiceInstance
        .post(`/pieeye-pfmdomain/api/domainStatus/_update`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
          requestBody: {
            status: status,
            emailId: (state.dashboardData || []).emailId,
            domainName: domainname,
          },
        })

        .catch((e) => {
          console.error(e);
        });
      dispatch({
        type: 'Update_Needed_DATA',
        payload: true,
      });
      dispatch({
        type: 'Active_tab_count',
        payload: state.active_tab_cnt - 1,
      });

      if (status === 'needed') {
        toast.success('Domain marked as needed.', {
          toastId: customId,
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      }
      //history.go(0);
      //history.goBack();
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <>
      <Card className="group" initial="rest" whileHover="hover" animate="rest">
        <div id="cardCheckWrapper">
          {status === 'needed' ? (
            ''
          ) : (
            <input
              type="checkbox"
              checked={checked}
              name={domain}
              id={domain}
              className="DsrCardCheckbox"
              disabled={disabled}
              onClick={MoveToSelectedDsr}
            />
          )}
        </div>
        <CardLogo id="DataLogo">
          {geterror ? (
            <img
              id={index}
              src={`//logo.clearbit.com/${domain}?size=${size}`}
              alt=""
              onError={onError}
            />
          ) : (
            <h1 className="imgid" id="card1">
              {domain.charAt(0).toUpperCase()}
            </h1>
          )}
        </CardLogo>
        <CardText>
          <CardTitle>{name}</CardTitle>
          <CardDescription id="DataDomain" style={wordWrapStyle}>
            {domain}
          </CardDescription>
        </CardText>
        <CardActionSecondaryButton
          className="ViewDetails"
          onClick={() => {
            var Name = userDetail.name || '';
            var payload = {
              index: index,
              id: id,
              name: Name,
              domain: domain,
              emailAddress: emailAddress,
              selectDatu: selectDatum,
              lastEmailDate: lastEmailDate,
              contactEmail: contactEmail,
              alternateContactEmail: alternateContactEmail,
            };
            dispatch({
              type: 'CARD_DETAIL_FOR_VIEW_TEMPLATE',
              payload: payload,
            });
            dispatch({
              type: 'VIEW_DETAIL',
              payload: viewDetail,
            });

            selectDatum(viewDetail);
            // history.push(`/my-data?id=${id}`);
            history.push(localStorage.getItem('AllDomainPageUrl'));
          }}
        >
          View Details
        </CardActionSecondaryButton>
        <ButtonContainer id="DataFooterButton">
          <CardActionButton
            className={
              status === 'initiated' || status === 'failed'
                ? 'disablereqbtn'
                : ''
            }
            onClick={() => {
              var Name = userDetail.name || '';
              var payload = {
                index: index,
                id: id,
                name: Name,
                domain: domain,
                emailAddress: emailAddress,
                selectDatu: selectDatum,
                lastEmailDate: lastEmailDate,
                contactEmail: contactEmail,
                alternateContactEmail: alternateContactEmail,
              };
              dispatch({
                type: 'CARD_DETAIL_FOR_VIEW_TEMPLATE',
                payload: payload,
              });
              if (state.mySelectedDataDetails !== undefined) {
                const list = state.mySelectedDataDetails.filter(
                  (item) => item.domainName !== domain
                );

                dispatch({
                  type: 'MY_SELECTED_DATA',
                  payload: list === undefined ? [] : list || [],
                });
              }
              history.push(`/request-deletion?id=${id}`);
            }}
          >
            {status === 'initiated'
              ? 'DSR Raised'
              : status === 'failed'
              ? 'DSR Failed'
              : 'Request Deletion'}
          </CardActionButton>
          <CardActionButton
            className={
              status === 'initiated' || status === 'failed'
                ? 'disablereqbtn'
                : ''
            }
            onClick={() => {
              udateStatusNeeded(
                domain,
                status === 'needed' ? 'pending' : 'needed'
              );
              if (state.mySelectedDataDetails !== undefined) {
                const list = state.mySelectedDataDetails.filter(
                  (item) => item.domainName !== domain
                );

                dispatch({
                  type: 'MY_SELECTED_DATA',
                  payload: list === undefined ? [] : list || [],
                });
              }
              if (state.DomainSearchDataDetails !== undefined) {
                const searchlist = state.DomainSearchDataDetails.filter(
                  (item) => (item || {}).domainName !== domain
                );

                dispatch({
                  type: 'Domain_Search_DATA',
                  payload: searchlist === undefined ? [] : searchlist || [],
                });
              }
            }}
          >
            {status === 'needed' ? 'Remove' : 'Mark As Needed'}
          </CardActionButton>
        </ButtonContainer>
      </Card>
    </>
  );
}
