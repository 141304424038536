import { sites } from './data/sites';
import createUUID from './util/uuid';

const Reducer = (state, action) => {
  switch (action.type) {
    case 'USER_SIGN_IN':
      localStorage.setItem('myId', action.payload.idToken);
      return {
        ...state,
        user: {
          username: action.payload.username,
          name: action.payload.name,
          email: action.payload.email,
          accessToken: action.payload.accessToken,
          idToken: action.payload.idToken,
          refreshToken: action.payload.refreshToken,
        },
      };
    case 'USER_SIGN_UP':
      return {
        ...state,
        user: {
          username: action.payload.username,
          name: action.payload.name,
          email: action.payload.email,
        },
      };

    case 'MICROSOFT_SIGN_IN':
      // TODO: Can we store the tokens in the cognito user?
      return {
        ...state,
        microsoftUser: {
          username: action.payload.username,
          name: action.payload.name,
          email: action.payload.email,
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
        },
      };

    // Below are fixtures that were used in prototyping. They can be modified to address their usage.
    case 'CONNECT_EMAIL_PROVIDER':
      const myData = randomData(action.payload.emailAddress);
      return {
        ...state,
        connectedAccounts: state.connectedAccounts.concat(action.payload),
        myData: state.myData.concat([...myData]),
      };

    case 'DISCONNECT_EMAIL_PROVIDER':
      const connectedAccounts = state.connectedAccounts.filter(
        (account) => account.emailAddress !== action.payload.emailAddress
      );

      const filteredData = state.myData.filter(
        (data) => data.emailAddress !== action.payload.emailAddress
      );

      return {
        ...state,
        connectedAccounts,
        myData: filteredData,
      };

    case 'CREATE_DATA_SUBJECT_REQUEST':
      return {
        ...state,
        dataSubjectRequests: state.dataSubjectRequests.concat(action.payload),
      };

    case 'DASHBOARD_DATA':
      return {
        ...state,
        dashboardData: action.payload,
      };

    case 'MY_DATA':
      return {
        ...state,
        myDataDetails: action.payload,
      };
    case 'MY_SELECTED_DATA':
      return {
        ...state,
        mySelectedDataDetails: action.payload,
      };
    case 'Pending_TILE_DATA':
      return {
        ...state,
        pendingtiledomainlist: action.payload,
      };
    case 'Reload_Domain_Table':
      return {
        ...state,
        reloadDomainTable: action.payload,
      };
    case 'No_Search_Result':
      return {
        ...state,
        noSearchresult: action.payload,
      };
    case 'Selected_Row_Count':
      return {
        ...state,
        selectedRowCount: action.payload,
      };
    case 'MY_Recommended_DATA':
      return {
        ...state,
        myRecommendedDataDetails: action.payload,
      };
    case 'Domains_Before_Month':
      return {
        ...state,
        domainsBeforeMonth: action.payload,
      };
    case 'Domains_Before_Six_Month':
      return {
        ...state,
        domainsBeforesixMonth: action.payload,
      };
    case 'Domains_Before_Twelve_Month':
      return {
        ...state,
        domainsBeforetwelveMonth: action.payload,
      };
    case 'NeededDomain_Last_Key':
      return {
        ...state,
        getNeededDomain_lastkey: action.payload,
      };
    case 'MY_Needed_DATA':
      return {
        ...state,
        myNeededDataDetails: action.payload,
      };
    case 'Update_Needed_DATA':
      return {
        ...state,
        updateNeededData: action.payload,
      };
    case 'Account_Disconnected':
      return {
        ...state,
        accountdisconnected: action.payload,
      };
    case 'User_Search_DATA':
      return {
        ...state,
        mySearchDataDetails: action.payload,
      };
    case 'Domain_Search_DATA':
      return {
        ...state,
        DomainSearchDataDetails: action.payload,
      };
    case 'Last_Key':
      return {
        ...state,
        getlastkey: action.payload,
      };
    case 'Active_tab_count':
      return {
        ...state,
        active_tab_cnt: action.payload,
      };
    case 'Active_tab_no':
      return {
        ...state,
        active_tab_no: action.payload,
      };
    case 'AllDomain_Last_Key':
      return {
        ...state,
        getAllDomain_lastkey: action.payload,
      };

    case 'VIEW_TEMPLATE':
      return {
        ...state,
        viewTemplateHtml: action.payload,
      };

    case 'CARD_DETAIL_FOR_VIEW_TEMPLATE':
      return {
        ...state,
        domainDataForViewTemplate: action.payload,
      };

    case 'MY_DSRS':
      return {
        ...state,
        myDsrs: action.payload,
      };

    case 'VIEW_DETAIL':
      return {
        ...state,
        viewDetail: action.payload,
      };

    case 'ALL_PROVIDER':
      return {
        ...state,
        allProvider: action.payload,
      };

    case 'GOOGLE':
      return {
        ...state,
        google: action.payload,
      };

    case 'MICROSOFT':
      return {
        ...state,
        microsoft: action.payload,
      };

    case 'YAHOO':
      return {
        ...state,
        yahoo: action.payload,
      };

    case 'DASHBOARD_DATA_COUNT':
      return {
        ...state,
        dashboardDataCount: action.payload,
      };
    case 'ACC_Refresh':
      return {
        ...state,
        accRefresh: action.payload,
      };

    case 'ACC_Switch':
      return {
        ...state,
        accSwitch: action.payload,
      };
    case 'Reload_Domain_Tile':
      return {
        ...state,
        reloadDomainTile: action.payload,
      };

    case 'NO_ACCOUNT_FOUND':
      return {
        ...state,
        noAccountFound: action.payload,
      };

    case 'AG_DATA_LIST':
      return {
        ...state,
        agDataList: action.payload,
      };

    case 'Progress_Bar':
      return {
        ...state,
        progressData: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;

// POC functions
// TODO: Remove
function randomData(emailAddress) {
  const result = [];
  const step = 5;
  let index = randomIntFromInterval(0, step);

  while (index < sites.length) {
    const site = sites[index];
    const emails = [];

    const emailCount = randomIntFromInterval(1, 10);
    for (let i = 0; i < emailCount; i++) {
      emails.push(randomEmail(site));
    }

    result.push({
      ...site,
      id: createUUID(),
      emailAddress,
      emails,
    });

    index = randomIntFromInterval(index + 1, index + step);
  }

  return result;
}

function randomEmail({ domain, name }) {
  const subjects = [
    `Welcome to ${name}`,
    `Your ${name} account`,
    `${name} order confirmation`,
    `Password reset`,
    `Your ${name} reciept`,
  ];

  return {
    from: `no-reply@${domain}`,
    subject: subjects[randomIntFromInterval(0, subjects.length - 1)],
    date: new Date(
      randomIntFromInterval(2005, 2021),
      randomIntFromInterval(1, 12),
      randomIntFromInterval(1, 28)
    ),
  };
}

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
