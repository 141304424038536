import React from 'react';
import { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { useHistory } from 'react-router-dom';
import Popup from 'views/Popup';
import useAnimatedNavToggler from '../../helpers/useAnimatedNavToggler.js';

import { Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../../images/Figma_LogoPFM.svg';
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import { Context } from '../../store';
import Login from 'views/Login.js';
import CreateAccount from 'views/CreateAccount.js';
import UserPopper from './full/UserPopper.js';
const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
   hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center border-b-0 ml-0!`};

  img {
    ${tw`w-4 mr-3`}
  }
`;

export const ImageLink = styled(NavLink)`
  ${tw`border-b-0`};

  img {
    ${tw`inline w-6`}
  }
  span {
    ${tw`lg:hidden ml-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function LightHeader({
  roundedHeaderButton = false,
  logoLink,
  links,
  mobileLinks,
  className,
  collapseBreakpointClass = 'lg',
}) {
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const [loginPopup, setLoginPopup] = useState(false);
  const [accountPopup, setAccountPopup] = useState(false);
  const history = useHistory();

  let isMobile = window.matchMedia('(max-width: 768px)').matches || false;
  // alert(isMobile)

  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const defaultLinks = [
    <NavLinks key={1}>
      <PrimaryLink
        id="LoginNavLink"
        //href="/login"
        tw="lg:ml-12!"
        onClick={() => {
          setLoginPopup(true);
          var x = document.getElementsByTagName('BODY')[0];
          x.style.overflow = 'hidden';
        }}
      >
        Login
      </PrimaryLink>
      <PrimaryLink
        id="accountPopup"
        css={roundedHeaderButton && tw`rounded-lg bg-[#E25668]`}
        onClick={(e) => {
          e.preventDefault();
          setAccountPopup(true);
          var x = document.getElementsByTagName('BODY')[0];
          x.style.overflow = 'hidden';
          //history.push('/email');
        }}
      >
        Get Started FREE
      </PrimaryLink>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  function setAccount(value) {
    setAccountPopup(value);
  }
  function setLogin(value) {
    setLoginPopup(value);
  }

  const defaultLogoLink = (
    <LogoLink
      href="/"
      className="BrandLogo"
      onClick={(e) => {
        e.preventDefault();

        if (state.user.username) {
          history.push('/dashboard');
        } else {
          history.push('/');
        }
      }}
    >
      <img src={logo} alt="logo" />
      <span className="MobileView">Privacy For Me</span>
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;
  mobileLinks = mobileLinks || defaultLinks;

  return (
    <>
      <Backdrop className={classes.backdrop} open={loginPopup || accountPopup}>
        {loginPopup && <Login setLogin={setLogin} setAccount={setAccount} />}
        {accountPopup && (
          <CreateAccount setLogin={setLogin} setAccount={setAccount} />
        )}
      </Backdrop>
      {/* <Header className={className || 'header-light'}>
        <Popup /> */}
      <Header className={className || 'header-light'}>
        <Popup />
        {/* //Add condition according to screen width then show Desktop and mobile view */}
        {!isMobile && (
          <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
            {logoLink}
            {links}
          </DesktopNavLinks>
        )}

        {isMobile && (
          <MobileNavLinksContainer
            css={collapseBreakpointCss.mobileNavLinksContainer}
          >
            {logoLink}
            {state.user.name && <UserPopper />}
            {/* <MobileNavLinks
          id="mobileLinks"
          initial={{ x: '150%', display: 'none' }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {mobileLinks}
        </MobileNavLinks>
        <NavToggle
          id="mobileLinksCross"
          onClick={toggleNavbar}
          className={showNavLinks ? 'open' : 'closed'}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" id="positionFixed" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle> */}
          </MobileNavLinksContainer>
        )}
      </Header>
    </>
  );
}

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
