import { useState, useContext, useEffect, memo } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Popper } from '@material-ui/core';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import tw from 'twin.macro';

import { axiosServiceInstance } from 'config/axiosConfig';

import { ImageLink } from 'components/headers/light.js';
import ReferFriendImage from 'images/Figma_Refer.svg';
import ReferFriendImage1 from 'images/Figma_N_ReferFd.svg';
import CustomizeIconImage from 'images/Figma_N-Account.svg';
import UserIconImage from 'images/Figma_User.svg';
import UserSignoutIconImage from 'images/Figma_N_Logout.svg';
import FeedbackIcon from 'images/Figma_N_Feedback.svg';
import FeedbackNIcon from 'images/Figma_Feedback.svg';
import GoogleUser from 'images/Figma_N_Google.svg';
import MicrosoftUser from 'images/Figma_N_Microsoft.svg';
import MicrosoftLogin from 'images/Figma_MicrosoftLogin.svg';
import GoogleLogin from 'images/Figma_GoogleLogin.svg';
import arrow from 'images/arrow.svg';

import { Context } from 'store';

const PopWrapper = tw.div`p-1 my-2 rounded border border-gray-500 `;
const NavItem = tw.button`block my-1 py-2 px-3 text-primary-500 whitespace-nowrap rounded text-left`;
const Icon = tw.img`inline-block mr-2 w-3`;

export default memo(function UserPopper() {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [anchorEl, setAnchorEl] = useState(null);

  const [detailsProvider, setDetailsProvider] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showAccount, setShowAccount] = useState('');

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const nameContent = state.user.name;
  var showLogo = '';
  var switchMailId = [];
  var selectedMail = [];

  useEffect(() => {
    if (!isLoading && state.user.username) {
      _getProvider();
    }
    if (showLogo !== '' || localStorage.getItem('selected_account')) {
      setShowAccount(showLogo);
    }
    if (state.accSwitch === true || state.accountdisconnected === true) {
      _getProvider();
      dispatch({
        type: 'ACC_Switch',
        payload: false,
      });
      dispatch({
        type: 'Account_Disconnected',
        payload: false,
      });
    }
  });

  var allProviders;
  async function _getProvider() {
    if (
      (state.dashboardData || []).dropDownData === undefined ||
      state.accSwitch
    ) {
      setLoading(true);
      let dropdownEmailId = localStorage.getItem('updatedEmailId');
      const providerDetails = await axiosServiceInstance
        .post(`/pieeye-pfmuser/api/dashboard/_getConnection`, {
          requestInfo: {
            userName: state.user.username || '',
            token: state.user.idToken || '',
          },
        })
        .catch((error) => {
          dispatch({
            type: 'NO_ACCOUNT_FOUND',
            payload: true,
          });
        });

      allProviders =
        ((providerDetails || []).data || []).responseListObject || '';
      if (allProviders.length) {
        dispatch({
          type: 'NO_ACCOUNT_FOUND',
          payload: false,
        });
      }
      setDetailsProvider(allProviders);
      dispatch({
        type: 'DASHBOARD_DATA',
        payload: {
          dropDownData:
            providerDetails === undefined
              ? []
              : (providerDetails || []).data.responseListObject,
          emailId:
            dropdownEmailId === null ||
            dropdownEmailId === 0 ||
            dropdownEmailId === undefined
              ? (providerDetails || []).data || [].responseListObject
                ? (providerDetails || []).data.responseListObject[0].emailId
                : ''
              : dropdownEmailId,
        },
      });
    } else {
      setDetailsProvider((state.dashboardData || []).dropDownData);
    }
  }

  switchMailId = (
    detailsProvider === undefined ? state.allProvider : detailsProvider || []
  ).filter((x) => {
    if (
      x.emailId !==
      ((state.dashboardData || []).emailId === undefined
        ? state.user.email
        : (state.dashboardData || []).emailId)
    ) {
      return x;
    }
  });

  selectedMail = (
    detailsProvider === undefined ? state.allProvider : detailsProvider || []
  ).filter((x) => {
    if (
      x.emailId ===
      ((state.dashboardData || []).emailId === undefined
        ? state.user.email
        : (state.dashboardData || []).emailId)
    ) {
      return x;
    }
  });
  showLogo = localStorage.getItem('selected_account')
    ? localStorage.getItem('selected_account')
    : (selectedMail[0] || []).emailType;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <ImageLink
          style={{ cursor: 'pointer' }}
          className={
            'NewNavLink MobileMr ' +
            (window.location.pathname === '/userFeedback' ? 'PageActive' : '')
          }
          onClick={(e) => {
            e.preventDefault();
            history.push('/userFeedback');
          }}
        >
          <Icon src={FeedbackNIcon} alt="FeedbackIcon" />
          Feedback
        </ImageLink>
        <ImageLink
          style={{ cursor: 'pointer' }}
          className={
            'NewNavLink MobileMr ' +
            (window.location.pathname === '/referAFriend' ? 'PageActive' : '')
          }
          onClick={(e) => {
            e.preventDefault();
            history.push('/referAFriend');
          }}
        >
          <Icon src={ReferFriendImage} alt="ReferFriendImage" />
          Refer A Friend
        </ImageLink>

        <ImageLink
          className="NewNavLink DarkNavLink"
          href="#"
          onClick={handleClick}
        >
          <span className="MobileView">
            {showAccount === 'Google' ? (
              <Icon
                src={GoogleLogin}
                alt="GoogleImage"
                className="LoginUserAccount"
              />
            ) : showAccount === 'Microsoft' ? (
              <Icon
                src={MicrosoftLogin}
                alt="MicrisoftImage"
                className="LoginUserAccount"
              />
            ) : (
              ''
            )}
          </span>
          <Icon src={UserIconImage} alt="User" className="PFMUser" />
          <span className="MobileView">
            {' '}
            {nameContent} &nbsp;
            <Icon
              src={arrow}
              alt="arrow"
              className={!open ? 'ArrowIcon' : 'ArrowIconDown'}
            />
          </span>
        </ImageLink>

        {open ? (
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
          >
            <PopWrapper className="PFMNavOption">
              <NavItem
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/account');
                  handleClick();
                }}
              >
                <Icon src={CustomizeIconImage} alt="Settings" />
                Account Settings
              </NavItem>
              <NavItem
                onClick={(e) => {
                  e.preventDefault();
                  handleClick();
                  history.push('/userFeedback');
                }}
              >
                <Icon src={FeedbackIcon} alt="FeedbackIcon" />
                Feedback
              </NavItem>
              <NavItem
                onClick={(e) => {
                  e.preventDefault();
                  handleClick();
                  history.push('/referAFriend');
                }}
              >
                <Icon src={ReferFriendImage1} alt="ReferFriendImage" />
                Refer A Friend
              </NavItem>

              {detailsProvider.length > 1 && (
                <NavItem
                  onClick={(e) => {
                    localStorage.setItem(
                      'connect_provider',
                      (switchMailId[0] || []).emailType
                    );
                    localStorage.setItem(
                      'selected_account',
                      (switchMailId[0] || []).emailType
                    );
                    handleClick();
                    e.preventDefault();
                    localStorage.setItem(
                      'updatedEmailId',
                      (switchMailId[0] || []).emailId
                    );
                    setShowAccount((switchMailId[0] || []).emailType);
                    // window.location.reload();
                    if (document.getElementById('deselectAll') !== null) {
                      document.getElementById('deselectAll').click();
                    }
                    dispatch({
                      type: 'Pending_TILE_DATA',
                      payload: [],
                    });
                    dispatch({
                      type: 'DASHBOARD_DATA',
                      payload: [],
                    });
                    dispatch({
                      type: 'ACC_Switch',
                      payload: true,
                    });
                    dispatch({
                      type: 'Reload_Domain_Tile',
                      payload: true,
                    });
                    dispatch({
                      type: 'Selected_Row_Count',
                      payload: 0,
                    });
                    dispatch({
                      type: 'AG_DATA_LIST',
                      payload: [],
                    });
                    history.push('/dashboard');
                  }}
                >
                  {' '}
                  {showAccount === 'Google' ? (
                    <>
                      {' '}
                      <Icon src={MicrosoftUser} alt="ReferFriendImage" />
                      Switch to Microsoft
                    </>
                  ) : (
                    <>
                      <Icon src={GoogleUser} alt="ReferFriendImage" />
                      Switch to Google
                    </>
                  )}
                </NavItem>
              )}

              <NavItem
                onClick={(e) => {
                  e.preventDefault();
                  localStorage.removeItem('selected_account');
                  localStorage.removeItem('myId');
                  localStorage.removeItem('routeReload');
                  localStorage.removeItem('fromSignUp');
                  localStorage.removeItem('connect_provider');
                  localStorage.removeItem('selected_provider');
                  handleClick();
                  Auth.signOut();
                }}
              >
                <Icon src={UserSignoutIconImage} alt="Sign Out" />
                Logout
              </NavItem>
            </PopWrapper>
          </Popper>
        ) : null}
      </div>
    </ClickAwayListener>
  );
});
