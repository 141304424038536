import React, { useContext, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container as ContainerBase } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import illustration from 'images/login-illustration.svg';
import logo from 'images/logo-pfm.svg';
import { ReactComponent as LoginIcon } from 'feather-icons/dist/icons/log-in.svg';
import { Context } from '../store';
import GmailLoginButton from 'components/oauth/GmailLoginButton';
import MicrosoftLoginButton from 'components/oauth/MicrosoftLoginButton';
import SignInForm from 'components/oauth/SignInForm';
import Google_logo from 'images/google-cert.svg';
import Microsoft_logo from 'images/microsoft-cert.svg';

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`; //overflow-hidden
const Content = tw.div` max-w-screen-sm min-w-0 m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-xl flex justify-center flex-1`;
const MainContainer = tw.div`p-6`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-3xl text-[#334F7E] xl:text-3xl font-extrabold`;
const FormContainer = tw.div`flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
export const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`pt-4 leading-none px-2 inline-block text-sm text-[#757575] tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default function Login({
  logoLinkUrl = '#',
  illustrationImageSrc = illustration,
  headingText = 'Sign In To Privacy For Me',
  socialButtons = [],
  submitButtonText = 'Sign In',
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = '/forgot-password',
  signupUrl = '#',
  setLogin,
  setAccount,
}) {
  const history = useHistory();
  const [state, dispatch] = useContext(Context); //eslint-disable-line no-unused-vars

  useLayoutEffect(() => {
    if (state.user && state.user.idToken) {
      const destination = localStorage.getItem('routeReload') || '/dashboard';
      localStorage.removeItem('routeReload');
      history.push(destination);
    }
  }, [history, state]);

  return (
    <>
      {localStorage.getItem('myId') ? null : (
        // <AnimationRevealPage disabled>
        // <Container id="Login">  id="LoginContent"

        <Content className="LoginPopup">
          <MainContainer>
            <button
              type="button"
              style={{
                // marginLeft: "660px",
                marginRight: '-28%',
                float: 'right',
                fontSize: 'x-large',
                color: '#757575',
              }}
              onClick={() => {
                setLogin(false);
                var x = document.getElementsByTagName('BODY')[0];
                x.style.overflow = 'auto';
              }}
            >
              &times;
            </button>
            {/* <LogoLink href={logoLinkUrl}>
                  <LogoImage src={logo} />
                </LogoLink> */}
            <MainContent>
              <Heading>{headingText}</Heading>

              <FormContainer className="">
                <SocialButtonsContainer>
                  <GmailLoginButton text="Sign in with Google" />
                  <MicrosoftLoginButton text="Sign in with Microsoft" />
                  {/* <YahooLoginButton style={{display: 'none'}}text="Sign In With Yahoo" /> */}

                  {socialButtons.map((socialButton, index) => (
                    <SocialButton
                      key={index}
                      href={socialButton.url}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch({
                          type: 'CONNECT_EMAIL_PROVIDER',
                          payload: {
                            ...socialButton.provider,
                          },
                        });
                        history.push('/dashboard');
                      }}
                    >
                      <span className="iconContainer">
                        <img
                          src={socialButton.iconImageSrc}
                          className="icon"
                          alt=""
                        />
                      </span>
                      <span className="text">{socialButton.text}</span>
                    </SocialButton>
                  ))}
                </SocialButtonsContainer>
                <DividerTextContainer className="orrow">
                  <DividerText className="orflex">
                    <span className="orBorder"></span>
                    <span className="orspeacing">Or</span>
                    <span className="orBorder"></span>
                  </DividerText>
                </DividerTextContainer>
                <SignInForm setLogin={setLogin} setAccount={setAccount} />
                <p
                  tw="mt-6 text-sm font-medium	text-center"
                  style={{ color: '#5071A8' }}
                >
                  <a
                    href={forgotPasswordUrl}
                    tw="border-b border-gray-500 border-dotted"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push('/forgot-password');
                    }}
                  >
                    Forgot Password ?
                  </a>
                </p>
                <div className="CertifiedLogo">
                  <div className="flex">
                    <img
                      className="GoogleLogo"
                      alt="GoogleLogo"
                      src={Google_logo}
                      style={{
                        marginRight: '25px',
                        width: '124px',
                        height: '40px',
                      }}
                    />{' '}
                    <img
                      className="MicrosoftLogo"
                      alt="MicrosoftLogo"
                      style={{ width: '97px', height: '44px' }}
                      src={Microsoft_logo}
                    />
                  </div>
                </div>
                <p
                  tw="mt-8 mb-8 text-sm text-gray-600 text-center"
                  className="textColor"
                >
                  Dont have an account?{' '}
                  <a
                    style={{ color: '#334F7E', fontWeight: '500' }}
                    href={signupUrl}
                    tw="border-b border-gray-500 border-dotted"
                    onClick={(e) => {
                      e.preventDefault();
                      // history.push('/createAccount');
                      setAccount(true);
                      setLogin(false);
                    }}
                  >
                    Sign Up
                  </a>
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>
        </Content>
        //  </Container>
        //  </AnimationRevealPage>
      )}
    </>
  );
}
