import { useState } from 'react';
import tw from 'twin.macro';

import { ErrorMessage, StatusMessage } from 'components/misc/Message';
import SmallPageWrapper from 'components/wrapper/SmallPageWrapper';
import ForgotPasswordForm from 'components/account/ForgotPasswordForm';
import ResetPasswordForm from 'components/account/ResetPasswordForm';
import ResetLogin from 'components/account/ResetLogin';

export const Wrapper = tw.div`p-8`;

export default function ForgotPassword() {
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(false);
  const [state, setState] = useState('forgot');
  const [email, setEmail] = useState(null);

  const errorMessage = error ? <ErrorMessage>{error}</ErrorMessage> : null;
  const statusMessage = status ? <StatusMessage>{status}</StatusMessage> : null;

  function getForm(state) {
    if (state === 'forgot') {
      return (
        <ForgotPasswordForm
          setEmail={setEmail}
          setError={setError}
          setState={setState}
          setStatus={setStatus}
        />
      );
    }

    if (state === 'reset') {
      return (
        <ResetPasswordForm
          email={email}
          setError={setError}
          setState={setState}
          setStatus={setStatus}
        />
      );
    }

    if (state === 'complete') {
      return <ResetLogin />;
    }
  }

  return (
    <SmallPageWrapper>
      <Wrapper>
        {getForm(state)}
        {statusMessage}
        {errorMessage}
      </Wrapper>
    </SmallPageWrapper>
  );
}
