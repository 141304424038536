import { motion } from 'framer-motion';
import tw from 'twin.macro';

const Row = tw.div`p-4 text-center`;
const Button = tw.button`font-display font-medium text-white bg-[#2C3E5D] rounded-lg py-1 px-10 uppercase`;

export default function IntroButtonRow({ onClick, text, index }) {
  return (
    <motion.div
      key={index}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{
        duration: 0.25,
        delay: 1.25,
        type: 'tween',
        stiffness: 260,
        damping: 20,
      }}
    >
      <Row>
        <Button onClick={onClick}>{text}</Button>
      </Row>
    </motion.div>
  );
}
