import { useContext } from 'react';
import tw from 'twin.macro';
import SearchBar from 'material-ui-search-bar';
import { Context } from 'store';
import { Typography } from '@material-ui/core';
const Wrapper = tw.div``;

export default function HeadingText({ title, text }) {
  const [state] = useContext(Context); //eslint-disable-line no-unused-vars

  return (
    <Wrapper>
      <Typography variant="h4" gutterBottom id="GridHeading">
        {title}
        {/* Companies with your data */}
      </Typography>
      <Typography paragraph id="GridSubHeading">
        {text}
        {/* Here you can find a list of companies that have your data. Click Request
        Deletion to start a Data Subject Request. */}
      </Typography>
    </Wrapper>
  );
}
