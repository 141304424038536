import React from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import Header from 'components/headers/light.js';
import Footer from 'components/footers/MiniCenteredFooter.js';
import { SectionHeading } from 'components/misc/Headings';

const UserLink = tw.span`text-primary-500`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default function BugBoundry({
  headingText = 'Vulnerability Disclosure Policy',
}) {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>PrivacyFor.Me</p>

            <p>July, 2021</p>

            <h2>Introduction</h2>
            <p>
              PrivacyFor.Me is committed to ensuring the security of our users
              by protecting their information. This policy is intended to give
              security researchers clear guidelines for conducting vulnerability
              discovery activities and to convey our preferences in how to
              submit discovered vulnerabilities to us.
            </p>
            <p>
              This policy describes 
              <strong>what systems and types of research</strong> are covered
              under this policy, <strong>how to send us </strong>vulnerability
              reports, and<strong> how long</strong> we ask security researchers
              to wait before publicly disclosing vulnerabilities.{' '}
            </p>

            <p>
              We encourage you to contact us to report potential vulnerabilities
              in our systems.
            </p>
            <h2>Authorization</h2>

            <p>
              <strong>
                If you make a good faith effort to comply with this policy
                during your security research, we will consider your research to
                be authorized and we will work with you to understand and
                resolve the issue quickly, and PrivacyFor.Me will not recommend
                or pursue legal action related to your research. Should legal
                action be initiated by a third party against you for activities
                that were conducted in accordance with this policy, we will make
                this authorization known.
              </strong>
            </p>

            <h2>Guidelines</h2>

            <p>Under this policy, “research” means activities in which you:</p>
            <ul>
              <li>
                <p>
                  Notify us as soon as possible after you discover a real or
                  potential security issue.
                </p>
              </li>
              <li>
                <p>
                  Make every effort to avoid privacy violations, degradation of
                  user experience, disruption to production systems, and
                  destruction or manipulation of data.
                </p>
              </li>
              <li>
                <p>
                  Only use exploits to the extent necessary to confirm a
                  vulnerability’s presence. Do not use an exploit to compromise
                  or exfiltrate data, establish persistent command line access,
                  or use the exploit to pivot to other systems.
                </p>
              </li>
              <li>
                <p>
                  Provide us a reasonable amount of time to resolve the issue
                  before you disclose it publicly.
                </p>
              </li>
              <li>
                <p>Do not submit a high volume of low-quality reports.</p>
              </li>
            </ul>

            <p>
              {' '}
              Once you’ve established that a vulnerability exists or encounter
              any sensitive data (including personally identifiable information,
              financial information, or proprietary information or trade secrets
              of any party), 
              <strong>
                you must stop your test, notify us immediately, and not disclose
                this data to anyone else.
              </strong>
            </p>

            <h2>Test methods</h2>
            <p>The following test methods are not authorized:</p>
            <ul>
              <li>
                <p>
                  {' '}
                  Network denial of service (DoS or DDoS) tests or other tests
                  that impair access to or damage a system or data
                </p>
              </li>

              <li>
                <p>
                  Physical testing (e.g. office access, open doors, tailgating),
                  social engineering (e.g. phishing, vishing), or any other
                  non-technical vulnerability testing
                </p>
              </li>
            </ul>

            <h2>Scope</h2>
            <p>This policy applies to the following systems and services:</p>
            <ul>
              <li>
                <p>privacyfor.me</p>
              </li>
              <li>
                <p>
                  <UserLink>agency-service.gov</UserLink>, and the following
                  hostnames:
                </p>
                <ul id="bulletLeftMargin">
                  <li>
                    <p>
                      Any other subdomain of privacyfor.me and all customer
                      applications are excluded from this policy
                      (app.privacyfor.me is specifically excluded)
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  Source code at 
                  <a href="https://github.com/agency-example/repo">
                    <UserLink> https://github.com/agency-example/repo</UserLink>
                  </a>
                </p>
              </li>
            </ul>
            <p>
              <strong>
                Any service not expressly listed above, such as any connected
                services, are excluded from scope
              </strong>
               and are not authorized for testing. Additionally, vulnerabilities
              found in systems from our vendors fall outside of this policy’s
              scope and should be reported directly to the vendor according to
              their disclosure policy (if any). If you aren’t sure whether a
              system is in scope or not, contact us
              at privacy@privacyfor.me before starting your research.
            </p>

            <p>
              Though we develop and maintain other internet-accessible systems
              or services, we ask that active research and testing only be
              conducted on the systems and services covered by the scope of this
              document. If there is a particular system not in scope that you
              think merits testing, please contact us to discuss it first. We
              will increase the scope of this policy over time.
            </p>

            <h2>Reporting a vulnerability</h2>
            <p>
              <strong>
                We accept vulnerability reports at this form or
                via privacy@privacyfor.me.
              </strong>{' '}
              Reports may be submitted anonymously. If you share your contact
              information, we will acknowledge receipt of your report within 3
              business days.
            </p>
            <p>
              We do not support PGP-encrypted emails. For particularly sensitive
              information, submit through our HTTPS web form.
            </p>

            <h2>What we would like to see from you</h2>
            <p>
              In order to help us triage and prioritize submissions, we
              recommend that your reports:
            </p>
            <ul>
              <li>
                <p>
                  Describe the location the vulnerability was discovered and the
                  potential impact of exploitation.
                </p>
              </li>
              <li>
                <p>
                  Offer a detailed description of the steps needed to reproduce
                  the vulnerability (proof of concept scripts or screenshots are
                  helpful).
                </p>
              </li>
              <li>
                <p>Be in English, if possible.</p>
              </li>
            </ul>

            <h2>What you can expect from us</h2>
            <p>
              When you choose to share your contact information with us, we
              commit to coordinating with you as openly and as quickly as
              possible.
            </p>
            <ul>
              <li>
                <p>
                  Within 3 business days, we will acknowledge that your report
                  has been received.
                </p>
              </li>
              <li>
                <p>
                  To the best of our ability, we will confirm the existence of
                  the vulnerability to you and be as transparent as possible
                  about what steps we are taking during the remediation process,
                  including on issues or challenges that may delay resolution.
                </p>
              </li>
              <li>
                <p>We will maintain an open dialogue to discuss issues.</p>
              </li>
            </ul>
            <h2>Questions</h2>
            <p>
              Questions regarding this policy may be sent
              to privacy@privacyfor.me. We also invite you to contact us with
              suggestions for improving this policy.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
}
