import tw from 'twin.macro';
import styled from 'styled-components';
import './buttonnewStyle.css';

export const SocialLoginButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: #1d2739;
  }
  .iconContainer {
    background: unset;
  }
  button {
    background: #fff;
  }
  button:hover {
    background: rgba(247, 250, 252, var(--tw-bg-opacity));
  }
`;

export default function LoginButtonLink({ iconImageSrc, text, url, onClick }) {
  return (
    <SocialLoginButton href={url} onClick={onClick} id="SocialLoginBtn">
      <span className="iconContainer">
        <img src={iconImageSrc} className="icon" alt={text} />
      </span>
      <span className="text">{text}</span>
    </SocialLoginButton>
    // <div class="g-sign-in-button" href={url} onClick={onClick}>
    //   <div class="content-wrapper">
    //     <div class="logo-wrapper1">
    //       <img src={iconImageSrc} alt={text} />
    //     </div>
    //     <span class="text-container">
    //       <span>{text}</span>
    //     </span>
    //   </div>
    // </div>
  );
}
