import React, { createContext, useReducer } from 'react';
import Reducer from './reducer';

const initialState = {
  // Cognito User
  user: {},
  // Connected Google User (via direct oauth)
  googleUser: {},
  // Connected Microsoft User (via direct oauth)
  microsoftUser: {},

  // Below are fixtures that were used in prototyping. They can be modified to address their usage.
  connectedAccounts: [],
  myData: [],
  dataSubjectRequests: [],
  error: null,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
