import 'tailwindcss/dist/base.css';
import './App.css';
import { css } from 'styled-components/macro'; //eslint-disable-line
import 'tailwindcss/dist/base.css';

import AppRouter from './AppRouter';
import Store from './store';
import AppAuth from 'AppAuth';

function App() {
  return (
    <Store>
      <AppAuth>
        <AppRouter />
      </AppAuth>
    </Store>
  );
}

export default App;
