import { Chip } from '@material-ui/core';
import tw from 'twin.macro';
import { SecondaryButton } from 'components/misc/Buttons';
import { useEffect, useState, useContext } from 'react';
import { Context } from '../../store';
//import createUUID from '../../util/uuid';
import { useHistory } from 'react-router-dom';

const Card = tw.a`flex flex-col h-full bg-gray-200 rounded border-2`;
const CardLogo = tw.div`flex h-24 items-center justify-center overflow-hidden`;
const CardText = tw.div`flex-1 px-6 py-6`;
const CardTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;
const CardDescription = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const CardActionSecondaryButton = tw(SecondaryButton)`my-3 mx-auto`;

const baseSize = 250;
const button = {
  display: 'flex',
  flexDirection: 'column',
};

export default function DsrCard({
  id,
  name,
  domain,
  emailAddress,
  userDetail,
  requestDate,
  statusDate,
  contactEmail,
  alternateContactEmail,
  onSelect,
  updateStatus,
  status,
}) {
  const dpi = window.devicePixelRatio;
  const size = baseSize * dpi;

  const requestedOn = new Date(statusDate).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  const lastUpdatedOn = new Date(requestDate).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const [geterror, seterror] = useState(true);
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('filter')) {
      const isfilter = params.get('filter');
      if (isfilter === 'initiated') {
        var reclaimeddomaincnt = (
          ((state || []).dashboardData || {}).myData || []
        ).reclaimedDomain;
        console.log(state);
        if (reclaimeddomaincnt <= 0) {
        } else if (reclaimeddomaincnt <= 4 && reclaimeddomaincnt > 0) {
          window.scrollTo(500, 500);
        } else {
          window.scrollTo(750, 750);
        }
      }
    }
  });

  const onError = (e) => {
    e.target.style.display = 'none';
    seterror(false);
  };
  const wordWrapStyle = {
    wordWrap: 'break-word',
  };
  // }
  let buttons;
  if (status === 'reclaimed') {
    buttons = (
      <div style={button}>
        <CardActionSecondaryButton onClick={onSelect}>
          View Request
        </CardActionSecondaryButton>
      </div>
    );
  } else if (status === 'failed') {
    buttons = (
      <div style={button} id="DataSubjectButtonFail">
        <CardActionSecondaryButton
          onClick={() => {
            var Name = userDetail.name || '';
            var payload = {
              index: 0,
              id: id,
              name: Name,
              domain: domain,
              emailAddress: emailAddress,
              selectDatu: statusDate,
              lastEmailDate: requestDate,
              contactEmail: contactEmail,
              alternateContactEmail: alternateContactEmail,
            };
            dispatch({
              type: 'CARD_DETAIL_FOR_VIEW_TEMPLATE',
              payload: payload,
            });
            history.push(`/request-deletion?id=${id}`);
          }}
        >
          Retry
        </CardActionSecondaryButton>
        <CardActionSecondaryButton>DSR Failed</CardActionSecondaryButton>
      </div>
    );
  } else {
    buttons = (
      <div style={button} id="DataSubjectButton">
        <CardActionSecondaryButton onClick={onSelect}>
          View Request
        </CardActionSecondaryButton>
        <CardActionSecondaryButton onClick={updateStatus}>
          Mark as Complete
        </CardActionSecondaryButton>
      </div>
    );
  }

  return (
    <Card
      className="group"
      id="DataSubjectCard"
      initial="rest"
      whileHover="hover"
      animate="rest"
    >
      <CardLogo id="DataLogo">
        {geterror ? (
          <img
            src={`//logo.clearbit.com/${domain}?size=${size}`}
            alt=""
            onError={(e) => onError(e)}
          />
        ) : (
          <h1 id="card1">{domain.charAt(0).toUpperCase()}</h1>
        )}
      </CardLogo>
      <CardText id="cardTextWrapper">
        <CardTitle style={wordWrapStyle}>{name}</CardTitle>
        <CardDescription id="DataDomain" style={wordWrapStyle}>
          {domain}
        </CardDescription>
        <CardDescription id="CardDetails">
          <td>
            <tr>
              {' '}
              <b>Requested on</b>{' '}
            </tr>
            <tr>
              {' '}
              <b>Status</b>{' '}
            </tr>
          </td>
          <td>
            <tr>
              <b> &nbsp; :</b>
            </tr>
            <tr>
              <b> &nbsp; :</b>
            </tr>
          </td>
          <td>
            {/* </CardDescription>
        <CardDescription id="CardDetails"> */}
            <tr>{status === 'reclaimed' ? lastUpdatedOn : requestedOn}</tr>
            <tr style={{ textTransform: 'capitalize' }}>
              <Chip
                color="primary"
                size="small"
                label={status === 'reclaimed' ? 'completed' : status}
              />
            </tr>
          </td>
        </CardDescription>
      </CardText>
      <ButtonContainer id="DSRButton">{buttons}</ButtonContainer>
    </Card>
  );
}
