import tw from 'twin.macro';
import styled from 'styled-components';

export const Header = tw.div`text-lg mb-2`;
export const Message = tw.p`mb-5`;
export const Form = tw.form`mx-auto max-w-sm`;
export const InputRow = tw.div`flex items-baseline`;
export const Label = tw.label`w-1/4`;
export const Input = tw.input`w-full px-4 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`; //hover:bg-primary-900
export const SubmitButton = styled.button`
  ${tw`mt-5 bg-[#E25668] tracking-wide font-semibold  text-gray-100 w-full py-4 rounded-lg  transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3 `}
  }
`;
//bg-primary-500
