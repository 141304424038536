import { Auth } from 'aws-amplify';

import googleIconImageSrc from 'images/google-icon.png';
import LoginButton from './LoginButton';

export default function GmailLoginButton({ text }) {
  return (
    <LoginButton
      onClick={(e) => {
        e.preventDefault();
        localStorage.setItem('loginType', 'signup');
        localStorage.setItem('selected_provider', 'Google');
        localStorage.setItem('connect_provider', 'Google');
        Auth.federatedSignIn({ provider: 'Google' });
      }}
      text={text}
      iconImageSrc={googleIconImageSrc}
    />
  );
}
