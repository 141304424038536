import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    const handleClickOpen = () => {
      this.setState({ show: true });
    };

    const handleClose = () => {
      this.setState({ show: false });
    };

    return (
      <div>
        <Button
          variant="outlined"
          id="ppbtn"
          style={{ display: 'none' }}
          color="primary"
          onClick={handleClickOpen}
        ></Button>
        <Dialog
          open={this.state.show}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Session is expired, Page will be refreshed in 5 seconds.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default Popup;
