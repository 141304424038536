import React from 'react';
import { useState } from 'react';

const DomainLogo = (props) => {
  const [isLogo, setIsLogo] = useState(true);

  return (
    <>
      {isLogo ? (
        <img
          className="imgid"
          id={props.data.domainName.trim()}
          alt={props.data.domainName.charAt(0).toUpperCase()}
          src={`//logo.clearbit.com/${props.data.domainName}`}
          width="30px"
          height="30px"
          onError={(e) => {
            if (e.target.onerror === null) {
              setTimeout(() => {
                setIsLogo(false);
              }, 50);
            }
          }}
        />
      ) : (
        <h1 className="imgid" id="custdomainlogo">
          {props.data.domainName.charAt(0).toUpperCase()}
        </h1>
      )}{' '}
    </>
  );
};

export default DomainLogo;
