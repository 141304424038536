import FullPageWrapper from 'components/wrapper/FullPageWrapper';
import FeedbackTypeform from '../../views/FeedbackTypeform';
import { Widget } from '@typeform/embed-react';
import './PopupFeedback.css';
const responsive = {};

export default function PopupFeedback() {
  return (
    <>
      <Widget
        id="YCqGQCD9"
        style={{
          maxWidth: '100%',
          width: '100%',
          height: '100vh',
        }}
        className="my-button"
      />
    </>
  );
}
